import moment from "moment";
import {helpers} from "../../src/helper/helpers";

export const GROUP_TYPE = {
    //0: Nhóm làm việc - 1: Nhóm kín - 2: Nhóm bí mật - 3: Tường công ty - 4: Group
    WORK_GROUP: 0,
    PRIVATE_GROUP: 1,
    SECRET_GROUP: 2,
    COMPANY_WALL_GROUP: 3,
    BRANCH_GROUP: 4,
    TEAM_GROUP: 5,
    PINNED_GROUP: 111,
};

export const POST_TYPE = {
    TEXT: 'TEXT',                   //Bài viết chỉ có text
    IMAGE: 'IMAGE',                 //Bài viết có chứa 1 ảnh
    ALBUM: 'ALBUM',                 //Bài viết chứa nhiều ảnh
    SUB_ALBUM: 'SUB_ALBUM',         //Bài viết con cho từng ảnh trong bài viết ALBUM
    FILE: 'FILE',                   //Bài viết chứa file
    CREATE_GROUP: 'CREATE_GROUP',   //Bài viết khi tạo 1 group
    UPDATE_GROUP_AVATAR: 'UPDATE_GROUP_AVATAR',   //Bài viết đã thay đổi ảnh bìa của nhóm
    VIDEO: 'VIDEO',   //VIDEO
    POLL: 'POLL'
};

export const CREATE_POST_LEVEL = {
    POST: '1',
    COMMENT: '2',
    REPLY_COMMENT: '3',
};

export const MODEL_TYPE = {
    USER: '1',
    GROUP: '2',
};

export const WORK_GROUP_TYPE = 0;
export const COMPANY_WALL_GROUP_TYPE = 3;
export const BRANCH_GROUP_TYPE = 4;
export const PRIVATE_GROUP_TYPE = 1;
export const CLANDESTINE_GROUP_TYPE = 2;
export const PRIVATE_GROUP_TYPE_NAME = 'Nhóm kín';
export const WORK_GROUP_TYPE_NAME = 'Nhóm làm việc';
export const CLANDESTINE_GROUP_TYPE_NAME = 'Nhóm bí mật';
export const COMPANY_WALL_GROUP_TYPE_NAME = 'Tường công ty';
export const BRANCH_GROUP_TYPE_NAME = 'Chi nhánh';
export const TEAM_GROUP_TYPE_NAME = 'Team';
export const TEAM_GROUP_TYPE = 5;

export const NOTIFICATION_TYPE = {
    ADD_MEMBER: 'ADD_MEMBER',
    CREATE_POST: 'CREATE_POST',
    LIKE_POST: 'LIKE_POST',
    COMMENT_POST: 'COMMENT_POST',
};

export const TYPE_OPTIONS = [
    {
        id: 0,
        title: 'Nhóm làm việc',
        description: 'Nhóm làm việc và báo cáo hằng ngày của từng phòng ban chuyên biệt'
    },
    {
        id: 1,
        title: 'Nhóm Kín',
        description: 'Bất kỳ ai trong Công ty cũng có thể tìm được nhóm và xem thành viên nhóm. Chỉ thành viên có thể xem bài viết.'
    },
    {
        id: 2,
        title: 'Nhóm Bí mật',
        description: 'Chỉ thành viên mới tìm được nhóm, xem thành viên và những gì họ đăng.'
    }
];

export const GROUP_CHAT_TYPE = {
    USER: 'USER',
    POSITION: 'POSITION',
    DEPARTMENT: 'DEPARTMENT',
};

//SYSTEM|TEXT|IMAGE|VIDEO|FILE
export const MESSAGE_CHAT_TYPE = {
    SYSTEM: 'SYSTEM',
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    FILE: 'FILE',
};

//   - CREATE_GROUP: tạo nhóm
//   - CHANGE_GROUP_AVATAR: đổi avatar nhóm
//   - CHANGE_GROUP_NAME: đổi tên nhóm
//   - CREATE_USER_CHAT: chat 1-1
export const TEXT_CHAT_SYSTEM = {
    CREATE_GROUP: 'CREATE_GROUP',
    CHANGE_GROUP_AVATAR: 'CHANGE_GROUP_AVATAR',
    CHANGE_GROUP_NAME: 'CHANGE_GROUP_NAME',
    CREATE_USER_CHAT: 'CREATE_USER_CHAT',
    LEAVE_GROUP: 'LEAVE_GROUP',
    REMOVE_USER: 'REMOVE_USER',
    ADD_USER_GROUP: 'ADD_USER_GROUP',
    PIN_MESSAGE: 'PIN_MESSAGE',
    UNPIN_MESSAGE: 'UNPIN_MESSAGE'
};

export const TYPE_ADD_GROUP_MEMBER = {
    CREATE_GROUP: 'CREATE_GROUP',
    UPDATE_GROUP: 'UPDATE_GROUP',
};

export const NOTIFICATION_CHAT = {
    ON: 'ON',
    OFF: 'OFF',
};

export const INVENTORY_TYPE_OPTIONS = {
    SKU: 1,
    USER: 2,
    ORDER: 3,
    KEYWORK: 4,
    NEGATIVE_SKU: 5,
    GOODS: 6,
    STATUS: 7,
};

export const INVENTORY_REPORT_TAB = {
    TOTAL_STOCK: 1,
    AVAILABLE_STOCK: 2,
    MANAGE_STOCK: 3,
    INVENTORY_INFO: 4,
    EXISTING_PRESENT: 5,
    SWITCH_STOCK_ADJ: 6,
};


export const INVENTORY_COUPON_TYPE = {
    NHAP_MUA: 1,  //nhập hàng mới
    NHAP_KHAC: 2,  // nhập điều chỉnh
    DON_DAT_MUA: 4, //đơn đặt hàng
    XUAT_KHAC: 5,  //xuất điều chỉnh
    XUAT_TMDT: 6, //xuất điều chỉnh shoppe
    XUAT_HANG_HONG: 8, //Huỷ hàng
    CHUYEN_KHO: 10,
    HOAN_TMDT: 11,
    NHAP_HOAN: 12,
    XUAT_BAN: 13,
};

export const INVENTORY_IMPORT_TABLE_HEADER = {
    IMPORT_AND_ORDER: [
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "vendor", key: "e", title: "Nhà cung cấp", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {
            field: "receipt_internal_code",
            key: "f",
            title: "Mã đơn hàng nội bộ",
            align: "center",
        },
        {field: "tracking", key: "g", title: "Mã tracking VC2", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
    ],
    OTHER: [
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
    ],
    TRANSFER: [
        {
            field: "",
            key: "r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Xuất tại kho", align: "left"},
        {field: "inventory_sub_name", key: "c_11", title: "Nhập tại kho", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
    ],
    ORDER: [
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "z", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Mã đặt hàng", align: "left"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "receipt_status", key: "e", title: "Trạng thái nhập hàng", align: "left"},
        {
            field: "status",
            key: "f",
            title: "Trạng thái",
            align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                const text = row[column.field] === 1 ? 'Đã hoàn thành' : 'Chưa hoàn thành';
                return (
                    <span class='inventory-status-display text-300-default'>{text}</span>
                );
            },
        },
        {field: "created_by", key: "g", title: "Người tạo", align: "left"},
        {field: "tracking", key: "w", title: "Mã tracking", align: "left"},
        {field: "sku", key: "i", title: "Hành động", align: "center"},
    ],
};

export const INVENTORY_EXPORT_TABLE_HEADER = {
    EXPORT: [
        {field: "stt", key: "z", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "vendor", key: "e", title: "Nhà cung cấp", align: "left"},
        {
            field: "status",
            key: "f",
            title: "Trạng thái",
            align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                const text = row[column.field] === 1 ? 'Đã hoàn thành' : 'Chưa hoàn thành';
                return (
                    <span class='inventory-status-display text-300-default'>{text}</span>
                );
            },
        },
        {field: "created_by", key: "g", title: "Người tạo", align: "left"},
        {field: "sku", key: "h", title: "Mã đặt hàng", align: "left"},
        {field: "sku", key: "i", title: "Hành động", align: "center"},
    ],
    TRANSFER: [
        {
            field: "",
            key: "r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Xuất tại kho", align: "left"},
        {field: "inventory_sub_name", key: "c_11", title: "Nhập tại kho", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
        {
            field: "status_order_item",
            key: "h1",
            title: "Trạng thái",
            align: "left"
        },
        {
            field: "action",
            key: "nxm_ac_1",
            title: "Hành động",
            align: "center",
        },
    ],
    OTHER_SHOPEE_CANCEL: [
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "vendor", key: "e", title: "Nhà cung cấp", align: "left"},
        {field: "created_by", key: "f", title: "Người tạo", align: "left"},
    ],
}

export const INVENTORY_COUPON_HEADER_TABLE = {
    PHIEU_NHAP_XUAT_MOI: [
        {
            field: "",
            key: "r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "vendor", key: "e", title: "Nhà cung cấp", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                let product_price = '-';
                if(rowIndex > 0){
                    product_price = helpers.formatCurrency(row.product_price).replaceAll('.', ',')
                }
                return (
                    <span>{product_price}</span>
                );
            }
        },
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                let product_rate = '-';
                if(rowIndex > 0){
                    product_rate = helpers.formatCurrency(row.product_rate).replaceAll('.', ',')
                }
                return (
                    <span>{product_rate}</span>
                );
            }
        },
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {
            field: "receipt_internal_code",
            key: "f",
            title: "Mã đơn hàng nội bộ",
            align: "center",
        },
        {field: "tracking", key: "g", title: "Mã tracking VC", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
        {
            field: "status_order_item",
            key: "h1",
            title: "Trạng thái",
            align: "left"
        },
        {
            field: "action",
            key: "nxm_ac_1",
            title: "Hành động",
            align: "center",
        },
    ],
    PHIEU_NHAP_XUAT_KHAC: [
        {
            field: "",
            key: "r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
        {
            field: "status_order_item",
            key: "h1",
            title: "Trạng thái",
            align: "left"
        },
        {
            field: "action",
            key: "nxk_ac_1",
            title: "Hành động",
            align: "center",
        },
    ],
    PHIEU_DIEU_CHUYEN: [
        {
            field: "",
            key: "r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Số phiếu", align: "left"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "manager", key: "d_11", title: "Đối tượng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "c", title: "Xuất tại kho", align: "left"},
        {field: "inventory_sub_name", key: "c_11", title: "Nhập tại kho", align: "left"},
        {field: "description", key: "h", title: "Ghi chú", align: "left",},
        {
            field: "status_order_item",
            key: "h1",
            title: "Trạng thái",
            align: "left"
        },
        {
            field: "action",
            key: "dc_ac_1",
            title: "Hành động",
            align: "center",
        },
    ],
    PHIEU_XUAT_HOAN_TMDT: [
        {
            field: "",
            key: "phieu_xuat_hoan_r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "phieu_xuat_hoan_z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "phieu_xuat_hoan_a", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "phieu_xuat_hoan_b", title: "Số phiếu", align: "left"},
        {field: "time", key: "phieu_xuat_hoan_d", title: "Ngày", align: "left"},
        {field: "manager", key: "phieu_xuat_hoan_d_11", title: "Đối tượng", align: "center"},
        {field: "platform", key: "phieu_xuat_hoan_d_12", title: "Nền tảng", align: "center"},
        {field: "product_sku", key: "phieu_xuat_hoan_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "phieu_xuat_hoan_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "phieu_xuat_hoan_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "phieu_xuat_hoan_sp_4", title: "Đơn giá", align: "right",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                let product_price = '-';
                if(rowIndex > 0){
                    product_price = helpers.formatCurrency(row.product_price).replaceAll('.', ',')
                }
                return (
                    <span>{product_price}</span>
                );
            }
        },
        {field: "product_shipping_fee", key: "phieu_xuat_hoan_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "phieu_xuat_hoan_sp_6", title: "Tỉ giá", align: "right",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                let product_rate = '-';
                if(rowIndex > 0){
                    product_rate = helpers.formatCurrency(row.product_rate).replaceAll('.', ',')
                }
                return (
                    <span>{product_rate}</span>
                );
            }
        },
        {field: "product_total_price", key: "phieu_xuat_hoan_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "phieu_xuat_hoan_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "inventory_main_name", key: "phieu_xuat_hoan_c", title: "Kho hàng", align: "left"},
        {field: "description", key: "phieu_xuat_hoan_h", title: "Ghi chú", align: "left",},
        {
            field: "status_order_item",
            key: "h1",
            title: "Trạng thái",
            align: "left"
        },
        {
            field: "action",
            key: "xh_ac_1",
            title: "Hành động",
            align: "center",
        },
    ],
    ORDER: [
        {
            field: "",
            key: "phieu_xuat_hoan_r",
            // type=checkbox
            type: "checkbox",
            title: "",
            width: 50,
            align: "center",
        },
        {
            field: "detail_btn", key: "z", title: "", align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                return (
                    <span class="material-symbols-outlined show-detail">info</span>
                );
            },
        },
        {field: "stt", key: "z", title: "STT", align: "center"},
        {field: "sync_history_id", key: "a1", title: "Mã đồng bộ", align: "left"},
        {field: "code", key: "b", title: "Mã đặt hàng", align: "left"},
        {field: "inventory_main_name", key: "c", title: "Kho hàng", align: "left"},
        {field: "product_sku", key: "order_coupon_sp_1", title: "SKU", align: "left"},
        {field: "product_name", key: "order_coupon_sp_2", title: "Sản phẩm", align: "left"},
        {field: "product_quantity", key: "order_coupon_sp_3", title: "Số lượng", align: "right"},
        {field: "product_price", key: "order_coupon_sp_4", title: "Đơn giá", align: "right"},
        {field: "product_shipping_fee", key: "order_coupon_sp_5", title: "Phí vận chuyển (VND)", align: "right"},
        {field: "product_rate", key: "order_coupon_sp_6", title: "Tỉ giá", align: "right"},
        {field: "product_total_price", key: "order_coupon_sp_7", title: "Tổng tiền (VND)", align: "right"},
        {field: "product_cost_price", key: "order_coupon_sp_8", title: "Giá nhập (VND)", align: "right"},
        {field: "time", key: "d", title: "Ngày", align: "left"},
        {field: "receipt_status", key: "e", title: "Trạng thái nhập hàng", align: "left"},
        {
            field: "status",
            key: "f",
            title: "Trạng thái",
            align: "center",
            renderBodyCell: ({row, column, rowIndex}, h) => {
                const text = row[column.field] === 1 ? 'Đã hoàn thành' : 'Chưa hoàn thành';
                return (
                    <span class='inventory-status-display text-300-default'>{text}</span>
                );
            },
        },
        {field: "created_by", key: "g", title: "Người tạo", align: "left"},
        {field: "tracking", key: "w", title: "Mã tracking", align: "left"},
        {field: "sku", key: "i", title: "Hành động", align: "center"},
    ],
    NHAP_HOAN_XUAT_BAN: [
        {field: "stt", key: "nhxb_1", title: "STT", align: "center"},
        {field: "product_sku", key: "nhxb_2", title: "SKU", align: "left"},
        {field: "product_name", key: "nhxb_c", title: "Sản phẩm", align: "left"},
        {field: "product_variant", key: "nhxb_3", title: "Biến thể", align: "left"},
        {field: "product_iso_code", key: "nhxb_4", title: "ĐVT", align: "left"},
        {field: "quantity", key: "nhxb_5", title: "Số lượng", align: "left"},
    ],
};

export const PRODUCT_LIST = [
    {field: "stt", key: "pl1", title: "STT", align: "center"},
    {field: "good", key: "pl2", title: "Sản phẩm", align: "left"},
    {field: "sku", key: "pl3", title: "SKU", align: "left"},
    {field: "value", key: "d", title: "Biến thể", align: "left"},
    {field: "currency", key: "g", title: "Ngoại tệ", align: "left"},
    {field: "quantity", key: "e", title: "Số lượng", align: "right"},
    {field: "price", key: "h", title: "Đơn giá", align: "right"},
    {field: "fee", key: "i_1", title: "Phí vận chuyển (VND)", align: "right"},
    {field: "rate", key: "i_2", title: "Tỷ giá", align: "right"},
    {field: "total_price", key: "i_3", title: "Thành tiền (VND)", align: "right"},
    {field: "description", key: "i_4", title: "Ghi chú", align: "left"},
]

export const INVENTORY_REPORT_EXPORT_TAB = {
    NHAP_MUA: 1,
    NHAP_KHAC: 2,
    NHAP_DIEU_CHUYEN: 10,
    XUAT_BAN: 4,
    XUAT_KHAC: 5,
    XUAT_SHOPEE: 6,
    XUAT_DIEU_CHUYEN: 10.1,
    XUAT_HANG_HONG: 8,
    KIEM_KE: 9,
};

export const SETTING_TAB = {
    SYNC_HISTORY: 1,
    INVENTORY_INFO: 2,
    SETTING: 3,
    VENDOR: 4
}

export const INVENTORY_ORDER_TAB = {
    ORDER: 1,
}

export const DATE_RANGE_PICKER_CONFIG = {
    "locale": {
        "format": "DD/MM/YYYY",
        "separator": " - ",
        "applyLabel": "Áp dụng",
        "cancelLabel": "Hủy",
        "fromLabel": "Từ",
        "toLabel": "Đến",
        "customRangeLabel": "Tùy chọn",
        "weekLabel": "Tuần",
        "daysOfWeek": [
            "CN",
            "T2",
            "T3",
            "T4",
            "T5",
            "T6",
            "T7"
        ],
        "monthNames": [
            "Tháng 1",
            "Tháng 2",
            "Tháng 3",
            "Tháng 4",
            "Tháng 5",
            "Tháng 6",
            "Tháng 7",
            "Tháng 8",
            "Tháng 9",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12"
        ],
        "firstDay": 1
    },
    "ranges": {
        'Tháng hiện tại': [moment().startOf('month'), moment().endOf('month')],
        'Tháng trước': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        '2 tháng trước': [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
        '3 tháng trước': [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')],
    },
};
export const DATE_PICKER_CONFIG = {
    "locale": {
        "format": "DD/MM/YYYY",
        "separator": " - ",
        "applyLabel": "Áp dụng",
        "cancelLabel": "Hủy",
        "fromLabel": "Từ",
        "toLabel": "Đến",
        "customRangeLabel": "Tùy chọn",
        "weekLabel": "Tuần",
        "daysOfWeek": [
            "CN",
            "T2",
            "T3",
            "T4",
            "T5",
            "T6",
            "T7"
        ],
        "monthNames": [
            "Tháng 1",
            "Tháng 2",
            "Tháng 3",
            "Tháng 4",
            "Tháng 5",
            "Tháng 6",
            "Tháng 7",
            "Tháng 8",
            "Tháng 9",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12"
        ],
        "firstDay": 1,
    },
};


