import BaseService from "./BaseService";

export default class NotificationService extends BaseService {

    static async getUnreadCount() {
        const res = await this.get(`/notifications/unread-count`);
        return res.data;
    }

    static async getNotifications(params = {}) {
        const res = await this.get(`/notifications`, params);
        return res.data;
    }

    static async readNotifications(id) {
        const res = await this.post(`/notifications/`+id+`/read`);
        return res.data;
    }

    static async postFcmToken(params) {
        const res = await this.post(`/notifications/add-token`, params);
        return res.data;
    }

    static async readAllNotification() {
        const res = await this.post(`/notifications/read-all`);
        return res.data;
    }
}

