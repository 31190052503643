<template>
  <div class="container-slide-show">
    <modal name="slide-show-modal"
           :click-to-close="false"
           width="100%"
           height="100%"
           class="select-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed">
      <div class="slides">
        <template v-if="isVideo">
          <video controls style="margin: auto" class="video pb-5px post-img">
            <source :src="items.media_file.path" :data-download-url="items.url" ref="imageSlide">
            Sorry, your browser doesn't support embedded videos.
          </video>
        </template>
        <template v-else v-for="(item, index) in items">
          <div v-if="index == active" class="img-wrapper" :key="index">
            <img class="post-img" :src="item.path || item.media_file.path" alt="aza" 
            :data-download-url="item.url || item.media_file.url" @wheel="zoomImage(1, $event)" ref="imageSlide" @mousedown="dragMouseDown" />
          </div>
        </template>
      </div>
      <div class="activeSlide" v-if="items.length > 1">
        <div class="container-active-slide slide-prev" @click="move(-1)">
        <div class="prev">
          <img src="/img/arrow-left.svg" alt="">
        </div>
      </div>
      <div class="container-active-slide slide-next" @click="move(1)">
        <div class="next">
          <img src="/img/arrow-right.svg" alt="">
        </div>
      </div>
      </div>
      <span class="material-icons close-modal cursor-pointer" @click="$modal.hide('slide-show-modal')">close</span>
      <div class="zoom-wrapper">
        <md-icon v-if="!isVideo" @click.native="zoomImage(1, $event)">zoom_in</md-icon>
        <md-icon v-if="!isVideo" @click.native="zoomImage(-1, $event)">zoom_out</md-icon>
        <md-icon v-if="!isVideo" @click.native="rotateImage">rotate_90_degrees_cw</md-icon>
        <md-icon @click.native="download()">download</md-icon>
      </div>
    </modal>
   </div>
</template>

<script>

export default {
  name: 'slide-show-modal',
  data() {
    return {
      items : [],
      slides: 0,
      active: 0,
      isVideo : false,
      imageSlideConfig: {
        step: 1,
        maxStep : 20,
        rotate : 0
      },
      position: {
        startDrag: false,
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  mounted() {
  },
  methods: {
    beforeOpened(event) {
      this.items = event.params.items;
      this.isVideo = event.params.isVideo && typeof event.params.isVideo !== 'undefined' ? event.params.isVideo : false;
      this.slides = event.params.items.length;

      this.active = event.params.activeId;
    },

    beforeClosed(event){
    },

    move(amount) {
      let newActive
      const newIndex = this.active + amount

      if(newIndex >= this.slides){
        newActive = 0
      } else if(newIndex < 0){
        newActive = this.slides - 1;
      } else {
        newActive = newIndex;
      }

      //reset step zoom in
      this.imageSlideConfig.step = 1;
      //set drag
      this.position.startDrag = false;
      this.imageSlideConfig.rotate = 0;
      this.active = newActive;
    },

    dragMouseDown: function (event) {
      event.preventDefault()

      // start drag when zoomed
      if(!this.position.startDrag){
        return;
      }
      // get the mouse cursor position at startup:
      this.position.clientX = event.clientX
      this.position.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },

    elementDrag: function (event) {
      event.preventDefault()
      this.position.movementX = this.position.clientX - event.clientX
      this.position.movementY = this.position.clientY - event.clientY
      this.position.clientX = event.clientX
      this.position.clientY = event.clientY
      // set the element new position:
      this.$refs.imageSlide[0].style.top = (this.$refs.imageSlide[0].offsetTop - this.position.movementY) + 'px'
      this.$refs.imageSlide[0].style.left = (this.$refs.imageSlide[0].offsetLeft - this.position.movementX) + 'px'
    },

    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    },

    zoomImage(step, event){
    
      //mouse wheel
      if (event.type == 'wheel' && event.deltaY < 0) {
        step = 1;
      } else if (event.type == 'wheel' && event.deltaY > 0) {
        step = -1;
      }

      if((this.imageSlideConfig.step == 1 && step < 1)){
        //reset drag image
        this.$refs.imageSlide[0].style.cursor = 'pointer';
        this.$refs.imageSlide[0].style.top = '0px';
        this.$refs.imageSlide[0].style.left = '0px';
        this.position.startDrag = false;
        return;
      }else{
        this.imageSlideConfig.step = this.imageSlideConfig.step += step;
      }

      // max step
      if (this.imageSlideConfig.step > this.imageSlideConfig.maxStep){
        return;
      }

      this.position.startDrag = true;
      this.$refs.imageSlide[0].style.cursor = 'move';
      this.$refs.imageSlide[0].style.transform = `scale(${this.imageSlideConfig.step}.0)`;
    },

    rotateImage(){
      this.imageSlideConfig.rotate = this.imageSlideConfig.rotate + 90;
      if(this.imageSlideConfig.rotate > 360){
        this.imageSlideConfig.rotate = 90;
      }
      this.$refs.imageSlide[0].style.transform = `rotate(${this.imageSlideConfig.rotate}deg)`;
    },

    download(){
      let url_download = this.isVideo ? this.$refs.imageSlide : this.$refs.imageSlide[0];
      window.open(url_download.getAttribute('data-download-url'));
    }
  },
}

</script>

<style lang="scss">

.container-slide-show {
  .vm--modal {
    background: rgba(0, 0, 0, 0.85);
    border-radius: 0px;
  }
  .prev, .next {
    position: absolute;
    top: 50%;
    left: 22%;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      transform: scale(1.2);
      opacity: 1;
    }

    img {
      width: 30px;
    }
  }

  .close-modal {
    position: absolute;
    top: 0px;
    left: 0px;
    color: white;
    margin: 20px;
    background: #00000078;
    padding: 5px;
    border-radius: 30px;
  }

  .container-active-slide {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    background: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.42);
      cursor: pointer;

      .prev, .next {
        transform: scale(1.2);
        opacity: 1;
      }
    }
  }

  .slide-prev {
    left: 0;
  }

  .slide-next {
    right: 0;
    // margin-left: auto;
    // margin-right: 25px;
    text-indent: 2px;
  }

  .slides {
    height: 100%;
    width: 100%;
    position: relative;
    .animated {
      transition: all 400ms;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    .slide-in {
      opacity: 0;
      transform: translate(-40%, -50%);
    }

    .slide-in-active {
      transition-delay: 150ms;
    }

    .slide-out {
      opacity: 1;
    }

    .slide-out-active {
      opacity: 0;
      transform: translate(-60%, -50%);
    }

    .img-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      transition: transform .1s;
      .post-img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: scale-down;
        position: absolute;
      }
    }
    video{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .zoom-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 20px 40px 0 0;
    border-radius: 30px;

    .md-icon {
      padding: 6px;
      border-radius: 50%;
      color: white;
      font-weight: 300;
      background: rgb(61 61 61 / 30%);
      width: auto;
      height: auto;
      margin: 0 4px;
      cursor: pointer;
      &:hover {
        background: rgb(61 61 61 / 50%);
      }
    }
  }
}
</style>