<template>
  <div>
    <modal name="list-coupon-and-receipt-modal"
           :click-to-close="false"
           width="900px"
           height="auto"
           class="create-group-modal modal-custom list-coupon-and-receipt-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Tìm kiếm nâng cao</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('list-coupon-and-receipt-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div style="width: 100%; height: auto">
          <filter-for-modal @change="handleFilterChange"></filter-for-modal>
          <div class="table">
            <div class="display-flex" v-if="dataTable.length > 0">
              <span class="neutral_1 heading-500 text-header-modal text-nowrap">Kết quả tìm kiếm</span>
              <div class="w_100">
                <div class="float-right">
                  <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                       @change-params="changePagingParam"/>
                </div>
              </div>
            </div>
            <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
            <inventory-table border-y
                             :columns="columns"
                             :table-data="dataTable"/>
          </div>
        </div>
      </md-card-content>
    </modal>
  </div>
</template>

<script>

import FilterForModal from "../../Workplace/Pages/Inventory/FilterForModal";
import InventoryTable from "../../Workplace/Pages/Inventory/Table";
import axios from "axios";
import InventoryService from "../../../store/services/InventoryService";
import PaginationTemplate from "../PaginationTemplate";
import {INVENTORY_COUPON_TYPE} from "../../../const/const";
import moment from "moment";

export default {
  name: 'list-coupon-and-receipt-modal',
  components: {
    FilterForModal,
    InventoryTable,
    PaginationTemplate,
  },
  computed: {
    show() {
      return this.showDialog;
    }
  },
  data() {
    return {
      isLoad: false,
      params: {
        page: 1,
        per_page: 50,
        last_page: 1,
        end_date: '',
        start_date: '',
        type: 1,
        type_time: 3,
        date: new Date().toISOString().slice(0,10),
        keywork: '',
      },
      pagingParams: {
        page: 1,
        perPage: 25,
        lastPage: 1,
        total: 0,
      },
      columns: [
        {
          field: "stt",
          key: "a1",
          title: "STT",
          width: 50,
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
            return ++rowIndex + index;
          },
        },
        {
          field: "code", key: "a2", title: "Mã", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return row.code
          },
        },
        {
          field: "", key: "a3", title: "Kho Hàng", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            if(this.params.type === 2){
              return row.inventory.name
            }else{
              const inventoryMainName = row.inventory_main.name
              const inventorySubName = row.inventory_sub !== null ? ('\nđến ' + row.inventory_sub.name) : '';
              return inventoryMainName + inventorySubName;
            }
          },
        },
        {
          field: "type", key: "a4", title: "Loại Phiếu", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return this.params.type === 2 ? 'Đơn đặt hàng' : this.getTitleCounpon(row.type);
          },
        },
        {
          field: "created_at", key: "a5", title: "Ngày", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            const time = moment(row.created_at).format('DD-MM-YYYY');
            return (
                <span>{time}</span>
            );
          },
        },
        {field: "", key: "a6", title: "Hành Động", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="search-action">
                  <button class="b-purple" on-click={() => this.getProductDetail(row)}>
                    <md-icon class="icon-16px">info</md-icon>
                  </button>
                  <button class="b-green ml-5px" on-click={() => this.updateOrderCoupon(row)}>
                    <md-icon class="icon-16px">edit</md-icon>
                  </button>
                </div>
            );
          },},
      ],
      dataTable: [],
    };
  },
  mounted() {
    // this.getCouponOrReceipt();
  },
  props: {},
  methods: {
    beforeOpened(event) {
      // this.$store.commit("updateModalIsOpen", true);
    },

    beforeClosed(event) {
      // this.$store.commit("updateModalIsOpen", false);
    },

    handleFilterChange(filters) {
      this.params.end_date = filters.end_date
      this.params.start_date = filters.start_date
      this.params.type = filters.type
      this.params.type_time = filters.type_time
      this.params.date = filters.date
      this.params.keywork = filters.keywork
      this.getCouponOrReceipt();
    },

    async getCouponOrReceipt(count = 0) {
      this.isLoad = true;
      this.dataTable = [];
      let params = {...this.params};

      InventoryService.getCouponOrReceipt(params).then(res => {
        if (res) {
          this.dataTable = res.data;
          this.pagingParams.page = res.meta.current_page;
          this.pagingParams.perPage = res.meta.per_page;
          this.pagingParams.lastPage = res.meta.last_page;
          this.pagingParams.total = res.meta.total;
        }
        this.isLoad = false;
      }).catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        count++;
        if (count < 3) {
          setTimeout(() => {
            this.getCouponOrReceipt(count)
          }, 1000);
        }
      });
    },
    changePagingParam(value) {
      this.params.page = value.page;
      this.getCouponOrReceipt();
    },
    getTitleCounpon(type) {
      switch (type) {
        case INVENTORY_COUPON_TYPE.NHAP_MUA:
          return 'Nhập hàng mới'
        case INVENTORY_COUPON_TYPE.NHAP_KHAC:
          return 'Nhập điều chỉnh'
        case INVENTORY_COUPON_TYPE.XUAT_KHAC:
          return 'Xuất điều chỉnh'
        case INVENTORY_COUPON_TYPE.XUAT_TMDT:
          return 'Xuất TMĐT'
        case INVENTORY_COUPON_TYPE.XUAT_HANG_HONG:
          return 'Huỷ hàng'
        case INVENTORY_COUPON_TYPE.CHUYEN_KHO:
          return 'Chuyển kho'
        case INVENTORY_COUPON_TYPE.HOAN_TMDT:
          return 'Hoàn TMĐT'
        case INVENTORY_COUPON_TYPE.NHAP_HOAN:
          return 'Nhập hoàn'
        case INVENTORY_COUPON_TYPE.XUAT_BAN:
          return 'Xuất bán'
        default:
          return 'Khác'
      }
    },
    getProductDetail(row) {
      InventoryService.getOrderCouponDetail({order_coupon_id: row.id}).then(res => {
        if (res && res.data) {
          this.$modal.show('detail-order-coupon-modal', {data: res.data, isOrderCoupon: true});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    },

    updateOrderCoupon(row) {
      InventoryService.getOrderCouponDetail({order_coupon_id: row.id}).then(res => {
        if (res && res.data) {
          this.$modal.show('update-order-coupon-modal', {data: res.data});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    }
  }

}
</script>

<style lang="scss">
.list-coupon-and-receipt-modal {
  .md-dialog-container {
    width: 515px !important;
  }
  .search-action {
    button {
      border: none;
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
      .md-icon {
        font-size: 16px !important;
      }
    }
  }
  .ve-table-content-wrapper {
    height: 50vh;
  }
}

</style>
