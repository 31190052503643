<template>
  <div
      class="wrapper"
      :class="[
      { 'nav-open': $sidebar.showSidebar, 'overflow-x-hidden': modalIsOpen },
    ]"
  >
    <notifications></notifications>
    <side-bar
        :active-color="sidebarBackground"
        :data-background-color="sidebarBackgroundColor"
    >
    </side-bar>
    <div class="main-panel">
      <template v-if="router_name !== 'Group' && router_name !== 'profile'">
        <top-navbar></top-navbar>
      </template>
      <div :class=" { content: router_name !== 'Group' && router_name !== 'profile', 'group-detail__container': router_name === 'Group' || router_name === 'profile'} ">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view/>
        </zoom-center-transition>
      </div>
    </div>
    <create-group-modal></create-group-modal>
    <create-post-modal></create-post-modal>
    <core-value-modal></core-value-modal>
    <branch-detail-modal></branch-detail-modal>
    <v-dialog/>
    <create-category-modal></create-category-modal>
    <user-post-interactive-modal></user-post-interactive-modal>
    <user-message-interactive-modal></user-message-interactive-modal>
    <tooltip />
    <show-comment-image />
    <slide-show-modal />
<!--    <add-user-modal></add-user-modal>-->
    <member-list-modal></member-list-modal>
    <update-company-structure-modal />
    <reaction-message-detail-modal />
    <notification-setting-modal @toggle-notification="toggleNotification"/>
    <add-group-member-modal />
    <request-join-chat-group-modal />
    <inventory-import-file-modal />
    <inventory-by-month-modal />
    <switch-stock-adjustment-modal />

  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import TopNavbar from "./TopNavbar.vue";
import CreateGroupModal from "../Components/Modal/CreateGroupModal";
import CreatePostModal from "../Components/Modal/CreatePostModal";
import CoreValueModal from "../Components/Modal/CoreValueModal";
import BranchDetailModal from "../Components/Modal/BranchDetailModal";
import CreateCategoryModal from "../Components/Modal/CreateCategoryModal";
import UserPostInteractiveModal from "../Components/Modal/UserPostInteractive";
import ShowCommentImage from "../Components/Modal/ShowCommentImage";
import UpdateCompanyStructureModal from "../Components/Modal/UpdateCompanyStructureModal";
import MemberListModal from "../Components/Modal/MemberListModal";
import SlideShowModal from "../Components/Modal/SlideShowModal";
import UserMessageInteractiveModal from "../Components/Modal/UserMessageInteractive.vue";
import NotificationSettingModal from "../Components/Modal/NotificationSettingModal";
import ReactionMessageDetailModal from "../Components/Modal/ReactionMessageDetailModal";
import AddGroupMemberModal from "../Components/Modal/AddGroupMemberModal";
import RequestJoinChatGroupModal from "../Components/Modal/RequestJoinChatGroupModal";
import InventoryImportFileModal from "../Components/Modal/InventoryImportFile";
import InventoryByMonthModal from "../Components/Modal/InventoryByMonth";
import SwitchStockAdjustmentModal from "../Components/Modal/SwitchStockAdjustmentModal";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    // initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

// import ContentFooter from "./ContentFooter.vue";
// import MobileMenu from "./Extra/MobileMenu.vue";
// import UserMenu from "./Extra/UserMenu.vue";

export default {
  components: {
    SwitchStockAdjustmentModal,
    InventoryImportFileModal,
    UserPostInteractiveModal,
    CreateCategoryModal,
    BranchDetailModal,
    CreatePostModal,
    TopNavbar,
    CreateGroupModal,
    CoreValueModal,
    ShowCommentImage,
    UpdateCompanyStructureModal,
    MemberListModal,
    SlideShowModal,
    UserMessageInteractiveModal,
    NotificationSettingModal,
    ReactionMessageDetailModal,
    AddGroupMemberModal,
    RequestJoinChatGroupModal,
    InventoryByMonthModal,
    // ContentFooter,
    // FixedPlugin,
    // MobileMenu,
    // UserMenu,
  },
  data() {
    return {
      sidebarBackgroundColor: "white",
      sidebarBackground: "orange",
      // sidebarBackgroundImage:
      //   process.env.VUE_APP_BASE_URL + "/img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: false,
      image: process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
      router_name: this.$route.name,
      modalIsOpen: this.$store.state.modalIsOpen
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    toggleNotification(e) {
      if (e.postId) {
        let posts = this.$store.state.post.posts;
        for (let i = 0; i < posts.length; i++) {
          if (posts[i].post.id === e.postId) {
            posts[i].post.enable_notification = e.enableNotification;
            break;
          }
        };
        this.$store.commit("updatePost", posts);
      }
    }
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
    '$route'(to, from) {
      this.router_name = to.name
    },
    '$store.state.auth.modalIsOpen': function (newVal, oldVal) {
      this.modalIsOpen = newVal
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

.overflow-x-hidden{
  overflow-x: visible;
}


@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}

//.main-panel {
//  overflow: visible !important;
//}
</style>
