import Vue from 'vue';
import Vuex from 'vuex';


import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import post from "./modules/post";
import category from "./modules/category";
import group from "./modules/group";
import chat from "./modules/chat";
import companyStructure from "./modules/company-structure";
import createPersistedState from "vuex-persistedstate";
import notification from "./modules/notification";
import notificationSetting from "./modules/notification-setting";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    post,
    category,
    group,
    chat,
    companyStructure,
    notification,
    notificationSetting
  },
  plugins: [createPersistedState()]
});


