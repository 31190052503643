<template>
    <img :style="'width: ' + width + '; height: ' + height" alt="" :src="imgUrl">
</template>

<script>
export default {
  name: "ImageTemplate",
  props: {
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "100%"
    },
    size: {
      type: String,
      default: null,
    },
    thumbnail: {
      type: Object,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    imgType: {
      type: String,
      default: 'avatar',
    }
  },
  computed: {
    imgUrl() {
      if (!this.image || !this.thumbnail) {
        if (this.imgType === 'avatar') {
          return '/img/profile/avatar.png';
        } else if (this.imgType === 'group'){
          return '/img/group/group_image_low.jpg';
        }
      }
      switch (this.size) {
        case 'small':
          return this.thumbnail.small_thumb;
        case 'medium':
          return this.thumbnail.medium_thumb;
        default:
          return this.image;
      }
    }
  }
}
</script>

<style lang="scss">
</style>