<template>
  <div class="forgot-password-container">
    <h2>
      Đổi mật khẩu mới
    </h2>
    <div class="mt-20px">
        <span>
          Vui lòng nhập mật khẩu mới cho tài khoản
        </span>
      <div class="form-password">
        <form action="" @submit.prevent="changePassword">
          <div class="input-wrapper">
            <md-icon>lock</md-icon>
            <input :type="newPasswordType ? 'password': 'text'" v-model="newPassword" placeholder="Mật khẩu mới" required>
            <div class="cursor-pointer" @click="newPasswordType = !newPasswordType">
              <md-icon>{{newPasswordType ? 'visibility' : 'visibility_off'}}</md-icon>
            </div>
          </div>
          <div class="input-wrapper">
            <md-icon>lock</md-icon>
            <input :type="confirmPasswordType ? 'password': 'text'" v-model="confirmPassword" placeholder="Xác nhận mật khẩu" required>
            <div class="cursor-pointer" @click="confirmPasswordType = !confirmPasswordType">
              <md-icon>{{confirmPasswordType ? 'visibility' : 'visibility_off'}}</md-icon>
            </div>
          </div>
          <button class="change-pass-btn mt-20px">Xác nhận</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from "../../../../store/services/ProfileService";

export default {
  name: "ForgotPassword",
  data() {
    return {
      newPasswordType: true,
      confirmPasswordType: true,
      newPassword: '',
      confirmPassword: '',
    }
  },
  methods: {
    changePassword() {
      const passRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!this.newPassword.match(passRegex)) {
        this.$store.dispatch("alerts/error", 'Trường Mật khẩu mới có định dạng không hợp lệ. Mật khẩu ít nhất 8 kí tự và phải có chữ hoa, chữ thường, chữ số và ký tự đặc biệt.');
      } else if (this.newPassword !== this.confirmPassword) {
        this.$store.dispatch("alerts/error", 'Trường Xác nhận mật khẩu và Mật khẩu mới phải giống nhau.');
      } else {

      }
    },
  }
}
</script>

<style scoped>

</style>