<template>
  <div class="filter-modal mb-20px">
    <div class="display-flex">
      <div class="mr-15px">
        <div class="title-option">
          <span class="title required">Từ Khoá</span>
        </div>
        <div class="input-option">
          <input class="input-keywork" type="text" placeholder="Nhập mã phiếu, mã đơn hàng"
                 v-model="keyword"/>
        </div>
      </div>
      <div class="mr-15px">
        <div class="title-option">
          <span class="title required">Loại Tìm Kiếm</span>
        </div>
        <multiselect
            label="title"
            track-by="title"
            open-direction="bottom"
            :options="typeOptions"
            :selectLabel="''"
            :deselectLabel="''"
            :selectedLabel="''"
            :class="'multiselect-type'"
            v-model="typeSelected"
        >
          <template slot="option" slot-scope="{ option }">
            <md-list-item>
              <div class="md-list-item-text">
                <span>{{ option.title }}</span>
              </div>
            </md-list-item>
          </template>
        </multiselect>
      </div>
      <div class="mr-15px">
        <div class="title-option">
          <span class="title">Thời Gian</span>
        </div>
        <div class="display-flex">
          <multiselect
              label="title"
              track-by="title"
              open-direction="bottom"
              :options="typeTimeOptions"
              :selectLabel="''"
              :deselectLabel="''"
              :selectedLabel="''"
              :class="'multiselect-type-time'"
              v-model="typeTimeSelected"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.title }}</span>
                </div>
              </md-list-item>
            </template>
          </multiselect>
          <div class="input-option" v-show="typeTimeSelected.id === 1 || typeTimeSelected.id === 2 || typeTimeSelected.id === 3">
            <datetime type="date form-control"
                      format="dd/MM/yyyy" v-model="date">
            </datetime>
          </div>
          <input id="date-range-modal" v-show="typeTimeSelected.id === 4" class="input-date mr-15px" type="text" name="daterange"/>
        </div>
      </div>
    </div>
    <div class="mr-15px mt-10px">
      <md-button :disabled="keyword.length < 3" class="btn-filter text-400-default" @click="onFilter">Tìm Kiếm</md-button>
    </div>
  </div>
</template>

<script>

import {DATE_RANGE_PICKER_CONFIG, INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../const/const";
import moment from "moment";
import {Datetime} from "vue-datetime";


export default {
  name: "filter-for-modal",
  components: {
    // Selection
    datetime: Datetime,
  },
  data() {
    return {
      INVENTORY_TYPE_OPTIONS,
      typeOptions: [
        {id: 1, title: 'Phiếu xuất nhập'},
        {id: 2, title: 'Đơn đặt hàng'}
      ],
      typeTimeOptions: [
        {id: 1, title: 'Bằng'},
        {id: 2, title: 'Lớn hơn'},
        {id: 3, title: 'Nhỏ hơn'},
        {id: 4, title: 'Khoảng'}
      ],
      typeSelected:  {id: 1, title: 'Phiếu xuất nhập'},
      typeTimeSelected: {id: 3, title: 'Nhỏ hơn'},
      keyword: '',
      date: new Date().toISOString(),
    };
  },
  computed: {},
  watch: {
  },
  mounted() {
  },
  created() {
    this.refreshDateFilter();
  },
  methods: {
    //Filter - update url
    emitFilters() {
      let startDate = '';
      let endDate = '';
      let date = '';

      const type = this.typeSelected.id
      const typeTime = this.typeTimeSelected.id

      if (typeTime === 4) {
        let idFilterDate = '#date-range-modal';
        startDate = $(idFilterDate).data('daterangepicker').startDate.format('YYYY-MM-DD');
        endDate = $(idFilterDate).data('daterangepicker').endDate.format('YYYY-MM-DD');
      }

      if (typeTime === 1 || typeTime === 2 || typeTime === 3) {
        date = new Date(this.date).toISOString().slice(0,10);
      }

      let keywork = this.keyword;

      this.$emit('change', {
        start_date: startDate,
        end_date: endDate,
        date: date,
        type: type,
        type_time: typeTime,
        keywork: keywork,
      });
    },
    onFilter() {
      this.emitFilters();
    },

    refreshDateFilter() {
      let dateRangePickerConfig = {...DATE_RANGE_PICKER_CONFIG};
      $(function () {
        let today = moment().format('DD/MM/YYYY')
        let firstDateOfMonth = moment().startOf('month').format('DD/MM/YYYY');
        dateRangePickerConfig.startDate = firstDateOfMonth;
        dateRangePickerConfig.endDate = today;
        $('input[name="daterange"]').daterangepicker(dateRangePickerConfig);
      });
    },
  }
}
</script>

<style lang="scss">
.filter-modal {
  text-align: start;

  .multiselect {
    .multiselect__tags {
      border-color: #D8DAE5;
      min-height: auto;
    }

    .multiselect__single {
      font-size: 14px;
    }
  }

  .multiselect-type {
    width: 180px;
    .multiselect__tags {
      border-radius: 4px !important;
    }
  }

  .multiselect-type-time {
    width: 120px;
    .multiselect__tags {
      border-radius: 4px 0 0 4px !important;
    }
  }

  .filter-selected {
    .item-select {
      padding: 4px 6px;
      margin-right: 6px;
      background: #EDEFF5;
      border: 1px solid #D8DAE5;
      border-radius: 4px;

      .close-icon {
        width: auto;
        margin-right: 2px;

        .md-icon {
          font-size: 14px !important;
          font-weight: 700;
        }
      }
    }
  }


  .input-date {
    height: 39px;
    width: 180px;
    border: 1px solid #D8DAE5;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
  }

  .input-keywork {
    width: 220px;
    height: 38px;
    border: 1px solid #D8DAE5;
    font-size: 14px;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .disable {
    background: #D8DAE5 !important;
  }

  .vdatetime-input {
    min-height: 38px;
    padding: 0px;
    text-align: center;
    border-radius: 0 4px 4px 0;
    border-left: none;
    font-size: 14px;
  }

  .opacity-0{
    opacity: 0;
  }
}
</style>