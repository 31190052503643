<template>
  <div>
    <modal name="update-company-structure-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Cập nhật</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('update-company-structure-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div class="md-gutter">
            <label class="required heading-400 neutral_3">Tên {{pageType == 'DEPARTMENT' ? 'phòng ban' : 'chức vụ'}}</label>
            <input type="text" class="form-control input-custom" v-model="name" required name="name">
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button type="submit" class="btn-active md-button" @click="save()">Cập nhật</button>
          <button class="btn-stroke md-button" @click="$modal.hide('update-company-structure-modal')">Huỷ</button>
        </md-dialog-actions>
    </modal>
  </div>
</template>

<script>

import SettingService from "../../../store/services/SettingService";

export default {
  name: 'update-company-structure-modal',
  data: () => ({
    pageType: "",
    name: '',
    id: ''
  }),
  methods: {
    save() {
      if (this.pageType == 'POSITION') {
        this.updatePosition();
      }
    },

    beforeOpened(event) {
      this.pageType = event.params.pageType;
      this.name = event.params.name;
      this.id = event.params.id;
      this.$store.commit("updateModalIsOpen", true);
    },

    beforeClosed(event){
      this.$store.commit("updateModalIsOpen", false);
    },
    updateDepartment() {

    },
    updatePosition() {
      SettingService.updatePosition(this.id, {name: this.name}).then(res => {
        if (res) {
          this.$store.dispatch("getPositionList");
          this.$modal.hide('update-company-structure-modal')
          this.$store.dispatch("alerts/success", 'Bạn đã cập nhật thành công.');
        }
      });
    }

  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}
.multiselect__element .multiselect__option{
  padding: 0px !important;
}
</style>
