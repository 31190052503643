<template>
  <div>
    <modal name="inventory-by-month-modal"
           :click-to-close="false"
           width='500px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <form class="" @submit.prevent="save()">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Chốt dữ liệu kho</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('inventory-by-month-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content class="import-coupon-modal" style="padding-top: 25px;padding-bottom: 45px;">
          <div style="margin-left: auto;">
            <label class="required heading-400 neutral_3">Thời gian:</label>
              <div class="display-flex">
                <input name="startDate" id="date-picker1" ref="datepicker" class="input-date mr-15px" autocomplete="off"/>

                <!--                <multiselect-->
<!--                    open-direction="bottom"-->
<!--                    :options="monthOptions"-->
<!--                    :selectLabel="''"-->
<!--                    :deselectLabel="''"-->
<!--                    :selectedLabel="''"-->
<!--                    :class="'multiselect-left'"-->
<!--                    v-model="monthSelected"-->
<!--                    placeholder="Chọn tháng"-->
<!--                >-->
<!--                  <template slot="option" slot-scope="{ option }">-->
<!--                    <md-list-item>-->
<!--                      <div class="md-list-item-text">-->
<!--                        <span>{{ option }}</span>-->
<!--                      </div>-->
<!--                    </md-list-item>-->
<!--                  </template>-->
<!--                </multiselect>-->
<!--                <multiselect-->
<!--                    open-direction="bottom"-->
<!--                    :options="yearOptions"-->
<!--                    :selectLabel="''"-->
<!--                    :deselectLabel="''"-->
<!--                    :selectedLabel="''"-->
<!--                    :class="'multiselect-left'"-->
<!--                    v-model="yearSelected"-->
<!--                    placeholder="Chọn năm"-->
<!--                >-->
<!--                  <template slot="option" slot-scope="{ option }">-->
<!--                    <md-list-item>-->
<!--                      <div class="md-list-item-text">-->
<!--                        <span>{{ option }}</span>-->
<!--                      </div>-->
<!--                    </md-list-item>-->
<!--                  </template>-->
<!--                </multiselect>-->

              </div>
            </div>
            <div class="mt-10px treeselect-container">
              <treeselect 
              v-model="inventory_ids" 
              :multiple="true" 
              :options="inventories"
              :always-open="true"
              @input="handleInput"
              search-nested
             />
            </div>
        </md-card-content>
        <md-dialog-actions>
          <button type="submit" class="btn-active md-button">Đồng bộ</button>
          <button class="btn-stroke md-button" @click="$modal.hide('inventory-by-month-modal')">Huỷ</button>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>
import PostService from "../../../store/services/PostService";
import EmployeeService from "../../../store/services/EmployeeService";
import GroupService from "../../../store/services/GroupService";
import CategoryService from "../../../store/services/CategoryService";
import {INVENTORY_COUPON_TYPE, INVENTORY_REPORT_TAB} from "../../../const/const";
import InventoryService from "../../../store/services/InventoryService";
import {helpers} from "../../../helper/helpers";
import moment from "moment";

export default {
  name: 'inventory-by-month-modal',
  computed: {},
  created() {
    $(function () {
      $('#date-picker1').datepicker({
        format: "mm-yyyy",
        startView: "months",
        minViewMode: "months",
        language: 'vi'
      });
      $("#date-picker1").datepicker("setDate", new Date());
    });
  },
  data() {
    return {
      show: false,
      inventories: [],
      options: [ 
        {
          id: 'a',
          label: 'a',
          children: [ {
              id: 'aa',
              label: 'aa',
            }, 
            {
              id: 'ab',
              label: 'ab',
            } 
          ],
        },
         {
          id: 'b',
          label: 'b',
        }, {
          id: 'c',
          label: 'c',
        } ],
        inventory_ids: []
      // monthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      // monthSelected: (moment().month() + 1),
      // yearOptions: [moment().year(), (moment().year() - 1), (moment().year() - 2), (moment().year() - 3), (moment().year() - 4)],
      // yearSelected: moment().year(),
    }
  },
  methods: {
    cacheOpeningStoke() {
      this.show = true;

      let dateMonthYear = $('#date-picker1').data('datepicker').date.toISOString();
      let daySelected = '01';
      let monthSelected = moment(dateMonthYear).format('MM');
      let yearSelected = moment(dateMonthYear).format('yyyy');

      let params = {
        date: yearSelected + '-' + monthSelected + '-' + daySelected,
        inventory_ids: this.inventory_ids
      };

      InventoryService.cacheOpeningStoke(params).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Chốt dữ liệu kho thành công.');
        } else {
          this.$store.dispatch("alerts/error", 'Chốt dữ liệu kho thành công. Nguyên nhân: ' + res.message);
        }
        this.show = false;
      });
    },

    save() {
      this.show = true;
      this.cacheOpeningStoke();
    },
    beforeOpened(event) {
      $(function () {
        $('#date-picker1').datepicker({
          format: "mm-yyyy",
          startView: "months",
          minViewMode: "months",
          language: 'vi'
        });
        $("#date-picker1").datepicker("setDate", new Date());
      });
      this.getInventories();
    },

    beforeClosed(event) {

    },

    getInventories() {
      const inventories = JSON.parse(localStorage.getItem("inventories"));
      let data = inventories.map(parent => ({
        id: parent.id,
        label: parent.name,
        children: parent.children.filter(subMenu => !!subMenu).map(subMenu => ({
          id: subMenu.id,
          label: subMenu.name
        }))
      }));
      
      data.unshift({
        "id": 0,
        "label": "Chọn tất cả"
      });
      this.inventories = data;
    },

    selectAll(){
      this.inventories.forEach((item, index) => {
        if(!this.inventory_ids.includes(item.id)){
          this.inventory_ids.push(item.id);
        }
      });
    },

    handleInput(value){
      if(value.length == 1 && value[0] == 0){
        this.selectAll();
      }
      this.inventory_ids = value.filter(v => v !== 0);
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 300px !important;
  }
}

.import-coupon-modal * {
  font-size: 14px;
}

.btn-dowload {
  flex-direction: row;
  justify-content: center;
  padding: 8px 15px;
  //width: 94px;
  //height: 36px;
  background: #4D77EC !important;
  border-radius: 4px;
  margin: 0px;
  margin-left: 15px;
}
.treeselect-container{
  .vue-treeselect__menu{
    max-height: 210px !important;
  }
}
.vue-treeselect__menu-container, .vue-treeselect__menu{
  position: unset !important;
}
</style>
