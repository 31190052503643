<template>
  <div class="edit-image-page__container">
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <div class="poll-header">
            <span class="neutral_1 heading-500 text-header-modal">Gắn thẻ đồng nghiệp</span>
          </div>
        </md-dialog-title>
      </md-card>

    <div class="h_100 w_100 tag-user-container">
      <div class="cs-search-container">
        <div class="input-container mt-15px">
          <md-icon>search</md-icon>
          <input class="input-search-field" type="text" placeholder="Tìm kiếm đồng nghiệp" name="search-group" v-model="memberParams.keyword" @keyup="getEmployees(true)" >
        </div>
      </div>
      <div class="input-auto-complete" v-if="tagUserList.length > 0">
        <div class="item-select" v-for="(item, index) in tagUserList" :key="item.id">
          <span>{{ item.fullname }}</span>
          <span class="close-icon" @click="removeTagUserItem(index)">x</span>
        </div>
      </div>
      <div class="p-15px" style="height: 350px; overflow-y: scroll;">
        <vue-element-loading :active="isLoadingMember" spinner="bar-fade-scale"/>
        <div v-for="(item, index) in memberOptions" :key="item.id">
          <div class="member-option" :style="index == 0 ? 'border-top: none' : ''" v-if="!tagUserIdList.includes(item.id)" @click="tagUserList.push(item)">
            <div style="width: 90%">
              <md-list-item class="cursor-pointer">
                <md-avatar>
                  <image-template :size="'small'" :image="item.avatar_url" :thumbnail="item.thumbnail"/>
                </md-avatar>
                <div class="md-list-item-text">
                  <span>{{ item.fullname }}</span>
                  <p>{{ item.team ? item.team.name : 'AZA Group' }}</p>
                </div>
              </md-list-item>
            </div>
          </div>
        </div>
        <div v-observe-visibility="reachedEndOfListForMembers" v-if="hasNextPageForMembers" />
      </div>
      <div class="tag-form-button-wrapper mt-20px mb-20px">
        <button class="mr-10px text-400-medium" @click="$emit('cancel-tag-user')">Hủy bỏ</button>
        <button class="text-400-medium" @click="$emit('confirm-tag-user', tagUserList)">Xong</button>
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeService from "../../../store/services/EmployeeService";
import ImageTemplate from "../../../components/ImageTemplate";

export default {
  components: {
    ImageTemplate
  },
  data() {
    return {
      tagUserList: [],
      oldTagUserItem: [],
      memberOptions: [],
      memberParams: {
        keyword: '',
        page: 1,
        last_page: 1,
        status: 1
      },
      isLoadingMember: false,
    }
  },
  props: {
    tagUserItems: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Object,
      default: null,
    }
  },
  computed: {
    hasNextPageForMembers() {
      return this.memberParams.page < this.memberParams.last_page;
    },
    tagUserIdList() {
      return this.tagUserList.map(item => {return item.id});
    }
  },
  created() {
    this.getEmployees(false);
  },
  mounted() {
    this.tagUserList = [].concat(this.tagUserItems);
  },
  watch: {
    'tagUserItems'(newV) {
      this.tagUserList = [].concat(newV);
    },
  },
  methods: {
    async getEmployees(isSearch = false) {
      this.isLoadingMember = true;
      if (this.timer1) {
        clearTimeout(this.timer1);
        this.timer1 = null;
      }
      this.timer1 = setTimeout(() => {
        if (isSearch) {
          this.memberParams.page = 1;
          this.memberOptions = [];
        }
        let params = {...this.memberParams};
        if (this.group) {
          params.groupId = this.group.id;
        }
        EmployeeService.getEmployees(params).then(res => {
          this.isLoadingMember = false;
          if (res) {
            this.memberOptions = this.memberOptions.concat(res.data);
            this.memberParams.last_page = res.meta.last_page;
          }
        });
      }, 800);
    },
    reachedEndOfListForMembers(reached) {
      if (reached) {
        this.memberParams.page++;
        this.getEmployees();
      }
    },
    confirmTagUser() {
      this.$emit('confirm-tag-user', );
    },
    removeTagUserItem(index) {
      this.tagUserList.splice(index, 1);
    },
  },

}
</script>

<style lang="scss">
.edit-image-page__container {
  .done-btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    color: #fff;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.6;
    padding: 0.3rem 0.65rem;
    background-color: #375464;
  }
  .header-custom {
    align-items: center;
    justify-content: space-between;
  }
  .list-edit-media__main {
    max-height: 748px;
    overflow-y: auto;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .media-item {
      padding: 0px 5px 20px 5px;
      width: 50%;
      position: relative;
      .bg-thumb {
        border-radius: 8px;
        background-size: auto 100%;
        background-position: center center;
        background-repeat: no-repeat;
        height: 280px;
        border: 1px solid #dedfe2;
      }
      .btn-remove-media {
        position: absolute;
        top: 20px;
        right: 25px;
        background: transparent;
        border: 0;
      }
    }
  }
}
</style>