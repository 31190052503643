<template>
  <div>
    <div class="post-has-background-input" v-html="innerValue"
         contenteditable="true" @input="$emit('input', $event.target.textContent)"></div>
  </div>
</template>

<script>
export default {
  name: "DivEditable",

  data() {
    return {
      innerValue: this.value,
    }
  },

  props: {
    value: String,
  },
}
</script>

<style scoped>

</style>