<template>
  <div
      class="sidebar sidebar-custom"
      :data-color="activeColor"
      :data-background-color="backgroundColor"
  >
    <div class="sidebar-wrapper custom-scroll-bar-2" ref="sidebarScrollArea">
      <md-list class="nav h_100">
        <inventory-side-bar />
      </md-list>
      <div class="sidebar-footer" v-popover:avatars.right>
        <div class="user-item">
          <div class="d-flex-center">
            <img :src="user.avatar_url || '/img/ic_user_default.svg'" alt="">
            <div class="ml-10px">
              <div class="text-400-semibold">
                {{user.fullname}}
              </div>
              <div class="text-300-default">{{user.email}}</div>
            </div>
          </div>
          <md-icon class="neutral_2">chevron_right</md-icon>
        </div>
      </div>
      <popover name="avatars">
        <md-list class="p-0px popover-avatar">
          <md-list-item v-on:click="$store.dispatch('logout')">
            <md-icon>logout</md-icon>
            <span class="md-list-item-text">Đăng xuất</span>
          </md-list-item>
        </md-list>
      </popover>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import AuthService from "../../../store/services/AuthService";
import NotificationService from "../../../store/services/NotificationService";
import {GROUP_CHAT_TYPE} from "../../../const/const";
import InventorySideBar from "./InventorySideBar";

export default {
  name: "sidebar",

  components: {InventorySideBar},
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    }
  },
  watch: {
    '$route'(to, from) {
      this.router_name = to.name
      this.tab = to.meta.tab
    },
    '$store.state.chat.unCountChatRead'(newV) {
      this.unCountChatRead = newV;
    },
    '$store.state.auth.user'(newV) {
      this.user = newV;
    },
    '$store.state.chat.dataSocket'(newV) {
      if(this.$route.name != 'messenger' || this.$route.params.messenger_id != newV.group.id){
        if(newV.sender.id != this.user.id){
          this.$store.dispatch("alerts/chatNoti", newV);
        }
      }
      if(newV.sender.id != this.user.id){
        this.$store.dispatch('chat/getUnreadGroupChat');
      }
      this.updateTitlePage(newV)
    },
    '$store.state.chat.dataReactionEmoji'(newV) {
      if(newV.sender.id == this.user.id && newV.sender.id !== newV.user_reacted.id){
        newV.scroll_to_id = true;
        this.$store.dispatch("alerts/chatNotiReaction", newV);
      }
    },
  },
  props: {
    activeColor: {
      type: String,
      default: "green",
    },
    backgroundImage: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$sidebar.toggleMinimize();
    this.tab = this.$route.meta.tab;
  },
  mounted() {
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      tab: 'workplace',
      unCountRead: 0,
      unCountChatRead: 0,
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    changeMenu(type) {
      this.tab = type;
    },

    async logout() {
      const fcmToken = localStorage.getItem("cmf_token");
      Cookies.remove('access_token');
      Cookies.remove('user_id');
      await this.deleteAllCookies;
      await AuthService.logout({fcm_token: fcmToken})
      window.location.reload();
    },

    deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },

    redirectToProfile() {
      this.$router.push('/profile/' + this.user.id);
      document.getElementById('mySettingBtn').click();
    },

    getUnreadGroupChat() {
      this.$store.dispatch('chat/getUnreadGroupChat');
    },

    getUnreadCount() {
      NotificationService.getUnreadCount().then(res => {
        if (res.data) {
          this.unCountRead = res.data.count;
          this.$store.commit("updateUnCountRead", this.unCountRead)
        }
      });
    },
    updateTitlePage (data) {
      let title = 'Aza Social';

      if (data.group.type === GROUP_CHAT_TYPE.USER){
        title = data.sender.fullname + ' đã nhắn tin cho bạn';
      }else{
        title = data.sender.fullname + ' đã nhắn tin vào ' + data.group.name;
      }

      document.title = title;
    },
  },

  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.title-sidebar {
  font-weight: 600 !important;
  font-size: 23px;
  margin-bottom: 2px !important;
}

.subtitle-sidebar {
  margin: 22px 0 12px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 6px 6px;
  border-top: 1px solid #e5e5e5;
  background: white;
  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 6px 10px;
    &:hover {
      cursor: pointer;
      background-color: #ffc666;
    }
    img {
      width: 36px;
      height: 36px !important;
      object-fit: cover;
      border: 1px solid #f0f0f0;
    }
    .md-icon {
      margin: initial!important;
    }
  }
  .popover-avatar {
    .md-ripple {
      padding: 6px 15px;
      border-radius: 3px;
    }
  }
}

</style>
