<template>
  <div>
    <modal name="reaction-message-detail-modal"
           :click-to-close="false"
           width="500px"
           height="auto"
           class="create-group-modal modal-custom reaction-message-detail-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Cảm xúc về tin nhắn</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('reaction-message-detail-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="post-layout">
        <div style="overflow: auto;height: 500px;" class="post-footer">
          <vue-element-loading :active="loading" />
          <!-- <vue-loadmore
              :on-loadmore="onLoadUserAction"
              :finished="finished"
              loading-text="Đang tải"
              finished-text="Hết"> -->
              <div class="cs-tab-menu">
                <div class="text-400-medium cursor-pointer" :class="tabActive == index ? 'active' : ''"  @click="chosenTab(index)" v-for="(count , index) in items.like_count" v-if="count > 0" :key="index">
                  <div v-if="index == 'all'">
                    <span>Tất cả</span>
                  </div>
                  <div v-else>
                    <span class="reaction-type mr-2px" :class="`reaction-${index}`"></span>
                    <span>({{ count }})</span>
                  </div>
                </div>
              </div>
              <md-list-item v-for="reaction in items.reactions" :key="reaction.id" class="cursor-pointer">
                <md-avatar>
                  <img class="avatar" :src="reaction.thumbnail.small_thumb || '/img/profile/avatar.png'">
                </md-avatar>
                <div class="md-list-item-text">
                  <span class="text-400-semibold ">{{ reaction.fullname }}</span>
                </div>
                <template>
                  <div class="reaction-icon " :class="`reaction-${reaction.like_type}`"></div>
                </template>
              </md-list-item>

          <!-- </vue-loadmore> -->
        </div>
      </md-card-content>
    </modal>
  </div>
</template>

<script>

import ChatService from "../../../store/services/ChatService";
export default {
  name: 'reaction-message-detail-modal',
  data() {
    return {
      items : [],
      listAction : [],
      loading : false,
      tabActive: 'all'
    }
  },
  methods: {
    beforeOpened(event) {
      // this.message_id = event.params.message_id;
      // this.$store.commit("updateModalIsOpen", true);
      this.getListReaction(event.params.message_id);
      this.tabActive = 'all';
    },

    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },

    async getListReaction(message_id){
      this.loading = true;
      await ChatService.getListReaction({chat_message_id : message_id}).then(res => {
        if (res.status) {
          this.items = res.data;
          this.listAction = res.data.reactions;
          this.loading = false;
        }
      });
      
      
    },

    chosenTab(type = ''){
     
      this.items.reactions = this.listAction;
      if(type !== 'all'){
        let data = this.items.reactions.filter(function(item){
          return item.like_type == type;
        });
        this.items.reactions = data;
      }

      this.tabActive = type;
    }
  }

}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    max-width: 515px !important;
  }
}
.reaction-message-detail-modal{
  .cs-tab-menu{
    justify-content: flex-start;
    grid-gap: 10px;
    div{
      width: unset;
      padding: 2px 4px;
    }
  }
  .md-list-item-content{
    padding: 6px 0 0 !important;
  }
  .user-info-wrapper {
    width: 280px;
    height: 160px;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid;
    .user-info-container {
      display: flex;
      width: 100%;
    }
    .md-avatar {
      width: 100px !important;
      height: 100px !important;
    }
    .info-text {
      width: 70%;
    }
    .info-image {
      width: 30%;
      img {
        border-radius: 50%;
      }
    }
  }

  .reaction-icon{
    width: 30px;
    height: 30px;
  }

  .follow-button {
    width: 195px;
    height: 35px;
    background-color: #EDFAD0;
    border: none;
    border-radius: 4px;
  }

  .reaction-type {
    width: 15px;
    height: 15px;
  }
}
</style>
