// import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import ContentLayout from "@/pages/Layout/ContentLayout.vue";

// Components pages
//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

//Auth
import Login from "@/pages/Dashboard/Pages/Login.vue";

//Other
import NoLoginLayout from "../pages/Layout/NoLoginLayout";
import ForgotPassword from "../pages/Workplace/Pages/NoLogin/ForgotPassword";
import ChangePassword from "../pages/Workplace/Pages/NoLogin/ChangePassword";
import ErrorPage from "../pages/Workplace/Pages/ErrorPage";

//Inventory
import Home from "../pages/Workplace/Pages/Inventory/Home";
import HomeAmin from "../pages/Workplace/Pages/Inventory/HomeAdmin";
import InventoryReportContent from "../pages/Workplace/Pages/Inventory/Index";



let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {middleware: guest}
    },
  ]
};

let workplacePages = {
    path: "/",
    component: ContentLayout,
    meta: {middleware: auth},
    children: [
        {
            path: "inventory",
            name: "Inventory",
            component: Home,
            meta: {middleware: auth, tab: 'inventory'},
        },
        {
            path: "inventory/:inventory_id/:tab",
            name: "inventory_detail",
            components: {default: InventoryReportContent},
            meta: {middleware: auth, tab: 'inventory', is_parent: false},
        },
        {
            path: "inventory/parent/:inventory_id/:tab",
            name: "inventory_detail",
            components: {default: InventoryReportContent},
            meta: {middleware: auth, tab: 'inventory', is_parent: true},
        },
    ]
};

let noLoginPages = {
    path: "/",
    component: NoLoginLayout,
    children: [
        {
            path: "forgot-password",
            name: "Forgot password",
            components: {default: ForgotPassword},
        },
        {
            path: "change-password",
            name: "Change password",
            components: {default: ChangePassword},
        },
    ]
};

let errorPage = {
    path: "/error",
    name: "Error page",
    component: ErrorPage,
};

const routes = [
    {
        path: "/",
        redirect: "/inventory",
        name: "Inventory",
    },
    authPages,
    workplacePages,
    noLoginPages,
    errorPage
];

export default routes;
