<template>
  <div>
    <!--    content-->
    <div class="w_75 mt-15px">
      <div class="text-400-bold">Ngày chốt kho</div>
      <div class="text-400-default mt-5px">Tất các các đơn hàng có ngày xuất bán nhập hoàn trước ngày này sẽ không
        được phép đồng bộ lại dữ liệu về xuất bán nhập hoàn, hoặc nếu đơn chưa
        có ngày xuất bán nhập hoàn chỉ có thể đồng bộ ngày xuất bán, nhập
        hoàn sau ngày này để không ảnh hưởng tới số liệu kho hàng
      </div>
      <div class="mt-10px">
        <input class="input-date mr-15px" id="inventory_last_opening_date" type="text" name="daterange"/>
        <md-button class="btn-filter text-400-default" @click="updateSetting()">
          <md-icon class="mr-5px">save</md-icon>
          <span>Lưu</span>
        </md-button>
      </div>
    </div>

  </div>
</template>

<script>
import {
  DATE_PICKER_CONFIG,
  DATE_RANGE_PICKER_CONFIG,
  INVENTORY_COUPON_TYPE,
  INVENTORY_TYPE_OPTIONS,
  SETTING_TAB
} from "../../../../../const/const";
import moment from "moment";
import InventoryService from "@/store/services/InventoryService";
// import $ from "jquery"


export default {
  name: 'Settings',
  data() {
    return {
      SETTING_TAB,
      tabActive: SETTING_TAB.SETTING,
      inventory_last_opening_date: moment().format('DD/MM/YYYY')
    };
  },
  created() {
    const dateToday = moment().format('DD/MM/YYYY')
    this.refreshDateFilter(dateToday);
    this.getSettings();
  },
  // mounted() {
  //   this.getReportTotalStockByMonth();
  // },
  watch: {},
  methods: {
    getSettings() {
      InventoryService.getSettings({}).then(res => {
        if (res && res.value) {
          this.inventory_last_opening_date = moment(res.value).format('DD/MM/YYYY')
          this.refreshDateFilter(this.inventory_last_opening_date);
        }
      });
    },

    updateSetting() {
      this.inventory_last_opening_date = $('#inventory_last_opening_date').data('daterangepicker').startDate.format('YYYY-MM-DD');
      let data = {
        'inventory_last_opening_date': this.inventory_last_opening_date
      }

      InventoryService.updateSettings(data).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Cập nhật cấu hình thành công.');
        } else {
          this.$store.dispatch("alerts/error", 'Cập nhật cấu hình không thành công. Nguyên nhân: ' + res.message);
        }
      });
    },
    refreshDateFilter(date) {
      let dateRangePickerConfig = {...DATE_PICKER_CONFIG};
      $(function () {
        dateRangePickerConfig.startDate = date;
        dateRangePickerConfig.singleDatePicker = true;
        $('#inventory_last_opening_date').daterangepicker(dateRangePickerConfig);
      });
    },

  }
  ,
}
</script>

<style lang="scss">
.btn-filter {
  flex-direction: row;
  justify-content: center;
  padding: 0 10px;
  height: 36px;
  background: #4D77EC !important;
  border-radius: 4px;
  margin: 0px;
}
.input-date {
  height: 36px;
  padding: 0 15px;
  border: 1px solid #D8DAE5;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
}
</style>