<template>
  <div class="poll-container mt-15px">
    <div class="poll-content-wrapper">
      <div v-if="isCreatePostModal" style="text-align: right">
        <span @click="$emit('update-poll')"><md-icon class="cursor-pointer">edit</md-icon></span>
        <span @click="$emit('remove-poll')" class="ml-10px"><md-icon class="cursor-pointer">cancel</md-icon></span>
      </div>
      <div class="heading-600">{{ pollData.question }}</div>
      <div class="text-400-default mt-10px" v-if="isExpiredTime">
        <md-icon>update</md-icon>
        <span class="ml-5px">Hết hạn bình chọn</span>
      </div>
      <div class="mt-15px">
        <div class="poll-option mt-10px" v-for="(option, index) in pollOptions" :key="option + index"
             :style="'background:linear-gradient(to right, ' + (option.isChecked ?
             'rgba(86, 202, 118, 0.16) ' : 'rgb(241, 242, 244) ') + (totalVote == 0 ? 0 : ((option.vote_ids.length / totalVote) * 100)) + '%, white '+ (totalVote == 0 ? 0 : ((option.vote_ids.length / totalVote) * 100)) + '%)'">
          <div class="d-flex">
            <md-checkbox v-if="!isExpiredTime && !isCreatePostModal && !isUpdatePostModal" class="md-success" @change="vote(index)" v-model="option.isChecked"></md-checkbox>
            <span class="ml-5px">{{option.answer}}</span>
          </div>
          <div>{{option.vote_ids.length}} bình chọn</div>
        </div>
        <md-input v-if="pollData.is_add_option && !isExpiredTime && !isCreatePostModal && !isUpdatePostModal" class="option-new mt-10px" placeholder="Nhập câu trả lời..." v-model="answerNew" @keyup.enter="addOption">
        </md-input>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import PostService from "../../../../store/services/PostService";

export default {
  name: "poll-content",
  data() {
    return {
      pollOptions: [],
      answerNew: '',
    }
  },
  props: {
    pollData: {
      type: Object,
      default: () => {
      },
    },
    isCreatePostModal: {
      type: Boolean,
      default: false
    },
    isUpdatePostModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalVote() {
      let total = 0;
      for (let i = 0; i < this.pollOptions.length; i++) {
        total += this.pollOptions[i].vote_ids.length;
      }
      return total;
    },
    isExpiredTime() {
      if (this.pollData.expired_time != null && this.pollData.expired_time != '') {
        let expiredTimeString = moment(this.pollData.expired_time, 'yyyy-MM-DD HH:mm:ss');
        let currentDate = new Date();
        let expiredTime = new Date(expiredTimeString);
        if (expiredTime < currentDate) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    let currentUserId = this.$store.state.auth.user.id;
    for (let i = 0; i < this.pollData.data.length; i++) {
      let option = { ...this.pollData.data[i]};
      option.isChecked = false;
      if (option.vote_ids.includes(currentUserId)) {
        option.isChecked = true;
      }
      this.pollOptions.push(option);
    }
  },
  methods: {
    vote(index) {
      this.votePoll(this.pollOptions[index].answer, this.pollOptions[index].isChecked);
    },
    addOption() {
      if (this.answerNew.trim() == '') {
        return;
      }
      for (let i = 0; i < this.pollOptions.length; i++) {
        if (this.pollOptions[i].answer.toLowerCase() == this.answerNew.trim().toLowerCase()) {
          return;
        }
      }

      let params = {
        "answer": this.answerNew
      }
      PostService.addNewPollOption(this.pollData.id, params).then(res => {
        if (res.status) {
          let currentUserId = this.$store.state.auth.user.id;
          this.pollOptions = [];
          for (let i = 0; i < res.data.length; i++) {
            let option = {...res.data[i]};
            option.isChecked = false;
            if (option.vote_ids.includes(currentUserId)) {
              option.isChecked = true;
            }
            this.pollOptions.push(option);
            this.answerNew = '';
          }
        }
      });
    },
    async votePoll(answer, isVote) {
      let params = {
        "answer": answer,
        "is_vote": isVote
      }
      await PostService.votePoll(this.pollData.id, params).then(res => {
        this.show = false;
        if (res.status) {
          let currentUserId = this.$store.state.auth.user.id;
          this.pollOptions = [];
          for (let i = 0; i < res.data.length; i++) {
            let option = { ...res.data[i]};
            option.isChecked = false;
            if (option.vote_ids.includes(currentUserId)) {
              option.isChecked = true;
            }
            this.pollOptions.push(option);
          }
        }
      });
    },
  }
}
;
</script>

<style lang="scss">
.poll-container {
  .poll-content-wrapper {
    width: 100%;
    min-height: 180px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(233, 234, 236);
    box-sizing: border-box;
    box-shadow: rgb(47 49 54 / 10%) 0px 4px 8px;
    border-radius: 8px;
    padding: 16px;
    height: auto;
    text-align: center;

    .poll-option {
      width: 100%;
      height: auto;
      border: 1px solid rgb(233, 234, 236);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 8px 12px;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
    }
  }
  .poll-option.active {
    border: 1px solid rgb(48, 169, 96);
  }
  .md-checkbox.md-checked .md-checkbox-container:after {
    border-color: rgb(48, 169, 96) !important;
  }
  .md-checkbox.md-theme-default.md-checked .md-checkbox-container {
    border-color: rgb(48, 169, 96) !important;
  }
  .option-new {
    -webkit-box-flex: 1;
    flex-grow: 1;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(222, 223, 226);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    color: rgb(38, 40, 44);
    outline: none;
    width: 100%;
  }
  .md-checkbox-label {
    color: black !important;
  }
}
</style>
