<template>
  <div class="inventory-content__container">
    <vue-element-loading :active="isLoad"/>
    <tabs class="mt-15px" @changed="changeTabActive">
      <tab name="Xuất TMDT" :tab_id="INVENTORY_REPORT_EXPORT_TAB.XUAT_SHOPEE">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_SHOPEE">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'xuat_tmdt'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách phiếu Xuất TMDT</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel">
                <md-icon>file_download</md-icon>
                <span>
                Xuất Excel
              </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getInventoryCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div v-if="canEdit">
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon"
                         v-if="couponSelectedRowKeys.length !== 0">
                <md-icon>delete</md-icon>
                <span>Xóa {{couponSelectedRowKeys.length}} phiếu</span>
              </md-button>
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCouponByQuery"
                         v-if="pagingParams.total > 0">
                <md-icon>delete_forever</md-icon>
                <span>Xóa tất cả</span>
              </md-button>
            </div>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="tableHeader.PHIEU_XUAT_HOAN_TMDT" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
      <tab name="Xuất khác" :tab_id="INVENTORY_REPORT_EXPORT_TAB.XUAT_KHAC">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_KHAC">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'xuat_khac'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Xuất khác</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel">
                <md-icon>file_download</md-icon>
                <span>
                Xuất Excel
              </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getInventoryCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div v-if="canEdit">
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon"
                         v-if="couponSelectedRowKeys.length !== 0">
                <md-icon>delete</md-icon>
                <span>Xóa {{couponSelectedRowKeys.length}} phiếu</span>
              </md-button>
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCouponByQuery"
                         v-if="pagingParams.total > 0">
                <md-icon>delete_forever</md-icon>
                <span>Xóa tất cả</span>
              </md-button>
            </div>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="tableHeader.PHIEU_NHAP_XUAT_KHAC" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
      <tab name="Xuất hàng hỏng" :tab_id="INVENTORY_REPORT_EXPORT_TAB.XUAT_HANG_HONG">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_HANG_HONG">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'xuat_hang_hong'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách phiếu Hủy hàng</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel">
                <md-icon>file_download</md-icon>
                <span>
                Xuất Excel
              </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getInventoryCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div v-if="canEdit">
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon"
                         v-if="couponSelectedRowKeys.length !== 0">
                <md-icon>delete</md-icon>
                <span>Xóa {{couponSelectedRowKeys.length}} phiếu</span>
              </md-button>
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCouponByQuery"
                         v-if="pagingParams.total > 0">
                <md-icon>delete_forever</md-icon>
                <span>Xóa tất cả</span>
              </md-button>
            </div>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="tableHeader.PHIEU_NHAP_XUAT_KHAC" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
      <tab name="Xuất bán" :tab_id="INVENTORY_COUPON_TYPE.XUAT_BAN">
        <div v-if="tabActive === INVENTORY_COUPON_TYPE.XUAT_BAN">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS]"
              :tab-active="tabActive"
              :id-filter-date="'XUAT_BAN'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách Xuất bán</div>
            <div class="d-flex-center">
              <div class="d-flex-center">
                <div class="export-excel-btn"
                     @click="dowloadExcel">
                  <md-icon>file_download</md-icon>
                  <span>
                                Xuất Excel
                              </span>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="getInventoryCoupon">
                  <md-icon>autorenew</md-icon>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="$modal.show('show-inventory-table-modal')">
                  <md-icon>zoom_out_map</md-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <ve-table border-y :columns="saleExportTableHeader" :table-data="inventoryCouponData"/>
        </div>
      </tab>
    </tabs>
    <detail-product-modal></detail-product-modal>
    <show-inventory-table-modal v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_SHOPEE"
                                :total-stock-data="inventoryCouponData"
                                :total-stock-columns="tableHeader.PHIEU_XUAT_HOAN_TMDT"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
    <show-inventory-table-modal
        v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_KHAC || tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_HANG_HONG"
        :total-stock-data="inventoryCouponData"
        :total-stock-columns="tableHeader.PHIEU_NHAP_XUAT_KHAC"
        :paging-params="pagingParams"
        :hidden-export-btn="true"
        @change-paging-params="changePagingParam"
        @export="dowloadExcel"/>
    <show-inventory-table-modal v-if="tabActive == INVENTORY_COUPON_TYPE.XUAT_BAN"
                                :total-stock-data="inventoryCouponData"
                                :total-stock-columns="tableHeader.NHAP_HOAN_XUAT_BAN"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
  </div>
</template>

<script>

import InventoryFilter from "../Filter";
import InventoryTable from "../Table";
import {
  INVENTORY_EXPORT_TABLE_HEADER,
  INVENTORY_REPORT_EXPORT_TAB,
  INVENTORY_TYPE_OPTIONS
} from "../../../../../const/const";
import {helpers} from "../../../../../helper/helpers";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import InventoryService from "@/store/services/InventoryService";
import moment from "moment";
import couponMixin from "@/mixins/coupon-mixin";
import ShowInventoryTableModal from "@/pages/Components/Modal/ShowInventoryTableModal";
import DetailProductModal from "@/pages/Components/Modal/DetailProductModal";

export default {
  mixins: [couponMixin],
  name: "ExportGoodsContent",
  components: {
    InventoryTable,
    InventoryFilter,
    PaginationTemplate,
    ShowInventoryTableModal,
    DetailProductModal
  },
  created() {
    this.tabActive = INVENTORY_REPORT_EXPORT_TAB.XUAT_DIEU_CHUYEN;
  },
  data() {
    return {
      canEdit: this.$store.state.auth.user.manage_inventory,
    }
  }

}
</script>

<style lang="scss">
.inventory-content__container {

}
</style>