import BaseService from "./BaseService";

export default class CategoryService extends BaseService {

    static async getCategories() {
        const res = await this.get(`/categories/tree`);
        return res.data;
    }

    static async createCategory(params = {}) {
        const res = await this.post(`/categories`, params);
        return res.data;
    }

    static async updateCategory(categoryId, params = {}) {
        const res = await this.post(`/categories/`+ categoryId, params);
        return res.data;
    }

    static async getDetailCategory(params = {}) {
        const res = await this.get(`/categories/` + params.category_id);
        return res.data;
    }

    static async getTreeCategory(params = {}) {
        const res = await this.get(`/categories/` + params.category_id + `/tree`);
        return res.data;
    }

    static async getCategory(params = {}) {
        const res = await this.get(`/categories`, params );
        return res.data;
    }
}
