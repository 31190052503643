<template>
  <div>
    <modal name="inventory-import-file-modal"
           :click-to-close="false"
           width='500px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <form class="" @submit.prevent="save()">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Đồng bộ phiếu</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('inventory-import-file-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content class="import-coupon-modal">
          <div class="md-gutter">
            <label class="required heading-400 neutral_3">Loại phiếu</label>
            <div class="display-flex">
              <multiselect
                  label="title"
                  track-by="title"
                  open-direction="bottom"
                  :options="couponOptions"
                  :selectLabel="''"
                  :deselectLabel="''"
                  :selectedLabel="''"
                  v-model="couponSelected"
                  style="width: 220px"
              >
                <template slot="option" slot-scope="{ option }">
                  <md-list-item>
                    <div class="md-list-item-text">
                      <span>{{ option.title }}</span>
                    </div>
                  </md-list-item>
                </template>
              </multiselect>
              <md-button @click="downloadExcelTemplate()" class="btn-dowload">File mẫu</md-button>
            </div>
          </div>

<!--          <div class="md-gutter pt-15px pb-15px">-->
<!--            <label class="required heading-400 neutral_3 mb-5px">Thị trường</label>-->
<!--            <multiselect-->
<!--                label="name"-->
<!--                track-by="name"-->
<!--                open-direction="bottom"-->
<!--                :options="countryOptions"-->
<!--                :selectLabel="''"-->
<!--                :deselectLabel="''"-->
<!--                :selectedLabel="''"-->
<!--                v-model="countrySelected"-->
<!--            >-->
<!--              <template slot="option" slot-scope="{ option }">-->
<!--                <md-list-item>-->
<!--                  <div class="md-list-item-text">-->
<!--                    <span>{{ option.name }}</span>-->
<!--                  </div>-->
<!--                </md-list-item>-->
<!--              </template>-->
<!--            </multiselect>-->
<!--          </div>-->

          <div class="md-gutter pt-15px pb-15px">
            <label class="required heading-400 neutral_3 mb-5px">Chọn file đồng bộ</label>
            <input type="file" class="form-control input-custom input-file" @change="onFileChange" required
                   name="file">
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button :class="{'disabled': (file == '')}" type="submit" class="btn-active md-button">Đồng bộ</button>
          <button class="btn-stroke md-button" @click="$modal.hide('inventory-import-file-modal')">Huỷ</button>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>
import PostService from "../../../store/services/PostService";
import EmployeeService from "../../../store/services/EmployeeService";
import GroupService from "../../../store/services/GroupService";
import CategoryService from "../../../store/services/CategoryService";
import {INVENTORY_COUPON_TYPE, INVENTORY_REPORT_TAB} from "../../../const/const";
import InventoryService from "../../../store/services/InventoryService";
import {helpers} from "../../../helper/helpers";

export default {
  name: 'inventory-import-file-modal',
  computed: {},
  created() {
  },
  data() {
    return {
      show: false,
      file: '',
      couponOptions: [
        {
          type: INVENTORY_COUPON_TYPE.DON_DAT_MUA,
          title: 'Đơn đặt hàng',
        },
        {
          type: INVENTORY_COUPON_TYPE.NHAP_MUA,
          title: 'Nhập mua',
        },
        {
          type: INVENTORY_COUPON_TYPE.NHAP_KHAC,
          title: 'Nhập khác',
        },
        {
          type: INVENTORY_COUPON_TYPE.XUAT_KHAC,
          title: 'Xuất khác',
        },
        {
          type: INVENTORY_COUPON_TYPE.CHUYEN_KHO,
          title: 'Chuyển kho',
        },
        {
          type: INVENTORY_COUPON_TYPE.XUAT_TMDT,
          title: 'Xuất TMĐT',
        },
        {
          type: INVENTORY_COUPON_TYPE.HOAN_TMDT,
          title: 'Hoàn TMĐT',
        },
        {
          type: INVENTORY_COUPON_TYPE.XUAT_HANG_HONG,
          title: 'Xuất hàng hỏng',
        },
      ],
      couponSelected: {},
      countryOptions: [],
      countrySelected: {},
      user: this.$store.state.auth.user,
    }
  },
  methods: {
    async getCountry() {
      await InventoryService.getCountry().then(res => {
        if (res) {
          this.countryOptions = res
          this.countrySelected = this.countryOptions[0];
        }
      });
    },

    async importFileExcel() {
      const formFile = new FormData();

      formFile.append("file", this.file);
      // formFile.append("country_id", this.countrySelected.id);
      formFile.append("user_id", this.user.id);
      formFile.append("type", this.couponSelected.type);

      await InventoryService.importFileExcel(this.couponSelected.type, formFile).then(res => {
        if (res) {
          this.show = false;
          if (res.status) {
            this.$store.dispatch("alerts/success", 'Đồng bộ file thành công');
            this.$modal.hide('inventory-import-file-modal')
          } else {
            this.$store.dispatch("alerts/error", res.message);
          }
        }
      }).catch(err => {
        this.show = false;
        this.$store.dispatch("alerts/error", 'Đồng bộ file không thành công.');
      });
    },

    async onFileChange(e) {
      this.file = e.target.files[0];
    },

    save() {
      this.show = true;
      this.importFileExcel();
    },

    downloadExcelTemplate() {
      let baseUrl = process.env.VUE_APP_API_INVENTORY_BASE_URL + '/v1';
      let url = `${baseUrl}/inventory/coupon/${this.couponSelected.type}/excel-template`;
      window.open(url, '_blank');
    },

    beforeOpened(event) {
      this.getCountry();
      this.couponSelected = this.couponOptions[0];
    },

    beforeClosed(event) {

    },

  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 300px !important;
  }
}
.import-coupon-modal {
  min-height: 350px !important;
}

.btn-dowload {
  flex-direction: row;
  justify-content: center;
  padding: 8px 15px;
  //width: 94px;
  //height: 36px;
  background: #4D77EC !important;
  border-radius: 4px;
  margin: 0px;
  margin-left: 15px;
}

</style>
