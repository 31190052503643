<template>
  <div class="edit-image-page__container">
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="d-flex header-custom">
            <span class="neutral_1 heading-500 text-header-modal d-flex-center">
              <span class="cursor-pointer" @click="cancelEditingMedia">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 hover:brightness-120" style="cursor: pointer;"><rect width="40" height="40" rx="20" fill="#142141" fill-opacity="0.06"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 13.2929C19.0976 13.6834 19.0976 14.3166 18.7071 14.7071L14.4142 19H28C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21H14.4142L18.7071 25.2929C19.0976 25.6834 19.0976 26.3166 18.7071 26.7071C18.3166 27.0976 17.6834 27.0976 17.2929 26.7071L11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.2929 13.2929C17.6834 12.9024 18.3166 12.9024 18.7071 13.2929Z" fill="#26282C"></path></svg>
              </span>
              <span class="ml-10px">Ảnh/Video</span>
            </span>
            <span class="float-right cursor-pointer">
              <button class="btn done-btn cursor-pointer" @click="confirmEditingMedia">
                <md-icon class="mr-5px" style="color: white">done</md-icon>
                Xong
              </button>
            </span>
          </span>
        </md-dialog-title>
      </md-card>

      <div class="list-edit-media__main">
        <div class="media-item" v-for="(media, index) in mediaList" :key="media.id">
          <button class="btn-remove-media cursor-pointer" @click="removeMedia(index, media)">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="hover:brightness-130">
              <path
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                  fill="white"></path>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M30.6673 16.0002C30.6673 24.1003 24.1008 30.6668 16.0007 30.6668C7.90047 30.6668 1.33398 24.1003 1.33398 16.0002C1.33398 7.89999 7.90047 1.3335 16.0007 1.3335C24.1008 1.3335 30.6673 7.89999 30.6673 16.0002ZM22.2768 11.6096C22.7975 11.0889 22.7975 10.2447 22.2768 9.72402C21.7561 9.20332 20.9119 9.20332 20.3912 9.72402L16.0007 14.1145L11.6101 9.72402C11.0894 9.20332 10.2452 9.20332 9.72451 9.72402C9.20381 10.2447 9.20381 11.0889 9.72451 11.6096L14.115 16.0002L9.72451 20.3907C9.20381 20.9114 9.20381 21.7556 9.72451 22.2763C10.2452 22.797 11.0894 22.797 11.6101 22.2763L16.0007 17.8858L20.3912 22.2763C20.9119 22.797 21.7561 22.797 22.2768 22.2763C22.7975 21.7556 22.7975 20.9114 22.2768 20.3907L17.8863 16.0002L22.2768 11.6096Z"
                    fill="#26282C"></path>
            </svg>
          </button>
          <div class="bg-thumb" :style="'background-image: url(' + media.path + ')'"></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mediaList: [],
      removedMediaIdList: [],
    }
  },
  props: {
    mediaEditList: {
      type: Array,
      default: () => [],
    }
  },
  mounted() {
    this.mediaList = [].concat(this.mediaEditList);
  },
  watch: {
    'mediaEditList'(newV) {
      this.mediaList = [].concat(newV);
    },
  },
  methods: {
    cancelEditingMedia() {
      this.removedMediaIdList = [];
      this.mediaList = this.mediaEditList;
      this.$emit('cancel-editing-media');
    },
    removeMedia(index, media) {
      this.mediaList.splice(index, 1);
      this.removedMediaIdList.push(media.id);
    },
    confirmEditingMedia() {
      let removedIdList = [].concat(this.removedMediaIdList);
      this.removedMediaIdList = [];
      this.$emit('confirm-editing-media', removedIdList);
    }
  },

}
</script>

<style lang="scss">
.edit-image-page__container {
  .done-btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    color: #fff;
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.6;
    padding: 0.3rem 0.65rem;
    background-color: #375464;
  }
  .header-custom {
    align-items: center;
    justify-content: space-between;
  }
  .list-edit-media__main {
    max-height: 748px;
    overflow-y: auto;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .media-item {
      padding: 0px 5px 20px 5px;
      width: 50%;
      position: relative;
      .bg-thumb {
        border-radius: 8px;
        background-size: auto 100%;
        background-position: center center;
        background-repeat: no-repeat;
        height: 280px;
        border: 1px solid #dedfe2;
      }
      .btn-remove-media {
        position: absolute;
        top: 15px;
        right: 15px;
        background: transparent;
        border: 0;
      }
    }
  }
}
</style>