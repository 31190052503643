import { render, staticRenderFns } from "./DetailOrderCouponModal.vue?vue&type=template&id=fb59a8b2&"
import script from "./DetailOrderCouponModal.vue?vue&type=script&lang=js&"
export * from "./DetailOrderCouponModal.vue?vue&type=script&lang=js&"
import style0 from "./DetailOrderCouponModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports