<template>
  <md-card-content :class="{'overflow-initial' : postPreview !== null}">
    <text-content :post=Post :post-preview="postPreview"></text-content>
    <image-content v-if="Post.medias && Post.medias.length !== 0 &&
    (Post.type==='IMAGE' || Post.type==='ALBUM' || Post.type==='UPDATE_GROUP_AVATAR' || Post.type==='UPDATE_USER_AVATAR' || Post.type==='UPDATE_USER_WALLPAPER')"
                   :mediaObject="{post_id: Post.id, media_count: Post.media_count, mediaList: Post.medias, type: Post.type}">
    </image-content>
    <file-content v-if="Post.medias && Post.medias.length !== 0 && (Post.type==='FILE')" :files=Post.medias></file-content>
    <poll-content v-if="pollData != null" :poll-data="pollData"></poll-content>
  </md-card-content>
</template>

<script>
import TextContent from "./Content/TextContent";
import FileContent from "./Content/FileContent";
import ImageContent from "./Content/ImageContent";
import VideoContent from "./Content/VideoContent";
import PollContent from "./Content/PollContent";
export default {
  name: "post-content",
  components: {ImageContent, FileContent, TextContent, PollContent},
  props: {
    Post: {
      type: Object,
      default: () => {},
    },
    pollData: {
      type: Object,
      default: null,
    },
    postPreview: {
      type: Object,
      default: null,
    },
    isPostPreview: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="css"></style>
