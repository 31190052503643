import PostService from "../services/PostService";

export default {
    state: {
        posts: [],
        postTotalPage: 1,
        previousDetail: 1,
        changePostFlag: 0,  // = 1 khi tạo post
        contentScrollTop: 0
    },

    getters: {
        getPost(state) {
            return state.posts;
        },
        getPostTotalPage(state) {
            return state.postTotalPage;
        },
        getPreviousDetail(state) {
            return state.previousDetail;
        },
        getCreatePostFlag(state) {
            return state.changePostFlag;
        },
        getContentScrollTop(state) {
            return state.contentScrollTop;
        }
    },

    mutations: {
        updatePost(state, payload) {
            state.posts = payload;
        },
        updatePostTotalPage(state, number) {
            state.postTotalPage = number;
        },
        updatePreviousDetail(state, number) {
            state.previousDetail = number;
        },
        changePostFlag(state, payload) {
            state.changePostFlag = payload;
        },
        updateContentScrollTop(state, payload) {
            state.contentScrollTop = payload;
        }
    },

    actions: {
        getPost({commit, dispatch}, payload) {
            return PostService.getPosts().then(res => {
                if (res) {
                    commit("updatePost", res.data);
                }
            });
        },
        refreshPostTotalPage({commit, dispatch}, payload) {
            commit("updatePostTotalPage", 1);
        },
        refreshPreviousDetail({commit, dispatch}, payload) {
            commit("updatePreviousDetail", 1);
        },
        changePostFlag({commit, dispatch}, payload) {
            commit("changePostFlag", payload);
        },
        changeContentScrollTop({commit, dispatch}, payload) {
            commit("updateContentScrollTop", payload);
        }
    }
};
