<template>
  <div class="inventory-content__container">
    <tabs class="mt-15px" @change="changeIsLoad">
      <tab name="Kho thực tế" @click="changeTabActive(INVENTORY_REPORT_TAB.TOTAL_STOCK)">
        <report-total-stock></report-total-stock>
      </tab>
      <tab name="Kho khả dụng" @click="changeTabActive(INVENTORY_REPORT_TAB.AVAILABLE_STOCK)">
        <report-available-stock></report-available-stock>
      </tab>
      <tab v-if="isShow()" name="Điều chuyển biến thể" @click="changeTabActive(INVENTORY_REPORT_TAB.SWITCH_STOCK_ADJ)">
        <switch-stock-adjustment></switch-stock-adjustment>
      </tab>
      <tab name="Dữ liệu chốt kho" @click="changeTabActive(INVENTORY_REPORT_TAB.INVENTORY_INFO)">
        <inventory-info></inventory-info>
      </tab>
<!--      <tab name="Tồn kho hiện tại" @click="changeTabActive(INVENTORY_REPORT_TAB.EXISTING_PRESENT)">-->
<!--        <report-existing-present></report-existing-present>-->
<!--      </tab>-->
    </tabs>
  </div>
</template>

<script>
import {INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../../const/const";
import ReportTotalStock from "./TotalStock";
import ReportAvailableStock from "./AvailableStock";
import InventoryInfo from "./InventoryByMonth"
import ReportExistingPresent from "./ExistingPresent"
import SwitchStockAdjustment from "./SwitchStockAdjustment";

export default {
  name: "InventoryContent",
  components: {
    SwitchStockAdjustment,
    ReportAvailableStock,
    ReportTotalStock,
    InventoryInfo,
    // ReportExistingPresent
  },
  data() {
    return {
      INVENTORY_REPORT_TAB,
      tabActive: INVENTORY_REPORT_TAB.TOTAL_STOCK,
      isLoad: false,
      inventoryId: this.$route.params.inventory_id,
      inventoryParentIds: JSON.parse(localStorage.getItem("inventoryParentIds")),
    };
  },
  props: {},
  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.inventoryId = newVal;
      setTimeout(() => {
        this.isShow();
      }, 1000);
    },
  },
  mounted() {
    this.tabActive = INVENTORY_REPORT_TAB.TOTAL_STOCK;
    this.$store.commit("updateTabActive", this.tabActive);
  },
  methods: {
    isShow() {
      return this.inventoryParentIds.includes(Number(this.inventoryId));
    },
    changeTabActive(tabActive) {
      this.tabActive = tabActive;
      // this.$store.commit("updateTabActive", tabActive);
    },
    changeIsLoad(value) {
      // console.log(value);
      // this.isLoad = value;
    }
  }
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }

}
</style>