<template>
  <div class="member-list-wrapper">
    <div class="header-wrapper">
      <div class="d-flex-center">
        <div class="header-icon"></div>
        <div class="ml-10px heading-700">{{ title }}</div>
      </div>
      <div class="d-flex-center" v-if="[636,842,119,1,2,311].includes(Number($store.state.auth.user.id)) || $store.state.auth.user.manage_inventory">
          <div class="create-order-btn">
          <div class="button-add-product" @click="active = !active" v-click-outside="unActive">
            <md-icon>add_circle_outline</md-icon>
            <span class="ml-5px">Tạo phiếu</span>
            <md-icon>arrow_drop_down</md-icon>
          </div>
          <div class="dropdown-list" v-if="active">
            <md-list>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.NHAP_MUA)">Nhập mua</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.CHUYEN_KHO)">Chuyển kho</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.NHAP_KHAC)">Nhập khác</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.HOAN_TMDT)">Hoàn TMĐT</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.XUAT_TMDT)">Xuất TMĐT</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.XUAT_HANG_HONG)">Xuất hàng hỏng</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.XUAT_KHAC)">Xuất khác</md-list-item>
              <md-list-item @click="createFormAddProduct(INVENTORY_COUPON_TYPE.DON_DAT_MUA)">Đơn đặt hàng</md-list-item>
            </md-list>
          </div>
        </div>
        <div class="synchronization-excel-btn ml-10px" @click="openModal()">
          <md-icon>sync</md-icon>
          <span class="ml-5px">Đồng bộ Excel</span>
        </div>
      </div>
    </div>
    <div class="member-list-tbl mt-15px">
      <inventory-content v-if="$route.params.tab === 'report'" />
      <import-content v-if="$route.params.tab === 'import'" />
      <export-content v-if="$route.params.tab === 'export'" />
      <coupon-transfer-content v-if="$route.params.tab === 'transfer'" />
      <order-coupon-content v-if="$route.params.tab === 'order'" />
      <setting-content v-if="$route.params.tab === 'setting'" />
    </div>
    <add-multiple-product-modal />
    <create-coupon-modal />
    <update-coupon-modal />
    <create-order-coupon-modal />
    <update-purchase-order-modal />
    <list-coupon-and-receipt-modal></list-coupon-and-receipt-modal>
    <detail-order-coupon-modal />
    <update-order-coupon-modal />
  </div>
</template>

<script>

import InventoryContent from "@/pages/Workplace/Pages/Inventory/Report/Content";
import ImportContent from "@/pages/Workplace/Pages/Inventory/Import/Content";
import ExportContent from "@/pages/Workplace/Pages/Inventory/Export/Content";
import CouponTransferContent from "@/pages/Workplace/Pages/Inventory/Transfer/Content";
import AddMultipleProductModal from "../../../Components/Modal/AddMultipleProductModal";
import OrderCouponContent from "@/pages/Workplace/Pages/Inventory/Order/Content";
import CreateOrderCouponModal from "@/pages/Components/Modal/CreateOrderCouponModal";
import CreateCouponModal from "@/pages/Components/Modal/CreateCouponModal";
import {INVENTORY_COUPON_TYPE} from "@/const/const";
import SettingContent from "@/pages/Workplace/Pages/Inventory/Setting/Content";
import UpdateCouponModal from "../../../Components/Modal/UpdateCouponModal";
import UpdatePurchaseOrderModal from "../../../Components/Modal/UpdatePurchaseOrderModal";
import ListCouponAndReceiptModal from "../../../Components/Modal/ListCouponAndReceiptModal";
import DetailOrderCouponModal from "../../../Components/Modal/DetailOrderCouponModal";
import UpdateOrderCouponModal from "../../../Components/Modal/UpdateOrderCouponModal";

export default {
  components: {
    ListCouponAndReceiptModal,
    CreateOrderCouponModal,
    InventoryContent,
    AddMultipleProductModal,
    ImportContent,
    ExportContent,
    CouponTransferContent,
    OrderCouponContent,
    CreateCouponModal,
    SettingContent,
    UpdateCouponModal,
    UpdatePurchaseOrderModal,
    DetailOrderCouponModal,
    UpdateOrderCouponModal
  },
  computed: {
    title() {
      let title = '';
      switch (this.$route.params.tab) {
        case 'report':
          title = 'Báo cáo kho';
          break;
        case 'import':
          title = 'Nhập hàng';
          break;
        case 'export':
          title = 'Xuất hàng';
          break;
        case 'transfer':
          title = 'Chuyển kho';
          break;
        case 'order':
          title = 'Đơn đặt hàng';
        case 'setting':
          title = 'Cài đặt';
          break;
      }
      return title;
    }
  },
  data() {
    return {
      active: false,
      INVENTORY_COUPON_TYPE,
    }
  },
  created() {
  },
  watch: {},
  methods: {
    unActive(){
      this.active = false;
    },

    createFormAddProduct(type){
      if(type === INVENTORY_COUPON_TYPE.NHAP_MUA){
        this.$modal.show('add-multiple-product-modal', {type : type});
      } else if (type === INVENTORY_COUPON_TYPE.DON_DAT_MUA) {
        this.$modal.show('create-order-coupon-modal');
      } else{
        this.$modal.show('create-coupon-modal', {type : type});
      }

    },
    openModal() {
      this.$modal.show('inventory-import-file-modal')
    },
  },
};

</script>


<style lang="scss">
.member-list-wrapper {
  padding: 20px 30px;
  height: calc(100vh - 38px);
  overflow: auto;
  .ve-table-container {
    //overflow-y: hidden;
  }
  .vuejs-refresh-head {
    display: none;
  }
  .ve-table-content-wrapper {
    //overflow-x: auto;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-icon {
      width: 24px;
      height: 28px;
      background: url("/img/inventory/report.svg");
    }
  }


  .input-container {
    border-radius: 4px !important;
    padding-left: 10px !important;
    background-color: white !important;
    margin-bottom: 0 !important;

    .md-icon {
      margin: auto !important;
    }
  }

  .vuejs-loadmore-finished-text {
    display: none !important;
  }

  .ml-btn-wrapper {
    height: 10%;
  }

  .dropdown-list {
    position: absolute;
    left: 0px;
    width: 180px;
    border: 1px solid #D8DAE5;
    background: white;
    top: 35px;
    border-radius: 0 0 3px 3px;
    box-shadow: rgb(47 49 54 / 10%) 0px 4px 8px;
    z-index: 100;
    .md-list-item{
      margin: 0;
      &:hover{
        cursor: pointer;
        background: #c9c9c9;
      }
      .md-list-item-container {
        .md-ripple {
          padding: 6px 10px;
          min-height: max-content;
          font-size: 14px;
        }
      }
    }
    .md-list{
      padding: 0;
      border-radius: 0 0 3px 3px;
    }
  }
  .button-add-product{
    padding: 5px 7px;
  }
  .create-order-btn{
    position: relative;
    padding: 0;
  }
  .velmld-overlay {
    background: none !important;
  }
}

.content .member-list-wrapper {
}
</style>