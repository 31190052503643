import BaseService from "./BaseService";

export default class VendorService extends BaseService {
    static async getVendor(params = {}) {
        const res = await this.get(`/vendor`, params, "INVENTORY");
        return res.data;
    }

    static async getDetailVendor(vendor_id) {
        const res = await this.get(`/vendor/${vendor_id}`, {}, "INVENTORY");
        return res.data;
    }


    static async createVendor(params = {}) {
        const res = await this.post(`/vendor`, params, "INVENTORY");
        return res.data;
    }

    static async updateVendor(vendor_id, params = {}) {
        const res = await this.post(`/vendor/${vendor_id}`, params, "INVENTORY");
        return res.data;
    }

    static async deleteVendor(vendor_id) {
        const res = await this.post(`/vendor/${vendor_id}/delete`, {}, "INVENTORY");
        return res.data;
    }
}
