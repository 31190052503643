<template>
  <div>
    <modal name="notification-setting-modal"
           :click-to-close="false"
           width="410px"
           height="auto"
           class="modal-custom notification-setting-modal"
           transition="easeInOut"
           @before-open="beforeOpen"
    >
          <md-card-content class="notification-setting__main">
            <div class="text-center heading-500">
              <span v-if="groupId">Cài đặt thông báo nhóm</span>
              <span v-else>Cài đặt thông báo bài viết</span>
              <span class="material-icons float-right cursor-pointer"
                    @click="$modal.hide('notification-setting-modal')">close</span>
            </div>
            <div class="mt-20px">
              <div class="option">
                <div v-if="groupId">
                  <div class="heading-400">Nhận tất cả thông báo</div>
                  <div class="text-300-default">Mọi bài viết trong nhóm</div>
                </div>
                <div v-else>
                  <div class="heading-400">Nhận thông báo bài viết</div>
                </div>
                <md-radio v-model="enableNotification" :value="'ON'"></md-radio>
              </div>
              <div class="option mt-20px">
                <div v-if="groupId">
                  <div class="heading-400">Tắt thông báo từ nhóm này</div>
                  <div class="text-300-default">Chỉ nhận thông báo về bài viết của quản trị viên</div>
                </div>
                <div v-else>
                  <div class="heading-400">Tắt thông báo từ bài viết này</div>
                </div>
                <md-radio v-model="enableNotification" :value="'OFF'"></md-radio>
              </div>
              <div class="mt-20px w_100 btn-wrapper">
                <button @click="$modal.hide('notification-setting-modal')">Hủy</button>
                <button class="ml-10px btn-success" @click="saveSetting">Lưu</button>
              </div>
            </div>
          </md-card-content>
    </modal>
  </div>
</template>

<script>

import GroupService from "../../../store/services/GroupService";
import PostService from "../../../store/services/PostService";

export default {
  name: 'notification-setting-modal',
  computed: {
  },
  mounted() {
    this.currentUrl = window.location.origin + '/group/' + this.groupId;
  },

  created() {
  },
  data: () => ({
    enableNotification: null,
    groupId: null,
    postId: null,
  }),

  methods: {
    beforeOpen(event) {
      this.refreshData();
      this.enableNotification = event.params.enable_notification;
      if (event.params.groupId) {
        this.groupId = event.params.groupId;
      }
      if (event.params.postId) {
        this.postId = event.params.postId;
      }
      // this.$store.commit("updateModalIsOpen", true);
    },
    refreshData() {
      this.enableNotification = null;
      this.groupId = null;
      this.postId = null;
    },
    saveSetting() {
      if (this.groupId) {
        GroupService.toggleNotification(this.groupId, {stage: this.enableNotification}).then(res => {
        });
      } else {
        PostService.toggleNotification(this.postId, {stage: this.enableNotification}).then(res => {
        });
      }
      const notificationSettingModel = {
        enableNotification: this.enableNotification,
        groupId: this.groupId,
        postId: this.postId
      };
      this.$store.dispatch("putNotificationSetting", notificationSettingModel);
      this.$emit('toggle-notification', notificationSettingModel);
      this.$modal.hide('notification-setting-modal');
    }
  },
}
</script>

<style lang="scss">
.notification-setting-modal{
  .notification-setting__main {
    padding: 0.75rem;
    border-radius: 24px;
  }
  .vm--modal {
    border-radius: 24px;
  }
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-wrapper {
    text-align: center;
    button {
      color: rgb(77, 77, 77);
      width: 159px;
      border: none;
      padding: 8px 47px;
      border-radius: 8px;
      cursor: pointer;
    }
    .btn-success {
      background: rgb(48, 169, 96);
      color: rgb(255, 255, 255);
    }
  }
}

</style>
