import axios from 'axios';
// import Api from '../config/Api';
import Cookies from 'js-cookie';

const domain = process.env.VUE_APP_API_BASE_URL + '/v1';
const adminDomain = process.env.VUE_APP_ADMIN_API_BASE_URL;
const inventoryDomain = process.env.VUE_APP_API_INVENTORY_BASE_URL + '/v1';

export default class BaseService {

    static async getByUrl(uri, params = {}, apiType = 'USER') {
        try {
            return await axios.get(uri, {params: params});
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async get(uri, params = {}, apiType = 'USER') {
        try {
            let config = {params: params};
            if (params.cancelToken) {
                config.cancelToken = params.cancelToken;
                delete params.cancelToken;
            }
            return await axios.get(this.getUrl(uri, apiType), config);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async post(uri, params = {}, apiType = 'USER') {
        try {
            return await axios.post(this.getUrl(uri, apiType), params);
        } catch (e) {
            console.log(e);
            return this.errorMsg(e);
        }
    }

    static async put(uri, params = {}, apiType = 'USER') {
        try {
            return await axios.put(this.getUrl(uri, apiType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async patch(uri, params = {}, apiType = 'USER') {
        try {
            return await axios.patch(this.getUrl(uri, apiType), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async show(uri) {
        try {
            return await axios.get(this.getUrl(uri));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async delete(uri, apiType = 'USER') {
        try {
            return await axios.delete(this.getUrl(uri, apiType));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static getUrl(uri, apiType) {
        let domainUrl = domain;
        if (apiType === 'ADMIN') {
            domainUrl = adminDomain;
        }
        if (apiType === 'INVENTORY') {
            domainUrl = inventoryDomain;
        }
        return domainUrl + uri;
    }

    static errorMsg(e) {
        if (!e.response && Cookies.get('access_token') && Cookies.get('user_id')) {
            throw e;
        }
        
        if(!Cookies.get('access_token') || !Cookies.get('user_id')){
            window.app.$store.dispatch("logout");
        }else if (e.response.status === 401) {
            window.app.$store.dispatch("logout");
        } else if (e.response.status === 403) {
            window.app.$router.push({name: "Error page"});
        } 
        return {data: e.response.data};
    }
}
