import SettingService from "../services/SettingService";

export default {
    state: {
        positionList: [],
        departmentTree: [],
    },

    getters: {
        getPositionList(state) {
            return state.positionList;
        },
        getDepartmentTree(state) {
            return state.departmentTree;
        },
    },

    mutations: {
        updatePositionList(state, payload) {
            state.positionList = payload;
        },
        updateDepartmentTree(state, payload) {
            state.departmentTree = payload;
        },
    },

    actions: {
        getPositionList({commit, dispatch}, payload) {
            return SettingService.getPositions().then(res => {
                if (res && res.data.length !== 0) {
                    commit("updatePositionList", res.data);
                }
            });

        },
        getDepartmentTree({commit, dispatch}, payload) {
            return SettingService.getDepartmentTree().then(res => {
                if (res && res.data.length !== 0) {
                    commit("updateDepartmentTree", res.data);
                }
            });
        }
    }
};
