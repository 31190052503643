<template>
  <div>
    <modal name="switch-stock-adjustment-modal"
           :click-to-close="false"
           width='500px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <form class="" @submit.prevent="save()">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Chốt dữ liệu kho</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('switch-stock-adjustment-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div style="margin-left: auto;">
            <label class="required heading-400 neutral_3">Thời gian:</label>
            <div class="display-flex">
              <input class="mr-15px input-date"  id="inventory_last_opening_date" type="date" name="inventory_last_opening_date" autocomplete="off">
            </div>
          </div>
        </md-card-content>
        <md-dialog-actions>
          <button type="submit" class="btn-active md-button">Tạo dữ liệu</button>
          <button class="btn-stroke md-button" @click="$modal.hide('switch-stock-adjustment-modal')">Huỷ</button>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>
import PostService from "../../../store/services/PostService";
import EmployeeService from "../../../store/services/EmployeeService";
import GroupService from "../../../store/services/GroupService";
import CategoryService from "../../../store/services/CategoryService";
import {DATE_RANGE_PICKER_CONFIG, INVENTORY_COUPON_TYPE, INVENTORY_REPORT_TAB} from "../../../const/const";
import InventoryService from "../../../store/services/InventoryService";
import {helpers} from "../../../helper/helpers";
import moment from "moment";

export default {
  name: 'switch-stock-adjustment-modal',
  computed: {},
  created() {
  },
  data() {
    return {
      show: false,
      inventory_last_opening_date: moment().format('DD/MM/YYYY')
    }
  },
  methods: {
    createAdjustmentCoupon() {
      this.show = true;
      this.inventory_last_opening_date = $('#inventory_last_opening_date').val()

      let params = {
        date: this.inventory_last_opening_date,
      };

      InventoryService.createAdjustmentCoupon(params).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Tạo dữ liệu kho thành công.');
          this.$store.commit("updateReportSwitchStockAdjAction", {date: params.date, action: 'create'});
        } else {
          this.$store.dispatch("alerts/error", 'Tạo dữ liệu kho thành công. Nguyên nhân: ' + res.message);
        }
        this.$modal.hide('switch-stock-adjustment-modal')
        this.show = false;
      });
    },

    save() {
      this.show = true;
      this.createAdjustmentCoupon();
    },
    beforeOpened(event) {
      const dateToday = moment().format('YYYY-MM-DD')
      this.refreshDateFilter(dateToday);
    },
    refreshDateFilter(date) {
      $(function () {
        $('#inventory_last_opening_date').val(date);
      });
    },


    beforeClosed(event) {

    },

  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 300px !important;
  }
}

.import-coupon-modal * {
  font-size: 14px;
}

.btn-dowload {
  flex-direction: row;
  justify-content: center;
  padding: 8px 15px;
  //width: 94px;
  //height: 36px;
  background: #4D77EC !important;
  border-radius: 4px;
  margin: 0px;
  margin-left: 15px;
}

</style>
