<template>
  <div>
    <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
    <inventory-filter
        @change="handleFilterChange"
        :pick-type="[INVENTORY_TYPE_OPTIONS.SKU]"
        :tab-active="tabActive"
        :only-show-date="true"
        :id-filter-date="'history_sync_coupon'"
    ></inventory-filter>
    <div class="mb-20px d-flex-space-between">
      <div class="heading-600">Lịch sử đồng bộ</div>
    </div>
    <div class="w_100">
      <div class="float-right">
        <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam"/>
      </div>
    </div>
    <inventory-table border-y :columns="couponSyncHistoryHeader" :table-data="couponSyncHistories"
                     :cell-style-option="cellStyleOption"/>
    <errors-modal/>
  </div>
</template>

<script>
import {INVENTORY_COUPON_TYPE, INVENTORY_TYPE_OPTIONS, SETTING_TAB} from "../../../../../const/const";
import moment from "moment";
import InventoryService from "@/store/services/InventoryService";
import {helpers} from "@/helper/helpers";
import InventoryTable from "@/pages/Workplace/Pages/Inventory/Table";
import InventoryFilter from "@/pages/Workplace/Pages/Inventory/Filter";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import ErrorsModal from "@/pages/Components/Modal/ErrorsModal";

export default {
  name: "SyncHistory",
  components: {
    InventoryTable,
    InventoryFilter,
    PaginationTemplate,
    ErrorsModal
  },
  props: {},
  data() {
    return {
      SETTING_TAB,
      INVENTORY_TYPE_OPTIONS,
      INVENTORY_COUPON_TYPE,
      isLoad: false,
      tabActive: SETTING_TAB.SYNC_HISTORY,
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      filterParams: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      },
      couponSyncHistories: [],
      cellStyleOption: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (column.field === "action") {
            return "action-column";
          }
        },
      },
      couponSyncHistoryHeader: [
        {
          field: "history_id", key: "sync_history_1a", title: "Mã LS", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span>{row['id']}</span>
            );
          },
        },
        {
          field: "user_name", key: "sync_history_2", title: "Người đồng bộ", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span>{row['user'].fullname}</span>
            );
          },
        },
        {
          field: "type", key: "sync_history_3", title: "Loại phiếu", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            const typeName = helpers.getCouponTypeName(row['coupon_type']);
            return (
                <span>{typeName}</span>
            );
          },
        },
        {field: "file_name", key: "sync_history_d", title: "Tên file", align: "left",},
        {
          field: "time",
          key: "sync_history_g",
          title: "Thời gian đồng bộ",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            const time = moment(row.created_at).format('HH:mm DD-MM-YYYY');
            return (
                <span>{time}</span>
            );
          },
        },
        {
          field: "status",
          key: "sync_history_e",
          title: "Trạng thái",
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {

            let statusText = 'Đang đồng bộ';
            let backgroundColor = 'background_r400';

            if (row.status === 2) {
              statusText = 'Hoàn thành';
              backgroundColor = 'background_b400';
            } else if (row.status === 3) {
              statusText = 'Xóa 1 phần';
              backgroundColor = 'background_o400';
            } else if (row.status === 4) {
              statusText = 'Xóa toàn bộ';
              backgroundColor = 'background_r400';
            }

            return (
                <span class={'inventory-status-display ' + backgroundColor}>{statusText}</span>
            );
          },
        },
        {
          field: "error_count",
          key: "sync_history_h",
          title: "Số lỗi",
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            const errorCount = helpers.convertStringToInt(row.message.length).toLocaleString();
            const color = errorCount > 0 ? 'secondary_r400' : ''
            return (
                <span class={'cursor-pointer ' + color}
                      on-click={() => this.showErrorModal(row.message)}>{errorCount}</span>
            );
          },
        },
        {
          field: "file_rows", key: "sync_history_k", title: "Tổng số dòng", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return helpers.convertStringToInt(row.file_rows).toLocaleString();
          }
        },
        {
          field: "total_coupon", key: "sync_history_k1", title: "Số phiếu tạo thành công", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return helpers.convertStringToInt(row.total_coupon).toLocaleString();
          }
        },
        {
          field: "deleted_at", key: "sync_history_k2", title: "Thời điểm xóa file", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span>{row['deleted_at']}</span>
            );
          }
        },
        {
          field: "action",
          key: "sync_history_i",
          title: "Hành động",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            const downloadUrl = 'https://azagroup.asia/api/v1/download-excel/';
            const exportExcelUrl = 'https://azagroup.asia/api/v1/export-excel/' + row.id;
            return (
                <div class="">
                  <a class="pt-5px tooltip" href={downloadUrl + row.file_name}>
                    <span class="tooltiptext">Tải File</span>
                    <span class="material-symbols-outlined show-detail">file_download</span>
                  </a>
                  {
                    (row.status === 2 || row.status === 3) &&
                    <span class="pt-5px tooltip ml-10px" on-click={() => this.exportExcelUrl(exportExcelUrl)}>
                       <span class="material-symbols-outlined show-detail">download_for_offline</span>
                      <span class="tooltiptext">Xuất excel dữ liệu đồng bộ thành công</span>
                    </span>
                  }
                  {
                    moment().diff(row.created_at, 'hours') <= 24 && row.status === 2 &&
                    <div class="pt-5px cursor-pointer tooltip ml-10px"
                         on-click={() => this.showConfirmDeleteOrder(row)}>
                      <span span class="tooltiptext">Xóa đơn/phiếu theo file</span><span
                      class="material-symbols-outlined show-detail">delete</span>
                    </div>
                  }
                </div>
            );
          },
        },
      ]
    };
  },
  mounted() {
    this.getCouponSyncHistory();
  },
  watch: {
    'isLoad': function (newVal) {
      this.$emit('change-is-load', newVal);
    },
  },
  methods: {
    refreshPagingParams() {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
    },
    handleFilterChange(filters) {
      this.refreshPagingParams();
      this.filterParams.end_date = filters.end_date
      this.filterParams.start_date = filters.start_date
      this.getCouponSyncHistory();
    },
    getCouponSyncHistory() {
      this.couponSyncHistories = [];
      this.isLoad = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let params = {...this.filterParams, ...paging};

      InventoryService.getCouponSyncHistory(params).then(res => {
        if (res) {
          this.pagingParams.lastPage = res.last_page;
          this.pagingParams.total = res.total;
          this.couponSyncHistories = res.data;
        }
        this.isLoad = false;
      });
    },
    changePagingParam(value) {
      this.pagingParams = value;
      this.getCouponSyncHistory();
    },

    exportExcelUrl(url) {
      window.open(url, '_blank');
    },

    showErrorModal(errors) {
      this.$modal.show('errors-modal', {errors: errors});
    },

    deleteInventoryOrder(sync_history_id) {
      InventoryService.deleteMultiOrder({
        ids: [],
        sync_history_id: sync_history_id
      }).then(res => {
        if (res.status) {
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/success", 'Bạn đã xóa thành công');
        } else {
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra');
        }
      });
    },
    deleteCouponByInventorySyncId(sync_history_id) {
      InventoryService.deleteCouponByInventorySyncId({
        sync_history_id: sync_history_id
      }).then(res => {
        if (res.status) {
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/success", 'Bạn đã xóa thành công');
        } else {
          this.$modal.hide('dialog');
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra');
        }
      });
    },
    showConfirmDeleteOrder(row) {
      this.$modal.show('dialog', {
        title: 'Xóa đơn đặt hàng theo phiếu đồng bộ?',
        text: `Bạn có chắc chắn muốn xóa các đơn đặt hàng của phiếu đồng bộ này?`,
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              if (row.coupon_type === INVENTORY_COUPON_TYPE.DON_DAT_MUA) {
                this.deleteCouponByInventorySyncId(row.id);
              } else {
                this.deleteCouponByInventorySyncId(row.id);
              }
            }
          }
        ]
      })
    },
  },
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }

  .show-detail {
    font-size: 20px;
    vertical-align: inherit;

    &:hover {
      cursor: pointer;
      color: red;
    }
  }

}
</style>