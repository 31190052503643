<template>
  <div class="link-metadata__container" v-if="linkMetadata">
    <button class="close-btn cursor-pointer" @click="closeLinkPreview"
            type="button" v-if="action === 'HANDLE_DATA'">
      <svg width="24" height="24" fill="var(--spectrum-global-color-contentPrimary)"
           xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.53 4.47a.75.75 0 0 1 0 1.06l-14 14a.75.75 0 0 1-1.06-1.06l14-14a.75.75 0 0 1 1.06 0Z"
              fill="#18202A"></path>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.47 4.47a.75.75 0 0 1 1.06 0l14 14a.75.75 0 1 1-1.06 1.06l-14-14a.75.75 0 0 1 0-1.06Z"
              fill="#18202A"></path>
      </svg>
    </button>
    <a :href="linkMetadata.url" target="_blank" class="link-preview-container">
      <img v-if="linkMetadata.image"
           :src="linkMetadata.image" alt="" class="img">
      <div class="box-right-content">
        <p class="src" v-if="linkMetadata.url">{{linkMetadata.url}}</p>
        <p class="title" v-if="linkMetadata.title">{{linkMetadata.title}}</p>
        <p class="desc" v-if="linkMetadata.description">{{linkMetadata.description}}</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "LinkMetadata",
  props: {
    linkMetadata: {
      type: Object,
      default: null,
    },
    action: {
      type: String,
      default: 'HANDLE_DATA'
    }
  },
  methods: {
    closeLinkPreview() {
      this.$emit("close-link-preview");
    },
  }
}
</script>

<style lang="scss">
.link-metadata__container {
  position: relative;
  width: 100%;
  .close-btn {
    background-color: #0000!important;
    border-color: #0000!important;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    padding: 7px;
    position: absolute;
    right: 0;
    top: 1px;
    z-index: 5;
  }
  .link-preview-container {
    background: #f1f2f4;
    border: 1px solid #dedfe2;
    border-radius: 6px;
    display: flex;
    margin-bottom: 12px;
    margin-top: 1rem;
    max-height: 126px;
    overflow: hidden;
    padding: 2px;
    position: relative;
    text-align: left;
    width: 100%;
    .img {
      border-radius: 2px;
      height: 120px;
      object-fit: cover;
      position: relative;
      width: 120px;
    }
    .box-right-content {
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 130px);
      padding-left: 6px;
      .src {
        color: #a9a9a9;
        height: 2em;
        line-height: 2em;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .desc {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        margin: 0;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
  }
}

</style>