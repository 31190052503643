<template>
  <div>
    <template v-if="isNoti">
      <div
          @click="close()"
          data-notify="container"
          class="alert open alert-with-icon"
          role="alert"
          :class="[verticalAlign, horizontalAlign, alertType]"
          :style="customPosition"
          data-notify-position="top-center"
      >
        <button
            type="button"
            aria-hidden="true"
            class="close"
            data-notify="dismiss"
            @click="close"
        >
          ×
        </button>
        <i data-notify="icon" class="material-icons" v-if="icon">{{ icon }}</i>
        <span data-notify="message" v-html="message"></span>
      </div>

    </template>
    <template v-if="isFirebaseNoti">
      <div
          @click="close()"
          data-notify="container"
          class="alert open alert-firebase"
          role="alert"
          :class="[verticalAlign, horizontalAlign, alertType]"
          :style="customPosition"
          data-notify-position="top-center"
      >
        <router-link :to="firebaseNotiContent.url">
          <md-list-item class="p-0px m-0px">
            <!--            <md-avatar>-->
            <!--              <img class="avatar" :src="firebaseNotiContent.image ? firebaseNotiContent.image:'/img/profile/avatar.png'">-->
            <!--            </md-avatar>-->
            <div class="message text-300-default">
              <template v-if="firebaseNotiContent.type == NOTIFICATION_TYPE.ADD_MEMBER">
                Bạn đã được <span class="text-300-medium">{{
                  detectData(detectData(firebaseNotiContent.data)).user.name
                }}</span> thêm vào <span class="text-300-medium">{{
                  detectData(firebaseNotiContent.data).group.name
                }}</span>
              </template>
              <template v-if="firebaseNotiContent.type == NOTIFICATION_TYPE.CREATE_POST">
                <span class="text-300-medium">{{ detectData(firebaseNotiContent.data).user.name }}</span> đã đăng bài
                vào <span class="text-300-medium">{{ detectData(firebaseNotiContent.data).group.name }}</span>
              </template>
              <template v-if="firebaseNotiContent.type == NOTIFICATION_TYPE.LIKE_POST">
                <span class="text-300-medium">{{ detectData(firebaseNotiContent.data).user.name }}</span> đã thích bài
                viết của bạn trong <span class="text-300-medium">{{
                  detectData(firebaseNotiContent.data).group.name
                }}</span>
              </template>
              <template v-if="firebaseNotiContent.type == NOTIFICATION_TYPE.COMMENT_POST">
                <template
                    v-if="detectData(firebaseNotiContent.data).created_by && detectData(firebaseNotiContent.data).created_by.id != user.id">
                  <span class="text-300-medium">{{ detectData(firebaseNotiContent.data).user.name }}</span> đã bình luận
                  bài viết của <span class="text-300-medium">{{
                    detectData(firebaseNotiContent.data).created_by.name
                  }}</span> trong <span class="text-300-medium">{{
                    detectData(firebaseNotiContent.data).group.name
                  }}</span>
                </template>
                <template v-else>
                  <span class="text-300-medium">{{ detectData(firebaseNotiContent.data).user.name }}</span> đã bình luận
                  bài viết của bạn trong <span
                    class="text-300-medium">{{ detectData(firebaseNotiContent.data).group.name }}</span>
                </template>
              </template>
              <div class="create-time mt-5px">{{ fromNow(firebaseNotiContent.created_at) }}</div>
            </div>
          </md-list-item>
        </router-link>
      </div>
    </template>

    <template v-if="isChatNoti">
      <div
          @click="close()"
          data-notify="container"
          class="alert open alert-firebase"
          role="alert"
          :class="[verticalAlign, horizontalAlign, alertType]"
          :style="customPosition"
          data-notify-position="top-center"
      >
        <div class="header">
          <span class="text-400-semibold neutral_2">Tin nhắn mới</span>
          <md-icon class="float-right neutral_2">close</md-icon>
        </div>
        <router-link :to="'/messenger/'+chatContent.group_id">
          <md-list-item class="message-noti">
            <md-avatar>
              <img v-if="chatContent.group.type === 'USER'" :src="chatContent.sender.avatar || '/img/profile/avatar.png'">
              <img v-else :src="chatContent.group.avatar || '/img/group/user-group.svg'">
            </md-avatar>
            <div class="text-content">
              <div class="text-400-medium text-1-line mb-5px">
                <span v-if="chatContent.group.type === 'USER'">{{ chatContent.sender.display_name || chatContent.sender.fullname}}</span>
                <span v-else>{{ chatContent.group.name }}</span>
              </div>
              <div class="text-content__message">
                <span v-if="chatContent.group.type === 'USER'" class="text-400-default text-1-line">{{ chatContent.text }}</span>
                <span v-else class="text-400-default text-1-line">{{chatContent.sender.fullname}} : {{ chatContent.text }}</span>
              </div>
              <div class="text-200-medium create-time secondary_b400">Vừa xong</div>
            </div>
          </md-list-item>
        </router-link>
      </div>
    </template>

    <template v-if="isChatNotiReaction">
      <div
          @click="close()"
          data-notify="container"
          class="alert open alert-firebase"
          role="alert"
          :class="[verticalAlign, horizontalAlign, alertType]"
          :style="customPosition"
          data-notify-position="top-center"
      >
        <router-link :to="'/messenger/' + chatContent.chat_group_id">
          <md-list-item class="p-10px m-0px">
            <md-avatar>
              <img class="avatar" :src="chatContent.user_reacted.avatar || '/img/profile/avatar.png'">
            </md-avatar>
            <div class="message">
              <span class="text-400-medium">{{ chatContent.user_reacted.fullname }} đã tương tác với tin nhắn của bạn</span>
            </div>
          </md-list-item>
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
  import {NOTIFICATION_TYPE} from "../../const/const";
  import moment from "moment";

  export default {
    name: "notification",
    props: {
      message: String,
      icon: String,
      verticalAlign: {
        type: String,
        default: "top"
      },
      horizontalAlign: {
        type: String,
        default: "center"
      },
      type: {
        type: String,
        default: "info"
      },
      timeout: {
        type: Number,
        default: 10500
      },
      timestamp: {
        type: Date,
        default: () => new Date()
      },
      isNoti: {
        type: Boolean,
        default: false
      },
      isFirebaseNoti: {
        type: Boolean,
        default: false
      },
      firebaseNotiContent: {
        type: Object,
        default: () => {
          return {};
        }
      },
      isChatNoti: {
        type: Boolean,
        default: false
      },
      isChatNotiReaction: {
        type: Boolean,
        default: false
      },
      chatContent: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        elmHeight: 0,
        NOTIFICATION_TYPE: NOTIFICATION_TYPE,
        user: this.$store.state.auth.user,
      };
    },
    computed: {
      hasIcon() {
        return this.icon && this.icon.length > 0;
      },
      alertType() {
        return `alert-${this.type}`;
      },
      customPosition() {
        let initialMargin = 20;
        let alertHeight = this.elmHeight + 10;
        let sameAlertsCount = this.$notifications.state.filter(alert => {
          return (
            alert.horizontalAlign === this.horizontalAlign &&
            alert.verticalAlign === this.verticalAlign &&
            alert.timestamp <= this.timestamp
          );
        }).length;
        let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
        let styles = {};
        if (this.verticalAlign === "top") {
          styles.top = `${pixels}px`;
        } else {
          styles.bottom = `${pixels}px`;
        }

        if (this.isFirebaseNoti) {
          styles.bottom = `${(sameAlertsCount - 1) * 100 + 20}px`;
        }
        return styles;
      }
    },
    methods: {
      close() {
        this.$emit("on-close", this.timestamp);
      },
      detectData(data) {
        return JSON.parse(data)
      },
      fromNow(date) {
        return moment(date).locale("vi").fromNow();
      },
    },
    mounted() {
      this.elmHeight = this.$el.clientHeight;
      if (this.timeout) {
        setTimeout(this.close, this.timeout);
      }
    }
  };
</script>
<style lang="scss" scoped>
  @media screen and (max-width: 755px) {
    .alert {
      width: auto !important;
      margin: 0 10px;

      &.left {
        left: 0 !important;
      }

      &.right {
        right: 0 !important;
      }

      &.center {
        margin: 0 10px !important;
      }
    }
  }

  .alert {
    z-index: 100;
    cursor: pointer;
    position: fixed;
    width: 41%;

    &.center {
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &.left,
    &.right,
    &.center {
      margin-top: 10px;
    }

    &.left {
      left: 20px;
    }

    &.right {
      right: 20px;
    }

    span {
      max-width: none;
    }
  }

  .alert-firebase {
    background-color: white !important;
    width: 400px !important;
    padding: 0px;
    box-shadow: -2px -1px 20px -10px rgb(0 0 0 / 16%), 1px 1px 4px 0px rgb(0 0 0 / 28%) !important;
    bottom: 60px !important;
    right: 50px !important;

    .header {
      padding: 8px 10px;
      border-bottom: 1px solid #d8d8d8;
      span {
        display: inline;
      }
      .md-icon {
        font-size: 20px !important;
        font-weight: 600;
      }
    }

    a {
      color: black !important;
    }
    .message-noti {
      margin: 0 !important;

      .md-avatar, .md-avatar img{
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%;
        border: 1px solid #dadada;
      }
      .md-list-item-content {
        align-items: start !important;
        justify-content: start !important;
      }
      .text-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        .text-content__message {
          margin-bottom: 8px;
        }
      }
    }
  }
</style>
