<template>
  <div class="select-post-background__container custom-scroll-bar-2">
    <md-card class="modal-header-custom">
      <md-dialog-title>
        <div class="poll-header">
              <span class="material-icons cursor-pointer"
                    @click="$emit('cancel-selecting-background')">keyboard_backspace</span>
          <span class="neutral_1 heading-500 text-header-modal">Chọn ảnh nền</span>
        </div>
      </md-dialog-title>
    </md-card>

    <div class="w_100 select-post-background__main">
      <div class="post-background-item__wrapper" v-for="(item, index) in backgroundImageList" :key="'select-background-item-' + index">
        <button class="post-background-item" :class="{active: item.path === backgroundSelected.background_url}" :style="'background-image: url(' + item.thumbnail.medium_thumb + ')'" @click="$emit('select-post-background', {'background_url': item.path, 'color': item.color})">
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  computed: {
  },
  methods: {
  },
  props: {
    backgroundSelected: {
      type: Object,
      default: null
    },
    backgroundImageList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.select-post-background__container {
  .select-post-background__main {
    display: flex;
    flex-wrap: wrap;
    max-height: 600px;
    overflow: auto;
  }
  .post-background-item__wrapper {
    width: 25%;
    height: 185px;
    padding: 15px;
  }
  .post-background-item {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 2px solid transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    transition: all 0.5s ease 0s;
  }
  .post-background-item:hover {
    opacity: .5;
  }
  .post-background-item.active {
    outline: none;
    border-color: green;
  }
}
</style>