var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{staticClass:"modal-custom",attrs:{"name":"create-group-modal","click-to-close":false,"width":"600px","height":"auto","transition":"easeInOut"},on:{"before-open":_vm.beforeOpened,"before-close":_vm.beforeClosed}},[_c('vue-element-loading',{attrs:{"active":_vm.show,"spinner":"bar-fade-scale"}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('md-card',{staticClass:"modal-header-custom"},[_c('md-dialog-title',[_c('span',{staticClass:"neutral_1 heading-500 text-header-modal"},[_vm._v("Tạo nhóm mới")]),_c('span',{staticClass:"material-icons float-right cursor-pointer",on:{"click":function($event){return _vm.$modal.hide('create-group-modal')}}},[_vm._v("close")])])],1),_c('md-card-content',[_c('div',{staticClass:"md-gutter"},[_c('label',{staticClass:"required heading-400 neutral_3"},[_vm._v("Tên nhóm")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control input-custom",attrs:{"type":"text","placeholder":"Tên nhóm","required":"","name":"name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})]),_c('div',{staticClass:"md-gutter pt-15px pb-15px"},[_c('label',{staticClass:"required heading-400 neutral_3"},[_vm._v("Chọn loại nhóm")]),_c('v-select',{attrs:{"options":_vm.typeOptions,"label":"title","placeholder":"Chọn loại nhóm","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":_vm.form.type == null}},'input',attributes,false),events))]}},{key:"option",fn:function(origin){return [_c('div',{staticClass:"flex"},[_c('div',{staticClass:"col text-overflow-ellipsis"},[_c('span',{staticClass:"text-400-medium neutral-1"},[_vm._v(_vm._s(origin.title))])]),_c('div',{staticClass:"col text-overflow-ellipsis"},[_c('span',{staticClass:"text-300-default neutral_2"},[_vm._v(_vm._s(origin.description))])])])]}}]),model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),(_vm.form.type == 0)?_c('div',{staticClass:"md-gutter pt-15px pb-15px"},[_c('label',{staticClass:"required heading-400 neutral_3"},[_vm._v("Chọn quy trình")]),_c('multiselect',{attrs:{"label":"title","track-by":"id","placeholder":"Chọn quy trình","open-direction":"bottom","options":_vm.categoryOptions,"multiple":false,"selectLabel":'Chọn',"deselectLabel":'Bỏ chọn',"selectedLabel":'Đã chọn',"searchable":true,"internal-search":false,"clear-on-select":false,"close-on-select":true,"show-no-results":false,"hide-selected":false},on:{"search-change":_vm.onSearchCategory},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('md-list-item',[_c('div',{staticClass:"md-list-item-text"},[_c('span',[_vm._v(_vm._s(option.title))]),_c('p',[_vm._v(_vm._s(option.description))])])])]}}],null,false,1200764772),model:{value:(_vm.categoryModel),callback:function ($$v) {_vm.categoryModel=$$v},expression:"categoryModel"}},[_c('template',{slot:"afterList"},[(_vm.hasNextPageForCategory)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.reachedEndOfListForCategory),expression:"reachedEndOfListForCategory"}]}):_vm._e()]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Chưa có dữ liệu!")])],2)],1):_vm._e(),_c('div',{staticClass:"md-gutter pt-15px pb-15px"},[_c('label',{staticClass:"heading-400 neutral_3"},[_vm._v("Quyền tham gia")]),_c('v-select',{attrs:{"options":_vm.joinPolicyOptions,"label":"title","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(origin){return [_c('div',{staticClass:"flex"},[_c('div',{staticClass:"col text-overflow-ellipsis"},[_c('span',{staticClass:"text-400-medium neutral-1"},[_vm._v(_vm._s(origin.title))])])])]}}]),model:{value:(_vm.form.join_policy),callback:function ($$v) {_vm.$set(_vm.form, "join_policy", $$v)},expression:"form.join_policy"}})],1),_c('div',{staticClass:"md-gutter pt-15px pb-15px"},[_c('label',{staticClass:"heading-400 neutral_3"},[_vm._v("Thêm thành viên")]),_c('multiselect',{attrs:{"label":"fullname","track-by":"id","placeholder":"Chọn thành viên","open-direction":"bottom","options":_vm.memberList,"multiple":true,"selectLabel":'Chọn',"deselectLabel":'Bỏ chọn',"selectedLabel":'Đã chọn',"searchable":true,"internal-search":false,"clear-on-select":false,"close-on-select":false,"show-no-results":false,"hide-selected":false},on:{"search-change":_vm.onSearchUser},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('md-list-item',[_c('md-avatar',[_c('img',{staticClass:"avatar",attrs:{"src":option.avatar_url?option.avatar_url:'/img/profile/avatar.png'}})]),_c('div',{staticClass:"md-list-item-text"},[_c('span',[_vm._v(_vm._s(option.fullname))]),_c('p',[_vm._v(_vm._s(option.team ? option.team.name : ''))])])],1)]}}]),model:{value:(_vm.members),callback:function ($$v) {_vm.members=$$v},expression:"members"}},[_c('template',{slot:"afterList"},[(_vm.hasNextPageForMember)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.reachedEndOfListForMember),expression:"reachedEndOfListForMember"}]}):_vm._e()]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Chưa có dữ liệu!")])],2)],1)]),_c('md-dialog-actions',[_c('button',{staticClass:"btn-active md-button",attrs:{"type":"submit"}},[_vm._v("Tạo nhóm")]),_c('button',{staticClass:"btn-stroke md-button",on:{"click":function($event){return _vm.$modal.hide('create-group-modal')}}},[_vm._v("Huỷ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }