<template>
  <div>
    <modal name="member-list-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom member-list-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Tạo tin nhắn riêng</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('member-list-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <div style="width: 100%; height: auto">
        <div>
          <div class="input-auto-complete">
            <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" @keyup="searchUser()" v-model="search">
          </div>

          <div class="content my-15px">
            <div v-for="member in memberList" :key="member.id">
                <md-list-item class="cursor-pointer chooseMember user-detail" @click="createChat(member)">
                  <md-avatar>
                    <img class="avatar" :src="member.avatar_url?member.avatar_url:'/img/profile/avatar.png'">
                  </md-avatar>
                  <div class="md-list-item-text">
                    <div class="text-400-medium mb-10px fullname">{{ member.fullname }}</div>
                    <div class="detail-info" v-if="member.agency">
                      <span class="material-icons">schema</span>
                      <span class="text-2-line text-info-name">
                        <span>{{ member.agency.name }}</span>
                        <span class="ml-5px text-300-default" v-if="member.team">></span>
                        <span class="ml-5px text-300-default" v-if="member.team">{{ member.team.name }}</span>
                    </span>
                    </div>
                    <div class="detail-info" v-else>
                      <span class="material-icons">schema</span>
                      <span class="text-2-line text-info-name">AZA GROUP</span>
                    </div>
                    <div class="detail-info" v-if="member.position">
                      <span class="material-icons">account_box</span>
                      <span class="text-info-name">{{ member.position.name }}</span>
                    </div>
                    <div class="detail-info">
                      <span class="material-icons">email</span>
                      <span class="text-info-name">{{ member.email }}</span>
                    </div>
                  </div>
                </md-list-item>
              </div>
          </div>
        </div>
      </div>

    </modal>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";
import SettingService from "../../../store/services/SettingService";
import {GROUP_CHAT_TYPE, CREATE_POST_LEVEL, MODEL_TYPE, POST_TYPE} from "../../../const/const";
import PostService from "../../../store/services/PostService";
import ChatService from "../../../store/services/ChatService";
import moment from "moment";
// import TreeViewItem from "../../Workplace/Pages/TreeViewItem";

export default {
  // components: {TreeViewItem},
  name: 'member-list-modal',
  computed: {},
  mounted() {
    this.getEmployees(this.member_params);
    this.getPosition(this.position_params);
    if (this.$store.state.companyStructure.departmentTree.length == 0) {
      this.getDepartmentTree(true);
    } else {
      this.departmentList = this.$store.state.companyStructure.departmentTree;
      this.departmentListFilter = this.$store.state.companyStructure.departmentTree;
    }
  },
  watch: {
    items(newValue, oldValue) {
      if (this.tabActive === 1) {
        this.listItems = this.memberListFilter.filter(item => newValue.includes(item.id));
      }
    },
    // search(newValue, oldValue) {
    //   if (this.tabActive === 1) {
    //     this.member_params.keyword = newValue;
    //     this.getEmployees(this.member_params);
    //   }
    // }
  },
  data() {
    return {
      tabActive: 1,
      member_params: {
        page: 1,
        per_page: 20,
        keyword: "",
        status: 1
      },
      position_params: {
        keyword: ""
      },
      member_last_page: 1,
      memberList: [],
      memberListFilter: [],
      positionList: [],
      positionListFilter: [],
      departmentList: [],
      departmentListFilter: [],
      items: [],
      listItems: [],
      forms: {
        name: '',
        model_ids: [],
        model_type: GROUP_CHAT_TYPE.USER
      },
      search: ''
    };
  },
  methods: {
    beforeOpened(event) {
      this.forms.name = ''
      this.forms.model_ids = [];
      this.items = []
    },

    beforeClosed(event) {
    },

    async getEmployees(params = {}) {
      params.page = this.member_params.page;
      await EmployeeService.getEmployees(params).then(res => {
        if (res && res.data) {
          this.memberList = res.data
          this.memberListFilter = this.memberListFilter.concat(this.memberList);
          const unique = [...new Map(this.memberListFilter.map((m) => [m.id, m])).values()];
          this.memberListFilter = unique;
          this.member_last_page = res.meta.last_page;
        }
      });
    },

    async getPosition(params = {}) {
      await SettingService.getPositions(params).then(res => {
        if (res && res.data) {
          this.positionList = res.data;
          this.positionListFilter = res.data;
        }
      });
    },

    chosenTab(tab) {
      this.tabActive = tab;
      this.items = [];
      this.search = '';
    },

    removeItem(item, index) {
      this.items.splice(index, 1);
    },

    async createChat(member) {

      //================CHECK-EXITS-GROUP-CHAT===============//
      let isChatExits = await this.checkGroupExits(member.id);

      if(isChatExits){
        //================CREATE-GROUP-CHAT===============//
        const formData = new FormData();

        //ADD VALUE
        formData.append("name", member.fullname);
        formData.append("model_type", GROUP_CHAT_TYPE.USER);
        formData.append("model_ids[]", member.id);

        // POST CHAT IS GROUP ALREADY EXIST
        await ChatService.createGroupChat(formData).then(res => {
            if (res.status) {
              // UPDATE STATE & GO TO MESSAGER
              delete res.data.isCreateChat;
              this.$store.commit('chat/updateChatGroups', res.data);
              this.$store.commit('chat/updateChatGroupSelected', res.data);
              this.$store.dispatch("chat/getChatGroups");
              this.$router.push("/messenger/" + res.data.id);
            } else {
              this.$store.dispatch("alerts/error", 'Bạn tạo cuộc trò chuyện không thành công. Nguyên nhân: ' + res.message);
            }
        });
      }else{
        const selectedChatGroup = {
          avatar: member.avatar_url,
          created_at: moment().format(),
          name: member.fullname,
          last_message: {},
          updated_at : moment().format(),
          user_id: member.id,
          isCreateChat: true,
          type: GROUP_CHAT_TYPE.USER
        };
        
         // UPDATE STATE & GO TO MESSAGER
        this.$store.commit('chat/updateChatGroupSelected', selectedChatGroup);
        this.$router.push("/messenger/new" + moment());
      }
      
      // CLOSE MODAL
      this.$modal.hide('member-list-modal');
    },

    async getDepartmentTree() {
      await SettingService.getDepartmentTree({keyword: this.keyword}).then(res => {
        if (res && res.data) {
          this.departmentList = res.data;
          this.departmentListFilter = res.data;
          this.$store.commit("updateDepartmentTree", res.data);
        }
      });
    },

    handleUpdateItem(items) {
      this.items = items;
    },

    async checkGroupExits(user_id){
      let status = false;
      
      await ChatService.checkChatExits({user_id : user_id}).then(res => {
        if(res.status){
          status = res.data.status;
        }
      });

      return status;
    },
    
    searchUser(){
      // clear timeout variable
      clearTimeout(this.timeout);
      const self = this;
      this.timeout = setTimeout(function () {
            // enter this block of code after 1 second
            // handle stuff, call search API etc.
            self.member_params.keyword = self.search;
            self.getEmployees(self.member_params);
        }, 800);
    }

  }

}
</script>

<style lang="scss">
.chooseMember:hover {
    background: var(--aza-color-sliver);
    border-radius: 5px;
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}

.user-detail {
  .md-ripple {
    padding: 6px 10px !important;
    align-items: start;
  }
  .fullname {
    margin-bottom: 5px !important;
  }
  .detail-info {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    .material-icons {
      width: auto;
      font-size: 16px;
      color: #4b4b4b;
    }
    .text-info-name {
      margin-left: 5px;
      font-size: 12px;
      color: #4b4b4b;
    }
  }
}

.member-list-modal {

  .content {
    height: 350px;
    overflow-y: scroll;

    .info-text {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }

  .md-list-item:focus {
    border-radius: 8px;

    .check-icon {
      width: 20px;
      opacity: 1;
    }
  }

  .check-icon {
    width: 20px;
    opacity: 0;
    color: rgb(48, 169, 96);
  }

  .input-auto-complete {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border: none;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    max-height: 90px;
    overflow-y: auto;
    padding: 8px 12px;
    min-height: 44px;
    border-bottom: 1px solid #F0F0F0;

    .item-select {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 24px;
      margin: 5px;
      background-color: rgb(236, 247, 231);
      border: none;
      border-radius: 6px;
      box-sizing: content-box;
      padding: 4px 8px;
      outline: 0px;
      overflow: hidden;
      color: rgb(48, 169, 96);
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      width: fit-content;

      .close-icon {
        display: flex;
        width: 16px;
        height: 16px;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border: none;
        border-radius: 50%;
        background: white;
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .input-search-field {
      font-size: 14px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0px;
      min-width: 30px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      border: 0px;
      margin: 0px;
      outline: 0px;
    }
  }

  .create-post-btn {
    width: 100% !important;
  }
}


</style>
