<template>
  <div class="first-step-page-container ">
    <div class="content-wrapper">
      <zoom-center-transition :duration="200" mode="out-in">
        <!-- your content here -->
        <router-view/>
      </zoom-center-transition>
    </div>
    <notifications></notifications>
  </div>
</template>
<script>

export default  {
};
</script>
<style lang="scss">
 .first-step-page-container {
   background: url("/img/first-step/home.jpg") no-repeat center;
   width: 100%;
   height: 100%;
   color: white;
   font-size: 16px;
   font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
   display: flex;

   .content-wrapper {
     width: 1001px;
     margin: auto;
     padding: 40px;
     background-color: rgba(16, 15, 13, 0.48);
     text-align: center;
   }

   .form-password, .form-profile {
     width: 420px;
     margin: 20px auto;
     .input-wrapper {
       width: 100%;
       display: flex;
       align-items: center;
       background-color: white;
       border-radius: 6px;
       margin-top: 20px;
       height: 40px;
       input {
         border: none;
         height: 100%;
         width: 100%;
         background: transparent;
       }
       .md-icon {
         margin: 10px;
       }
     }
     .change-pass-btn {
       width: 100%;
       height: 40px;
       cursor: pointer;
       background-color: #28a745;
       border-radius: 6px;
       border: none;
       color: white;
       font-size: 16px;
     }
   }
 }
</style>
