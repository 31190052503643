<template>
  <div>
    <inventory-filter
        @change="handleFilterChange"
        :pick-type="[INVENTORY_TYPE_OPTIONS.SKU]"
        :tab-active="tabActive"
        :id-filter-date="'ReportExistingPresent'"
        :show-date="false"
        :show-inventory="true"
    ></inventory-filter>

    <div class="mb-20px d-flex-space-between">
      <div class="heading-600 mt-4">Tồn hiện tại</div>
      <div class="d-flex-center">
        <multiselect
            label="name"
            track-by="name"
            open-direction="bottom"
            :options="[{'id': 1,'name': 'Tất cả'},{'id': 2,'name': 'Chỉ tồn âm'}]"
            :selectLabel="''"
            :deselectLabel="''"
            :selectedLabel="''"
            :allow-empty="false"
            v-model="typeSelected"
        >
          <template slot="option" slot-scope="{ option }">
            <md-list-item>
              <div class="md-list-item-text">
                <span>{{ option.name }}</span>
              </div>
            </md-list-item>
          </template>
        </multiselect>
      </div>
    </div>
    <div>
      <div class="w_100">
        <div class="float-right">
          <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                               @change-params="changePagingParam"/>
        </div>
      </div>
      <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
      <ve-table border-y :columns="totalSubStockColumns" :table-data="totalStockInPage" :sort-option="sortOption"
                :cell-style-option="cellStyleOption"/>
    </div>
  </div>
</template>

<script>

import InventoryFilter from "../Filter";
import InventoryService from "../../../../../store/services/InventoryService";
import {INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../../const/const";
import {helpers} from "../../../../../helper/helpers";
import ShowInventoryTableModal from "@/pages/Components/Modal/ShowInventoryTableModal";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import axios from 'axios';
import moment from "moment";

export default {
  name: "ReportExistingPresent",
  components: {
    InventoryFilter,
    PaginationTemplate
  },
  data() {
    return {
      INVENTORY_TYPE_OPTIONS,
      isLoad: false,
      inventoryId: this.$route.params.inventory_id,
      tabActive: INVENTORY_REPORT_TAB.TOTAL_STOCK,
      totalStockParams: {
        start_date: '',
        end_date: '',
        product_id: [],
        inventory_id: this.$route.params.inventory_id,
      },
      inventoryList: [],
      totalSubStockColumns: [
        {
          field: "",
          key: "b1",
          title: "STT",
          width: 50,
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
            return ++rowIndex + index;
          },
        },
        {field: "sku", key: "b2", title: "SKU", align: "center"},
        {
          field: "product_name",
          key: "b3",
          title: "Sản phẩm",
          align: "left",
        },
        {field: "value", key: "b4", title: "Biến thể", align: "left"},
        {field: "dvt", key: "b5", title: "ĐVT", align: "left"},
        {
          title: "Tồn kho",
          align: "center",
          children: [
            {field: "last", key: "b18", title: "Số lượng", align: "right", sortBy: "",},
          ]
        },
      ],
      totalData: [],
      totalStockData: [],
      totalStockInPage: [],
      totalStockOfMainInventory: [],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      eventCustomOption: {
        bodyRowEvents: ({row, rowIndex}) => {
          return {
            click: (event) => {
              this.getInsufficientProduct(rowIndex);
            },
          };
        },
      },
      axiosSource: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      cellStyleOption: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (column.field === 'begin') {
            return "background_a400 text-400-medium";
          } else if (column.field === 'totalBuy') {
            return "background_o300 text-400-medium";
          } else if (column.field === 'totalSell') {
            return "background_yellow_a200 text-400-medium";
          } else if (column.field === 'last') {
            if (row.last < 0) {
              return "background_cyan_200 text-400-medium";
            } else {
              return "background_deep_purple_300 text-400-medium";
            }
          }
        },
        headerCellClass: ({column, rowIndex}) => {
          if (column.title === 'Đầu kỳ' || column.field === 'begin') {
            return "background_a400 text-400-medium";
          } else if (column.field === 'totalBuy') {
            return "background_o300 text-400-medium";
          } else if (column.field === 'totalSell') {
            return "background_yellow_a200 text-400-medium";
          } else if (column.title === 'Cuối kỳ' || column.field === 'last') {
            return "background_deep_purple_300 text-400-medium";
          } else {
            return "background_blue_100";
          }
        },
      },
      cellSpanOption: {
        bodyCellSpan: ({row, column, rowIndex}) => {
          if (rowIndex === 0) {
            if (column.field === "inventory_name") {
              return {
                colspan: 4,
                rowspan: 1,
              };
            } else if (column.field === "sku") {
              return {
                colspan: 4,
                rowspan: 1,
              };
            } else if (column.field === "product_name") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            } else if (column.field === "value") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            } else if (column.field === "dvt") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            }

          }
        },
      },
      inventoryParentIds: JSON.parse(localStorage.getItem("inventoryParentIds")),
      typeSelected: {'id': 1, 'name': 'Tất cả'},
    };
  },
  created() {
    let today = moment().format('YYYY-MM-DD')
    let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    this.totalStockParams.start_date = firstDateOfMonth;
    this.totalStockParams.end_date = today;
    this.changeDataInPage();
  },
  props: {},
  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.inventoryId = newVal;
      this.totalStockParams.inventory_id = newVal;
      this.totalStockParams.product_id = [];
      let today = moment().format('YYYY-MM-DD')
      let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      this.totalStockParams.start_date = firstDateOfMonth;
      this.totalStockParams.end_date = today;
      this.resetData();
      setTimeout(() => {
        this.getInsufficientProduct();
      }, 1000);
    },
    'totalStockData': function (newVal) {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
      this.pagingParams.total = this.totalStockData.length;
      this.changeDataInPage();
    },
    'isLoad': function (newVal) {
      this.$emit('change-is-load', newVal);
    },
    'typeSelected': function (newVal) {
      this.totalStockData = [];
      if (newVal.id === 1) {
        this.totalStockData = this.totalData;
      }
      if (newVal.id === 2) {
        const reg = /[,.]/g;
        this.totalStockData = this.totalData.filter(item => {
          return (
              item.last.replaceAll(reg, '') < 0
          );
        })
      }
    },
    // '$store.state.auth.tabActive'(newV) {
    //   if (newV == INVENTORY_REPORT_TAB.EXISTING_PRESENT) {
    //     this.getReportTotalStock();
    //   }
    // },
  },
  mounted() {
    if (this.isInventoryParent()) {
      const inventories = JSON.parse(localStorage.getItem("inventories"));
      for (let i = 0; i < inventories.length; i++) {
        if (inventories[i]['id'] == this.inventoryId) {
          this.totalStockParams.inventory_id = inventories[i]['children'][0]['id']
          break;
        }
      }
    }
    this.getInsufficientProduct();
  },
  methods: {
    async getInsufficientProduct(count = 0) {
      this.isLoad = true;
      this.totalStockData = [];
      if (this.axiosSource) {
        this.axiosSource.cancel('Canceled by user');
      }

      this.axiosSource = axios.CancelToken.source();
      let params = {...this.totalStockParams};
      params.cancelToken = this.axiosSource.token;

      InventoryService.getInsufficientProduct(params).then(res => {
        if (res) {
          this.inventoryList = res.products;
          for (let i = 0; i < this.inventoryList.length; i++) {
            let row = {...this.inventoryList[i]};
            for (let key in row) {
              let value = parseFloat(row[key]);
              if (!isNaN(value) && key !== "sku") {
                row[key] = value.toLocaleString('en');
              }
            }
            this.totalStockData.push(row);
          }
          this.totalData = this.totalStockData;
        }
        this.isLoad = false;
      }).catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        count++;
        if (count < 3) {
          setTimeout(() => {
            this.getInsufficientProduct(count)
          }, 1000);
        }
      });
    },

    changePagingParam(value) {
      this.pagingParams = value;
      this.changeDataInPage();
    },

    changeDataInPage() {
      this.totalStockInPage = [];
      let quotient = Math.floor(this.pagingParams.total / this.pagingParams.perPage);
      if (this.pagingParams.total / this.pagingParams.perPage > quotient) {
        quotient += 1;
      }
      this.pagingParams.lastPage = quotient;
      let startIndex = (this.pagingParams.page - 1) * this.pagingParams.perPage;
      let endIndex = startIndex + this.pagingParams.perPage;
      for (let i = startIndex; i < endIndex && i < this.totalStockData.length; i++) {
        if (this.totalStockData[i]) {
          this.totalStockInPage.push(this.totalStockData[i]);
        }
      }
    },

    resetData() {
      this.inventoryList = [];
      this.totalStockData = [];
      this.totalData = [];
      this.totalStockInPage = [];
    },

    sortChange(params) {
      this.pagingParams.page = 1;
      this.totalStockData.sort((a, b) => {
        for (const property in params) {
          const reg = /[,.]/g
          if (params[property] === 'asc') {
            return a[property].replaceAll(reg, '') - b[property].replaceAll(reg, '');
          } else if (params[property] === 'desc') {
            return b[property].replaceAll(reg, '') - a[property].replaceAll(reg, '');
          }
        }
        return 0;
      });
      this.changeDataInPage();
    },

    handleFilterChange(filters) {
      this.totalStockParams.end_date = filters.end_date
      this.totalStockParams.start_date = filters.start_date
      this.totalStockParams.product_id = filters.product_id
      this.totalStockParams.inventory_id = filters.inventory_id
      this.getInsufficientProduct();
    },

    isInventoryParent() {
      return this.inventoryParentIds.includes(Number(this.inventoryId));
    },
  }
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }

  .main-inventory {
    font-weight: bold !important;
    color: black !important;

    td {
      font-weight: 700 !important;
    }
  }

}
</style>