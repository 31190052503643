<template>
  <div class="forgot-password-container">
    <h2>
      Quên mật khẩu
    </h2>
    <div class="mt-20px">
        <span v-if="!hasSentEmail">
          Vui lòng nhập đúng email đã đăng ký theo tài khoản của bạn.
          <br>
          Chúng tôi sẽ gửi về email của bạn thông tin thay đổi mật khẩu mới
        </span>
        <span v-else style="color: red">
          Thông tin thay đổi mật khẩu đã được gửi vào email {{email}}.
          <br>
          Vui lòng kiểm tra hòm thư.
        </span>
      <div class="form-password" v-if="!hasSentEmail">
        <form action="" @submit.prevent="sendEmail">
          <div class="input-wrapper">
            <md-icon>email</md-icon>
            <input type="email" v-model="email" placeholder="Email" required>
          </div>
          <button class="change-pass-btn mt-20px">Gửi thông tin</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: '',
      hasSentEmail: false,
    }
  },
  methods: {
    sendEmail() {
      this.hasSentEmail = true
    }
  }
}
</script>

<style scoped>

</style>