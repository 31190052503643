<template>
  <div class="mt-10px">
    <video controls style="margin: auto">
      <source :src="media.path">
      Sorry, your browser doesn't support embedded videos.
    </video>
  </div>
</template>

<script>
export default {
  name: "video-content",
  props: {
    mediaObject: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  methods: {
    redirectToPostDetail(media) {
      this.$store.dispatch('refreshPostTotalPage');
      this.$store.state.post.previousDetail = 2;
      let link = media.single_post_id ? this.getLink(media.single_post_id) : this.getLink(this.mediaObject.post_id);
      this.$router.push(link);
    },
    getLink(singlePostId) {
      return '/post/image/' + singlePostId
    },
  }
};
</script>

<style lang="scss" scoped>
.image-content-wrapper2 {
  height: 600px;
}

.wrapper-image {
  height: 100%;
  cursor: pointer;
}

.wrapper-image1 {
  .card-media {
    max-height: 800px;
    background: black;

    img {
      margin: auto;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.wrapper-image2, .wrapper-image3, .wrapper-image4, .wrapper-image5 {
  .card-media {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    display: inline-block;
  }
}

.wrapper-image2 {
  .card-media:nth-child(1), .card-media:nth-child(2) {
    padding-bottom: 1px;
  }

  .card-media {
    height: 50%;
    width: 100%;
  }
}

.wrapper-image3 {
  .card-media:nth-child(1) {
    width: 100%;
    height: 60%;
  }

  .card-media:nth-child(2), .card-media:nth-child(3) {
    height: 40%;
    width: 50%;
  }

  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3) {
    padding-bottom: 1px;
    padding-right: 1px;
  }
}

.wrapper-image4 {
  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4) {
    width: 50%;
    height: 50%;
  }

  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4) {
    padding-bottom: 1px;
    padding-right: 1px;
  }
}

.wrapper-image5 {
  .card-media:nth-child(1), .card-media:nth-child(2) {
    width: 50%;
    height: 60%;
  }

  .card-media:nth-child(3), .card-media:nth-child(4), .card-media:nth-child(5) {
    width: calc(100% / 3);
    height: 40%;
  }

  .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4), .card-media:nth-child(5) {
    padding-bottom: 1px;
    padding-right: 1px;
  }

  .card-media:nth-child(5) {
    position: relative;

    .total-image-other-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.5;
      display: flex;
    }

    .total-image-other {
      position: absolute;
      top: 50%;
      align-self: center;
      text-align: center;
      width: 100%;
      color: white;
      font-size: 2rem;
      transform: translateY(-50%);
    }
  }
}
</style>
