<template>
  <div>
    <modal name="detail-order-coupon-modal"
           :click-to-close="false"
           width="95%"
           margin="auto"
           left="0"
           top="20px"
           padding="10px"
           height="95%"
           class="coupon-modal modal-custom detail-order-coupon-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
    <template v-if="this.item">
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Chi tiết đơn đặt hàng</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('detail-order-coupon-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <div class="my-15px" style="overflow: auto; max-height: 700px">
        <md-card-content>
          <div class="infomation-add">
            <div class="title-form">
               <span>Thông tin phiếu</span>
            </div>
            <div class="options-box-layout">
              <div class="options-left">
                <div class="option">
                    <div class="title-option">
                      <span class="title">Mã phiếu</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input v-model="item.code" disabled></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Người tạo</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.created_by.fullname"></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Trạng thái nhập hàng</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.import_status_name"></md-input>
                      </md-field>
                      <!-- <selection :options="[{label: 'Phiếu tạm', id: 0},{label: 'Đã hoàn thành', id: 1}]" :selected="0">
                      </selection> -->
                    </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title">Trạng thái thanh toán</span>
                  </div>
                  <div class="input-option">
                    <md-field>
                      <md-input disabled v-model="item.payment_status_name"></md-input>
                    </md-field>
                    <!-- <selection :options="[{label: 'Phiếu tạm', id: 0},{label: 'Đã hoàn thành', id: 1}]" :selected="0">
                    </selection> -->
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title">Ngày đặt hàng</span>
                  </div>
                  <div class="input-option">
                    <md-field>
                      <md-input disabled v-model="item.date"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title">Ngày dự kiến về kho</span>
                  </div>
                  <div class="input-option">
                    <md-field>
                      <md-input disabled v-model="item.due_date"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Ngày tạo</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.created_at"></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option" v-if="[1].includes(item.type)">
                    <div class="title-option">
                      <span class="title">Đơn đặt hàng</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.receipt.code"></md-input>
                      </md-field>
                    </div>
                </div>
              </div>
              <div class="options-right">
                <div class="option">
                  <div class="title-option">
                    <span class="title">Kho hàng</span>
                  </div>
                  <div class="input-option">
                    <md-field class="pd-0">
                      <md-input disabled v-model="item.inventory.name"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Tổng giá</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="totalPrice"></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option code-tracking" v-if="[1,2,5,6,8].includes(item.type)">
                    <div class="title-option">
                      <span class="title">Mã tracking</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input v-model="item.tracking" disabled></md-input>
                      </md-field>
                    </div>
                </div>
                <template v-if="item.type == 3 || item.type == 7">
                  <div class="option">
                    <div class="title-option">
                      <span class="title">Kho nhập</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="item.import_inventory"></md-input>
                      </md-field>
                    </div>
                  </div>
                  <div class="option">
                    <div class="title-option">
                      <span class="title">Kho xuất</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="item.export_inventory"></md-input>
                      </md-field>
                    </div>
                  </div>
                </template>


                <div class="option" v-if="[1,2,4,5,6,8].includes(item.type)">
                    <div class="title-option">
                      <span class="title">Kho hàng</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="item.inventory_main.name"></md-input>
                      </md-field>
                    </div>
                </div>
                <!-- <div class="option">
                    <div class="title-option">
                      <span class="title required">Kho nhập</span>
                    </div>
                    <div class="input-option">
                      <selection :options="[{label: 'Kho Việt Nam', id: 12}]">
                      </selection>
                    </div>
                </div> -->
                <div class="option">
                    <div class="title-option">
                      <span class="title">Ghi chú</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-textarea rows="3" disabled v-model="item.description"></md-textarea>
                      </md-field>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-content class="product-list">
          <div class="header-list">
            <span>Danh sách sản phẩm</span>
          </div>
          <inventory-table border-y :columns="PRODUCT_LIST" :table-data="product_list"/>
        </md-card-content>
        <md-card-content class="product-list" v-if="importGoodsCoupons.length > 0">
          <div class="header-list">
            <span>Danh sách phiếu nhập</span>
          </div>
          <inventory-table border-y :columns="importGoodsCouponHeader" :table-data="importGoodsCoupons"/>
        </md-card-content>
      </div>
      <md-dialog-actions class="p-15px w_100">
        <button class="create-post-btn btn-active md-button w_100" @click="$modal.hide('detail-order-coupon-modal')">
          Đóng
        </button>
      </md-dialog-actions>
    </template>
    </modal>
  </div>
</template>

<script>
// import Selection from '../Select2/Selection';
import moment from "moment";
import InventoryTable from "../../../pages/Workplace/Pages/Inventory/Table";
import {
  PRODUCT_LIST
} from "../../../const/const";
export default {
  name: 'detail-order-coupon-modal',
  components: {
    InventoryTable
  },
  data() {
    return {
      PRODUCT_LIST,
      product_list: [],
      item: null,
      totalPrice: 0,
      isOrderCoupon: false,
      importGoodsCoupons: [],
      importGoodsCouponHeader: [
        {field: "stt", key: "importGoodsCouponHeader_1", title: "STT", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return ++rowIndex;
          },},
        {field: "code", key: "importGoodsCouponHeader_2", title: "Số phiếu", align: "left"},
        {field: "created_at", key: "importGoodsCouponHeader_3", title: "Ngày", align: "left"},
        {field: "vendor_name", key: "importGoodsCouponHeader_4", title: "Nhà cung cấp", align: "left"},
      ],
    }
  },
  methods: {
    beforeOpened(event) {
      let data = event.params.data;
      this.isOrderCoupon = event.params.isOrderCoupon ? true : false;
      this.importGoodsCoupons = [];

      let created_at = moment.utc(data.created_at);

      data.created_at = created_at.format('DD-MM-YYYY');
      data.date = moment(data.date).format('DD-MM-YYYY');
      data.due_date = moment(data.due_date).format('DD-MM-YYYY');
      let import_status = '';
      switch (data.import_status) {
        case 1:
          import_status = 'Chưa về kho';
          break;
        case 2:
          import_status = 'Thiếu';
          break;
        case 3:
          import_status = 'Thừa';
          break;
        case 4:
          import_status = 'Đủ';
          break;
      }

      data.import_status_name = import_status;

      let payment_status_name = '';
      switch (data.payment_status) {
        case 1:
          payment_status_name = 'Thanh toán đủ';
          break;
        case 2:
          payment_status_name = 'Đặt cọc';
          break;
        case 3:
          payment_status_name = 'Chưa thanh toán phí vận chuyển';
          break;
      }
      data.payment_status_name = payment_status_name;


      this.item = data;

      data.coupons.forEach(item => {
        item.created_at = moment(item.created_at).format('DD-MM-YYYY');
        item.vendor_name = item.vendor ? item.vendor.name : '';
        this.importGoodsCoupons.push(item);
      })

      let products = data.product_receipts;

      for (let i = 0; i < products.length; i++) {
        let item = products[i];
        let product = {};
        product.stt = i + 1;
        product.id = item.id;
        product.good = item.product.goods.name
        product.sku = item.product.sku;
        let productValues = item.product.values;
        product.value = productValues.variant.name + ': ' + productValues.value
        product.currency = item.currency ? item.currency.code : '';
        product.quantity = parseInt(item.quantity || 0).toLocaleString();
        product.rate = item.rate || 0;
        product.fee = item.shipping_fee || 0;
        product.total_price = parseInt(item.total_price).toLocaleString();
        product.description = item.description || "";

        this.product_list.push(product);
        this.totalPrice += parseInt(item.total_price);
      }
    },

    beforeClosed(event){
      this.product_list = [];
      this.totalPrice = 0;
      this.item = null
    },
  }

}
</script>

<style lang="scss">
.detail-order-coupon-modal{

}
</style>
