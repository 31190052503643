import DropDown from "./components/Dropdown.vue";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import {
  CollapseTransition,
  SlideYDownTransition,
  FadeTransition,
  ZoomCenterTransition
} from "../node_modules/vue2-transitions";
import VueJSModal from "vue-js-modal";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', ''),
  },
});

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("CollapseTransition", CollapseTransition);
    Vue.component("SlideYDownTransition", SlideYDownTransition);
    Vue.component("FadeTransition", FadeTransition);
    Vue.component("ZoomCenterTransition", ZoomCenterTransition);
    Vue.component("v-select", vSelect);
  }
};



export default GlobalComponents;
