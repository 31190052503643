<template>
  <div>
    <modal name="core-value-modal"
           :click-to-close="false"
           width='800px'
           height="auto"
           class="modal-custom core-value-modal"
           transition="easeInOut"
           @before-open="beforeOpen"
           @before-close="beforeClosed"
    >

        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 modal-title">Giá trị cốt lõi</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('core-value-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div class="core-value-content neutral_2 pb-15px">
            <div class="tabs">
              <div class="tab" v-for="(item, index) in coreValues" :key="item.id">
                <input type="radio" :id="'rd' + index" name="rd" :checked="index === checkedIndex">
                <label class="tab-label" :for="'rd' + index">
                  <div class="core-value-title">
                      <div class="cardinal-number text-300-medium neutral_white">{{index + 1}}</div>
                    <span>
                      {{item.value}}
                    </span>
                  </div>
                </label>
                <div class="tab-content" v-html="item.description">
                </div>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button type="submit" class="btn-active md-button heading-300" @click="$modal.hide('core-value-modal')">Tôi đã hiểu</button>
        </md-dialog-actions>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'core-value-modal',
  computed: {
  },
  mounted() {
    this.currentUrl = window.location.origin + '/group/' + this.groupId;
  },

  created() {
  },
  data: () => ({
    coreValues: [],
    checkedIndex: 0
  }),

  methods: {
    beforeOpen(event) {
      this.coreValues = event.params.coreValues;
      this.checkedIndex = event.params.checkedIndex;
      // this.$store.commit("updateModalIsOpen", true);
    },
    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },
  },
}
</script>

<style lang="scss">
.core-value-modal{
  h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
  }
  h2 {
    margin: 0 0 .5em;
    font-weight: normal;
  }
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .tabs {
    border-radius: 8px;
    overflow: hidden;
  }
  .tab {
    width: 100%;
    overflow: hidden;
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      font-weight: bold;
      cursor: pointer;
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all .35s;
      }
    }
    &-content {
      max-height: 0;
      border-bottom: none;
      background: white;
      padding: 0 15px;
      transition: all .35s;
      display: none;
      u {
          padding-left: 30px;
      }
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      cursor: pointer;
    }

    .tab-label {
      border-bottom: 1px solid #EDEFF5;
    }
  }

  // :checked
  input:checked {
    + .tab-label {
      &::after {
        transform: rotate(90deg);
      }
    }
    ~ .tab-content {
      max-height: 200vh;
      display: block;
        padding: 10px 15px 10px 35px;
      border-bottom: 1px solid #EDEFF5;
    }
  }

  .core-value-title {
    display: flex;
    .cardinal-number {
      width: 24px;
      height: 24px;
      background: #d16397;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }

  .core-value-content {
    height: 70vh;
    overflow-y: scroll;
  }
}

</style>
