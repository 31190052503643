const notification ={
    state: {
        unCountRead: 0,
    },

    getters: {
        getUnCountRead(state) {
            return state.unCountRead;
        },
    },

    mutations: {
        updateUnCountRead(state, payload) {
            state.unCountRead = payload;
        },
    },

    actions: {
    }
};

export default notification;

