<template>
  <div class="p-relative" >
    <VEmojiPicker class="p-absolute emoji" v-if="showDialogEmoji" @select="onSelectEmoji" v-click-outside="hiddenDialogEmoji"/>

    <!-- count like + comment -->
    <div class="post-count">
      <md-card-actions md-alignment="space-between">
        <span v-on-clickaway="onClickMoreBtnAway" class="neutral_2 like-count-wrap cursor-pointer" @click="$modal.show('user-post-interactive-modal',
        {postId: post.id, type: 'like'})" v-if="likeCounts == 0">
          <img
              alt="like" class="icon-like-count"
              src="/img/post/content/ic_like_1.png"/>
          <span class="ml-5px">{{ likeCounts }}</span>
        </span>
        <span v-on-clickaway="onClickMoreBtnAway" @click="$modal.show('user-post-interactive-modal',
        {postId: post.id, type: 'like'})" class="neutral_2 like-count-wrap cursor-pointer" v-else>
          <span v-for="item in listReactionCurrentClass" :key="item" class="icon-like-count" :class="item"></span>
          <span class="ml-5px">{{ likeCounts }}</span>
        </span>
        <template v-if="group !== []">
          <div class="cursor-pointer d-flex">
            <div v-if="group"  @click="$modal.show('user-post-interactive-modal', {postId: post.id, type: 'read'})">
              <span class="neutral_2" v-if="post.read_count < group.member_count">{{ likeCounts > post.read_count ? likeCounts : post.read_count }} người đã xem</span>
              <span class="neutral_2" v-else>Tất cả đã xem</span>
            </div>
            <span class="ml-15px neutral_2">{{ commentCounts }} bình luận</span>
          </div>
        </template>
      </md-card-actions>

    </div>

    <!-- action like + comment -->
    <md-card-actions class="post-action">
      <md-bottom-bar class="list-action">

        <button class="like-btn md-bottom-bar-item" :class="reactionCurrentColorClass" @click="actionLike(null, 'likeBtn')">
          <md-icon v-if="reactionExisting == 1 || reactionExisting == null">thumb_up</md-icon>
          <div v-else class="md-icon" :class="reactionCurrentIconClass"></div>
          <span class="ml-5px">{{reactionCurrentName}}</span>
        </button>
        <div class="reaction-box background_neutral_white">
          <div class="reaction-icon reaction-like" data-reaction="1" @click="actionLike(1, 'reaction')">
            <label>Thích</label>
          </div>
          <div class="reaction-icon reaction-love" data-reaction="2" @click="actionLike(2, 'reaction')">
            <label>Yêu thích</label>
          </div>
          <div class="reaction-icon reaction-care" data-reaction="3" @click="actionLike(3, 'reaction')">
            <label>Thương thương</label>
          </div>
          <div class="reaction-icon reaction-haha" data-reaction="4" @click="actionLike(4, 'reaction')">
            <label>Haha</label>
          </div>
          <div class="reaction-icon reaction-wow" data-reaction="5" @click="actionLike(5, 'reaction')">
            <label>Wow</label>
          </div>
          <div class="reaction-icon reaction-sad" data-reaction="6" @click="actionLike(6, 'reaction')">
            <label>Buồn</label>
          </div>
          <div class="reaction-icon reaction-angry" data-reaction="7" @click="actionLike(7, 'reaction')">
            <label>Tuyệt vời</label>
          </div>
        </div>
        <md-bottom-bar-item class="comment-btn" to="" md-label="Bình luận" md-icon="chat"
        ></md-bottom-bar-item>

        <button class="share-btn md-bottom-bar-item" @click="sharePost">
          <div class="md-icon">
            <svg width="20px" height="20px" fill="var(--spectrum-global-color-contentPrimary)" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" style="margin-right: 8px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.713 2.307A.75.75 0 0 0 13.25 3v4.263c-2.673.094-5.535.714-7.808 2.468C2.918 11.68 1.25 14.923 1.25 20a.75.75 0 0 0 1.397.38c1.34-2.283 2.377-3.623 3.907-4.444 1.444-.774 3.42-1.135 6.696-1.18V19a.75.75 0 0 0 1.28.53l8-8a.75.75 0 0 0 0-1.06l-8-8a.75.75 0 0 0-.817-.163ZM20.939 11l-6.189 6.19V14a.75.75 0 0 0-.75-.75c-3.769 0-6.255.346-8.154 1.364-1.156.62-2.055 1.468-2.885 2.55.488-3.042 1.777-4.995 3.397-6.245C8.471 9.289 11.278 8.75 14 8.75a.75.75 0 0 0 .75-.75V4.81L20.94 11Z" fill="#18202A"></path></svg>
          </div>
          <span class="ml-5px">Chia sẻ</span>
        </button>
        <!--        <md-bottom-bar-item to="" md-label="Chia sẻ" md-icon="redo"></md-bottom-bar-item>-->
      </md-bottom-bar>
    </md-card-actions>

    <!-- user comment -->
    <div v-if="showComment" class="post-comment-wrapper">
      <md-list-item class="post-comment">
        <md-avatar>
          <image-template :size="'small'" :image="user.avatar_url" :thumbnail="user.thumbnail"/>
        </md-avatar>

        <div class="md-list-item-text p-relative">
          <md-field md-inline>
            <md-input class="comment" placeholder="Viết bình luận..." :ref='"input_" + post.id' v-model="user_comment"
                      @click="hiddenDialogEmoji()"
                      @keyup.enter="postComment(CREATE_POST_LEVEL.COMMENT)">
            </md-input>
          </md-field>
          <img class="img-emoji" src="/img/post/footer/smile.png" @click="toogleDialogEmoji"
               >
          <img class="choose-image" src="/img/post/footer/ic_picture.svg" @click="openUploadCommentImage">
          <input @change="onChangeCommentImage" type="file" :id="'imageUpload' + post.id"
                 accept="image/jpg, image/png, image/jpeg" hidden>
        </div>
      </md-list-item>

      <div class="comment-img-wrapper" :id="'commentImageWrapper' + post.id">
        <img class="comment-img" :id="'commentImage' + post.id" src="/img/group/group_image_low.jpg" alt="">
        <div class="cancel-btn cursor-pointer" @click="removeCommentImage">
          <md-icon>cancel</md-icon>
        </div>
      </div>

      <div v-for="comment in dataComments" :key="comment.id">
        <post-comment :comment="comment" @remove-comment="removeComment">
        </post-comment>
      </div>

      <!-- watch more comment -->
      <span class="text-400-medium secondary_b400 px-15px my-5px cursor-pointer" style="display: block"
            v-if="commentCount >= 5 && paramsComment.page <= last_page" @click="getMoreComment()">Xem thêm bình luận
        <span class="ml-15px"><vue-element-loading :active="isLoadMoreComment" spinner="spinner" size="18"
                                                   background-color="transparent"/>
        </span>
      </span>
      <!--    </template>-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PostService from "../../../store/services/PostService";
import {CREATE_POST_LEVEL, POST_TYPE} from "../../../const/const";
import {VEmojiPicker} from 'v-emoji-picker';
import postComment from "./postComment";
import {mixin as clickaway} from "vue-clickaway";
import $ from "jquery";
import ImageTemplate from "../../../components/ImageTemplate";

export default {
  name: "post-footer",
  mixins: [clickaway],
  components: {
    VEmojiPicker,
    postComment,
    ImageTemplate
  },
  computed: {
    reactionCurrentName() {
      switch (this.reactionExisting) {
        case 2:
          return 'Yêu thích';
        case 3:
          return 'Thương thương';
        case 4:
          return 'Haha';
        case 5:
          return 'Wow';
        case 6:
          return 'Buồn';
        case 7:
          return 'Tuyệt vời';
        default:
          return 'Thích';
      }
    },
    reactionCurrentColorClass() {
      switch (this.reactionExisting) {
        case 1:
          return 'is_like';
        case 2:
          return 'is_love';
        case 3:
        case 4:
        case 5:
        case 6:
          return 'is_reaction_other'
        case 7:
          return 'is_angry';
        default:
          return '';
      }
    },
    reactionCurrentIconClass() {
      switch (this.reactionExisting) {
        case 2:
          return 'reaction-love';
        case 3:
          return 'reaction-care';
        case 4:
          return 'reaction-haha';
        case 5:
          return 'reaction-wow';
        case 6:
          return 'reaction-sad';
        case 7:
          return 'reaction-angry';
        default:
          return '';
      }
    },
    listReactionCurrentClass() {
      let reactionList = [];
      let reactionClassList = [];
      //Thuật toán lấy ra 3 reaction có số lượng lớn nhất
      for (const key in this.reactionTotal) {
        if (this.reactionTotal[key] > 0) {
          let reaction = {
            "type": key,
            "total": this.reactionTotal[key]
          };
          reactionList.push(reaction);
        }
      }
      for (let i = 0; i < reactionList.length - 1; i++) {
        for (let j = i + 1; j < reactionList.length; j++) {
          if (reactionList[i].total < reactionList[j].total) {
            let temp = reactionList[j];
            reactionList[j] = reactionList[i];
            reactionList[i] = temp;
          }
        }
      }
      for (let i = 0; i < reactionList.length; i++) {
        switch (reactionList[i].type) {
          case 'love':
            reactionClassList.push('reaction-love');
            break;
          case 'care':
            reactionClassList.push('reaction-care');
            break;
          case 'haha':
            reactionClassList.push('reaction-haha');
            break;
          case 'wow':
            reactionClassList.push('reaction-wow');
            break;
          case 'sad':
            reactionClassList.push('reaction-sad');
            break;
          case 'angry':
            reactionClassList.push('reaction-angry');
            break;
          default:
            reactionClassList.push('reaction-like');
        }
        if (reactionClassList.length == 3) {
          break;
        }
      }
      return reactionClassList;
    }
  },
  props: {
    post: {
      type: Object,
      default: () => {
      },
    },
    userPost: {
      type: Object,
      default: () => {
      },
    },
    liked: {
      type: Boolean,
      default: false
    },
    likeCount: {
      type: Number,
      default: 0
    },
    commentCount: {
      type: Number,
      default: 0
    },
    comments: {
      type: Array,
      default: () => [],
    },
    reactionCurrent: {
      type: Number,
      default: null
    },
    reactionStats: {
      type: Object,
      default: () => {
      },
    },
    group: {
      type: Object,
      default: () => {
      },
    },
    postPreview: {
      type: Object,
      default: null,
    }
  },
  watch: {
    'comments': function (newVal, oldVal) {
      this.dataComments = newVal
    },
    'commentCount': function (newVal, oldVal) {
      this.commentCounts = newVal
    },
    'reactionCurrent': function (newVal, oldVal) {
      this.reactionExisting = newVal
    },
    'reactionStats': function (newVal, oldVal) {
      this.reactionTotal = newVal;
      this.likeCounts = 0;
      for (const key in newVal) {
        this.likeCounts += newVal[key];
      }
    },
  },
  data() {
    return {
      user: this.$store.state.auth.user,
      user_comment: '',
      CREATE_POST_LEVEL: CREATE_POST_LEVEL,
      like: this.liked,
      commentCounts: this.commentCount,
      likeCounts: 0,
      showDialogEmoji: false,
      showComment: true,
      focusInput: false,
      paramsComment: {
        page: 1,
        limit: 10,
        latest_id: 0
      },
      last_page: 1,
      dataComments: this.comments,
      isLoadMoreComment: false,
      commentImage: null,
      commentImageUrl: "",
      reactionExisting: this.reactionCurrent,
      reactionTotal: this.reactionStats
    }
  },
  mounted() {
    $('.like-btn').hover(function (event) {
      $(this).parent().find("div.reaction-box").show();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }, function (event) {
      setTimeout(function (){}, 3000);
      $(this).parent().find("div.reaction-box").hide();
      event.stopPropagation();
      event.stopImmediatePropagation();
    });

    $('.reaction-box').hover(function (e) {
      $(this).show();
    }, function () {
      $(this).hide();
    });

    $('.reaction-icon').click(function (event) {
      $(this).parent().hide();
    });
    for (const key in this.reactionTotal) {
      this.likeCounts += this.reactionTotal[key];
    }
  },

  updated() {
    $('.like-btn').hover(function (event) {
      $(this).parent().find("div.reaction-box").show();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }, function (event) {
      setTimeout(function (){}, 3000);
      $(this).parent().find("div.reaction-box").hide();
      event.stopPropagation();
      event.stopImmediatePropagation();
    });

    $('.reaction-box').hover(function (e) {
      $(this).show();
    }, function () {
      $(this).hide();
    });

    $('.reaction-icon').click(function (event) {
      $(this).parent().hide();
    });
  },

  methods: {
    getProfileLink(id) {
      return '/profile/' + id;
    },
    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },
    hiddenDialogEmoji() {
      this.showDialogEmoji = false
    },
    postComment(level) {
      if (this.user_comment !== '') {
        let data = {
          content: this.user_comment,
          created_at: moment(),
          id: 0,
          image: null,
          user: {
            avatar_url: this.user.avatar_url,
            email: this.user.email,
            fullname: this.user.fullname,
            id: this.user.id
          }
        }
        let formData = new FormData();
        formData.append("html_content", this.user_comment);
        formData.append("type", POST_TYPE.TEXT);
        formData.append("parent_post_id", this.post.id);
        formData.append("level", level);
        formData.append("model_type", 1);
        if (this.commentImage) {
          formData.append("files[]", this.commentImage);
          data.image = this.commentImageUrl;
        }

        PostService.createPost(formData).then(res => {
          if (res.status) {
            this.commentCounts++;
            data.id = res.data.post_id;
            this.dataComments.unshift(data);
            this.commentImage = null;
          }
        });

        this.user_comment = '';
        this.removeCommentImage();
      }
    },

    actionLike(type, action) {
      const isReact = this.reactionExisting === null;
      let params = {
        "type": type,
        "is_like": isReact,
        "post_id": this.post.id,
      };

      if (action == 'likeBtn') {
        type = 1;
        if (isReact) {
          params.type = 1;
          this.likeCounts++;
          this.reactionTotal.like++;
          this.reactionExisting = 1;
        } else {
          params.type = this.reactionExisting;
          this.likeCounts--;
          this.decrementReactionTotal(this.reactionExisting);
          this.reactionExisting = null;
        }
      } else {
        params.is_like = true;
        if (isReact) {
          this.likeCounts++;
          this.increaseReactionTotal(type);
        } else {
          let oldReaction = this.reactionExisting;
          this.decrementReactionTotal(oldReaction);
          this.increaseReactionTotal(type);
        }
        this.reactionExisting = type;
      }

      PostService.likePost(params).then(res => {
      });
      this.$store.state.post.posts[0]['post']['reaction_current'] = this.reactionExisting;
      this.$store.state.post.posts[0]['post']['reaction_stats'] = this.reactionTotal;
      this.userReadPost(this.post.id);
    },

    toogleDialogEmoji() {
      this.showDialogEmoji = !this.showDialogEmoji;
    },

    onSelectEmoji(emoji) {
      this.user_comment += emoji.data;
      try {
        this.$nextTick(function () {
          this.$refs["input_" + this.post.id].$el.focus()
        })
      } catch (e) {
      }
    },

    getMoreComment() {
      this.isLoadMoreComment = true;
      if (this.paramsComment.page == 1) {
        this.paramsComment.latest_id = this.comments.length > 0 ? this.comments[0]['id'] : 0
      }

      PostService.getComments(this.post.id, this.paramsComment).then(res => {
        if (res.status) {
          this.isLoadMoreComment = false;
          const dataNew = res.data.new ? res.data.new : [];
          const dataOld = this.paramsComment.page === 1 ? [] : this.dataComments;

          this.commentCounts = res.data.in_page.meta.total;
          this.last_page = res.data.in_page.meta.last_page;
          const data = dataNew.concat(dataOld, res.data.in_page.comments);
          const uniqueData = [];

          const unique = data.filter(element => {
            const isDuplicate = uniqueData.includes(element.id);

            if (!isDuplicate) {
              uniqueData.push(element.id);

              return true;
            }

            return false;
          });

          this.dataComments = unique
          this.paramsComment.latest_id = dataNew.length > 0 ? dataNew[0]['id'] :
              res.data.in_page.comments.length > 0 ? res.data.in_page.comments[0]['id'] : this.paramsComment.latest_id
          this.paramsComment.page++
        }
      });
    },

    removeComment(e) {
      let commentList = this.dataComments.filter(x => {
        return x.id != e;
      });
      this.dataComments = commentList;
      this.commentCounts--;
    },
    openUploadCommentImage() {
      let elmId = "imageUpload" + this.post.id;
      document.getElementById(elmId).click();
    },
    onChangeCommentImage(e) {
      let cmtWrapper = "commentImageWrapper" + this.post.id;
      let cmtImage = "commentImage" + this.post.id;
      this.commentImage = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.commentImageUrl = reader.result;
        document.getElementById(cmtImage).src = this.commentImageUrl;
      };
      reader.readAsDataURL(e.target.files[0]);
      document.getElementById(cmtWrapper).style.display = 'block';

    },
    removeCommentImage() {
      let cmtWrapper = "commentImageWrapper" + this.post.id;
      this.commentImage = null;
      document.getElementById(cmtWrapper).style.display = 'none';
      let imgUpload = "#imageUpload" + this.post.id;
      document.querySelector(imgUpload).type = "text";
      document.querySelector(imgUpload).type = "file";
    },
    toggleMoreBtn() {
      this.moreBtnActive = !this.moreBtnActive;
    },
    onClickMoreBtnAway() {
      this.moreBtnActive = false;
    },

    increaseReactionTotal(type) {
      switch (type) {
        case 1:
          this.reactionTotal.like++;
          break;
        case 2:
          this.reactionTotal.love++;
          break;
        case 3:
          this.reactionTotal.care++;
          break;
        case 4:
          this.reactionTotal.haha++;
          break;
        case 5:
          this.reactionTotal.wow++;
          break;
        case 6:
          this.reactionTotal.sad++;
          break;
        case 7:
          this.reactionTotal.angry++;
          break;
      }
    },
    decrementReactionTotal(type) {
      switch (type) {
        case 1:
          this.reactionTotal.like--;
          break;
        case 2:
          this.reactionTotal.love--;
          break;
        case 3:
          this.reactionTotal.care--;
          break;
        case 4:
          this.reactionTotal.haha--;
          break;
        case 5:
          this.reactionTotal.wow--;
          break;
        case 6:
          this.reactionTotal.sad--;
          break;
        case 7:
          this.reactionTotal.angry--;
          break;
      }
    },
    userReadPost(postId) {
      PostService.userReadPost(postId).then(res => {
      });
    },
    sharePost() {
      let postId;
      if (this.postPreview) {
        postId = this.postPreview.post.id;
      } else {
        postId = this.post.id
      }
      this.$modal.show('create-post-modal', {sharedPostId: postId});
    }
  }
};
</script>

<style lang="scss">
.md-card .md-card-actions .md-button {
  border: none !important;
}

.is_like .md-icon {
  color: #097bed !important
}

.post-footer .img-emoji {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  right: 10px;
  top: 8px;
}

.post-comment {
  .choose-image {
    position: absolute;
    width: 20px !important;
    cursor: pointer;
    right: 38px;
    top: 8px;
  }
}

.emoji {
  top: -205px;
  right: 0px;
  z-index: 100;
  height: 300px;
}

.container-emoji {
  height: 215px !important;
}

.post-footer {
  .list-action {
    .md-ripple {
      z-index: inherit !important;
    }
  }
}

.post-another-comment {
  z-index: inherit !important;

  .md-list-item-container {
    .md-ripple {
      z-index: inherit !important;
    }
  }

  .md-list-item-content:hover {
    .comment-more-btn {
      display: block;
    }
  }

  .md-button-clean {
    overflow: inherit;
  }

  .comment-more-option {
    padding: 0 !important;
    padding-left: 15px !important;
    background: none !important;
    border-radius: 0 !important;
    font-family: 'Inter';
  }

  .more-dropdown {
    z-index: 5 !important;
    top: 25px;
    width: 180px !important;
  }
}

.comment-more-btn {
  display: none;
}

.comment-more-btn:hover {
  background-color: #EDEFF5;
  border-radius: 50%;
}

.post-comment-wrapper {
  .comment-img-wrapper {
    display: none;
    width: auto;
    height: 160px;
    padding-bottom: 10px;
    padding-left: 65px;
    padding-right: 15px;
    position: relative;

    .comment-img {
      height: 100% !important;
      width: auto;
      border-radius: 6px;
    }

    .cancel-btn {
      position: absolute;
      top: 0;
      right: 25px;
      width: 10px;
      height: 10px;
    }
  }
}

.post-count {
  .more-dropdown {
    position: absolute;
    width: 400px;
    top: 40px;
    z-index: 8;
    border: 1px solid #D8DAE5;
    background-color: white;
    border-radius: 4px;

    ul {
      border: 1px solid #D8DAE5;
      border-radius: 4px;
    }
  }
}
</style>
