<template>
  <div class="inventory-content__container">
    <tabs class="mt-15px">
      <tab name="Lịch sử đồng bộ" @click="changeTabActive(SETTING_TAB.SYNC_HISTORY)" >
        <sync-history @change-is-load="changeIsLoad"></sync-history>
      </tab>
      <tab name="Nhà cung cấp" @click="changeTabActive(SETTING_TAB.VENDOR)">
        <vendor @change-is-load="changeIsLoad"></vendor>
      </tab>
      <tab name="Cấu hình" @click="changeTabActive(SETTING_TAB.SETTING)">
        <setting @change-is-load="changeIsLoad"></setting>
      </tab>
    </tabs>
  </div>
</template>

<script>
import {
  INVENTORY_COUPON_TYPE,
  INVENTORY_REPORT_TAB,
  INVENTORY_TYPE_OPTIONS,
  SETTING_TAB
} from "../../../../../const/const";
import moment from "moment";
import InventoryService from "@/store/services/InventoryService";
import {helpers} from "@/helper/helpers";
import InventoryTable from "@/pages/Workplace/Pages/Inventory/Table";
import InventoryFilter from "@/pages/Workplace/Pages/Inventory/Filter";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import ErrorsModal from "@/pages/Components/Modal/ErrorsModal";
import SyncHistory from "./SettingContainer";
import Setting from "./Setting";
import Vendor from "./Vendor";

export default {
  name: "SettingContent",
  components: {
    SyncHistory,
    Setting,
    Vendor
  },
  props: {},
  data() {
    return {
      SETTING_TAB,
      tabActive: SETTING_TAB.SYNC_HISTORY,
      isLoad:false
    };
  },

  watch: {
  },
  methods: {
    changeTabActive(tabActive) {
      this.tabActive = tabActive;
    },
    changeIsLoad(value){
      this.isLoad = value;
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }
  .show-detail{
    font-size: 20px;
    vertical-align: inherit;
    &:hover{
      cursor: pointer;
      color: red;
    }
  }

}
</style>