<template>
  <div class="view__container">
    <div class="text-center center-div">
      <img src="/img/inventory/inventory_home.jpg" class="img-content">
      <h4 class="text mb-5px mt-15px">Chào mừng bạn đến với</h4>
      <h4 class="text">Trang quản lý kho hàng AZA GROUP</h4>
      <div style="margin-bottom: 80px"/>
    </div>
    <list-coupon-and-receipt-modal></list-coupon-and-receipt-modal>
    <detail-order-coupon-modal />
    <update-order-coupon-modal />
  </div>
</template>

<script>

import AuthService from "../../../../store/services/AuthService";
import ListCouponAndReceiptModal from "../../../Components/Modal/ListCouponAndReceiptModal";
import DetailOrderCouponModal from "../../../Components/Modal/DetailOrderCouponModal";
import UpdateOrderCouponModal from "../../../Components/Modal/UpdateOrderCouponModal";
import AddMultipleProductModal from "../../../Components/Modal/AddMultipleProductModal";
import UpdateCouponModal from "../../../Components/Modal/UpdateCouponModal";
import UpdatePurchaseOrderModal from "../../../Components/Modal/UpdatePurchaseOrderModal";

export default {
  name: "Home",
  components: {
    ListCouponAndReceiptModal,
    DetailOrderCouponModal,
    UpdateOrderCouponModal
  },
  data() {
    return {
      link: this.$route.query.link,
      extension: this.$route.query.extension,
    }
  },
  created() {
    this.checkHasUser();
  },
  methods: {
    async checkHasUser() {
      if(!this.$store.state.auth.user.id){
        // Cookies.remove('access_token');
        // Cookies.remove('user_id');
        // await this.deleteAllCookies;
        await AuthService.logout();
        window.app.$store.dispatch("logout");
        // window.location.reload();
        // router.push({name: "Login"});
      }
    },
    deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    // async getProduct(params = {}) {
    //   await InventoryService.getInventories(params).then(res => {
    //       // console.log(res);
    //   });
    // },
  }
}
</script>

<style lang="scss">
.view__container {
  width: 100%;
  height: 100vh;
  background: white;
  text-align: center;
  .img-content {
    margin: auto;
    max-width: 600px;
  }
  .text {
    font-weight: 500;
    font-size: 26px;
  }
}
</style>