<template>
  <div class="user-info-wrapper userinfoPost" v-if="userPost">
    <div class="user-info-container">
      <div class="info-text">
        <div class="heading-500-semibold mb-5px cursor-pointer" @click="goToProfile()">{{ userPost ? userPost.fullname : '' }}</div>
        <div class="detail-info" v-if="userPost.parent_department">
          <span class="material-icons">schema</span>
          <span class="ml-5px text-2-line">
            <span>{{ userPost.parent_department }}</span>
            <span class="ml-5px" v-if="userPost.department">></span>
            <span class="ml-5px" v-if="userPost.department">{{ userPost.department }}</span>
          </span>
        </div>
        <div class="detail-info" v-if="userPost.position">
          <span class="material-icons">account_box</span>
          <span class="ml-5px">{{ userPost.position }}</span>
        </div>
        <div class="detail-info">
          <span class="material-icons">email</span>
          <span class="ml-5px">{{ userPost.email }}</span>
        </div>
        <div class="detail-info">
          <span class="material-icons">phone_iphone</span>
          <span class="ml-5px">{{ userPost.phone }}</span>
        </div>
      </div>
      <div class="info-image">
        <a @click="goToProfile()" class="cursor-pointer" :class="userPost.avatar_url">
          <image-template :size="'medium'" :image="userPost.avatar_url" :thumbnail="userPost.thumbnail"/>
        </a>
        <div class="sendMessage mt-10px" v-if="router_name !== 'messenger' || (type === 'GROUP' && router_name === 'messenger')">
          <md-button class="neutral_1 text-400-medium messageSend" @click="goToMessager(userPost)">
            <md-icon class="mr-5px">chat</md-icon>
            Nhắn tin
          </md-button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import ChatService from "../../../store/services/ChatService";
import {GROUP_CHAT_TYPE} from "../../../const/const";
import ImageTemplate from "../../../components/ImageTemplate";
import moment from "moment";
export default {
  components: {
    ImageTemplate
  },
  props: {
    userPost: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      type : this.$store.state.chat.chatGroupSelected.type,
      router_name : this.$route.name,
    }
  },
  methods: {
    async goToMessager(member) {

      //================CHECK-EXITS-GROUP-CHAT===============//
      let isChatExits = await this.checkGroupExits(member.id);

      if (isChatExits) {
        //================CREATE-GROUP-CHAT===============//
        const formData = new FormData();

        //ADD VALUE
        formData.append("name", member.fullname);
        formData.append("model_type", GROUP_CHAT_TYPE.USER);
        formData.append("model_ids[]", member.id);

        // POST CHAT IS GROUP ALREADY EXIST
        await ChatService.createGroupChat(formData).then(res => {
          if (res.status) {
            // UPDATE STATE & GO TO MESSAGER
            delete res.data.isCreateChat;
            this.$store.commit('chat/updateChatGroups', res.data);
            this.$store.commit('chat/updateChatGroupSelected', res.data);
            this.$store.dispatch("chat/getChatGroups");
            this.$router.push("/messenger/" + res.data.id);
          } else {
            this.$store.dispatch("alerts/error", 'Bạn tạo cuộc trò chuyện không thành công. Nguyên nhân: ' + res.message);
          }
        });
      } else {
        const selectedChatGroup = {
          avatar: member.avatar_url,
          created_at: moment().format(),
          name: member.fullname,
          last_message: {},
          updated_at: moment().format(),
          user_id: member.id,
          isCreateChat: true,
          type: GROUP_CHAT_TYPE.USER
        };

        // UPDATE STATE & GO TO MESSAGER
        this.$store.commit('chat/updateChatGroupSelected', selectedChatGroup);
        this.$router.push("/messenger/new" + moment());
      }

    },

    async checkGroupExits(user_id){
      let status = false;
      
      await ChatService.checkChatExits({user_id : user_id}).then(res => {
        if(res.status){
          status = res.data.status;
        }
      });

      return status;
    },

    goToProfile(){
      this.$router.push('/profile/' + this.userPost.id);
    }
  }
};
</script>

<style lang="scss">
  .sendMessage{
    float: right;
    margin-top: 5px;
    .messageSend {
      background: #f59350 !important;
      padding: 5px;
    }
}
.userinfoPost{
  height: 180px !important;
}
 
</style>
