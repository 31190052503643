<template>
  <div>
    <modal name="detail-product-modal"
           :click-to-close="false"
           width="95%"
           margin="auto"
           left="0"
           top="20px"
           padding="10px"
           height="auto"
           class="coupon-modal modal-custom detail-product-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
    <template v-if="this.item">
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Chi tiết phiếu</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('detail-product-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <div class="my-15px coupon-modal-content">
        <md-card-content>
          <div class="infomation-add">
            <div class="title-form">
               <span>Thông tin phiếu</span>
            </div>
            <div class="options-box-layout">
              <div class="options-left">
                <div class="option">
                    <div class="title-option">
                      <span class="title">Mã phiếu</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input v-model="item.code" disabled></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Người tạo</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.created_by.fullname"></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Trạng thái</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.status"></md-input>
                      </md-field>
                      <!-- <selection :options="[{label: 'Phiếu tạm', id: 0},{label: 'Đã hoàn thành', id: 1}]" :selected="0">
                      </selection> -->
                    </div>
                </div>
                <div class="option">
                    <div class="title-option">
                      <span class="title">Ngày tạo</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.created_at"></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option" v-if="[1].includes(item.type)">
                    <div class="title-option">
                      <span class="title">Đơn đặt hàng</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-input disabled v-model="item.receipt.code"></md-input>
                      </md-field>
                    </div>
                </div>
              </div>
              <div class="options-right">
                <div class="option">
                    <div class="title-option">
                      <span class="title">Tổng giá</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="totalPrice"></md-input>
                      </md-field>
                    </div>
                </div>
                <div class="option code-tracking" v-if="[1,2,5,6,8].includes(item.type)">
                    <div class="title-option">
                      <span class="title">Mã tracking</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input v-model="item.tracking" disabled></md-input>
                      </md-field>
                    </div>
                </div>
                <template v-if="item.type == 3 || item.type == 7">
                  <div class="option">
                    <div class="title-option">
                      <span class="title">Kho nhập</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="item.import_inventory"></md-input>
                      </md-field>
                    </div>
                  </div>
                  <div class="option">
                    <div class="title-option">
                      <span class="title">Kho xuất</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="item.export_inventory"></md-input>
                      </md-field>
                    </div>
                  </div>
                </template>


                <div class="option" v-if="[1,2,4,5,6,8].includes(item.type)">
                    <div class="title-option">
                      <span class="title">Kho hàng</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input disabled v-model="item.inventory_main.name"></md-input>
                      </md-field>
                    </div>
                </div>
                <!-- <div class="option">
                    <div class="title-option">
                      <span class="title required">Kho nhập</span>
                    </div>
                    <div class="input-option">
                      <selection :options="[{label: 'Kho Việt Nam', id: 12}]">
                      </selection>
                    </div>
                </div> -->
                <div class="option">
                    <div class="title-option">
                      <span class="title">Ghi chú</span>
                    </div>
                    <div class="input-option">
                      <md-field>
                        <md-textarea rows="3" disabled></md-textarea>
                      </md-field>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-content class="product-list">
          <div class="header-list">
            <span>Danh sách sản phẩm</span>
          </div>
          <inventory-table border-y :columns="PRODUCT_LIST" :table-data="product_list"/>
        </md-card-content>
      </div>
      <md-dialog-actions class="p-15px w_100">
        <button class="create-post-btn btn-active md-button w_100" @click="$modal.hide('detail-product-modal')">
          Đóng
        </button>
      </md-dialog-actions>
    </template>
    </modal>
  </div>
</template>

<script>
// import Selection from '../Select2/Selection';
import moment from "moment";
import InventoryTable from "../../../pages/Workplace/Pages/Inventory/Table";
import {
  PRODUCT_LIST
} from "../../../const/const";
export default {
  name: 'detail-product-modal',
  components: {
    InventoryTable
  },
  data() {
    return {
      PRODUCT_LIST,
      product_list: [],
      item: null,
      totalPrice: 0,
      isOrderCoupon: false,
      importGoodsCoupons: [],
    }
  },
  methods: {
    beforeOpened(event) {
      // event.params.data.type = 3;
      let data = event.params.data;
      console.log(data);
      let type = data.type;
      this.isOrderCoupon = event.params.isOrderCoupon ? true : false;
      this.importGoodsCoupons = [];

      if (type == 3 && data.inventory_sub) {
        data.export_inventory = data.inventory_sub.name;
        data.import_inventory = data.inventory_main.name;
      } else if (type == 7 && data.inventory_sub) {
        data.export_inventory = data.inventory_main.name;
        data.import_inventory = data.inventory_sub.name;
      }

      data.created_at = moment(data.created_at).format('YYYY-MM-DD');
      data.status = data.status == 1 ? 'Đã hoàn thành' : 'Phiếu Tạm';
      this.item = data;
      if (!this.item.receipt) {
        this.item.receipt = {};
        this.item.receipt.code = '';
      }

      if (this.isOrderCoupon) {
        this.importGoodsCoupons = data.coupons;
      }
      let products = this.isOrderCoupon ? data.product_receipts : data.products;

      for (let i = 0; i < products.length; i++) {
        let item = products[i];
        let product = {};
        product.stt = i + 1;
        product.id = item.id;
        product.good = item.product.good ? item.product.good.name : item.product.goods.name;
        product.sku = item.product.sku;
        let productValues = item.product.value ? item.product.value : item.product.values;
        product.value = productValues.variant.name + ': ' + productValues.value
        product.currency = item.currency ? item.currency.code : '';
        product.quantity = parseInt(item.quantity || 0).toLocaleString();
        product.rate = item.rate || 0;
        product.fee = item.shipping_fee || 0;
        product.total_price = parseInt(item.total_price).toLocaleString();
        product.description = item.description || "";

        this.product_list.push(product);
        this.totalPrice += parseInt(item.total_price);
      }
    },

    beforeClosed(event){
      this.product_list = [];
      this.totalPrice = 0;
      this.item = null
    },
  }

}
</script>

<style lang="scss">
.detail-product-modal{

}
</style>
