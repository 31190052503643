<template>
  <div class="p-relative postSubComment" :set="user = comment.user">
    <template>
      <div v-if="user" class="header-popover user-popover" v-show="userPopupName"
           v-on:mouseleave="onMouseleave($event)">
        <user-info :user-post="user" />
      </div>
    </template>
    <md-list-item class="post-another-comment">
      <md-avatar v-if="user">
        <router-link :to="getProfileLink(user.id)">
          <img class="avatar" :src="user.avatar_url || '/img/profile/avatar.png'">
        </router-link>
      </md-avatar>
      <div class="md-list-item-text p-relative">
          <span class="text-300-semibold neutral_2 hover-link cursor-pointer userPopoverName" v-on:mouseover="onMouseover($event)" v-on:click="onClick('user', user.id)"
                v-on:mouseleave="onMouseleave($event)" v-popover.top="{ name: userPopoverName}">{{ user.display_name || user.fullname }}</span>
        <p class="white-space-normal comment-content" :id="'text-html-' + comment.id" v-html="convertUrlContent(comment.content)"></p>
        <span class="cursor-pointer hover-link" @click="handleViewMore" style="color: #0d47a1" v-if="isLargeText">{{showingFullText ? "...Thu gọn" : "...Xem thêm"}}</span>
        <div class="comment-img-wrapper cursor-pointer" style="display: block" v-if="comment.image">
          <img class="comment-img" :src="comment.image" alt="" @click="showFullCommentImage">
        </div>
      </div>
      <div class="md-icon more-btn-wrapper menu-comment" v-show="user.id === this.$store.state.auth.user.id"
           @click="toggleMoreBtn" v-on-clickaway="onClickMoreBtnAway">
        <md-icon class="cursor-pointer comment-more-btn">more_horiz</md-icon>
        <div class="more-dropdown" v-show="activeDropdown">
          <md-list class="p-0px">
            <md-list-item class="cursor-pointer" @click="showUpdateCommentModal">
              <div class="md-list-item-text comment-more-option">Sửa bình luận</div>
            </md-list-item>
            <md-list-item class="cursor-pointer" @click="showConfirmDeleteComment">
              <div class="md-list-item-text comment-more-option">Xóa bình luận</div>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </md-list-item>

    <div class="sub-comment-wrapper">
      <div class="pl-15px sub-comment-action">
        <span class="p-relative">
          <span class="text-300-semibold neutral_3 cursor-pointer like-btn" :class="reactionCurrentColorClass"
                @click="actionLike(1, 'likeBtn')">{{ reactionCurrentName }}</span>
          <span class="text-300-semibold neutral_3 cursor-pointer">Phản hồi</span>
          <span class="text-300-default neutral_3 cursor-pointer">{{ fromNow(comment.created_at) }}</span>
          <div class="reaction-box background_neutral_white">
          <div class="reaction-icon reaction-like" data-reaction="1" @click="actionLike(1, 'reaction')">
            <label>Thích</label>
          </div>
          <div class="reaction-icon reaction-love" data-reaction="2" @click="actionLike(2, 'reaction')">
            <label>Yêu thích</label>
          </div>
          <div class="reaction-icon reaction-care" data-reaction="3" @click="actionLike(3, 'reaction')">
            <label>Thương thương</label>
          </div>
          <div class="reaction-icon reaction-haha" data-reaction="4" @click="actionLike(4, 'reaction')">
            <label>Haha</label>
          </div>
          <div class="reaction-icon reaction-wow" data-reaction="5" @click="actionLike(5, 'reaction')">
            <label>Wow</label>
          </div>
          <div class="reaction-icon reaction-sad" data-reaction="6" @click="actionLike(6, 'reaction')">
            <label>Buồn</label>
          </div>
          <div class="reaction-icon reaction-angry" data-reaction="7" @click="actionLike(7, 'reaction')">
            <label>Tuyệt vời</label>
          </div>
        </div>
        </span>
        <span class="neutral_2 like-count-wrap cursor-pointer" v-show="likeCounts > 0" @click="$modal.show('user-post-interactive-modal',
        {postId: comment.id, type: 'like'})">
          <span v-for="item in listReactionCurrentClass" :key="item" class="icon-like-count" :class="item"></span>
                  <span class="ml-5px">{{ likeCounts }}</span>
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mixin as clickaway} from "vue-clickaway";
import PostService from "../../../store/services/PostService";
import UserInfo from "../PopoverInfo/UserInfo";

export default {
  name: "post-sub-comment",
  mixins: [clickaway],
  components: {UserInfo},
  props: {
    comment: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  computed: {
    reactionCurrentName() {
      switch (this.reactionExisting) {
        case 2:
          return 'Yêu thích';
        case 3:
          return 'Thương thương';
        case 4:
          return 'Haha';
        case 5:
          return 'Wow';
        case 6:
          return 'Buồn';
        case 7:
          return 'Tuyệt vời';
        default:
          return 'Thích';
      }
    },
    reactionCurrentColorClass() {
      switch (this.reactionExisting) {
        case 1:
          return 'is_like';
        case 2:
          return 'is_love';
        case 3:
        case 4:
        case 5:
        case 6:
          return 'is_reaction_other'
        case 7:
          return 'is_angry';
        default:
          return '';
      }
    },
    reactionCurrentIconClass() {
      switch (this.reactionExisting) {
        case 2:
          return 'reaction-love';
        case 3:
          return 'reaction-care';
        case 4:
          return 'reaction-haha';
        case 5:
          return 'reaction-wow';
        case 6:
          return 'reaction-sad';
        case 7:
          return 'reaction-angry';
        default:
          return '';
      }
    },
    listReactionCurrentClass() {
      let reactionList = [];
      let reactionClassList = [];
      //Thuật toán lấy ra 3 reaction có số lượng lớn nhất
      for (const key in this.reactionTotal) {
        if (this.reactionTotal[key] > 0) {
          let reaction = {
            "type": key,
            "total": this.reactionTotal[key]
          };
          reactionList.push(reaction);
        }
      }
      for (let i = 0; i < reactionList.length - 1; i++) {
        for (let j = i + 1; j < reactionList.length; j++) {
          if (reactionList[i].total < reactionList[j].total) {
            let temp = reactionList[j];
            reactionList[j] = reactionList[i];
            reactionList[i] = temp;
          }
        }
      }
      for (let i = 0; i < reactionList.length; i++) {
        switch (reactionList[i].type) {
          case 'love':
            reactionClassList.push('reaction-love');
            break;
          case 'care':
            reactionClassList.push('reaction-care');
            break;
          case 'haha':
            reactionClassList.push('reaction-haha');
            break;
          case 'wow':
            reactionClassList.push('reaction-wow');
            break;
          case 'sad':
            reactionClassList.push('reaction-sad');
            break;
          case 'angry':
            reactionClassList.push('reaction-angry');
            break;
          default:
            reactionClassList.push('reaction-like');
        }
        if (reactionClassList.length == 3) {
          break;
        }
      }
      return reactionClassList;
    },
    userPopoverName() {
      return this.comment.id + '_' + this.comment.user.id
    },
  },
  watch: {
    'reactionCurrent': function (newVal, oldVal) {
      this.reactionExisting = newVal
    },
  },
  mounted() {
    for (const key in this.reactionTotal) {
      this.likeCounts += this.reactionTotal[key];
    };
    let elm = 'text-html-' + this.comment.id;
    let offsetHeight = document.getElementById(elm).offsetHeight;
    // 20 chính là line-height của thẻ div
    if (offsetHeight / 20 > 20) {
      this.isLargeText = true;
      this.showingFullText = false;
      document.getElementById(elm).classList.add("text-ellipsis");
    }
  },
  data() {
    return {
      activeDropdown: false,
      liked: this.comment.liked,
      likeCounts: 0,
      reactionExisting: this.comment.reaction_current,
      reactionTotal: this.comment.reaction_stats,
      isClick: false,
      isLargeText: false,
      userPopupName : false
    }
  },
  methods: {
    getProfileLink(id) {
      return '/profile/' + id;
    },
    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    },
    toggleMoreBtn() {
      this.activeDropdown = true;
    },
    onClickMoreBtnAway() {
      this.activeDropdown = false;
    },
    showConfirmDeleteComment() {
      this.$modal.show('dialog', {
        title: 'Xóa bình luận?',
        text: 'Bạn có chắc chắn muốn xóa bình luận này không?',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              this.deleteComment();
            }
          }
        ]
      })
    },

    showUpdateCommentModal() {
      this.$modal.show('update-comment-modal', {commentData: this.comment, postParentId: this.getPostId});
    },
    deleteComment() {
      PostService.deletePost(this.comment.id).then(res => {
        if (res && res.status) {
          this.$emit('remove-comment', this.comment.id);
        }
        this.$modal.hide('dialog');
        this.activeDropdown = false;
      });
    },
    actionLike(type, action) {
      const isReact = this.reactionExisting === null;

      const params = {
        "type": type,
        "is_like": isReact,
        "post_id": this.comment.id,
      };

      if (action == 'likeBtn') {
        type = 1;
        if (isReact) {
          params.type = 1;
          this.likeCounts++;
          this.reactionTotal.like++;
          this.reactionExisting = 1;
        } else {
          params.type = this.reactionExisting;
          this.likeCounts--;
          this.decrementReactionTotal(this.reactionExisting);
          this.reactionExisting = null;
        }
      } else {
        params.is_like = true;
        if (isReact) {
          this.likeCounts++;
          this.increaseReactionTotal(type);
        } else {
          let oldReaction = this.reactionExisting;
          this.decrementReactionTotal(oldReaction);
          this.increaseReactionTotal(type);
        }
        this.reactionExisting = type;
      }

      PostService.likePost(params).then(res => {
      });
    },
    showFullCommentImage() {
      this.$modal.show('show-comment-image', {imgUrl: this.comment.image});
    },
    convertUrlContent(content) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return content.replace(urlRegex, function (url) {
        return '<a href="' + url + '">' + url + '</a>';
      });
    },
    increaseReactionTotal(type) {
      switch (type) {
        case 1:
          this.reactionTotal.like++;
          break;
        case 2:
          this.reactionTotal.love++;
          break;
        case 3:
          this.reactionTotal.care++;
          break;
        case 4:
          this.reactionTotal.haha++;
          break;
        case 5:
          this.reactionTotal.wow++;
          break;
        case 6:
          this.reactionTotal.sad++;
          break;
        case 7:
          this.reactionTotal.angry++;
          break;
      }
    },
    decrementReactionTotal(type) {
      switch (type) {
        case 1:
          this.reactionTotal.like--;
          break;
        case 2:
          this.reactionTotal.love--;
          break;
        case 3:
          this.reactionTotal.care--;
          break;
        case 4:
          this.reactionTotal.haha--;
          break;
        case 5:
          this.reactionTotal.wow--;
          break;
        case 6:
          this.reactionTotal.sad--;
          break;
        case 7:
          this.reactionTotal.angry--;
          break;
      }
    },
    handleViewMore() {
      let elm = 'text-html-' + this.comment.id;
      // showingFullText = true => đang mở cần thu lại, thu lại là thêm class text-ellipsis
      if (this.showingFullText) {
        document.getElementById(elm).classList.add("text-ellipsis");
      } else {
        document.getElementById(elm).classList.remove("text-ellipsis");
      }
      this.showingFullText = !this.showingFullText;
    },
    onMouseover(e) {
      this.userPopupName = e.target.classList.contains('userPopoverName');;
      this.isClick = true;
      e.target.click();
    },
    onMouseleave(e) {
      this.userPopupName = e.relatedTarget.classList.contains('user-info-wrapper');
      this.isClick = true;
      e.target.click();
    },
    onClick(type, id) {
      if (this.isClick) {
        this.isClick = false;
        return;
      } else {
        if (type === 'group') {
          this.$router.push(this.getGroupLink(id));
        } else if (type === 'user') {
          this.$router.push(this.getProfileLink(id));
        }
      }
      return;
    },
  }
};
</script>

<style lang="scss">
.file-size {
  display: inline-block;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}

.post-file {
  z-index: 0 !important;

  .md-list-item-content {
    z-index: 0 !important;
  }
}
</style>
