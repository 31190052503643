<template>
  <div class="view__container" style="overflow: auto;">
      <h4 class="text mb-5px">Chào mừng bạn đến với</h4>
      <h4 class="text">Trang quản lý kho hàng AZA GROUP</h4>
      <div style="margin-bottom: 20px"/>

    <div class="table p-15px" v-if="dataTable.length > 0">
      <div class="display-flex">
        <span class="neutral_1 heading-500 text-header-modal text-nowrap">Phiếu CHUYỂN KHO điều chỉnh số liệu SKU</span>
        <div class="w_100">
          <div class="float-right">
            <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                 @change-params="changePagingParam"/>
          </div>
        </div>
      </div>
      <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
      <inventory-table border-y
                       :columns="columns"
                       :table-data="dataTable"/>
    </div>

    <div class="table p-15px" v-if="dataTable1.length > 0">
      <div class="display-flex">
        <span class="neutral_1 heading-500 text-header-modal text-nowrap">Phiếu NHẬP KHÁC điều chỉnh số liệu SKU</span>
        <div class="w_100">
          <div class="float-right">
            <pagination-template :class="'mb-10px float-right'" :params="pagingParams1"
                                 @change-params="changePagingParam1"/>
          </div>
        </div>
      </div>
      <vue-element-loading spinner="bar-fade-scale" :active="isLoad1"/>
      <inventory-table border-y
                       :columns="columns"
                       :table-data="dataTable1"/>
    </div>

    <list-coupon-and-receipt-modal></list-coupon-and-receipt-modal>
    <detail-order-coupon-modal />
    <update-order-coupon-modal />
  </div>
</template>

<script>

import AuthService from "../../../../store/services/AuthService";
import ListCouponAndReceiptModal from "../../../Components/Modal/ListCouponAndReceiptModal";
import DetailOrderCouponModal from "../../../Components/Modal/DetailOrderCouponModal";
import UpdateOrderCouponModal from "../../../Components/Modal/UpdateOrderCouponModal";
import moment from "moment";
import InventoryService from "../../../../store/services/InventoryService";
import axios from "axios";
import {INVENTORY_COUPON_TYPE} from "../../../../const/const";
import InventoryTable from "./Table";
import PaginationTemplate from "../../../Components/PaginationTemplate";

export default {
  name: "HomeAdmin",
  components: {
    ListCouponAndReceiptModal,
    DetailOrderCouponModal,
    UpdateOrderCouponModal,
    InventoryTable,
    PaginationTemplate,
  },
  data() {
    return {
      link: this.$route.query.link,
      extension: this.$route.query.extension,
      isLoad: false,
      isLoad1: false,
      params: {
        page: 1,
        per_page: 10,
        last_page: 1,
        type: 1,
        coupon_type: 10,
        status: 3,
        type_time: 3,
        date: new Date().toISOString().slice(0,10),
      },
      params1: {
        page: 1,
        per_page: 10,
        last_page: 1,
        type: 1,
        coupon_type: 2,
        status: 3,
        type_time: 3,
        date: new Date().toISOString().slice(0,10),
      },
      pagingParams: {
        page: 1,
        perPage: 10,
        lastPage: 1,
        total: 0,
      },
      pagingParams1: {
        page: 1,
        perPage: 10,
        lastPage: 1,
        total: 0,
      },
      columns: [
        {
          field: "stt",
          key: "a1",
          title: "STT",
          width: 50,
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
            return ++rowIndex + index;
          },
        },
        {
          field: "code", key: "a2", title: "Mã", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return row.code
          },
        },
        {
          field: "", key: "a3", title: "Kho Hàng", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            if(this.params.type === 2){
              return row.inventory.name
            }else{
              const inventoryMainName = row.inventory_main.name
              const inventorySubName = row.inventory_sub !== null ? ('-' + row.inventory_sub.name) : '';
              return inventoryMainName + inventorySubName;
            }
          },
        },
        {
          field: "type", key: "a4", title: "Loại Phiếu", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return this.params.type === 2 ? 'Đơn đặt hàng' : this.getTitleCounpon(row.type);
          },
        },
        {
          field: "created_at", key: "a5", title: "Ngày", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            const time = moment(row.created_at).format('DD-MM-YYYY');
            return (
                <span>{time}</span>
            );
          },
        },
        {field: "", key: "a6", title: "Hành Động", align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="search-action">
                  <button class="b-purple" on-click={() => this.getProductDetail(row)}>
                    <md-icon class="icon-20px">info</md-icon>
                  </button>
                  <button class="b-green ml-5px" on-click={() => this.updateOrderCoupon(row)}>
                    <md-icon class="icon-20px">edit</md-icon>
                  </button>
                </div>
            );
          },},
      ],
      dataTable: [],
      dataTable1: [],
    }
  },
  mounted() {
    this.checkHasUser();
    this.getCouponCK();
    this.getCouponNK();
  },
  methods: {
    async checkHasUser() {
      if(!this.$store.state.auth.user.id){
        await AuthService.logout()
        window.location.reload();
        router.push({name: "Login"});
      }
    },
    deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    async getCouponCK(count = 0) {
      this.isLoad = true;
      this.dataTable = [];
      let params = {...this.params};

      InventoryService.getCouponOrReceipt(params).then(res => {
        if (res) {
          this.dataTable = res.data;
          this.pagingParams.page = res.meta.current_page;
          this.pagingParams.perPage = res.meta.per_page;
          this.pagingParams.lastPage = res.meta.last_page;
          this.pagingParams.total = res.meta.total;
        }
        this.isLoad = false;
      }).catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        count++;
        if (count < 3) {
          setTimeout(() => {
            this.getCouponOrReceipt(count)
          }, 1000);
        }
      });
    },
    async getCouponNK(count = 0) {
      this.isLoad1 = true;
      this.dataTable1 = [];
      let params = {...this.params1};

      InventoryService.getCouponOrReceipt(params).then(res => {
        if (res) {
          this.dataTable1 = res.data;
          this.pagingParams1.page = res.meta.current_page;
          this.pagingParams1.perPage = res.meta.per_page;
          this.pagingParams1.lastPage = res.meta.last_page;
          this.pagingParams1.total = res.meta.total;
        }
        this.isLoad1 = false;
      }).catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        count++;
        if (count < 3) {
          setTimeout(() => {
            this.getCouponOrReceipt(count)
          }, 1000);
        }
      });
    },
    changePagingParam(value) {
      this.params.page = value.page;
      this.getCouponCK();
    },
    changePagingParam1(value) {
      this.params1.page = value.page;
      this.getCouponNK();
    },
    getTitleCounpon(type) {
      switch (type) {
        case INVENTORY_COUPON_TYPE.NHAP_MUA:
          return 'Nhập hàng mới'
        case INVENTORY_COUPON_TYPE.NHAP_KHAC:
          return 'Nhập điều chỉnh'
        case INVENTORY_COUPON_TYPE.XUAT_KHAC:
          return 'Xuất điều chỉnh'
        case INVENTORY_COUPON_TYPE.XUAT_TMDT:
          return 'Xuất TMĐT'
        case INVENTORY_COUPON_TYPE.XUAT_HANG_HONG:
          return 'Huỷ hàng'
        case INVENTORY_COUPON_TYPE.CHUYEN_KHO:
          return 'Chuyển kho'
        case INVENTORY_COUPON_TYPE.HOAN_TMDT:
          return 'Hoàn TMĐT'
        case INVENTORY_COUPON_TYPE.NHAP_HOAN:
          return 'Nhập hoàn'
        case INVENTORY_COUPON_TYPE.XUAT_BAN:
          return 'Xuất bán'
        default:
          return 'Khác'
      }
    },
    getProductDetail(row) {
      InventoryService.getOrderCouponDetail({order_coupon_id: row.id}).then(res => {
        if (res && res.data) {
          this.$modal.show('detail-order-coupon-modal', {data: res.data, isOrderCoupon: true});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    },
    updateOrderCoupon(row) {
      InventoryService.getOrderCouponDetail({order_coupon_id: row.id}).then(res => {
        if (res && res.data) {
          this.$modal.show('update-order-coupon-modal', {data: res.data});
        } else {
          this.$store.dispatch("alerts/error", res.message);
        }
      });
    }
  }
}
</script>

<style lang="scss">
.view__container {
  width: 100%;
  height: 100vh;
  background: white;
  text-align: center;
  .text {
    font-weight: 500;
    font-size: 26px;
  }
}
</style>