import ChatService from "../services/ChatService";

const chat ={
    namespaced: true,
    state: {
        chatGroups: [],
        chatGroupSelected: {},
        dataSocket: {},
        dataReactionEmoji: {},
        modifyMessageSocket: {},
        unCountChatRead: 0,
    },

    getters: {
        getChatGroups(state) {
            return state.chatGroups;
        },
    },

    mutations: {
        updateChatGroups(state, payload) {
            state.chatGroups = payload;
        },
        updateChatGroupSelected(state, payload) {
            state.chatGroupSelected = payload;
        },
        updateDataSocket(state, payload) {
            state.dataSocket = payload;
        },
        updateDataReactionEmoji(state, payload) {
            state.dataReactionEmoji = payload;
        },
        updateUnCountChatRead(state, payload) {
            state.unCountChatRead = payload;
        },
        modifyMessageSocket(state, payload) {
            state.modifyMessageSocket = payload;
        },
    },

    actions: {
        getChatGroups({commit, dispatch}, payload) {
            const params = {
                'page': 1,
                'per_page': 20,
                'status': "ALL"     // "ALL"  - "UNREAD"
            };
            return ChatService.getChatGroups(params).then(res => {
                if (res) {
                    commit("updateChatGroups", res.data);
                }
            });
        },

        getUnreadGroupChat({commit, dispatch}, payload) {
            return  ChatService.getUnreadGroupChat().then(res => {
                if (res.status) {
                    commit("updateUnCountChatRead", res.data);
                }
            });
        },

        dataSocket({commit, dispatch}, payload) {
            commit("updateDataSocket", payload);
        },

        dataReactionEmoji({commit, dispatch}, payload) {
            commit("updateDataReactionEmoji", payload);
        },

        modifyMessageSocket({commit, dispatch}, payload) {
            commit("modifyMessageSocket", payload);
        },
    }
};

export default chat;

