<template>
  <div class="filter mb-20px">
    <div class="display-flex" style="flex-wrap: wrap;">
      <div :class="onlyShowDate ? 'display-none' : 'display-flex my-5px'" style="margin-right: 15px; flex-wrap: wrap;">
        <template v-if="pickType.includes(Number(INVENTORY_TYPE_OPTIONS.KEYWORK))">
          <input class="input-search-field input-keywork" :class="{'mr-15px': this.pickType.length > 1}" type="text"
                 placeholder="Tìm Kiếm..." v-model="keyword"/>
        </template>
        <template
            v-if="typeSelected">
          <multiselect
              label="title"
              track-by="title"
              open-direction="bottom"
              :options="typeOptions"
              :selectLabel="''"
              :deselectLabel="''"
              :selectedLabel="''"
              :class="'multiselect-left'"
              v-model="typeSelected"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.title }}</span>
                </div>
              </md-list-item>
            </template>
          </multiselect>
        </template>
        <template v-if="typeSelected && typeSelected.id === INVENTORY_TYPE_OPTIONS.SKU">
          <multiselect v-model="product"
                       label="sku"
                       track-by="id"
                       placeholder="Nhập SKU"
                       open-direction="bottom"
                       :options="productList"
                       :selectLabel="''"
                       :deselectLabel="''"
                       :selectedLabel="''"
                       :searchable="true"
                       :class="'multiselect-right'"
                       @search-change="onSearchProduct"
                       @select="selectProduct"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <p class="text-400-medium mb-2px">{{ option.sku }}</p>
                  <p class="neutral_3 text-300-default">{{ option.goods.name }}</p>
                </div>
              </md-list-item>
            </template>
            <template slot="afterList">
              <div v-observe-visibility="reachedEndOfListForProduct" v-if="hasNextPageForProduct"/>
            </template>
            <span slot="noResult">Chưa có dữ liệu!</span>
          </multiselect>
        </template>
        <template v-if="typeSelected && typeSelected.id === INVENTORY_TYPE_OPTIONS.USER">
          <multiselect v-model="user"
                       label="fullname"
                       track-by="id"
                       placeholder="Tìm kiếm"
                       open-direction="bottom"
                       :options="userList"
                       :selectLabel="''"
                       :deselectLabel="''"
                       :selectedLabel="''"
                       :searchable="true"
                       @search-change="onSearchUser"
                       @select="selectUser"
                       style="width: 300px">
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <md-avatar>
                  <img class="avatar" :src="option.avatar_url?option.avatar_url:'/img/profile/avatar.png'">
                </md-avatar>

                <div class="md-list-item-text">
                  <span>{{ option.fullname }}</span>
                  <p>{{ option.team ? option.team.name : '' }}</p>
                </div>
              </md-list-item>
            </template>
            <template slot="afterList">
              <div v-observe-visibility="reachedEndOfListForUser" v-if="hasNextPageForUser"/>
            </template>
            <span slot="noResult">Chưa có dữ liệu!</span>
          </multiselect>
        </template>
        <template v-if="typeSelected && typeSelected.id === INVENTORY_TYPE_OPTIONS.GOODS">
          <multiselect v-model="good"
                       label="name"
                       track-by="id"
                       placeholder="Tìm kiếm"
                       open-direction="bottom"
                       :options="goodList"
                       :selectLabel="''"
                       :deselectLabel="''"
                       :selectedLabel="''"
                       :searchable="true"
                       @search-change="onSearchGood"
                       @select="selectGood"
                       style="width: 300px">
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.name }} ({{ option.code }})</span>
                </div>
              </md-list-item>
            </template>
            <template slot="afterList">
              <div v-observe-visibility="reachedEndOfListForGood" v-if="hasNextPageForGood"/>
            </template>
            <span slot="noResult">Chưa có dữ liệu!</span>
          </multiselect>
        </template>
        <template v-if="showInventory">
          <multiselect
              label="name"
              track-by="name"
              open-direction="bottom"
              :options="inventoryOptions"
              :selectLabel="''"
              :deselectLabel="''"
              :selectedLabel="''"
              style="width: 200px!important; padding-left: 15px"
              v-model="inventorySelected"
              :allow-empty="false"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.name }}</span>
                </div>
              </md-list-item>
            </template>
          </multiselect>
        </template>
        <template v-if="pickType.includes(Number(INVENTORY_TYPE_OPTIONS.NEGATIVE_SKU))">
          <multiselect
              label="title"
              track-by="id"
              open-direction="bottom"
              :options="listTypeOptions"
              :selectLabel="''"
              :deselectLabel="''"
              :selectedLabel="''"
              style="width: 200px !important; padding-left: 15px"
              :class="'multiselect-left'"
              v-model="listTypeSelected"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.title }}</span>
                </div>
              </md-list-item>
            </template>
          </multiselect>
        </template>
        <template v-if="pickType.includes(Number(INVENTORY_TYPE_OPTIONS.STATUS))">
          <multiselect
              label="title"
              track-by="id"
              open-direction="bottom"
              :options="listStatusOptions"
              :selectLabel="''"
              :deselectLabel="''"
              :selectedLabel="''"
              style="width: 200px !important; padding-left: 15px"
              :class="'multiselect-left'"
              v-model="listStatusSelected"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.title }}</span>
                </div>
              </md-list-item>
            </template>
          </multiselect>
        </template>
      </div>
      <div class="display-flex my-5px">
        <template v-if="showDate">
          <input v-if="!datePicker" v-bind:id="`${idFilterDate}`" class="input-date mr-15px" type="text"
                 name="daterange"/>
          <div v-else class="mr-15px display-flex">
            <template v-if="timeOnlyShowMonth">
              <input name="startDate" id="date-picker" ref="datepicker" class="input-date mr-15px" autocomplete="off"/>
            </template>
            <template v-else>
              <multiselect
                  open-direction="bottom"
                  :options="timeOptions"
                  :selectLabel="''"
                  :deselectLabel="''"
                  :selectedLabel="''"
                  @open="getSwitchStockAdjustmentDate"
                  :class="''"
                  :style="'width:150px'"
                  v-model="timeSelected"
              >
                <template slot="option" slot-scope="{ option }">
                  <md-list-item>
                    <div class="md-list-item-text">
                      <span>{{ option }}</span>
                    </div>
                  </md-list-item>
                </template>
              </multiselect>
            </template>
          </div>
        </template>
        <md-button class="btn-filter text-400-default" @click="onFilter">Tạo báo cáo</md-button>
      </div>
    </div>
    <div class="mb-15px mt-20px filter-selected"
         v-if="form.products.length > 0 || form.users.length > 0 || form.goods.length > 0">
      <div class="display-flex mb-10px" v-if="form.products.length > 0">
        <span class="mr-10px">SKU:</span>
        <div class="">
          <span class="item-select" v-for="(item, index) in form.products" :key="item.id">
           <span class="close-icon cursor-pointer" @click="removeItem(item, index, 'product')"><md-icon
               class="reset-icon">close</md-icon></span>
           <span>{{ item.sku }}</span>
          </span>
        </div>
        <span class=" cursor-pointer delete-all" @click="removeAllItem('product')">Xoá tất cả</span>
      </div>

      <div class="display-flex" v-if="form.users.length > 0">
        <span class="">Người tạo:</span>
        <div class="">
          <span class="item-select" v-for="(item, index) in form.users" :key="item.id">
           <span class="close-icon cursor-pointer" @click="removeItem(item, index, 'user')"><md-icon>close</md-icon></span>
           <span>{{ item.fullname }}</span>
          </span>
        </div>
        <span class="cursor-pointer delete-all" @click="removeAllItem('user')">Xoá tất cả</span>
      </div>
      <div class="display-flex" v-if="form.goods.length > 0">
        <span class="">Sản phẩm:</span>
        <div class="">
          <span class="item-select" v-for="(item, index) in form.goods" :key="item.id">
           <span class="close-icon cursor-pointer" @click="removeItem(item, index, 'good')"><md-icon>close</md-icon></span>
           <span>{{ item.name }} ({{ item.code }})</span>
          </span>
        </div>
        <span class="cursor-pointer delete-all" @click="removeAllItem('good')">Xoá tất cả</span>
      </div>

    </div>
  </div>
</template>

<script>

import {DATE_RANGE_PICKER_CONFIG, INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../const/const";
import InventoryService from "../../../../store/services/InventoryService";
import moment from "moment";

export default {
  name: "inventory-filter",
  data() {
    return {
      INVENTORY_TYPE_OPTIONS,
      typeOptionSku: {id: 1, title: 'SKU'},
      typeOptionUser: {id: 2, title: 'Người tạo'},
      typeOptionOrder: {id: 3, title: 'Đơn hàng'},
      typeOptionGood: {id: 6, title: 'Sản phẩm'},
      typeOptions: [],
      typeSelected: {},
      form: {
        products: [],
        users: [],
        orders: [],
        goods: [],
      },
      //sku - product
      productLastPage: 1,
      productParams: {
        page: 1,
        per_page: 20,
        keyword: "",
        inventory_id: this.$route.params.inventory_id,
      },
      productOptions: new Set(),
      productList: [],
      product: {},
      //user - người tạo
      userLastPage: 1,
      userParams: {
        page: 1,
        per_page: 20,
        search_text: ""
      },
      userOptions: new Set(),
      userList: [],
      user: {},
      //goods
      goodLastPage: 1,
      goodParams: {
        page: 1,
        per_page: 20,
        keyword: "",
        inventory_id: this.$route.params.inventory_id,
      },
      goodOptions: new Set(),
      goodList: [],
      good: {},
      //other
      monthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      monthSelected: (moment().month() + 1),
      yearOptions: [moment().year(), (moment().year() - 1), (moment().year() - 2), (moment().year() - 3), (moment().year() - 4)],
      yearSelected: moment().year(),
      keyword: '',
      inventoryOptions: [],
      inventorySelected: {},
      inventoryParentIds: JSON.parse(localStorage.getItem("inventoryParentIds")),
      inventoryId: this.$route.params.inventory_id,
      timeOptions: [],
      timeSelected: '',
      listTypeOptions: [
        {title: 'Tất cả', id: 0},
        {title: 'Phiếu tạo tay', id: 1}, // sync_history_id
        {title: 'Phiếu đồng bộ từ excel', id: 2}, // reference_code
        {title: 'Phiếu ĐC đã duyệt', id: 3},
      ],
      listTypeSelected: {title: 'Tất cả', id: 0},
      listStatusOptions: [
        {title: 'Tất cả', id: 'all'},
        {title: 'Đã nhập hàng', id: 1},
        {title: 'Phiếu tạm', id: 2},
      ],
      listStatusSelected: {title: 'Đã nhập hàng', id: 1},
      timeoutSearch: null
    };
  },
  props: {
    selectMultipleProduct: {
      type: Boolean,
      default: true
    },
    selectMultipleUser: {
      type: Boolean,
      default: true
    },
    selectMultipleOrder: {
      type: Boolean,
      default: false
    },
    selectMultipleGood: {
      type: Boolean,
      default: true
    },
    tabActive: {
      type: Number,
      default: INVENTORY_REPORT_TAB.TOTAL_STOCK
    },
    pickType: {
      type: Array,
      default: () => [INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.USER, INVENTORY_TYPE_OPTIONS.ORDER, INVENTORY_TYPE_OPTIONS.GOODS]
    },
    onlyShowDate: {
      type: Boolean,
      default: false
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showInventory: {
      type: Boolean,
      default: false
    },
    idFilterDate: {
      type: String,
      default: 'filter_date'
    },
    timeOnlyShowMonth: {
      type: Boolean,
      default: false
    },
    datePicker: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    hasNextPageForProduct() {
      return this.productParams.page < this.productLastPage;
    },
    hasNextPageForUser() {
      return this.userParams.page < this.userLastPage;
    },
    hasNextPageForGood() {
      return this.goodParams.page < this.goodLastPage;
    },
  },
  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.inventoryId = newVal;
      this.productParams.inventory_id = newVal;
      if(this.$route.params.tab !== "report"){
        this.getProduct();
        this.getGood();
        this.getUser();
        this.getInventory();
        this.getSwitchStockAdjustmentDate();
      }

      this.refreshDateFilter();
      this.refreshProductAndUserData();
    },
    'typeSelected': function (newVal) {
      this.form.products = [];
      this.form.users = [];
      this.form.orders = [];
      this.form.goods = [];
    },
    '$store.state.category.reportSwitchStockAdjAction': function (newVal, oldVal) {
      if (newVal.action == 'create') {
        this.timeOptions.push(newVal.date)
        this.timeSelected = newVal.date
      }

      if (newVal.action == 'delete') {
        this.timeSelected = ''
      }
      this.getSwitchStockAdjustmentDate();
    },
    '$route.query.tab': function (oldVal, newVal) {
      console.log(oldVal, newVal);
    }
  },
  mounted() {

    if(this.$route.params.tab !== "report"){
      this.getProduct();
      this.getGood();
      this.getUser();
      this.getInventory();
      this.getSwitchStockAdjustmentDate();
    }

    this.refreshProductAndUserData();
  },
  beforeMount(){
    if(this.$route.params.tab === 'report'){
      this.setDateFilterByParamsOnLoad();
    }
  },
  created() {
    this.refreshDateFilter();
  },
  methods: {
    // get Product
    async getProduct() {
      await InventoryService.getProductOfInventory(this.productParams).then(res => {
        if (res && res.data) {
          res.data.forEach(item => this.productOptions.add(item));
          this.productList = Array.from(this.productOptions);
          this.productLastPage = res.last_page;
        }
      });
    },
    onSearchProduct(search) {
      this.productParams.page = 1;
      this.productParams.per_page = 20;
      this.productParams.keyword = search;
      this.productParams.inventory_id = this.$route.params.inventory_id;
      this.productOptions = new Set();
      this.productLastPage = 1;
      clearTimeout(this.timeoutSearch);
      const self = this;
      this.timeoutSearch = setTimeout(function () {
        self.getProduct();
      }, 800);
    },
    reachedEndOfListForProduct(reached) {
      if (reached) {
        this.productParams.page++;
        this.getProduct();
      }
    },
    selectProduct(product) {
      if (product.id) {
        if (this.selectMultipleProduct) {
          this.form.products.push(product);
          const unique = [...new Map(this.form.products.map((m) => [m.id, m])).values()];
          this.form.products = unique;
        } else {
          this.form.products = [product];
        }
      }
    },

    // get User
    async getUser() {
      await InventoryService.getUserOfInventory(this.userParams).then(res => {
        if (res && res.data) {
          res.data.forEach(item => this.userOptions.add(item));
          this.userList = Array.from(this.userOptions);
          this.userLastPage = res.last_page;
        }
      });
    },
    onSearchUser(search) {
      this.userParams.page = 1;
      this.userParams.per_page = 20;
      this.userParams.keyword = search;
      this.userParams.inventory_id = this.$route.params.inventory_id;
      this.userOptions = new Set();
      this.userLastPage = 1;
      this.getUser();
    },
    reachedEndOfListForUser(reached) {
      if (reached) {
        this.userParams.page++;
        this.getUser();
      }
    },
    selectUser(user) {
      if (user.id) {
        if (this.selectMultipleUser) {
          this.form.users.push(user);
          const unique = [...new Map(this.form.users.map((m) => [m.id, m])).values()];
          this.form.users = unique;
        } else {
          this.form.users = [user];
        }
      }
    },

    // get Goods
    async getGood() {
      await InventoryService.getGoodOfInventory(this.goodParams).then(res => {
        if (res && res.data) {
          res.data.forEach(item => this.goodOptions.add(item));
          this.goodList = Array.from(this.goodOptions);
          this.goodLastPage = res.last_page;
        }
      });
    },
    onSearchGood(search) {
      this.goodParams.page = 1;
      this.goodParams.per_page = 20;
      this.goodParams.keyword = search;
      this.goodOptions = new Set();
      this.goodLastPage = 1;
      clearTimeout(this.timeoutSearch);
      const self = this;
      this.timeoutSearch = setTimeout(function () {
        self.getGood();
      }, 800);
    },
    reachedEndOfListForGood(reached) {
      if (reached) {
        this.goodParams.page++;
        this.getGood();
      }
    },
    selectGood(good) {
      if (good.id) {
        if (this.selectMultipleGood) {
          this.form.goods.push(good);
          const unique = [...new Map(this.form.goods.map((m) => [m.id, m])).values()];
          this.form.goods = unique;
        } else {
          this.form.goods = [good];
        }
      }
    },

    //get Inventory
    async getInventory() {
      const inventories = JSON.parse(localStorage.getItem("inventories"));
      for (let i = 0; i < inventories.length; i++) {
        if (inventories[i]['id'] == this.inventoryId) {
          this.inventoryOptions = inventories[i]['children'];
          this.inventorySelected = inventories[i]['children'][0];
          break;
        }
        if (!this.isInventoryParent()) {
          for (let x = 0; x < inventories[i]['children'].length; x++) {
            if (inventories[i]['children'][x]['id'] == this.inventoryId) {
              this.inventoryOptions = [inventories[i]['children'][x]];
              this.inventorySelected = inventories[i]['children'][x];
              break;
            }
          }
        }
      }
    },

    //getSwitchStockAdjustmentDate
    async getSwitchStockAdjustmentDate() {
      if(this.timeOptions.length > 0){
        return;
      }
      const params = {
        'inventory_id': this.inventoryId
      }
      await InventoryService.switchStockAdjustmentDate(params).then(res => {
        if (res) {
          this.timeOptions = res
        }
      });
    },

    isInventoryParent() {
      return this.inventoryParentIds.includes(Number(this.inventoryId));
    },
    //Remove Item
    removeItem(item, index, type) {
      if (type === 'product') {
        this.form.products.splice(index, 1);
      }
      if (type === 'user') {
        this.form.users.splice(index, 1);
      }
      if (type === 'good') {
        this.form.goods.splice(index, 1);
      }
    },
    removeAllItem(type) {
      if (type === 'product') {
        this.form.products = []
      }
      if (type === 'user') {
        this.form.users = []
      }
      if (type === 'good') {
        this.form.goods = []
      }
    },

    getIdsByArray(arr) {
      let ar = [];
      for (let i = 0; i < arr.length; i++) {
        ar.push(arr[i]['id']);
      }
      return ar
    },

    //Filter - update url
    emitFilters() {
      let startDate = '';
      let endDate = '';

      if (!this.datePicker) {
        let idFilterDate = '#' + this.idFilterDate;
        if (this.showDate) {
          startDate = $(idFilterDate).data('daterangepicker').startDate.format('YYYY-MM-DD');
          endDate = $(idFilterDate).data('daterangepicker').endDate.format('YYYY-MM-DD');
        }
      } else {
        if (this.timeOnlyShowMonth) {
          let dateMonthYear = $('#date-picker').data('datepicker').date.toISOString();
          this.daySelected = '01';
          this.monthSelected = moment(dateMonthYear).format('MM');
          this.yearSelected = moment(dateMonthYear).format('yyyy');
        } else {
          this.daySelected = moment(this.timeSelected).format('DD');
          this.monthSelected = moment(this.timeSelected).format('MM');
          this.yearSelected = moment(this.timeSelected).format('yyyy');
        }
      }

      let date = this.yearSelected + '-' + this.monthSelected + '-' + this.daySelected;

      let productIds = this.form.products.length > 0 ? this.getIdsByArray(this.form.products) : [];
      let userIds = this.form.users.length > 0 ? this.getIdsByArray(this.form.users) : [];
      let orderIds = this.form.orders.length > 0 ? this.getIdsByArray(this.form.orders) : [];
      let goodIds = this.form.goods.length > 0 ? this.getIdsByArray(this.form.goods) : [];
      let keywork = this.keyword;
      let inventoryId = this.inventorySelected.id;
      let listTypeSelected = this.listTypeSelected.id;
      let listStatusSelected = this.listStatusSelected.id;

      let changeParams = {
        tab: this.tabActive,
        start_date: startDate,
        end_date: endDate,
        product_id: productIds,
        good_id: goodIds,
        user_id: userIds,
        order_id: orderIds,
        inventory_id: inventoryId,
        list_type: listTypeSelected,
        date: date,
        keywork: keywork,
        status: listStatusSelected,
      };

      let query_params = {};
      if(this.$route.params.tab === 'report'){
        query_params.tab = this.tabActive
        if(startDate && endDate && [INVENTORY_REPORT_TAB.TOTAL_STOCK].includes(this.tabActive)){
          query_params.start_date = startDate;
          query_params.end_date = endDate;
        }
       
        if(this.timeSelected && this.timeOptions){
          query_params.date = date;
        }
        if(this.timeOnlyShowMonth){
          query_params.dateMonth = this.yearSelected + '-' + this.monthSelected;
        }

        this.$router.push({ query: { ...this.$route.query, ...query_params }, hash: this.$route.hash });
      }
      
      this.$emit('change', changeParams);
    },
    onFilter() {
      this.emitFilters();
    },

    refreshDateFilter() {
      let dateRangePickerConfig = {...DATE_RANGE_PICKER_CONFIG};
      $(function () {
        let today = moment().format('DD/MM/YYYY')
        let firstDateOfMonth = moment().startOf('month').format('DD/MM/YYYY');
        dateRangePickerConfig.startDate = firstDateOfMonth;
        dateRangePickerConfig.endDate = today;
        $('input[name="daterange"]').daterangepicker(dateRangePickerConfig);

        $('#date-picker').datepicker({
          format: "mm-yyyy",
          startView: "months",
          minViewMode: "months",
          language: 'vi'
        });
        $("#date-picker").datepicker("setDate", new Date());


        $('#date-time-picker').datepicker({
          format: "dd-mm-yyyy",
          // startView: "months",
          // minViewMode: "months",
          language: 'vi'
        });
        $("#date-time-picker").datepicker("setDate", new Date());
      });
    },
    refreshProductAndUserData() {
      this.form.products = [];
      this.form.users = [];
      this.form.orders = [];
      this.form.goods = [];

      for (let i = 0; i < this.pickType.length; i++) {
        if (this.pickType[i] === INVENTORY_TYPE_OPTIONS.SKU) {
          this.typeOptions.push(this.typeOptionSku);
        }
        if (this.pickType[i] === INVENTORY_TYPE_OPTIONS.USER) {
          this.typeOptions.push(this.typeOptionUser)
        }
        if (this.pickType[i] === INVENTORY_TYPE_OPTIONS.ORDER) {
          this.typeOptions.push(this.typeOptionOrder)
        }
        if (this.pickType[i] === INVENTORY_TYPE_OPTIONS.GOODS) {
          this.typeOptions.push(this.typeOptionGood)
        }
      }

      this.typeSelected = this.typeOptions[0];
    },
    setDateFilterByParamsOnLoad() {
     
      if(this.$route && this.$route.params.tab === 'report' && Object.keys(this.$route.query).length == 0){
          return;
      }
      // console.log(this.tabActive);
      setTimeout(() => {
        let dateRangePickerConfig = {...DATE_RANGE_PICKER_CONFIG};
      let endDate = '';
      let startDate = '';
      const router_query = this.$route.query;

      if(router_query.start_date && router_query.end_date){
        endDate = moment(this.$route.query.end_date).format('DD/MM/YYYY')
        startDate = moment(this.$route.query.start_date).format('DD/MM/YYYY');
        dateRangePickerConfig.startDate = startDate;
        dateRangePickerConfig.endDate = endDate;

        $('input[name="daterange"]').daterangepicker(dateRangePickerConfig);
      }
      if(router_query.date){
        this.getSwitchStockAdjustmentDate();
        this.timeSelected = router_query.date;
      }

        $('#date-picker').datepicker({
          format: "mm-yyyy",
          startView: "months",
          minViewMode: "months",
          language: 'vi'
        });
        if(router_query.dateMonth){
          $("#date-picker").datepicker("setDate", new Date(router_query.dateMonth));
        }
        
        // $('#date-time-picker').datepicker({
        //   format: "dd-mm-yyyy",
        //   // startView: "months",
        //   // minViewMode: "months",
        //   language: 'vi'
        // });
        // $("#date-time-picker").datepicker("setDate", new Date());
        this.$emit('change', this.$route.query);
      }, 500)
    },
  }
}
</script>

<style lang="scss">
.filter {
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  font-weight: 500;

  .multiselect {
    .multiselect__tags {
      border-color: #D8DAE5;
      min-height: auto;
    }

    .multiselect__single {
      font-size: 14px;
    }
  }

  .multiselect-left {
    width: 140px;

    .multiselect__tags {
      border-radius: 4px 0 0 4px !important;

    }
  }

  .multiselect-right {
    width: 270px;

    .multiselect__tags {
      border-radius: 0 4px 4px 0 !important;
      border-left: none;
    }
  }

  .filter-selected {

    .item-select {
      padding: 4px 6px;
      margin-right: 6px;
      background: #EDEFF5;
      border: 1px solid #D8DAE5;
      border-radius: 4px;

      .close-icon {
        width: auto;
        margin-right: 2px;

        .md-icon {
          font-size: 14px !important;
          font-weight: 700;
        }
      }
    }
  }

  .delete-all {
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #376BFB;
    flex: none;
    order: 4;
    flex-grow: 0;
  }

  .input-date {
    height: 38px;
    border: 1px solid #D8DAE5;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
  }

  .input-keywork {
    height: 36px;
    border: 1px solid #D8DAE5;
    font-size: 14px;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn-filter {
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    height: 36px;
    background: #4D77EC !important;
    border-radius: 4px;
    margin: 0px;
  }
}
</style>