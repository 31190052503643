<template>
  <div>
    <inventory-filter
        @change="handleFilterChange"
        :pick-type="[INVENTORY_TYPE_OPTIONS.SKU]"
        :tab-active="tabActive"
        :only-show-date="true"
        :date-picker="true"
        :time-only-show-month="false"
        :id-filter-date="'inventory-by-month'"
    ></inventory-filter>
    <template v-if="totalStockData.length == 0  && !isClickFilter">
      <p class="text-center">Chọn điều kiện trong bộ lọc và click và Tạo báo cáo</p>
    </template>
    <template v-else>
      <div class="mb-20px d-flex-space-between">
        <div class="heading-600 mt-4">Chuyển kho điều chỉnh</div>
        <div class="d-flex-center">
          <div class="export-excel-btn" v-if="totalStockData.length > 0" @click="exportTotalStockData">
            <md-icon>file_download</md-icon>
            <span>
              Xuất Excel
            </span>
          </div>
          <div class="ml-10px export-excel-btn" v-if="totalStockData.length > 0 && params.date" @click="acceptAll">
            <md-icon>done_all</md-icon>
            <span>
              Duyệt toàn bộ phiếu
            </span>
          </div>
          <div class="ml-10px export-excel-btn" v-if="params.date" @click="confirmDelete">
            <md-icon>delete</md-icon>
            <span>
              Xoá dữ liệu ngày
            </span>
          </div>
          <div class="ml-10px zoom-out-btn" @click="generate">
            <md-icon>add_circle_outline</md-icon>
            <span>
              Tạo dữ liệu điều chỉnh
            </span>
          </div>
        </div>
      </div>
      <div>
        <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
        <inventory-table ref="tableContainer" border-y :columns="columns" :table-data="totalStockData" :cell-span-option="cellSpanOption"/>
      </div>
    </template>
  </div>
</template>

<script>

import InventoryService from "../../../../../store/services/InventoryService";
import InventoryFilter from "@/pages/Workplace/Pages/Inventory/Filter";
import {INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../../const/const";
import InventoryTable from "../Table";

export default {
  name: "SwitchStockAdjustment",
  components: {
    InventoryTable,
    InventoryFilter,
  },
  data() {
    return {
      INVENTORY_TYPE_OPTIONS,
      isLoad: false,
      inventoryId: this.$route.params.inventory_id,
      tabActive: INVENTORY_REPORT_TAB.SWITCH_STOCK_ADJ,
      params: {
        inventory_id: this.$route.params.inventory_id,
      },
      columns: [
        {field: "reference_code", key: "a1", title: "Mã tham chiếu", align: "center"},
        {field: "product_name", key: "a8", title: "Sản phẩm", align: "left",},
        {field: "quantity_move", key: "a15", title: "Số lượng điều chuyển", align: "left",},
        {field: "code", key: "a2", title: "Mã phiếu", align: "center"},
        {field: "inventory_main_name", key: "a3", title: "Kho xuất", align: "left"},
        {field: "inventory_sub_name", key: "a4", title: "Kho nhập", align: "left"},
        {field: "date", key: "a6", title: "Ngày", align: "left"},
        {field: "description", key: "a7", title: "Ghi chú", align: "left"},
        {field: "product_sku", key: "a9", title: "SKU", align: "left",},
        {field: "quantity", key: "a10", title: "Số lượng", align: "right",},
        {field: "quantity_in_main_stock", key: "a11", title: "Tồn kho xuất", align: "right",},
        {field: "later_main_quantity", key: "a12", title: "Tồn xuất sau ĐC", align: "right",},
        {field: "quantity_in_sub_stock", key: "a13", title: "Tồn kho nhập", align: "right",},
        {field: "later_sub_quantity", key: "a14", title: "Tồn nhập sau ĐC", align: "right",},
        {
          field: "action", key: "a16", title: "Hành động", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="d-flex-center">
                  <div class="cursor-pointer text-success" on-click={() => this.confirmAccept(row)}>
                    <span>Duyệt</span>
                  </div>
                  <span class="ml-5px mr-5px">/</span>
                  <span class="cursor-pointer text-danger" on-click={() => this.confirmReject(row)}>
                    <span>Không Duyệt</span>
                  </span>
                </div>
            );
          },
        },
      ],
      cellSpanOption: {
        bodyCellSpan: ({row, column, rowIndex}) => {
          if (column.field === "reference_code" || column.field === "action" || column.field === "product_name" || column.field === "quantity_move") {
            return {
              rowspan: row.rowspan_reference_code,
              colspan: 1,
            };
          }
        },
      },
      totalStockData: [],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      axiosSource: null,
      isClickFilter: false
    };
  },
  props: {},
  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.params.inventory_id = newVal;
      this.isClickFilter = false;
      // setTimeout(() => {
      //   this.getSwitchStockAdjustmentCoupons();
      // }, 1000);
    },
    'isLoad': function (newVal) {
      this.$emit('change-is-load', newVal);
    },
    '$store.state.category.reportSwitchStockAdjAction': function (newVal, oldVal) {
      console.log(newVal);
      if (newVal.action == 'create') {
        this.params.date = newVal.date
      }

      if (newVal.action == 'delete') {
        this.params.date = ''
      }
      this.getSwitchStockAdjustmentCoupons();
    },
    // '$store.state.auth.tabActive'(newV) {
    //   if(newV == INVENTORY_REPORT_TAB.SWITCH_STOCK_ADJ){
    //     this.getSwitchStockAdjustmentCoupons();
    //   }
    // },
    '$store.state.category.reloadOnListProduct': function (newVal) {
      if(newVal === 1){
        this.getSwitchStockAdjustmentCoupons();
        // reset
        this.$store.commit("updateReloadOnListProduct", 0);
      }
    }
  },
  mounted() {
    // this.getSwitchStockAdjustmentCoupons();
  },
  methods: {
    async getSwitchStockAdjustmentCoupons(count = 0) {
      if(this.$route.query.tab && this.$route.query.tab != this.tabActive){
        return;
      }
      this.isLoad = true;
      this.totalStockData = [];
      let totalStockData = [];
      let stateTotalStockData = [];
      let params = {...this.params};
      InventoryService.getSwitchStockAdjustmentCoupons(params).then(res => {
        if (res) {
          for (var key in res) {
            var item = res[key];
            for (let x = 0; x < item.length; x++) {
              let products = item[x].products

              // let quantityMove = products.reduce((prev, current) => (Number(prev.quantity) > Number(current.quantity)) ? prev : current)
              let quantityMove = Math.max.apply(Math, products.map(function (o) {
                return o.quantity;
              }))

              for (let k = 0; k < products.length; k++) {
                let columns = {};
                columns.rowspan_reference_code = 0;
                for (let c = 0; c < item.length; c++) {
                  columns.rowspan_reference_code += item[c].products.length;
                }
                columns.reference_code = key;
                columns.action = key;
                columns.code = item[x].code;
                columns.inventory_main_name = item[x].inventory_main_name;
                columns.inventory_sub_name = item[x].inventory_sub_name;
                columns.date = item[x].date;
                columns.description = item[x].description;
                columns.product_name = products[k].product_name;
                columns.product_sku = products[k].product_sku;
                columns.quantity = Number(products[k].quantity);
                columns.quantity_move = quantityMove;
                columns.quantity_in_main_stock = Number(products[k].quantity_in_main_stock);
                columns.later_main_quantity = Number(products[k].quantity_in_main_stock) - Number(products[k].quantity);
                columns.quantity_in_sub_stock = Number(products[k].quantity_in_sub_stock);
                columns.later_sub_quantity = Number(products[k].quantity_in_sub_stock) + Number(products[k].quantity);
                totalStockData.push(columns);
              }
            }
          }

          let result = [];
          for (let i = 0; i < totalStockData.length; i++) {
            let ite = {};
            ite.rowspan_reference_code = totalStockData[i].rowspan_reference_code;
            ite.reference_code = totalStockData[i].reference_code;
            ite.code = totalStockData[i].code;
            ite.inventory_main_name = totalStockData[i].inventory_main_name;
            ite.inventory_sub_name = totalStockData[i].inventory_sub_name;
            ite.date = totalStockData[i].date;
            ite.description = totalStockData[i].description;
            ite.product_name = totalStockData[i].product_name;
            ite.product_sku = totalStockData[i].product_sku;
            ite.quantity = totalStockData[i].quantity;
            ite.quantity_move = totalStockData[i].quantity_move;
            ite.quantity_in_main_stock = totalStockData[i].quantity_in_main_stock;
            ite.later_main_quantity = totalStockData[i].later_main_quantity;
            ite.quantity_in_sub_stock = totalStockData[i].quantity_in_sub_stock;
            ite.later_sub_quantity = totalStockData[i].later_sub_quantity;
            ite.action = totalStockData[i].action;

            if (result.length > 0) {
              const last = result.pop();
              if (last.action === ite.reference_code) {
                delete ite.reference_code;
                ite.rowspan_reference_code = 0;
              }
            }
            result.push(ite);
            stateTotalStockData.push(result[0]);
          }

          let processedData = {};

          stateTotalStockData.forEach(item => {
              const key = `${item.product_sku}_${item.action}`;

              if (!processedData[key]) {
                  processedData[key] = {
                      quantity_in_main_stock: item.quantity_in_main_stock,
                      quantity_in_sub_stock: item.quantity_in_sub_stock,
                  };
              }else{
                  item.quantity_in_main_stock = processedData[key].quantity_in_main_stock;
                  // item.quantity_in_sub_stock = processedData[key].quantity_in_sub_stock;
              }

              processedData[key].later_main_quantity = item.quantity_in_main_stock - item.quantity;
              processedData[key].later_sub_quantity = item.quantity_in_sub_stock + item.quantity;

              // Cập nhật giá trị quantity_in_main_stock và quantity_in_sub_stock cho lần sau
              processedData[key].quantity_in_main_stock = processedData[key].later_main_quantity;
              processedData[key].quantity_in_sub_stock = processedData[key].later_sub_quantity;

              item.later_main_quantity = processedData[key].later_main_quantity;
              item.later_sub_quantity = processedData[key].later_sub_quantity;
          });
          processedData = {};
          this.totalStockData = stateTotalStockData;
        }
        this.isLoad = false;
      })
    },

    handleFilterChange(filters) {
      this.params.date = filters.date
      this.getSwitchStockAdjustmentCoupons();
      this.isClickFilter = true;
    },

    confirmAccept(row) {
      this.$modal.show('dialog', {
        title: 'Xác nhận điều chỉnh',
        text: `Bạn có chắc chắn DUYỆT các 2 phiếu chuyển kho điều chỉnh đã chọn?`,
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Đồng ý',
            handler: () => {
              this.accept(row);
              this.$modal.hide('dialog');
            }
          }
        ]
      })
    },

    confirmReject(row) {
      this.$modal.show('dialog', {
        title: 'Xác nhận điều chỉnh',
        text: "Bạn có chắc chắn KHÔNG DUYỆT 2 phiếu chuyển kho điều chỉnh đã chọn? Hệ thống sẽ xóa 2 phiếu điều chỉnh này khi bạn xác nhận đồng ý.",
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Đồng ý',
            handler: () => {
              this.reject(row);
              this.$modal.hide('dialog');
            }
          }
        ]
      })
    },

    confirmDelete(row) {
      this.$modal.show('dialog', {
        title: 'Xác nhận xoá',
        text: "Bạn có chắc chắn muốn xoá các phiếu chuyển kho điều chỉnh dưới đây. Hệ thống sẽ xóa các phiếu điều chỉnh này khi bạn xác nhận đồng ý.",
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Đồng ý',
            handler: () => {
              this.delete();
              this.$modal.hide('dialog');
            }
          }
        ]
      })
    },

    accept(row) {
      this.isLoad = true;
      const params = {
        "reference_code": row.reference_code
      }
      InventoryService.switchStockAdjustmentAccept(params).then(res => {
        if (res.status) {
          this.getSwitchStockAdjustmentCoupons();
          this.$store.dispatch("alerts/success", 'Duyệt phiếu điều chỉnh thành công.');
        }
      }).catch(err => {
      });
      this.isLoad = false;
    },

    acceptAll() {
      if(!this.params.inventory_id || !this.params.date){
        this.$store.dispatch("alerts/error", 'Có lỗi xảy ra. Vui lòng thử lại.');
        return;
      }

      const params = {
        "date": this.params.date,
        "inventory_id": this.params.inventory_id
      }

      this.$modal.show('dialog', {
        title: 'Xác nhận duyệt toàn bộ phiếu',
        text: "Bạn có chắc chắn muốn Duyệt tất cả phiếu điều chỉnh không?",
        buttons: [
          {
            title: 'Hủy',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: 'Đồng ý',
            handler: async () => {
              this.isLoad = true;
              await InventoryService.switchStockAdjustmentAcceptAll(params).then(res => {
                if (res.status) {
                  this.getSwitchStockAdjustmentCoupons();
                  this.$store.dispatch("alerts/success", 'Duyệt toàn bộ phiếu điều chỉnh thành công.');
                }else{
                  this.$store.dispatch("alerts/error", 'Duyệt toàn bộ phiếu điều chỉnh không thành công.');
                }
              }).catch((e) => {
                this.$store.dispatch("alerts/error", 'Duyệt toàn bộ phiếu điều chỉnh không thành công.');
              });
              this.isLoad = false;
              this.$modal.hide('dialog');
            }
          }
        ]
      })
    },

    reject(row) {
      this.isLoad = true;
      const params = {
        "reference_code": row.reference_code
      }
      InventoryService.switchStockAdjustmentReject(params).then(res => {
        if (res.status) {
          this.getSwitchStockAdjustmentCoupons()
          this.$store.dispatch("alerts/success", 'Xóa phiếu điều chỉnh thành công.');
        }
      }).catch(err => {
      });
      this.isLoad = false;
    },

    delete() {
      this.isLoad = true;
      const params = {
        "date": this.params.date
      }
      InventoryService.deleteAdjustmentCoupon(params).then(res => {
        if (res.status) {
          this.getSwitchStockAdjustmentCoupons()
          this.$store.dispatch("alerts/success", 'Xóa phiếu điều chỉnh thành công.');
          this.$store.commit("updateReportSwitchStockAdjAction", {date: this.params.date, action: 'delete'});
        }
      }).catch(err => {
      });
      this.isLoad = false;
    },

    generate() {
      this.$modal.show('switch-stock-adjustment-modal')
    },

    exportTotalStockData(){
      let table = this.$refs.tableContainer.$el.querySelector('table');

      if(table === null){
        this.$store.dispatch("alerts/error", 'Xuất file excel lỗi. Vui lòng thử lại');
        return;
      }
      // tên của workshhet nếu muốn thay đổi - mặc định "Worksheet"
      let name = '';

      var uri = 'data:application/vnd.ms-excel;base64,'
          , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
          , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
          , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
      
      // Clone table để không ảnh hưởng đến dữ liệu đang hiển thị
      var clonedTable = table.cloneNode(true);

      var rows = clonedTable.querySelectorAll('tr');
      let removeFistLoop = false;
      rows.forEach(function(row) {
          if(row.classList.length){
              var lastTh = row.querySelector('th:last-child');
              if (lastTh) {
                  row.removeChild(lastTh);
              }
              var lastTd = row.querySelector('td:last-child');
              
              if (lastTd && !removeFistLoop) {
                  row.removeChild(lastTd);
                  removeFistLoop = true;
              }
              var elementChild = row.querySelector('td:has(*)');
              if(elementChild){
                  row.removeChild(elementChild);
              }
          }
      });
      removeFistLoop = false;
      var ctx = {worksheet: name || 'Worksheet', table: clonedTable.innerHTML}
      var link = document.createElement('a');
      link.href = uri + base64(format(template, ctx))
      link.download = `Báo cáo kho ${this.params.date} điều chuyển biến thể.xls`;
      link.click();
    }
  }
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }

  .main-inventory {
    font-weight: bold !important;
    color: black !important;

    td {
      font-weight: 700 !important;
    }
  }

}
</style>