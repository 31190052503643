<template>
  <div class="post-layout" :class="isPostPreview?'post-preview':''">
    <md-card :key="post.id">
      <div class="post-header">
        <post-header :post=post.post
                     :userPost=post.user
                     :group=post.group
                     :isGroup="isGroup"
                     :isPost="isPost"
                     :poll-data="post.poll"
                     :is-post-preview="isPostPreview"
                     :post-preview="post.preview_post"
                     :users-tagged="post.users_tagged" />
      </div>

      <div class="post-content">
        <post-content :post=post.post :poll-data="post.poll" :post-preview="post.preview_post" :is-post-preview="isPostPreview"></post-content>
      </div>

      <div class="post-footer" v-if="!isPostPreview">
        <post-footer :post=post.post
                     :comments=post.comments
                     :userPost=post.user
                     :liked=post.post.liked
                     :likeCount=post.post.like_count
                     :commentCount=post.post.comment_count
                     :reaction-current="post.post.reaction_current"
                     :reaction-stats="post.post.reaction_stats"
                     :group=post.group
                     :post-preview="post.preview_post"
        ></post-footer>
      </div>
    </md-card>
  </div>
</template>
<script>
import PostHeader from "../../Components/Post/postHeader";
import PostContent from "../../Components/Post/postContent";
import PostFooter from "../../Components/Post/postFooter";
import UpdatePostModal from "../../Components/Modal/UpdatePostModal";

export default {
  name: "post-layout",
  components: {PostFooter, PostContent, PostHeader},
  props: {
    post: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isPost: {
      type: Boolean,
      default: false,
    },
    isPostPreview: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
  },
  // provide: function() {
  //   return {
  //     getPostId:this.post.post.id,
  //   }
  // },
  methods: {
  }
};
</script>
