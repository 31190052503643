<template>
  <div>
    <modal name="create-group-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale" />
      <form class="" @submit.prevent="save()">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500 text-header-modal">Tạo nhóm mới</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('create-group-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div class="md-gutter">
            <label class="required heading-400 neutral_3">Tên nhóm</label>
            <input type="text" placeholder="Tên nhóm" class="form-control input-custom" v-model="form.name" required name="name">
          </div>

          <div class="md-gutter pt-15px pb-15px">
            <label class="required heading-400 neutral_3">Chọn loại nhóm</label>
            <v-select :options="typeOptions" label="title" placeholder="Chọn loại nhóm"
                      :reduce="(option) => option.id"
                      v-model="form.type">
              <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    :required="form.type == null"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template slot="option" slot-scope="origin">
                <div class="flex">
                  <div class="col text-overflow-ellipsis">
                    <span class="text-400-medium neutral-1">{{ origin.title }}</span>
                  </div>
                  <div class="col text-overflow-ellipsis">
                    <span class="text-300-default neutral_2">{{ origin.description }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </div>

          <div class="md-gutter pt-15px pb-15px" v-if="form.type == 0">
            <label class="required heading-400 neutral_3">Chọn quy trình</label>
            <multiselect v-model="categoryModel"
                         label="title" track-by="id"
                         placeholder="Chọn quy trình" open-direction="bottom"
                         :options="categoryOptions"
                         :multiple="false"
                         :selectLabel="'Chọn'"
                         :deselectLabel="'Bỏ chọn'"
                         :selectedLabel="'Đã chọn'"
                         :searchable="true"
                         :internal-search="false"
                         :clear-on-select="false"
                         :close-on-select="true"
                         :show-no-results="false"
                         :hide-selected="false"
                         @search-change="onSearchCategory">
              <template slot="option" slot-scope="{ option }">
                <md-list-item>
                  <div class="md-list-item-text">
                    <span>{{ option.title }}</span>
                    <p>{{ option.description }}</p>
                  </div>
                </md-list-item>
              </template>
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfListForCategory" v-if="hasNextPageForCategory" />
              </template>
              <span slot="noResult">Chưa có dữ liệu!</span>
            </multiselect>
          </div>

          <div class="md-gutter pt-15px pb-15px">
            <label class="heading-400 neutral_3">Quyền tham gia</label>
            <v-select :options="joinPolicyOptions" label="title"
                      :reduce="(option) => option.id"
                      v-model="form.join_policy">
              <template slot="option" slot-scope="origin">
                <div class="flex">
                  <div class="col text-overflow-ellipsis">
                    <span class="text-400-medium neutral-1">{{ origin.title }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </div>

          <div class="md-gutter pt-15px pb-15px">
            <label class="heading-400 neutral_3">Thêm thành viên</label>
            <multiselect v-model="members"
                         label="fullname" track-by="id"
                         placeholder="Chọn thành viên" open-direction="bottom"
                         :options="memberList" :multiple="true"
                         :selectLabel="'Chọn'"
                         :deselectLabel="'Bỏ chọn'"
                         :selectedLabel="'Đã chọn'"
                         :searchable="true"
                         :internal-search="false"
                         :clear-on-select="false"
                         :close-on-select="false"
                         :show-no-results="false" :hide-selected="false" @search-change="onSearchUser">
              <template slot="option" slot-scope="{ option }">
                <md-list-item>
                  <md-avatar>
                    <img class="avatar" :src="option.avatar_url?option.avatar_url:'/img/profile/avatar.png'">
                  </md-avatar>

                  <div class="md-list-item-text">
                    <span>{{ option.fullname }}</span>
                    <p>{{ option.team ? option.team.name : '' }}</p>
                  </div>
                </md-list-item>

              </template>
              <template slot="afterList">
                <div v-observe-visibility="reachedEndOfListForMember" v-if="hasNextPageForMember" />
              </template>
              <span slot="noResult">Chưa có dữ liệu!</span>
            </multiselect>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button type="submit" class="btn-active md-button">Tạo nhóm</button>
          <button class="btn-stroke md-button" @click="$modal.hide('create-group-modal')">Huỷ</button>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>
import PostService from "../../../store/services/PostService";
import EmployeeService from "../../../store/services/EmployeeService";
import GroupService from "../../../store/services/GroupService";
import CategoryService from "../../../store/services/CategoryService";

export default {
  name: 'create-group-modal',
  computed: {
    hasNextPageForMember() {
      return this.member_params.page < this.member_last_page;
    },
    hasNextPageForAdmin() {
      return this.admin_params.page < this.admin_last_page;
    },
    hasNextPageForWorkProcess() {
      return this.work_process_params.page < this.work_process_last_page;
    },
    hasNextPageForCategory() {
      return this.category_params.page < this.category_last_page;
    }
  },
  created() {
  },
  data: () => ({
    form: {
      name: null,
      type: null,
      category_id: null,
      members: [],
      admin: [],
      description: null,
      join_policy: 1
    },
    typeOptions: [
      {
        id: 0,
        title: 'Nhóm làm việc',
        description: 'Nhóm làm việc và báo cáo hằng ngày của từng phòng ban chuyên biệt'
      },
      {
        id: 1,
        title: 'Nhóm Kín',
        description: 'Bất kỳ ai trong Công ty cũng có thể tìm được nhóm và xem thành viên nhóm. Chỉ thành viên có thể xem bài viết.'
      },
      {
        id: 2,
        title: 'Nhóm Bí mật',
        description: 'Chỉ thành viên mới tìm được nhóm, xem thành viên và những gì họ đăng.'
      }
    ],
    joinPolicyOptions: [
      {
        id: 1,
        title: 'Admin duyệt quyền tham gia nhóm',
      },
      {
        id: 2,
        title: 'Duyệt tự động',
      }
    ],
    memberOptions: new Set(),
    memberList: [],
    members: [],
    adminOptionSet: new Set(),
    adminOptionList: [],
    adminList: [],
    show: false,
    member_params: {
      page: 1,
      per_page: 10,
      search_text: ""
    },
    member_last_page:1,
    admin_params: {
      page: 1,
      per_page: 10,
      search_text: ""
    },
    admin_last_page:1,
    work_process_params: {
      page: 1,
      per_page: 10,
      search_text: ""
    },
    work_process_last_page: 1,
    workProcessModel: [],
    workProcessSet: new Set(),
    workProcessOptions: [],
    category_params: {
      page: 1,
      per_page: 10,
      search_text: "",
      parent_id: 1
    },
    category_last_page: 1,
    categoryModel: null,
    categorySet: new Set(),
    categoryOptions: [],
  }),
  methods: {
    save() {
      this.form.members = this.members.map(item => item.id);
      // this.form.admin = this.adminList.map(item => item.id);
      // if(this.form.type == 0){
        this.form.category_id = this.categoryModel.id;
      // }
      this.createGroup(this.form);
      this.show = true;
    },
    onSearchUser(search) {
      this.member_params = {
        page: 1,
        per_page: 10,
      };
      this.member_params.search_text = search;
      this.memberOptions = new Set();
      this.member_last_page = 1;

      this.getEmployees({keyword: search});
      // this.memberOptions = this.memberOptions.filter(function (item) { return item.fullname.includes(search); })
    },

    // onSearchAdmin(search) {
    //   this.admin_params = {
    //     page: 1,
    //     per_page: 10,
    //   };
    //   this.admin_params.search_text = search;
    //   this.adminOptionSet = new Set();
    //   this.admin_last_page = 1;
    //
    //   this.getAdmins({keyword: search});
    //   // this.memberOptions = this.memberOptions.filter(function (item) { return item.fullname.includes(search); })
    // },

    onSearchWorkProcess(search) {
      this.work_process_params = {
        page: 1,
        per_page: 10,
      };
      this.work_process_params.search_text = search;
      this.work_process_last_page = 1;
      this.workProcessSet = new Set();
      this.getWorkProcess({keyword: search});
    },

    onSearchCategory(search) {
      this.category_params = {
        page: 1,
        per_page: 10,
      };
      this.category_params.search_text = search;
      this.category_last_page = 1;
      this.categorySet = new Set();
      this.getCategories({keyword: search});
    },

    beforeOpened(event) {
      this.getWorkProcess();
      this.getEmployees({}, true);
      this.getCategories();
      this.form.name = null;
      this.form.type = null;
      this.form.category_id = null;
      this.form.members = [];
      this.form.admin = [this.$store.state.auth.user.id];
      this.form.description = null;
      this.form.join_policy = 1;
      this.members = [];
      this.adminList = [];
      this.workProcessModel = [];
      this.categoryModel = [];
      // this.$store.commit("updateModalIsOpen", true);
    },

    beforeClosed(event){
      // this.$store.commit("updateModalIsOpen", false);
    },

    async createGroup(data) {
      await GroupService.createGroup(data).then(res => {
        this.show = false;
        if (res && res.status) {
          this.$store.dispatch("alerts/success", 'Bạn tạo nhóm thành công.');
          this.$modal.hide('create-group-modal');
        } else {
          let html = ''
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              html += '<p>' + res.data[i][0] + '</p>';
            }
          }
          this.$store.dispatch("alerts/error", 'Bạn tạo nhóm không thành công. Nguyên nhân : ' + res.message + html);
        }
      });
    },

    async getWorkProcess(params = {}) {
      // params.page = this.work_process_params.page;
      // await PostService.getWorkProcess(params).then(res => {
      //   if (res && res.status) {
      //     res.data.forEach(item => this.workProcessSet.add(item));
      //     this.workProcessOptions = Array.from(this.workProcessSet);
      //     this.work_process_last_page = res.meta.last_page;
      //   }
      // });
    },

    async getEmployees(params = {}, isInitial = false) {
      params.page = this.member_params.page;
      await EmployeeService.getEmployees(params).then(res => {
        if (res && res.data) {
          res.data.forEach(item => this.memberOptions.add(item));
          this.memberList = Array.from(this.memberOptions);
          this.member_last_page = res.meta.last_page;
          if (isInitial) {
            res.data.forEach(item => this.adminOptionSet.add(item));
            this.adminOptionList = Array.from(this.adminOptionSet);
            this.admin_last_page = res.meta.last_page;
          }
        }
      });
    },

    async getCategories(params = {}) {
      params.page = this.category_params.page;
      await CategoryService.getCategory(params).then(res => {
        if (res && res.data) {
          res.data.forEach(item => this.categorySet.add(item));
          this.categoryOptions = Array.from(this.categorySet);
          this.category_last_page = res.meta.last_page;
        }
      });
    },

    // async getAdmins(params = {}) {
    //   params.page = this.admin_params.page;
    //   await EmployeeService.getEmployees(params).then(res => {
    //     if (res) {
    //       res.data.forEach(item => this.adminOptionSet.add(item));
    //       this.adminOptionList = Array.from(this.adminOptionSet);
    //       this.admin_last_page = res.meta.last_page;
    //     }
    //   });
    // },

    reachedEndOfListForMember(reached) {
      if (reached) {
        this.member_params.page++;
        this.getEmployees({keyword: this.member_params.search_text});
      }
    },
    reachedEndOfListForAdmin(reached) {
      if (reached) {
        this.admin_params.page++;
        this.getAdmins({keyword: this.admin_params.search_text});
      }
    },
    reachedEndOfListForWorkProcess(reached) {
      if (reached) {
        this.work_process_params.page++;
        this.getWorkProcess({keyword: this.work_process_params.search_text});
      }
    },
    reachedEndOfListForCategory(reached) {
      if (reached) {
        this.category_params.page++;
        this.getWorkProcess({keyword: this.category_params.search_text});
      }
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}
.multiselect__element .multiselect__option{
  padding: 0px !important;
}
</style>
