<template>
  <div>
    <modal name="form-vendor-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom form-vendor-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Tạo nhà cung cấp mới</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('form-vendor-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="container-table">
        <div class="form-input-vendor vender-name">
          <div class="form-title required">
            <span>Tên NCC</span>
          </div>
          <div class="form-input">
            <input placeholder="Chọn tên cho nhóm" v-model="name" />
          </div>
          <div class="error" v-if="errors.name">
            <span>{{ errors.name }}</span>
          </div>
        </div>
        <div class="form-input-vendor vender-description">
          <div class="form-title required">
            <span>Mô tả</span>
          </div>
          <div class="form-input">
            <input placeholder="Nhập mô tả nhà cung cấp..." v-model="description" />
          </div>
        </div>
        <div class="form-input-vendor vender-phone">
          <div class="form-title required">
            <span>Số điện thoại</span>
          </div>
          <div class="form-input">
            <input placeholder="Số điện thoại" v-model="phone" />
          </div>
          <div class="error" v-if="errors.phone">
            <span>{{ errors.phone }}</span>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <button type="submit" class="btn-active md-button heading-300" @click="onSubmit">{{ vendor_id ? 'Cập nhật' : 'Tạo mới' }}</button>
        <button type="submit" class="btn-close md-button heading-300" @click="$modal.hide('form-vendor-modal')">Hủy</button>
      </md-dialog-actions>

    </modal>
  </div>
</template>

<script>

export default {
  name: 'form-vendor-modal',
  computed: {},
  mounted() {
  },
  data() {
    return {
      name: '',
      description: '',
      phone: '',
      vendor_id: null, // sử dụng để update,
      errors: {
        name: '',
        description: '',
        phone: ''
      }
    }
  },
  methods: {
    beforeOpened(event) {
      this.resetParams();
      if(event.params.form_update){
        this.vendor_id = event.params.form_update.id;
        this.name = event.params.form_update.name;
        this.description = event.params.form_update.description;
        this.phone = event.params.form_update.phone;
      }
    },

    beforeClosed(event) {
      this.resetParams();
    },

    onSubmit(){

      // validation
      if(this.name === ''){
        this.errors.name = 'Vui lòng nhập tên nhà cung cấp';
        return;
      }

      // format phone number
      if(this.phone !== '' && !this.phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)){
        this.errors.phone = 'Địng dạng số điện thoại không chính xác';
        return;
      }

      let data = {
        name: this.name,
        description: this.description,
        phone: this.phone,
        vendor_id: this.vendor_id
      }
      this.$emit('submit-vendor', data);
      this.resetParams();
      this.$modal.hide('form-vendor-modal');
    },

    resetParams(){
      this.name = '';
      this.description = '';
      this.phone = '';
      this.vendor_id = null; // sử dụng để update
      this.errors = {
        name: '',
        description: '',
        phone: ''
      }
    }

  }

}
</script>

<style lang="scss">
.form-vendor-modal{
    .container-table{
      min-height: 300px;
    }
    .form-input-vendor {
      margin-bottom: 10px;
      .form-input{
        input{
          width: 100%;
          height: 40px;
          padding: 5px;
          border: 1px solid #EDEFF5;
          border-radius: 5px;
          margin-top: 5px;
          font-size: 12px;
        }
      }
      .error{
        font-size: 12px;
          padding-left: 5px;
          color: red;
    }
  }
  .btn-close{
    background-color: #F9FAFC !important;
    border: 1px solid #D8DAE5;
    color: #000000 !important;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
