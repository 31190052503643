import Vue from "vue";
import axios from "axios";
// Plugins
import App from "./App.vue";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import Popover from 'vue-js-popover';
import "vue-select/dist/vue-select.css";
import VueJSModal from 'vue-js-modal';
import VueClipboard from 'vue-clipboard2';
import CKEditor from 'ckeditor4-vue';
import VueElementLoading from 'vue-element-loading';
import VEmojiPicker from 'v-emoji-picker';
import VueLoadmore, {locale} from 'vuejs-loadmore';
import Multiselect from 'vue-multiselect';
import VueObserveVisibility from 'vue-observe-visibility';
import 'vue-datetime/dist/vue-datetime.css';
import {Settings} from 'luxon';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import {Tabs, Tab} from 'vue-tabs-component';
import 'vue-tabs-component/docs/resources/tabs-component.css';
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
//import library vuesax
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css'; //Vuesax styles
// router & store setup
import router from "./router";
import store from "./store";
import NotificationService from "./store/services/NotificationService";
import {helpers} from "./helper/helpers";
import DateRangePicker from 'vue2-daterange-picker';
 // import the component
 import Treeselect from '@riophae/vue-treeselect'
 // import the styles
 import '@riophae/vue-treeselect/dist/vue-treeselect.css'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

Settings.defaultLocale = 'vi';
Vue.use(Vuesax, {
    theme:{
        colors:{
            primary:'#FCAF17',
            success:'rgb(23, 201, 100)',
            danger:'rgb(242, 19, 93)',
            warning:'rgb(255, 130, 0)',
            dark:'rgb(36, 33, 69)'
        }
    }
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Popover, {tooltip: true});
Vue.use(VueAxios, axios);
Vue.use(VueJSModal, {dialog: true, dynamic: true, injectModalsContainer: true});
Vue.use(VueJSModal);
Vue.use(VueClipboard);
Vue.use(CKEditor);
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('multiselect', Multiselect);
Vue.config.productionTip = false;
Vue.use(VEmojiPicker);
Vue.use(VueLoadmore, {lang: 'en-US'});
Vue.use(VueObserveVisibility);
Vue.use(FloatingVue);
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('date-range-picker', DateRangePicker);
Vue.use(VueEasytable);
Vue.component('treeselect', Treeselect);

let accessTokenLocal = localStorage.getItem("access_token");
let userIdLocal = localStorage.getItem("user_id");
let accessTokenCookie = helpers.getCookie("access_token");
let userIdCookie = helpers.getCookie("access_token");

let accessToken = accessTokenLocal;
let userId = userIdLocal;
if (accessTokenLocal == null) {
    accessToken = accessTokenCookie;
    localStorage.setItem("access_token", accessTokenCookie);
}

if (userIdLocal == null) {
    userId = userIdCookie;
    localStorage.setItem("user_id", userIdCookie);
}

if (accessToken !== null && accessToken !== '') {
    let token = accessToken.replace('%', '|');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // axios.defaults.headers.common.aza_social_token = `${token}`;
    // setTimeout(() => {
    //     let fcmToken = localStorage.getItem("cmf_token");
    //     let sendCmfToken = localStorage.getItem("send_cmf_token");
    //     if (fcmToken != null && sendCmfToken != 'true') {
    //         NotificationService.postFcmToken({token: fcmToken}).then(res => {
    //             localStorage.setItem("send_cmf_token", true);
    //         });
    //     }
    // }, 1000);
}
const app = new Vue({
    router: router,
    store: store,
    el: "#app",
    render: h => h(App)
});

store.$app = app;
window.app = app;