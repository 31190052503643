<template>
  <div class="vendor">
    <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
    <div class="mb-20px d-flex-space-between">
      <div class="heading-600">Danh sách NCC</div>
      <div class="d-flex-center">
        <md-button class="btn-filter text-400-default" @click="$modal.show('form-vendor-modal')">
          <span>Thêm NCC</span>
        </md-button>
      </div>
    </div>
    <!-- <div class="w_100">
      <div class="float-right">
        <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam"/>
      </div>
    </div> -->
    <inventory-table border-y :columns="VendorHeader" :table-data="vendors"
                     :cell-style-option="cellStyleOption"/>
    <form-vendor-modal @submit-vendor="onSaveVendor($event)"></form-vendor-modal>
  </div>
</template>

<script>
import {
  DATE_RANGE_PICKER_CONFIG,
  INVENTORY_COUPON_TYPE,
  INVENTORY_TYPE_OPTIONS,
  SETTING_TAB
} from "../../../../../const/const";
import moment from "moment";
import VendorService from "../../../../../store/services/VendorService";
import InventoryTable from "@/pages/Workplace/Pages/Inventory/Table";
// import PaginationTemplate from "@/pages/Components/PaginationTemplate";
// import $ from "jquery"
import FormVendorModal from "../../../../Components/Modal/FormVendorModal";

export default {
  name: 'Settings',
  components: {
    InventoryTable,
    // PaginationTemplate,
    FormVendorModal
  },
  data() {
    return {
      SETTING_TAB,
      INVENTORY_TYPE_OPTIONS,
      INVENTORY_COUPON_TYPE,
      isLoad: false,
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0
      },
      vendors: [],
      VendorHeader: [
        {
          field: "stt", key: "sync_history_1", title: "STT", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return ++rowIndex;
          },
        },
        {
          field: "name",
          key: "vendor_name",
          title: "Tên",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span>{row.name}</span>
            );
          }
        },
        {
          field: "description",
          key: "vendor_description",
          title: "Mô tả",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span>{row.description ? row.description : '--'}</span>
            );
          }
        },
        {
          field: "phone",
          key: "vendor_phone",
          title: "Hotline",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <span>{row.phone ? row.phone : '--'}</span>
            );
          }
        },
        {
          field: "action_vendor",
          key: "action_vendor",
          title: "Hành động",
          align: "left",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return (
                <div class="action-vendor-container">
                  <button class="p-2px cursor-pointer edit-vendor tooltip ml-5px" title="Chỉnh sửa nhà cung cấp" on-click={() => this.showFormUpdateVendor(row.id)}>
                    <md-icon>edit</md-icon>
                  </button>
                  {/* <button class="p-2px cursor-pointer delete-vendor tooltip ml-5px" title="Xóa nhà cung cấp" on-click={() => this.deleteVendor(row.id)}>
                    <md-icon>delete</md-icon>
                  </button> */}
                </div>
            );
          }
        },
      ],
      cellStyleOption: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (column.field === "action_vendor") {
            return "action-vendor";
          }
        },
      }
    };
  },
  created() {

  },
  mounted() {
    this.getVendors();
  },
  watch: {
  },
  methods: {
    async getVendors(){
      this.isLoad = true;
      await VendorService.getVendor({}).then(res => {
        if(res.data){
          this.vendors = res.data;
        }
      });
      this.isLoad = false;
    },

    deleteVendor(vendor_id){
      this.$modal.show('dialog', {
        title: 'Xóa nhà cung cấp',
        text: 'Bạn có chắc muốn xóa nhà cung cấp',
        buttons: [
          {
            title: 'Không',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Xóa',
            handler: () => {
              VendorService.deleteVendor(vendor_id).then(res => {
                if(res.status){
                  this.vendors.splice(this.vendors.findIndex(vendor => vendor.id === vendor_id), 1);
                  this.$store.dispatch("alerts/success", 'Đã xóa nhà cung cấp!');
                }else{
                  this.$store.dispatch("alerts/error", 'Có lỗi xảy ra!');
                }
              });
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },

    onSaveVendor(vendor){
      if(vendor.vendor_id !== null){
        this.onUpdateVendor(vendor);
      }else{
        this.createVendor(vendor);
      }
    },

    createVendor(vendor){
      VendorService.createVendor(vendor).then(res => {
        if(res.status){
          this.vendors.push(res.data.vendor);
        }else{
          this.$store.dispatch("alerts/error", 'Có lỗi xảy ra khi tạo thông tin nhà cung cấp!');
        }
      });
    },

    onUpdateVendor(vendor){
      VendorService.updateVendor(vendor.vendor_id, vendor).then(res => {
        if(res.status){
          this.vendors.map((item) => {
            if(item.id === vendor.vendor_id){
              item.name = vendor.name;
              item.description = vendor.description;
              item.phone = vendor.phone;
            }
          });
        }else{
          this.$store.dispatch("alerts/error", 'Có lỗi xảy ra khi cập nhật thông tin nhà cung cấp!');
        }
      });
    },
    showFormUpdateVendor(vendor_id){
      VendorService.getDetailVendor(vendor_id).then(res => {
        if(res.status){
          this.$modal.show('form-vendor-modal', {form_update: res.data.vendor})
        }
      });
    }
  },
}
</script>

<style lang="scss">
.btn-filter {
  flex-direction: row;
  justify-content: center;
  padding: 0 10px;
  height: 36px;
  background: #4D77EC !important;
  border-radius: 4px;
  margin: 0px;
}
.action-vendor{
  width: 100px;
  .action-vendor-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button{
    border: unset;
    border-radius: 5px;
    .md-icon{
      font-size: 17px !important;
      color: #fff !important;
    }
  }
  .edit-vendor{
    background: #41C7B7;
  }
  .delete-vendor{
    background: red;
  }
}
</style>