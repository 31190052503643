import {isEmpty, isEqual, omit, pick} from 'lodash';

export default {
  data() {
    return {
      isLoading: false,
      apiValidationErrors: {}
    };
  },
  methods: {
    /* extract API server validation errors and assigns them to local mixin data */
    setApiValidation(serverErrors, refs = null) {
      this.apiValidationErrors = serverErrors;
    },

    resetApiValidation() {
      this.apiValidationErrors = {};
    }
  }
};
