import BaseService from "./BaseService";

export default class SettingService extends BaseService {

  static async getCompanyInfo(params = {}) {
    // const res = await this.get(`/settings/company-info`, params);
    const res = {
      "status": true,
      "message": "Thành công",
      "data": {
        "company" :{
          "headquarters": {
            "logo" : "/img/toolbar/logo_toolbar.svg",
            "name" : "AZA GROUP",
            "address" : "18 Tô Vĩnh Diện",
            "slogan" : "Liên tục tiến lên <p>Không ngừng vươn xa - Vô địch</p>",
            "phone" : "String",
            "founding_date" : "Date"
          },
          "branch" : [
            {
              "id": 2,
              "logo" : "/img/group/logo_titans.jpg",
              "name" : "CHI NHÁNH TITANS",
              "address" : "27  Tô Vĩnh Diện, Thanh Xuân, Hà Nội",
              "slogan" : "Khó khăn chỉ là nhỏ bé",
              "phone" : "0987638467",
              "founding_date" : "09/04/2021"
            },
            {
              "id": 4,
              "logo" : "/img/group/logo_fati.jpg",
              "name" : "CHI NHÁNH FATI ENTERPRISE",
              "address" : "27 Tô Vĩnh Diện, Thanh Xuân, Hà Nội",
              "slogan" : "TRAO GIÁ TRỊ - KẾT SỨC MẠNH - NỐI THÀNH CÔNG",
              "phone" : "0869223336",
              "founding_date" : "02/03/2021"
            },
            {
              "id": 14,
              "logo" : "/img/group/logo_aza.jpg",
              "name" : "CHI NHÁNH AZA",
              "address" : "27 Tô Vĩnh Diện, Thanh Xuân, Hà Nội",
              "slogan" : "Liên tục tiến lên - Không ngừng vươn xa - Vô địch",
              "phone" : "0869223336",
              "founding_date" : "2017-08-21"
            },
            {
              "id": 6,
              "logo" : "/img/group/logo_phoenix.jpg",
              "name" : "CHI NHÁNH PHOENIX",
              "address" : "35 Tô Vĩnh Diện, Thanh Xuân, Hà Nội",
              "slogan" : "Đột phá để thành công.",
              "phone" : "0358770643",
              "founding_date" : "01/05/2022"
            },
            {
              "id": 5,
              "logo" : "/img/group/logo_ironman.jpg",
              "name" : "CHI NHÁNH IRONMAN",
              "address" : "Tầng 4, tòa nhà An Cường",
              "slogan" : "Thách Thức Mọi Giới Hạn",
              "phone" : "0966652844",
              "founding_date" : "01/03/2022"
            }
          ]
        },
        "core_values" : [
          {
            "value" : "TRUNG THỰC - CHÍNH TRỰC",
            "description" : "<ul><li>Trong tất cả c&aacute;c c&ocirc;ng việc m&igrave;nh l&agrave;m: tất cả c&aacute;c số liệu, con số b&aacute;o c&aacute;o phải đ&uacute;ng, đủ, ch&iacute;nh x&aacute;c. Tuyệt đối kh&ocirc;ng được c&oacute; h&agrave;nh vi gian lận, gian dối, lừa lọc. Chỉ c&oacute; trung thực th&igrave; mới hợp t&aacute;c được l&acirc;u d&agrave;i, mới c&oacute; sự uy t&iacute;n,tin nhiệm, tin tưởng từ đồng nghiệp, l&atilde;nh đạo, cấp tr&ecirc;n để đảm nhận c&aacute;c trọng tr&aacute;ch lớn.</li><li>Ch&iacute;nh trực: L&agrave; con người ngay thẳng, kh&ocirc;ng l&agrave;m những việc khuất tất, sai tr&aacute;i c&oacute; lỗi với đồng nghiệp, với c&ocirc;ng ty. Lu&ocirc;n l&agrave;m c&aacute;c việc đ&uacute;ng, c&oacute; lợi cho tổ chức, c&ocirc;ng ty.</li></ul>"
          },
          {
            "value" : "CHỦ ĐỘNG",
            "description" : "<ul style=\"list-style-type: disc;\"><li>Chủ động l&agrave;m, chủ động học hỏi, t&igrave;m giải ph&aacute;p, xử l&yacute; vấn đề, chủ động l&agrave;m tất cả mọi việc để ho&agrave;n th&agrave;nh mục ti&ecirc;u của m&igrave;nh đ&atilde; đề ra.</li><li>C&oacute; 3 cấp độ của chủ động:<br />-&nbsp;Cấp độ 1: Nhắc mới l&agrave;m: Người kh&aacute;c bảo, chỉ mới l&agrave;m. Cấp độ n&agrave;y hay c&ograve;n gọi l&agrave; bị động, thụ động.<br />-&nbsp;Cấp độ 2: Kh&ocirc;ng cần nhắc, kh&ocirc;ng cần b&aacute;o cũng chủ động l&agrave;m để ho&agrave;n th&agrave;nh mục ti&ecirc;u của m&igrave;nh. Chủ động để tốt cho bản th&acirc;n m&igrave;nh, để nhanh ch&oacute;ng xử l&yacute; vấn đề v&agrave; ho&agrave;n th&agrave;nh được mục ti&ecirc;u của m&igrave;nh.<br />=&gt;&nbsp;L&agrave;m cho con người chậm chạp, kết quả thấp kh&ocirc;ng ph&ugrave; hợp với m&ocirc;i trường l&agrave;m việc của C&ocirc;ng ty.<br />- Cấp độ 3:Chủ động của chủ động<br /><br />Chủ động đề xuất c&aacute;c giải ph&aacute;p, chủ động nhắc nhở, chỉ bảo mọi người, team để ho&agrave;n th&agrave;nh tốt mục ti&ecirc;u của m&igrave;nh.<br /><br /></li></ul>"
          },
          {
            "value" : "CẦU TIẾN",
            "description" : "<ul style=\"list-style-type: disc;\"><li>Đề ra cho m&igrave;nh c&aacute;c mục ti&ecirc;u r&otilde; r&agrave;ng, khi ho&agrave;n th&agrave;nh được mục ti&ecirc;u. Tiếp tục tiến l&ecirc;n đề ra cho m&igrave;nh những mục ti&ecirc;u cao hơn, để chinh phục ch&iacute;nh bản th&acirc;n m&igrave;nh, để kh&aacute;m ph&aacute; khả năng tiềm ẩn- ph&aacute; bỏ giới hạn của m&igrave;nh bản th&acirc;n.</li><li>Li&ecirc;n tục học hỏi, ho&agrave;n thiện l&agrave;m cho bản th&acirc;n m&igrave;nh tốt hơn, ph&aacute;t triển mỗi ng&agrave;y. Học hỏi những việc hay, những c&aacute;c thức l&agrave;m mới, kiến thức mới đi &aacute;p dụng v&agrave;o trong c&ocirc;ng việc.</li></ul>"
          },{
            "value" : "TRÁCH NHIỆM",
            "description" : "<ul style=\"list-style-type: disc;\">\n" +
                "<li>C&ocirc;ng việc được giao, được phụ tr&aacute;ch phải chịu 100% tr&aacute;ch nhiệm với kết quả đạt được, xay ra. Để t&igrave;m nguy&ecirc;n nh&acirc;n- giải ph&aacute;p v&agrave; ng&agrave;y c&agrave;ng ph&aacute;t triển hơn.</li>\n" +
                "<li>Kh&ocirc;ng đổi lỗi- Kh&ocirc;ng than phiền- Kh&ocirc;ng bao biện- Kh&ocirc;ng l&yacute; do.</li>\n" +
                "</ul>\n"
          },
          {
            "value" : "CHIA SẺ - BIẾT ƠN",
            "description" : "<ul style=\"list-style-type: disc;\">\n" +
                "<li><em>Chia Sẻ<br /></em>- Hỗ trợ, gi&uacute;p đỡ đồng đội m&igrave;nh tiến l&ecirc;n để tốt hơn,ph&aacute;t triển hơn.<br />- Chia sẻ cho đi những g&igrave; m&igrave;nh biết, những c&aacute;i g&igrave; hay m&igrave;nh l&agrave;m được để gi&uacute;p cho đồng đội, tập thể c&agrave;ng ph&aacute;t triển.<br />- Nhờ văn h&oacute;a chia sẻ tập thể sẽ ng&agrave;y c&agrave;ng lớn mạnh v&agrave; m&igrave;nh c&agrave;ng nhận được nhiều từ sự chia sẻ của người kh&aacute;c.<br />- Ai cũng mạnh một điểm ri&ecirc;ng, chia sẻ để được học hỏi nhiều hơn, cho đi nhiều hơn, hạnh ph&uacute;c hơn.</li>\n" +
                "<li><em>Biết Ơn<br /></em>- Tr&acirc;n trọng, biết ơn với những g&igrave; m&igrave;nh đang c&oacute;, với ai đ&oacute; đ&atilde; gi&uacute;p đỡ m&igrave;nh tiến bộ, ph&aacute;t triển hơn, đạt được mục ti&ecirc;u. Biết ơn l&agrave;m m&igrave;nh cảm thấy hạnh ph&uacute;c hơn, y&ecirc;u đời hơn. Biết ơn l&agrave;m x&oacute;a bỏ sự th&ugrave; hận, đố kỵ, x&iacute;ch m&iacute;ch. L&agrave;m cho tập thể y&ecirc;u thương, đo&agrave;n kết hơn.<br />- H&atilde;y gửi lời cảm ơn tr&acirc;n th&agrave;nh đến ai đ&oacute; đ&atilde; gi&uacute;p đỡ m&igrave;nh những l&uacute;c kh&oacute; khăn,gi&uacute;p m&igrave;nh đạt được mục ti&ecirc;u. Khi n&oacute;i ra lời biết ơn th&igrave; người nhận được lời biết ơn cũng sẽ v&ocirc; c&ugrave;ng hạnh ph&uacute;c v&igrave; m&igrave;nh tạo ra v&agrave; mang lại gi&aacute; trị, gi&uacute;p được ai đ&oacute;. Gi&uacute;p người ta cảm thấy c&ocirc;ng việc m&igrave;nh l&agrave;m c&oacute; &yacute; nghĩa v&agrave; l&agrave; động lực để người ta tiếp tục l&agrave;m c&aacute;c c&ocirc;ng việc kh&aacute;c.</li>\n" +
                "</ul>\n"
          },
          {
            "value" : "KỶ LUẬT - CAM KẾT",
            "description" : "<ul><p>Muốn c&oacute; một đội nh&oacute;m v&ocirc; địch, x&acirc;y dựng một đội nh&oacute;m tuyệt vời, đi xa hơn c&ugrave;ng nhau. Cần Phải C&oacute; 2 Yếu Tố: <strong>Cam Kết V&agrave; Kỷ Luật</strong></p>\n" +
                "<p>Ch&uacute;ng ta phải biết kỷ luật v&agrave; tu&acirc;n thủ kỷ luật của c&ocirc;ng ty</p>\n" +
                "<p>_KỶ LUẬT l&agrave; những quy định, quy ước của tập thể, c&oacute; t&iacute;nh bắt buộc về h&agrave;nh vi cần phải tu&acirc;n theo nhằm đảm bảo lợi &iacute;ch chung của tập thể</p>\n" +
                "<p><strong>KỶ LUẬT L&Agrave; SỨC MẠNH</strong></p>\n" +
                "<p>Kỷ luật gi&uacute;p ch&uacute;ng ta thay đổi được lối sống bằng c&aacute;ch tự chủ bản th&acirc;n, vượt qua c&aacute;c c&aacute;m dỗ, vượt qua ch&iacute;nh m&igrave;nh</p>\n" +
                "<p>Nếu tất cả mọi người đều l&agrave;m được, tu&acirc;n thủ được giờ giấc l&agrave;m việc. Th&igrave; tại sao m&igrave;nh lại kh&ocirc;ng l&agrave;m được?</p>\n" +
                "<p><strong>II</strong></p>\n" +
                "<p>Để th&agrave;nh c&ocirc;ng ch&uacute;ng ta phải tự kỷ luật bản th&acirc;n m&igrave;nh nghĩa l&agrave; đặt ra những nguy&ecirc;n t&aacute;c ri&ecirc;ng để l&agrave;m những việc bạn n&ecirc;n l&agrave;m, để ho&agrave;n th&agrave;nh được mục ti&ecirc;u đ&atilde; đặt ra. Cho d&ugrave; bạn c&oacute; th&iacute;ch hay kh&ocirc;ng? Cho d&ugrave; kh&oacute; khăn, đau khổ, gian lao, vất vả</p>\n" +
                "<p>VD: Tự &eacute;p bản th&acirc;n m&igrave;nh phải dậy l&uacute;c 6h hằng ng&agrave;y để tập thể dục hay để mỗi tuần đọc 1 cuốn s&aacute;ch</p>\n" +
                "<p>Để c&oacute; kỷ luật đ&uacute;ng, Ch&uacute;ng ta cần phải c&oacute; th&aacute;i độ đ&uacute;ng ngay từ đầu. Kh&ocirc;ng sợ Kh&oacute;, kh&ocirc;ng sợ mệt, kh&ocirc;ng sợ đau, kh&ocirc;ng l&yacute; do, kh&ocirc;ng bao biện</p>\n" +
                "<p>T&iacute;nh kỷ luật ch&iacute;nh l&agrave; l&yacute; tr&iacute;, chứ kh&ocirc;ng phải cảm x&uacute;c nhất thời của bạn. V&igrave; t&iacute;nh kỷ luật gi&uacute;p bạn tiếp tục tiến l&ecirc;n, tiếp tục chiến đấu, thực hiện ho&agrave;n th&agrave;nh mục ti&ecirc;u của m&igrave;nh khi m&agrave; năng lượng đ&atilde; xuống, khi m&agrave; cơ thể đ&atilde; mệt, khi m&agrave; kh&oacute; khăn thử th&aacute;ch ng&agrave;y c&agrave;ng nhiều hơn</p>\n" +
                "<p>Tất cả c&aacute;c nh&agrave; v&ocirc; địch tr&ecirc;n thế giới đều c&oacute; kỷ luật</p>\n" +
                "<p>Kỷ luật để l&agrave;m việc chăm chỉ hơn. Bạn biết kh&ocirc;ng? Kh&ocirc;ng phải l&uacute;c n&agrave;o cũng c&oacute; người gi&aacute;m s&aacute;t b&ecirc;n cạnh bạn, kh&ocirc;ng phải l&uacute;c n&agrave;o cũng c&oacute; người đ&uacute;c th&uacute;c, th&uacute;c giục bạn l&agrave;m. Khi kh&ocirc;ng c&oacute; ai b&ecirc;n cạnh, chỉ mỗi m&igrave;nh bản hay tự kỷ luật ch&iacute;nh bản th&acirc;n m&igrave;nh để ho&agrave;n th&agrave;nh mục ti&ecirc;u. N&oacute; gi&uacute;p ch&uacute;ng ta chiến đấu khi ch&uacute;ng ta bị tổn thưởng, khi bị đau đơn, kh&oacute; khăn, trắc trở. Để bạn tiếp tục tiến l&ecirc;n</p>\n" +
                "<p>Bởi v&igrave; Cuộc Sống Kh&ocirc;ng Cho Bạn Những G&igrave; M&agrave; Bạn Muốn</p>\n" +
                "<p>Cuộc Sống Trao Cho Bạn Những Điều M&agrave; Bạn Xứng Đ&aacute;ng. V&agrave; nếu bạn kh&ocirc;ng chiến đấu v&igrave; n&oacute;, kh&ocirc;ng chịu hy sinh cho n&oacute;, nếu bạn kh&ocirc;ng nỗ lực cho m&igrave;nh, th&igrave; kh&ocirc;ng kh&ocirc;ng xứng đ&aacute;ng với n&oacute;, bạn kh&ocirc;ng bao giờ c&oacute; được n&oacute;. H&atilde;y vượt qua khỏi nổi đau, nỗi sợ hại. Ph&iacute;a b&ecirc;n kia nỗi đau l&agrave; sự trưởng th&agrave;nh l&agrave; sự th&agrave;nh c&ocirc;ng đang chờ đợi bạn .</p>\n" +
                "<p>Nỗi Đau, Kh&oacute; Khăn xuất hiện đ&oacute; l&agrave; thời điểm thể hiện tinh thần chiến binh của m&igrave;nh. H&atilde;y Cho t&ocirc;i thấy tinh thần chiến binh của bạn. <strong>T&Ocirc;N TRỌNG KỶ LUẬT. Mạnh mẽ l&ecirc;n</strong><strong>!</strong></p>\n" +
                "<p>Kh&ocirc;ng điều g&igrave; c&oacute; thể cản trở bạn nếu bạn kh&ocirc;ng bao giờ dừng lại. Kh&ocirc;ng bao giờ được dừng lại v&igrave; điều g&igrave; , kh&ocirc;ng bao giờ được ph&aacute; vỡ kỷ luật.</p>\n" +
                "<p><strong>Bởi v&igrave; Cuộc Sống Kh&ocirc;ng Cho Bạn Những G&igrave; M&agrave; Bạn Muốn</strong></p>\n" +
                "<p><strong>Cuộc Sống Trao Cho Bạn Những Điều M&agrave; Bạn Xứng Đ&aacute;ng</strong></p>\n" +
                "<p>Cho t&ocirc;i thấy tinh thần chiến binh của c&aacute;c bạn, cho t&ocirc;i thấy kỷ luật của c&aacute;c bạn</p>\n" +
                "<p><strong>*** Đừng Hỏi Tại Sạo Lại Tổn Thương</strong></p>\n" +
                "<p><strong>*** Đừng Hỏi Tại Sao Lại L&agrave; T&ocirc;i?</strong></p>\n" +
                "<p><strong>M&agrave; H&atilde;y N&oacute;i: </strong></p>\n" +
                "<p><strong>&ldquo; Chọn T&ocirc;i Đi &ldquo;</strong></p>\n" +
                "<p><strong>&ldquo; Chỉ C&oacute; Vậy Th&ocirc;i Sao &ldquo;</strong></p>\n" +
                "<p><strong>Thử Th&aacute;ch Cho T&ocirc;i Th&ecirc;m Đi</strong> &ldquo;</p>\n" +
                "<p>H&atilde;y ki&ecirc;n định khi m&agrave; dường như kh&ocirc;ng c&oacute; cơ hội gi&agrave;nh chiến thắng, khi bạ kh&ocirc;ng thấy kết quả. H&atilde;y ki&ecirc;n tr&igrave;, h&atilde;y mạnh mẽ l&ecirc;n, Dũng Cảm L&ecirc;n, T&ocirc;n trọng kỷ luật v&agrave; Th&agrave;nh C&ocirc;ng Sẽ Đến</p>\n" +
                "<p>Đại B&agrave;ng kh&ocirc;ng chơi với loại Ngỗng, Đại b&agrave;ng kh&ocirc;ng chơi với lo&agrave;i vịt.&nbsp; Ch&uacute;ng l&agrave; 2 đẳng cấp kh&aacute;c nhau. Đội nh&oacute;m v&ocirc; địch kh&ocirc;ng bao gồm những con người v&ocirc; kỷ luật, thiếu tr&aacute;ch nhiệm, th&aacute;i độ ti&ecirc;u cực . Nếu c&aacute;c. Bạn kh&ocirc;ng thay đổi, c&aacute;c bạn sẽ bị đ&agrave;o thải. Nếu ng&agrave;y h&ocirc;m nay trở đi c&ograve;n ai vi phạm kỷ luật sẽ bị thi h&agrave;nh kỷ luật. T&ocirc;i rất y&ecirc;u thương c&aacute;c bạn nhưng ch&uacute;ng ta đ&agrave;nh phải chia tay&nbsp;</p>\n" +
                "<p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KỶ LUẬT L&Agrave; SỨC MẠNH </strong></p></ul>"
          },
          {
            "value" : "NIỀM TIN",
            "description" : "<p><em>Nếu bạn tin bạn l&agrave;m được, bạn sẽ l&agrave;m được</em></p>\n" +
                "<p><em>Nếu bạn kh&ocirc;ng tin bạn l&agrave;m được, bạn sẽ kh&ocirc;ng l&agrave;m được.</em></p>\n" +
                "<ul>\n" +
                "<li>Niềm tin l&agrave; sức mạnh v&ocirc; h&igrave;nh cực lớn, gi&uacute;p cho bạn kh&aacute;m ph&aacute; được khả năng tiền ẩn. Ph&aacute; bỏ giới hạn bản th&acirc;n; đạt được những mục ti&ecirc;u, những th&agrave;nh quả m&agrave; chưa từng nghĩ tới.</li>\n" +
                "<li><strong><em>Niềm Tin &ndash; Suy Nghĩ &ndash; H&agrave;nh Động &ndash; Kết Quả</em></strong></li>\n" +
                "<li>Niềm tin gi&uacute;p bạn ki&ecirc;n tr&igrave;, c&oacute; động lực để vượt qua c&aacute;c kh&oacute; khăn, c&aacute;c thử th&aacute;ch muốn l&agrave;m bạn bỏ cuộc.Để tiến tới v&agrave; đạt được mục ti&ecirc;u.</li>\n" +
                "</ul>"
          },
          {
            "value" : "LIÊN TỤC TIẾN LÊN",
            "description" : "<ul>\n" +
                "<li>Li&ecirc;n tục tiến l&ecirc;n h&agrave;ng ng&agrave;y: Hằng ng&agrave;y h&atilde;y học hỏi một kiến thức mới, kỹ năng mới; để cho bản th&acirc;n m&igrave;nh ng&agrave;y c&agrave;ng ph&aacute;t triển. Mỗi ng&agrave;y chỉ cần tiến l&ecirc;n hơn ng&agrave;y h&ocirc;m qua 1%. Th&igrave; sau 1 năm bạn đ&atilde; ph&aacute;t triển gấp 36 lần.</li>\n" +
                "<li>Li&ecirc;n tục đạt v&agrave; chinh phục mục ti&ecirc;u cao hơn, c&aacute;c th&aacute;ch thức v&agrave; giới hạn kh&oacute; hơn.</li>\n" +
                "</ul>"
          }
        ]
      }
    };
    return res.data;
  }

  static async getPositions(params = {}) {
    // const res = await this.get(`/company/positions/`, params);
    // return res.data;
  }

  static async updatePosition(positionId, params = {}) {
    const res = await this.post(`/company/positions/` + positionId, params);
    return res.data;
  }

  static async deletePosition(positionId) {
    const res = await this.delete(`/company/positions/` + positionId);
    return res.data;
  }

  static async getDepartmentTree(params = {}) {
    // const res = await this.get(`/company/departments/tree`, params);
    // return res.data;
  }

  static async deleteDepartment(departmentId) {
    const res = await this.delete(`/company/departments/` + departmentId);
    return res.data;
  }

  static async updateDepartment(departmentId, params = {}) {
    const res = await this.post(`/company/departments/` + departmentId, params);
    return res.data;
  }

  static async getPostBackgroundList(params = {}) {
    const res = await this.get(`/settings/post-background-list`);
    return res.data;
  }
}

