<template>
  <span class="cursor-pointer" v-if="usersTagged.length > 0">
    <span class="text-400-default"> cùng với </span>
    <span class="cursor-pointer text-400-semibold hover-link userPopoverName" @click="$router.push(getProfileLink(usersTagged[0].id))"
          v-on:mouseleave="onMouseleave($event)" v-on:mouseover="onMouseover($event, usersTagged[0])">{{usersTagged[0].fullname}}</span>
    <span v-if="usersTagged[1]" >
      <span class="text-400-default">, </span>
      <span class="cursor-pointer text-400-semibold hover-link userPopoverName" @click="$router.push(getProfileLink(usersTagged[1].id))"
            v-on:mouseleave="onMouseleave($event)" v-on:mouseover="onMouseover($event, usersTagged[1])">{{usersTagged[1].fullname}}</span>
    </span>
    <span v-if="usersTagged.length > 2">
      <span class="text-400-default"> và </span>
      <span class="cursor-pointer text-400-semibold" v-on:mousemove="showOtherUserTagged($event)" v-on:mouseleave="hideOtherUserTagged">{{usersTagged.length - 2}} người khác</span>
    </span>
  </span>
</template>

<script>
import $ from "jquery";
export default {
  name: "TagUserView",
  props: {
    usersTagged: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onMouseover(e, userInfo) {
      let offsetLeft = e.target.offsetLeft - 20;
      let offsetTop = e.target.offsetTop;
      let userPopupName = e.target.classList.contains('userPopoverName');
      this.$emit("mouseover-user-tagged", {
        userPopupName: userPopupName,
        offsetLeft: offsetLeft,
        offsetTop: offsetTop,
        userTagged: userInfo
      })
    },
    onMouseleave(e) {
      let userPopupName = e.relatedTarget.classList.contains('user-info-wrapper');
      this.$emit("mouseleave-user-tagged", {
        userPopupName: userPopupName,
      })
    },
    showOtherUserTagged(e) {
      let leftCss = e.target.offsetLeft;
      let topCss = 35 + e.target.offsetTop;
      $('.tag-users__popover').css("top", topCss + 'px');
      $('.tag-users__popover').css("left", leftCss + 'px');
      $('.tag-users__popover').show();
    },
    hideOtherUserTagged() {
      $('.tag-users__popover').hide();
    },
    getProfileLink(id) {
      return '/profile/' + id;
    },
  }
}
</script>

<style scoped>

</style>