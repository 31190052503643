import InventoryService from "@/store/services/InventoryService";
import moment from "moment";
import {
    INVENTORY_COUPON_HEADER_TABLE,
    INVENTORY_EXPORT_TABLE_HEADER,
    INVENTORY_IMPORT_TABLE_HEADER,
    INVENTORY_REPORT_EXPORT_TAB,
    INVENTORY_TYPE_OPTIONS
} from "@/const/const";
import {INVENTORY_COUPON_TYPE} from "../const/const";
import {helpers} from "../helper/helpers";
import * as Constants from "constants";

export default {
    data() {
        return {
            INVENTORY_TYPE_OPTIONS,
            INVENTORY_REPORT_EXPORT_TAB,
            INVENTORY_IMPORT_TABLE_HEADER,
            INVENTORY_EXPORT_TABLE_HEADER,
            INVENTORY_COUPON_TYPE,
            isLoad: false,
            inventoryId: this.$route.params.inventory_id,
            tabActive: INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA,
            totalStockParams: {
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
                product_id: [],
                inventory_id: this.$route.params.inventory_id,
                type: INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA,
                keywork: ''
            },
            inventoryCouponData: [],
            totalStockData: [],
            pagingParams: {
                page: 1,
                perPage: 50,
                lastPage: 1,
                total: 0,
            },

            checkboxOption: {
                // row select change event
                selectedRowChange: ({row, isSelected, selectedRowKeys}) => {
                    this.couponSelectedRowKeys = selectedRowKeys.filter(item => item !== undefined);
                },
                // selected all change event
                selectedAllChange: ({isSelected, selectedRowKeys}) => {
                    this.couponSelectedRowKeys = selectedRowKeys.filter(item => item !== undefined);
                    console.log(this.couponSelectedRowKeys);
                },
            },

            cellStyleOption: {
                bodyCellClass: ({row, column, rowIndex}) => {
                    if (rowIndex === 0) {
                        return "background_blue_100 text-400-medium";
                    }
                    if (column.field === "product_cost_price") {
                        return "border-right-important";
                    } else if (column.field === "action") {
                        return "action-column";
                    }
                },
            },

            cellSpanOption: {
                bodyCellSpan: ({row, column, rowIndex}) => {
                    if (row['rowspan'] > 1) {
                        if (column.field !== "product_sku"
                            && column.field !== "product_name"
                            && column.field !== "product_quantity"
                            && column.field !== "product_price"
                            && column.field !== "product_rate"
                            && column.field !== "product_shipping_fee"
                            && column.field !== "product_total_price"
                            && column.field !== "product_cost_price") {
                            return {
                                rowspan: row['rowspan'],
                                colspan: 1,
                            };
                        }
                    } else if (row['rowspan'] === 0) {
                        if (column.field !== "product_sku"
                            && column.field !== "product_name"
                            && column.field !== "product_quantity"
                            && column.field !== "product_price"
                            && column.field !== "product_rate"
                            && column.field !== "product_shipping_fee"
                            && column.field !== "product_total_price"
                            && column.field !== "product_cost_price") {
                            return {
                                rowspan: 0,
                                colspan: 0,
                            };
                        }
                    }

                    if (rowIndex === 0) {
                        if (column.field === "stt") {
                            return {
                                colspan: 8,
                                rowspan: 1,
                            };
                        } else if (column.type === "checkbox" || column.field === "detail_btn"
                            || column.field === "code" || column.field === "time"
                            || column.field === "manager" || column.field === "product_sku" || column.field === "product_name") {
                            return {
                                colspan: 0,
                                rowspan: 0,
                            };
                        }
                    }
                }
            },

            couponSelectedRowKeys: [],
            couponOverview: {},
        };
    },
    computed: {
        tableHeader() {
            let headerList = {...INVENTORY_COUPON_HEADER_TABLE};
            for (const key in headerList) {
                if (headerList.hasOwnProperty(key)) {
                    let subHeaderList = headerList[key];
                    for (let i = 0; i < subHeaderList.length; i++) {
                        if (subHeaderList[i].field && subHeaderList[i].field === 'detail_btn') {
                            subHeaderList[i].renderBodyCell = ({row, column, rowIndex}, h) => {
                                return (
                                    <span class="material-symbols-outlined show-detail"
                                          on-click={() => this.getProductDetail(row)}>info</span>
                                );
                            };
                        }

                        if (this.$store.state.auth.user.manage_inventory) {
                            if(subHeaderList[i].field && subHeaderList[i].field === 'action'){
                                subHeaderList[i].renderBodyCell = ({row, column, rowIndex}, h) => {
                                    if (rowIndex !== 0) {
                                        return (
                                            <div class="d-flex">
                                                <button title="Chỉnh sửa" class="p-2px cursor-pointer tooltip ml-5px"
                                                        on-click={() => this.updateOrderCoupon(row)}>
                                                    <md-icon>edit</md-icon>
                                                </button>
                                            </div>
                                        );
                                    }
                                }
                            }

                            if(subHeaderList[i].field && subHeaderList[i].field === 'status_order_item'){
                                subHeaderList[i].renderBodyCell = ({row, column, rowIndex}, h) => {
                                    if (rowIndex !== 0) {
                                        return (
                                            <div class="status-order-item">
                                                <md-switch title="Thay đổi trạng thái" onChange={ () => this.updateStatusOrderItem(row, rowIndex)}
                                                           v-model={row.is_active}></md-switch>
                                            </div>
                                        );
                                    }
                                }
                            }

                        }
                    }
                }
            }
            return headerList;
        },
        saleExportTableHeader() {
            let column = [...INVENTORY_COUPON_HEADER_TABLE.NHAP_HOAN_XUAT_BAN];
            column[0].renderBodyCell = ({row, column, rowIndex}, h) => {
                let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
                return ++rowIndex + index;
            }
            return column;
        }
    },
    props: {},
    watch: {
        '$route.params.inventory_id': function (newVal) {
            this.inventoryId = newVal;
            this.totalStockParams.inventory_id = newVal;
            let today = moment().format('YYYY-MM-DD')
            let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
            this.totalStockParams.start_date = firstDateOfMonth;
            this.totalStockParams.end_date = today;
            this.getInventoryCoupon();
            this.getReportWarehouse();
        },
        '$store.state.category.reloadOnListProduct': function (newVal) {
            if(newVal === 1){
                this.getInventoryCoupon();
                // reset
                this.$store.commit("updateReloadOnListProduct", 0);
            }
        }
    },
    created() {
        // this.getInventoryCoupon();
        let today = moment().format('YYYY-MM-DD')
        let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        this.totalStockParams.start_date = firstDateOfMonth;
        this.totalStockParams.end_date = today;
    },
    methods: {
        updateOrderCoupon(row) {
            InventoryService.getDetailProduct({coupon_id: row.id}).then(res => {
                if (res.data) {
                    const data = res.data;
                    if (data.type == INVENTORY_COUPON_TYPE.NHAP_MUA) {
                        this.$modal.show('update-purchase-order-modal', {data: data});
                    } else {
                        this.$modal.show('update-coupon-modal', {data: data});
                    }
                } else {
                    this.$store.dispatch("alerts/error", res.message);
                }
            });
        },
        async getInventoryCoupon() {
            if (this.tabActive === INVENTORY_COUPON_TYPE.NHAP_HOAN || this.tabActive === INVENTORY_COUPON_TYPE.XUAT_BAN) {
                return;
            }
            this.inventoryCouponData = [];
            this.couponSelectedRowKeys = [];
            this.isLoad = true;
            let paging = {
                page: this.pagingParams.page,
                per_page: this.pagingParams.perPage
            };
            let couponParams = {...this.totalStockParams, ...paging};
            if (this.$route.params.tab === 'transfer') {
                couponParams.type = 10;
                if (this.tabActive === INVENTORY_REPORT_EXPORT_TAB.NHAP_DIEU_CHUYEN) {
                    couponParams.coupon_name = 'cnk';
                } else if (this.tabActive === INVENTORY_REPORT_EXPORT_TAB.XUAT_DIEU_CHUYEN) {
                    couponParams.coupon_name = 'cxk';
                }
            }
            await this.getCouponOverview(couponParams);

            await InventoryService.getCoupons(couponParams).then(res => {
                if (res) {
                    this.pagingParams.lastPage = res.meta.last_page
                    this.pagingParams.total = res.meta.total;
                    for (let i = 0; i < res.data.length; i++) {
                        let inventoryCouponRow = {};
                        inventoryCouponRow.id = res.data[i].id;
                        let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
                        inventoryCouponRow.stt = i + 1 + index;
                        inventoryCouponRow.is_active = res.data[i].status === 1; // Đã nhập hàng
                        inventoryCouponRow.sync_history_id = res.data[i].sync_history_id;
                        inventoryCouponRow.code = res.data[i].code;
                        inventoryCouponRow.inventory_main_name = res.data[i].inventory_main ? res.data[i].inventory_main.name : '';
                        inventoryCouponRow.inventory_sub_name = res.data[i].inventory_sub ? res.data[i].inventory_sub.name : '';
                        inventoryCouponRow.time = moment(res.data[i].created_at).format('DD-MM-YYYY');
                        inventoryCouponRow.vendor = res.data[i].vendor_id ? res.data[i].vendor_id : '';
                        inventoryCouponRow.products = res.data[i].products;
                        inventoryCouponRow.receipt_status = res.data[i].receipt_status;
                        inventoryCouponRow.status = res.data[i].status;
                        inventoryCouponRow.created_by = res.data[i].created_by && res.data[i].created_by.fullname ? res.data[i].created_by.fullname : '';
                        inventoryCouponRow.rowKey = res.data[i].id;
                        inventoryCouponRow.receipt_internal_code = res.data[i].receipt_internal_code;
                        inventoryCouponRow.manager = res.data[i].manager ? res.data[i].manager.fullname : '';
                        inventoryCouponRow.tracking = res.data[i].tracking;
                        inventoryCouponRow.description = res.data[i].description;
                        inventoryCouponRow.platform = res.data[i].platform_type;
                        inventoryCouponRow.rowspan = 1;
                        let productReceipt1 = res.data[i].products[0];
                        inventoryCouponRow.product_sku = productReceipt1 ? productReceipt1.product.sku : '';
                        inventoryCouponRow.product_name = productReceipt1 ? productReceipt1.product.goods.name : '';
                        inventoryCouponRow.product_quantity = productReceipt1 ? parseInt(productReceipt1.quantity).toLocaleString() : '0';
                        inventoryCouponRow.product_price = productReceipt1 ? parseInt(productReceipt1.price).toLocaleString() : '0';
                        inventoryCouponRow.product_shipping_fee = productReceipt1 ? (productReceipt1.shipping_fee ? parseInt(productReceipt1.shipping_fee).toLocaleString() : '0') : '0';
                        inventoryCouponRow.product_rate = productReceipt1 ? productReceipt1.rate : '0';
                        inventoryCouponRow.product_total_price = productReceipt1 ? parseInt(productReceipt1.total_price).toLocaleString() : '0';
                        inventoryCouponRow.product_cost_price = productReceipt1 ? parseInt(productReceipt1.total_price / productReceipt1.quantity).toLocaleString() : '0';

                        this.inventoryCouponData.push(inventoryCouponRow);

                        if (res.data[i].products.length > 1) {
                            inventoryCouponRow.rowspan = res.data[i].products.length;
                            for (let j = 1; j < res.data[i].products.length; j++) {
                                let productReceipt = res.data[i].products[j];
                                let subInventoryColumn = this.buildSubInventoryColumn(productReceipt.product.sku, productReceipt.product.goods.name,
                                    parseInt(productReceipt.quantity).toLocaleString(), parseInt(productReceipt.price).toLocaleString(), productReceipt.shipping_fee ? parseInt(productReceipt.shipping_fee).toLocaleString() : '0',
                                    productReceipt.rate, parseInt(productReceipt.total_price).toLocaleString(), parseInt(productReceipt.total_price / productReceipt.quantity).toLocaleString());
                                this.inventoryCouponData.push(subInventoryColumn);
                                if (j === 1 && res.data[i].products.length > 2) {
                                    inventoryCouponRow.rowspan = 3;
                                    this.inventoryCouponData.push(this.buildSubInventoryColumn('...', '...', '...', '...', '...', '...', '...', '...'));
                                    break;
                                }
                            }
                        }
                    }
                }
                this.isLoad = false;
                this.$modal.hide('dialog');
            });
        },

        async getCouponOverview(couponParams) {
            let params = {...couponParams};
            params.coupon_type = couponParams.type;
            params.type = 1; // Phiếu đặt hàng, 2 là đơn đặt hàng
            InventoryService.getCouponOverview(params).then(res => {
                if (res) {
                    this.couponOverview = res;
                    let firstRow = {};
                    firstRow.stt = "Tổng";
                    firstRow.product_quantity = this.couponOverview ? parseInt(this.couponOverview.quantity).toLocaleString() : '0';
                    firstRow.product_price = this.couponOverview ? parseInt(this.couponOverview.price).toLocaleString() : '0';
                    firstRow.product_shipping_fee = this.couponOverview ? (this.couponOverview.shipping_fee ? parseInt(this.couponOverview.shipping_fee).toLocaleString() : '0') : '0';
                    firstRow.product_rate = this.couponOverview ? this.couponOverview.rate : '0';
                    firstRow.product_total_price = this.couponOverview ? parseInt(this.couponOverview.total_price).toLocaleString() : '0';
                    firstRow.product_cost_price = this.couponOverview ? parseInt(this.couponOverview.total_price / this.couponOverview.quantity).toLocaleString() : '0';
                    this.inventoryCouponData.unshift(firstRow);
                }
            });
        },

        buildSubInventoryColumn(sku, product_name, quantity, price, shipping_fee, rate, total_price, cost_price) {
            let subInventoryColumn = {};
            subInventoryColumn.rowspan = 0;
            subInventoryColumn.product_sku = sku;
            subInventoryColumn.product_name = product_name;
            subInventoryColumn.product_quantity = quantity;
            subInventoryColumn.product_price = price;
            subInventoryColumn.product_shipping_fee = shipping_fee;
            subInventoryColumn.product_rate = rate;
            subInventoryColumn.product_total_price = total_price;
            subInventoryColumn.product_cost_price = cost_price;

            return subInventoryColumn;
        },

        getReportWarehouse() {
            if (this.tabActive !== INVENTORY_COUPON_TYPE.NHAP_HOAN && this.tabActive !== INVENTORY_COUPON_TYPE.XUAT_BAN) {
                return;
            }
            this.isLoad = true;
            let paging = {
                page: this.pagingParams.page,
                per_page: this.pagingParams.perPage
            };
            let couponParams = {...this.totalStockParams, ...paging};
            if (this.tabActive === INVENTORY_COUPON_TYPE.NHAP_HOAN) {
                couponParams.type = 1;
            } else if (this.tabActive === INVENTORY_COUPON_TYPE.XUAT_BAN) {
                couponParams.type = 2;
            }
            InventoryService.getReportWarehouse(couponParams).then(res => {
                if (res) {
                    if (res.meta) {
                        this.pagingParams.lastPage = res.meta.last_page
                        this.pagingParams.total = res.meta.total;
                    } else {
                        this.pagingParams.lastPage = 1
                        this.pagingParams.total = res.data.length;
                        this.pagingParams.perPage = res.data.length;
                    }
                    this.inventoryCouponData = res.data;
                }
                this.isLoad = false;
            });
        },
        handleFilterChange(filters) {
            this.refreshPagingParams();
            this.totalStockParams.end_date = filters.end_date;
            this.totalStockParams.start_date = filters.start_date;
            this.totalStockParams.keywork = filters.keywork;
            this.totalStockParams.product_id = filters.product_id;
            this.totalStockParams.good_id = filters.good_id;
            this.totalStockParams.list_type = filters.list_type;
            this.totalStockParams.status = filters.status;
            this.getInventoryCoupon();
            this.getReportWarehouse();
        },
        changeTabActive(selectedTab) {
            this.tabActive = selectedTab.tab.$attrs.tab_id;
            this.totalStockParams = {
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
                product_id: [],
                good_id: [],
                inventory_id: this.$route.params.inventory_id,
                type: this.tabActive
            };
            this.refreshPagingParams();
            this.getInventoryCoupon();
            this.getReportWarehouse();
        },

        dowloadExcel() {
            let baseUrl = process.env.VUE_APP_API_INVENTORY_BASE_URL + '/v1';
            let type = this.totalStockParams.type;
            if (this.totalStockParams.type == INVENTORY_REPORT_EXPORT_TAB.NHAP_DIEU_CHUYEN) {
                type = 10;
                this.totalStockParams.coupon_name = 'cnk';
            }
            if (this.totalStockParams.type == INVENTORY_REPORT_EXPORT_TAB.XUAT_DIEU_CHUYEN) {
                type = 10;
                this.totalStockParams.coupon_name = 'cxk';
            }

            let url = `${baseUrl}/inventory/coupon/${type}/export-excel?${helpers.convertObjToQueryUrl(
                this.totalStockParams
            )}`;

            window.open(url, '_blank');
        },

        changePagingParam(value) {
            this.pagingParams = value;
            this.getInventoryCoupon();
            this.getReportWarehouse();
            // this.isLoad = true;
            // this.getGroups();
        },
        deleteInventoryCoupon() {
            InventoryService.deleteMultiCoupon({
                inventory_id: this.totalStockParams.inventory_id,
                type: this.totalStockParams.type,
                coupon_ids: this.couponSelectedRowKeys
            }).then(res => {
                if (res.status) {
                    this.getInventoryCoupon();
                    this.$modal.hide('dialog');
                    this.$store.dispatch("alerts/success", 'Bạn đã xóa thành công');
                }
            });
        },
        deleteInventoryCouponByQuery() {
            this.$store.dispatch("alerts/info", 'Đang trong quá trình xoá.Bạn vui lòng chờ trong ít phút');

            if (this.totalStockParams.type == INVENTORY_REPORT_EXPORT_TAB.NHAP_DIEU_CHUYEN) {
                this.totalStockParams.coupon_name = 'cnk';
            }
            if (this.totalStockParams.type == INVENTORY_REPORT_EXPORT_TAB.XUAT_DIEU_CHUYEN) {
                this.totalStockParams.coupon_name = 'cxk';
            }

            InventoryService.deleteMultiCouponByQuery({
                inventory_id: this.totalStockParams.inventory_id,
                type: this.totalStockParams.type,
                start_date: this.totalStockParams.start_date,
                end_date: this.totalStockParams.end_date,
                coupon_name: this.totalStockParams.coupon_name,
            }).then(res => {
                if (res.status) {
                    this.getInventoryCoupon();
                    this.$modal.hide('dialog');
                    this.$store.dispatch("alerts/success", 'Bạn đã xóa thành công');
                }
            });
        },
        showConfirmDeleteCoupon() {
            if (this.couponSelectedRowKeys.length === 0) {
                this.$store.dispatch("alerts/error", 'Bạn hãy chọn phiếu cần xóa!');
                return;
            }
            this.$modal.show('dialog', {
                title: 'Xóa phiếu?',
                text: `Bạn có chắc chắn muốn xóa các phiếu đã chọn?`,
                buttons: [
                    {
                        title: 'Không',
                        handler: () => {
                            this.$modal.hide('dialog');
                        }
                    },
                    {
                        title: 'Xóa',
                        handler: () => {
                            this.deleteInventoryCoupon();
                        }
                    }
                ]
            })
        },
        showConfirmDeleteCouponByQuery() {
            const time = (this.pagingParams.total / 3000).toFixed(1)
            this.$modal.show('dialog', {
                title: 'Xóa phiếu? <vue-element-loading spinner="bar-fade-scale"/>',
                text: `Bạn có chắc chắn muốn xóa các phiếu theo filter đã chọn?. Thời gian dự tính ${time < 1 ? 1 : time} phút.`,
                buttons: [
                    {
                        title: 'Không',
                        handler: () => {
                            this.$modal.hide('dialog');
                        }
                    },
                    {
                        title: 'Xóa',
                        handler: () => {
                            this.deleteInventoryCouponByQuery();
                        }
                    }
                ]
            })
        },

        refreshPagingParams() {
            this.pagingParams = {
                page: 1,
                perPage: 50,
                lastPage: 1,
                total: 0,
            };
        },
        getProductDetail(row) {
            InventoryService.getDetailProduct({coupon_id: row.id}).then(res => {
                if (res.data) {
                    this.$modal.show('detail-product-modal', {data: res.data});
                } else {
                    this.$store.dispatch("alerts/error", res.message);
                }
            });
        },
        updateStatusOrderItem(row, rowIndex){
            // giữ trạng thái để confirm.
            const isActive = this.inventoryCouponData[rowIndex].is_active;
            this.inventoryCouponData[rowIndex].is_active = !this.inventoryCouponData[rowIndex].is_active;
            this.$modal.show('dialog', {
                title: 'Thay đổi trạng thái phiếu?',
                text: `Bạn có chắc chắn muốn thay đổi trạng thái phiếu đã chọn?`,
                buttons: [
                    {
                        title: 'Đồng ý',
                        handler: () => {
                            let params = {
                                status: isActive ? 1 : 2
                            };
                            InventoryService.updateStatusCoupon(params, row.id).then(res => {
                                if (res.data) {
                                    // Thay đổi trạng thái
                                    this.inventoryCouponData[rowIndex].is_active = !this.inventoryCouponData[rowIndex].is_active;
                                    this.getInventoryCoupon();
                                } else {
                                    this.$store.dispatch("alerts/error", res.message);
                                }
                                this.$modal.hide('dialog');
                            });
                        }
                    },
                    {
                        title: 'Hủy',
                        handler: () => {
                            this.$modal.hide('dialog');
                        }
                    }
                ]
            })
        }
    }
}
