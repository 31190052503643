import {GROUP_TYPE} from "../../const/const";
import GroupService from "../services/GroupService";

export default {
    state: {
        groups: {
            'pinnedGroups': [],
            'branchGroups': [],
            'workGroups': [],
            'companyWallGroups': [],
        },
        postGroup: [],
        groupData: {},
    },

    getters: {
        getGroups(state) {
            return state.groups;
        },
    },

    mutations: {
        updateGroups(state, payload) {
            let groups = payload;

            let workGroups = [];
            let branchGroups = [];
            let companyWallGroups = [];
            let pinnedGroups = [];

            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                if (group.is_pin) {
                    pinnedGroups.push(group);
                }
                switch (group.type) {
                    case GROUP_TYPE.WORK_GROUP:
                        workGroups.push(group);
                        break;
                    case GROUP_TYPE.COMPANY_WALL_GROUP:
                        companyWallGroups.push(group);
                        break;
                    case GROUP_TYPE.BRANCH_GROUP:
                        branchGroups.push(group);
                        break;
                }
            }

            state.groups.workGroups = workGroups;
            state.groups.branchGroups = branchGroups;
            state.groups.companyWallGroups = companyWallGroups;
            state.groups.pinnedGroups = pinnedGroups;
        },
        updatePostGroup(state, payload) {
            state.postGroup = payload;
        },
        updateGroupData(state, payload) {
            state.groupData = payload;
        }
    },

    actions: {
        getGroups({commit, dispatch}, payload) {
            return GroupService.getGroups({per_page: 100}).then(res => {
                if (res && res.data.length !== 0) {
                    commit("updateGroups", res.data);
                }
            });

        },
    }
};
