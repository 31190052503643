<template>
  <div class="inventory-content__container">
    <vue-element-loading :active="isLoad"/>
    <tabs class="mt-15px" @changed="changeTabActive">
      <tab name="Nhập mua" :tab_id="INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'nhap_mua'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách phiếu Nhập mua</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                   @click="dowloadExcel">
                <md-icon>file_download</md-icon>
                <span>
                Xuất Excel
              </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="getInventoryCoupon">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                   @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div class="w_100 mb-10px">
            <div v-if="canEdit">
              <md-button class="delete-coupon-btn mr-15px " @click="showConfirmDeleteCoupon"
                         v-if="couponSelectedRowKeys.length !== 0" >
                <md-icon>delete</md-icon>
                <span>Xóa {{couponSelectedRowKeys.length}} phiếu</span>
              </md-button>
              <md-button class="delete-coupon-btn"  @click="showConfirmDeleteCouponByQuery"
                         v-if="pagingParams.total > 0">
                <md-icon>delete_forever</md-icon>
                <span>Xóa tất cả</span>
              </md-button>
            </div>
            <div class="float-right">
              <pagination-template :class="'float-right'" :params="pagingParams" @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="tableHeader.PHIEU_NHAP_XUAT_MOI"
                           :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"
          />
        </div>
      </tab>
      <tab name="Nhập khác" :tab_id="INVENTORY_REPORT_EXPORT_TAB.NHAP_KHAC">
        <div v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.NHAP_KHAC">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'nhap_khac'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách phiếu Nhập khác</div>
            <div class="d-flex-center">
              <div class="d-flex-center">
                <div class="export-excel-btn"
                     @click="dowloadExcel">
                  <md-icon>file_download</md-icon>
                  <span>
                Xuất Excel
              </span>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="getInventoryCoupon">
                  <md-icon>autorenew</md-icon>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="$modal.show('show-inventory-table-modal')">
                  <md-icon>zoom_out_map</md-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div v-if="canEdit">
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon"
                         v-if="couponSelectedRowKeys.length !== 0">
                <md-icon>delete</md-icon>
                <span>Xóa {{couponSelectedRowKeys.length}} phiếu</span>
              </md-button>
              <md-button class="delete-coupon-btn"  @click="showConfirmDeleteCouponByQuery"
                         v-if="pagingParams.total > 0">
                <md-icon>delete_forever</md-icon>
                <span>Xóa tất cả</span>
              </md-button>
            </div>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="tableHeader.PHIEU_NHAP_XUAT_KHAC" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
      <tab name="Hoàn TMDT" :tab_id="INVENTORY_COUPON_TYPE.HOAN_TMDT">
        <div v-if="tabActive === INVENTORY_COUPON_TYPE.HOAN_TMDT">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS, INVENTORY_TYPE_OPTIONS.STATUS]"
              :tab-active="tabActive"
              :id-filter-date="'hoan_tmdt'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách hoàn TMDT</div>
            <div class="d-flex-center">
              <div class="d-flex-center">
                <div class="export-excel-btn"
                     @click="dowloadExcel">
                  <md-icon>file_download</md-icon>
                  <span>
                Xuất Excel
              </span>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="getInventoryCoupon">
                  <md-icon>autorenew</md-icon>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="$modal.show('show-inventory-table-modal')">
                  <md-icon>zoom_out_map</md-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div v-if="canEdit">
              <md-button class="delete-coupon-btn" @click="showConfirmDeleteCoupon"
                         v-if="couponSelectedRowKeys.length !== 0">
                <md-icon>delete</md-icon>
                <span>Xóa {{couponSelectedRowKeys.length}} phiếu</span>
              </md-button>
              <md-button class="delete-coupon-btn"  @click="showConfirmDeleteCouponByQuery"
                         v-if="pagingParams.total > 0">
                <md-icon>delete_forever</md-icon>
                <span>Xóa tất cả</span>
              </md-button>
            </div>
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <inventory-table border-y :columns="tableHeader.PHIEU_XUAT_HOAN_TMDT" :table-data="inventoryCouponData"
                           :cell-span-option="cellSpanOption"
                           :cell-style-option="cellStyleOption"
                           :checkbox-option="checkboxOption"/>
        </div>
      </tab>
      <tab name="Nhập hoàn" :tab_id="INVENTORY_COUPON_TYPE.NHAP_HOAN">
        <div v-if="tabActive === INVENTORY_COUPON_TYPE.NHAP_HOAN">
          <inventory-filter
              @change="handleFilterChange"
              :pick-type="[INVENTORY_TYPE_OPTIONS.KEYWORK, INVENTORY_TYPE_OPTIONS.SKU, INVENTORY_TYPE_OPTIONS.GOODS]"
              :tab-active="tabActive"
              :id-filter-date="'nhap_hoan'"
          ></inventory-filter>
          <div class="mb-20px d-flex-space-between">
            <div class="heading-600">Danh sách Nhập hoàn</div>
            <div class="d-flex-center">
              <div class="d-flex-center">
                <div class="export-excel-btn"
                     @click="dowloadExcel">
                  <md-icon>file_download</md-icon>
                  <span>
                Xuất Excel
              </span>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="getInventoryCoupon">
                  <md-icon>autorenew</md-icon>
                </div>
                <div class="ml-10px zoom-out-btn"
                     @click="$modal.show('show-inventory-table-modal')">
                  <md-icon>zoom_out_map</md-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="w_100">
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                   @change-params="changePagingParam"/>
            </div>
          </div>
          <ve-table border-y :columns="saleExportTableHeader" :table-data="inventoryCouponData" />
        </div>
      </tab>
    </tabs>
    <detail-product-modal></detail-product-modal>
    <show-inventory-table-modal v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA"
                                :total-stock-data="inventoryCouponData"
                                :total-stock-columns="tableHeader.PHIEU_NHAP_XUAT_MOI"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
    <show-inventory-table-modal v-if="tabActive === INVENTORY_REPORT_EXPORT_TAB.NHAP_KHAC"
                                :total-stock-data="inventoryCouponData"
                                :total-stock-columns="tableHeader.PHIEU_NHAP_XUAT_KHAC"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
    <show-inventory-table-modal v-if="tabActive == INVENTORY_COUPON_TYPE.HOAN_TMDT"
                                :total-stock-data="inventoryCouponData"
                                :total-stock-columns="tableHeader.PHIEU_XUAT_HOAN_TMDT"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
    <show-inventory-table-modal v-if="tabActive == INVENTORY_COUPON_TYPE.NHAP_HOAN"
                                :total-stock-data="inventoryCouponData"
                                :total-stock-columns="tableHeader.NHAP_HOAN_XUAT_BAN"
                                :paging-params="pagingParams"
                                :hidden-export-btn="true"
                                @change-paging-params="changePagingParam"
                                @export="dowloadExcel"/>
  </div>
</template>

<script>

import couponMixin from "@/mixins/coupon-mixin";
import InventoryFilter from "../Filter";
import InventoryTable from "../Table";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import DetailProductModal from "../../../../Components/Modal/DetailProductModal";
import {INVENTORY_COUPON_HEADER_TABLE, INVENTORY_IMPORT_TABLE_HEADER, INVENTORY_REPORT_EXPORT_TAB} from "@/const/const";
import ShowInventoryTableModal from "@/pages/Components/Modal/ShowInventoryTableModal";

export default {
  mixins: [couponMixin],
  name: "ImportGoodsContent",
  components: {
    InventoryTable,
    InventoryFilter,
    PaginationTemplate,
    DetailProductModal,
    ShowInventoryTableModal,
  },
  data() {
    return {
      canEdit: this.$store.state.auth.user.manage_inventory,
    }
  },
  created() {
    this.tabActive = INVENTORY_REPORT_EXPORT_TAB.NHAP_MUA;
  },
}

</script>

<style lang="scss">

</style>