<template>
  <div>
    <modal name="add-multiple-product-modal"
           :click-to-close="false"
           width="95%"
           margin="auto"
           left="0"
           top="20px"
           padding="10px"
           height="auto"
           class="coupon-modal modal-custom add-multiple-product-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Tạo mới phiếu nhập hàng</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('add-multiple-product-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <div class="my-15px coupon-modal-content">
        <md-card-content>
          <div class="infomation-add">
            <div class="title-form">
              <span class="text-400-medium">Thông tin phiếu</span>
            </div>
            <div class="options-box-layout">
              <div class="options-left">
                <!--  -->
                <!-- <div class="option" v-if="isUpdate">
                    <div class="title-option">
                      <span class="title required">Mã phiếu nhập</span>
                    </div>
                    <div class="input-option">
                      <md-field class="pd-0">
                        <md-input readonly></md-input>
                      </md-field>
                    </div>
                </div> -->
                <div class="option">
                  <div class="title-option">
                    <span class="title required">Người tạo</span>
                  </div>
                  <div class="input-option">
                    <md-field>
                      <md-input :disabled="disabled_list.created_by" :value="getNameForUser(currentUser)"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title">Đối tượng</span>
                  </div>
                  <div class="input-option">
                    <multiselect v-model="optionMultiselect.customers.value"
                                 deselect-label="Can't remove this value"
                                 track-by="id"
                                 placeholder="Tìm kiếm"
                                 :disabled="optionMultiselect.customers.disabled"
                                 :options="optionMultiselect.customers.list"
                                 :allow-empty="false"
                                 :selectLabel="''"
                                 :deselectLabel="''"
                                 :selectedLabel="''"
                                 :searchable="true"
                                 @search-change="onSearch('customers', $event)"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <span>{{ getNameForUser(option) }}</span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc"><span class="option__title">{{ getNameForUser(props.option) }}</span></div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title required">Đơn đặt hàng</span>
                  </div>
                  <div class="input-option">

                    <multiselect v-model="optionMultiselect.orderCoupon.value"
                                 deselect-label="Can't remove this value"
                                 track-by="internal_code"
                                 label="internal_code"
                                 :disabled="optionMultiselect.orderCoupon.disabled"
                                 placeholder="Đơn đặt hàng"
                                 :options="optionMultiselect.orderCoupon.list"
                                 :searchable="false"
                                 :allow-empty="false" @input="inputOrderCoupon">
                      <template slot="singleLabel" slot-scope="{ option }">
                        <span>{{ option.internal_code }}</span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title required">Nhà cung cấp</span>
                  </div>
                  <div class="input-option">
                    <multiselect v-model="optionMultiselect.supplier.value"
                                 deselect-label="Can't remove this value"
                                 track-by="id"
                                 label="name"
                                 placeholder="Nhà cung cấp"
                                 :disabled="optionMultiselect.supplier.disabled"
                                 :options="optionMultiselect.supplier.list"
                                 :searchable="false"
                                 :allow-empty="false"
                                 @search-change="onSearch('supplier', $event)"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <span>{{ option.name }}</span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title required">Ngày nhập đơn</span>
                  </div>
                  <div class="input-option">
                    <datetime type="date form-control"
                              format="dd/MM/yyyy" v-model="importDate">
                    </datetime>
                  </div>
                </div>
              </div>
              <div class="options-right">
                <div class="option code-tracking">
                  <div class="title-option">
                    <span class="title">Mã tracking</span>
                  </div>
                  <div class="input-option">
                    <md-field class="pd-0">
                      <md-input v-model="formData.tracking"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title required">Kho hàng</span>
                  </div>
                  <div class="input-option">
                    <multiselect v-model="optionMultiselect.inventory.value"
                                 :options="optionMultiselect.inventory.list"
                                 placeholder="Kho hàng"
                                 group-values="children"
                                 group-label="name"
                                 deselect-label="Can't remove this value"
                                 :group-select="false"
                                 :allow-empty="false"
                                 track-by="id"
                                 label="name"
                                 @select="onSelected($event, 'inventory')"
                    >
                      <span slot="noResult">Không tìm thấy dữ liệu!</span>
                    </multiselect>
                    <!-- </multiselect> -->
                  </div>
                </div>
                <div class="option">
                  <div class="title-option">
                    <span class="title">Ghi chú</span>
                  </div>
                  <div class="input-option">
                    <textarea rows="3" v-model="formData.description"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-content class="product-list">
          <div class="header-list">
            <span>Danh sách sản phẩm</span>
          </div>
          <div class="container-product">
            <table>
              <thead>
              <tr>
                <th>Thao tác</th>
                <th>STT</th>
                <th class="product-header-title">Sản phẩm</th>
                <th class="product-header-sku">SKU</th>
                <th class="product-header-type">Biến thể</th>
                <th>Số lượng</th>
                <th>ĐVT</th>
                <th>Đơn giá</th>
                <th>Phí vận chuyển (VND)</th>
                <th>Tỷ giá</th>
                <th>Thành tiền (VND)</th>
                <th>Ghi chú</th>
              </tr>
              </thead>
              <tfoot>
              <tr v-for="(item, index) in products" :key="'product-row-' + index">
                <td>
                  <div class="list-action case-update">
                    <div class="action delete" @click="deleteRow(index)">
                      <img src="/img/modal/delete.svg" alt="aza" class="icon-action">
                    </div>
                  </div>
                </td>
                <td class="stt">{{ index + 1 }}</td>
                <td class="product-name">
                  {{ item.skuSelected ? item.skuSelected.goods.name : '' }}
                </td>
                <td class="SKU">
                  <multiselect v-model="item.skuSelected" class="mt-10px"
                               label="sku" track-by="id"
                               placeholder="Chọn sản phẩm" open-direction="top"
                               :options="optionMultiselect.addProductSku.list" :multiple="false"
                               :disabled="optionMultiselect.addProductSku.disabled"
                               :selectLabel="'Chọn'"
                               :deselectLabel="'Bỏ chọn'"
                               :selectedLabel="'Đã chọn'"
                               :internal-search="false"
                               :clear-on-select="false"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-no-results="false" :hide-selected="false"
                               @input="selectProductSku(item)"
                               @search-change="searchProductSku"
                  >
                    <template slot="option" slot-scope="{ option }">
                      <md-list-item>
                        <div class="md-list-item-text">
                          <p>{{ option.sku }}</p>
                        </div>
                      </md-list-item>
                    </template>
                    <span slot="noResult">Chưa có dữ liệu!</span>
                  </multiselect>
                </td>
                <td>
                  {{ getValueOfProductSku(item.skuSelected) }}
                </td>
                <td>
                  <div class="quantity input-prd">
                    <input type="number" v-model="item.quantity" @input="calculator(item)"
                           class="input-add-pdoruct">
                  </div>
                </td>
                <td>{{getCurrencyOfProductSku(item.skuSelected)}}</td>
                <td>
                  <div class="input-prd">
                    <input type="number" v-model="item.price" @input="calculator(item)" class="input-add-pdoruct">
                  </div>
                </td>
                <td>
                  <div class="input-prd">
                    <input type="number" v-model="item.shipping_fee" @input="calculator(item)"
                           class="input-add-pdoruct">
                  </div>
                </td>
                <td>
                  <div class="input-prd">
                    <input type="number" v-model="item.rate" @input="calculator(item)" class="input-add-pdoruct">
                  </div>
                </td>
                <td>
                  <div class="input-prd">
                    <input type="number" v-model="item.total_price" @input="calculator(item, true)"
                           class="input-add-pdoruct">
                  </div>
                </td>
                <td>
                  <div class="input-prd">
                    <textarea type="text" v-model="item.description" class="input-add-pdoruct"></textarea>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
            <button class="add-product-row mt-15px" @click="addRow" v-show="showAddProductBtn">
              <span>Thêm sản phẩm</span>
            </button>
          </div>
        </md-card-content>
      </div>
      <md-dialog-actions class="p-15px w_100 text-400-medium">
        <button class="create-post-btn btn-active md-button w_100" @click="createCoupon">
          <vue-element-loading spinner="bar-fade-scale" :active="loadingCreate"/>
          Tạo phiếu
        </button>
        <button class="create-post-btn btn-stroke md-button w_100" @click="$modal.hide('add-multiple-product-modal')">
          Hủy
        </button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
// import Selection from '../Select2/Selection';
import InventoryService from "../../../store/services/InventoryService";
import EmployeeService from "../../../store/services/EmployeeService";
import {INVENTORY_COUPON_TYPE} from "@/const/const";
import moment from "moment";
import {helpers} from "@/helper/helpers";
import {Datetime} from "vue-datetime";

export default {
  name: 'add-multiple-product-modal',
  components: {
    // Selection
    datetime: Datetime,
  },
  data() {
    return {
      INVENTORY_COUPON_TYPE,
      timer: null,
      type: null,
      disabled_list: {
        created_by: false,
        internal_code: false,
      },
      products: [],
      templateData: {
        quantity: '',
        rate: 1,
        price: '',
        shipping_fee: '',
        total_price: '',
        description: '',
        skuSelected: null,
      },
      formData: {
        tracking: '',
        description: '',
        type: null,
      },
      isCreatedFromReceipt: false,
      isUpdate: true,
      optionMultiselect: {
        orderCoupon: {
          disabled: false,
          value: null,// v-model
          list: []
        },
        addProductSku: {
          disabled: false,
          list: []
        },
        customers: {
          disabled: false,
          value: null,// v-model
          list: []
        },
        supplier: {
          disabled: false,
          value: null, // v-model
          list: []
        },
        inventory: {
          disabled: false,
          value: null, // v-model
          list: []
        }
      },
      showAddProductBtn: false,
      currentUser: this.$store.state.auth.user,
      loadingCreate: false,
      importDate: ''
    }
  },

  computed: {
  },

  watch: {
  },

  methods: {

    beforeOpened(event) {
      //reset
      this.importDate = '';
      Object.entries(this.optionMultiselect).forEach(([key, value]) => {
        this.optionMultiselect[key] = {
          disabled: false,
          value: null, // v-model
          list: []
        }
      });
      this.formData = {
        tracking: '',
        description: '',
        type: null,
      };
      this.products = [];
      this.loadingCreate = false;

      this.formData.type = event.params.type;
      // disable cho phiếu nhập mua
      if (this.formData.type === INVENTORY_COUPON_TYPE.NHAP_MUA) {
        this.disabled_list.created_by = true;
        this.disabled_list.internal_code = true;
        if (event.params.inventory_id && event.params.order_coupon_data) {
          this.optionMultiselect.inventory.value = {id: event.params.inventory_id, name: event.params.inventory_name};
          this.optionMultiselect.orderCoupon.value = event.params.order_coupon_data;
          this.getOrderCoupons(event.params.inventory_id);
          this.getProducts(event.params.inventory_id);
          this.inputOrderCoupon();
        } else {
          Object.entries(this.optionMultiselect).forEach(([key, value]) => {
            this.optionMultiselect[key].disabled = true;
          });
        }

      }
      this.getEmployees();
      this.getInventories();
      this.getListSupplier();
    },

    beforeClosed(event) {
    },

    addRow() {
      this.products.push({...this.templateData});
    },

    async getEmployees(keyword = "") {
      let params = {
        page: 1,
        per_page: 20,
        keyword: keyword
      }
      let dataEmployee = new Set();
      await EmployeeService.getEmployees(params).then(res => {
        if (res && res.data) {
          res.data.forEach(item => dataEmployee.add(item));
          this.optionMultiselect.customers.list = Array.from(dataEmployee);
        }
      });
    },

    async getInventories() {
      const userId = this.$store.state.auth.user.id;
      await InventoryService.getInventories({user_id: userId}).then(res => {
        if (res) {
          this.optionMultiselect.inventory.list = res;
        }
      });
    },

    async getListSupplier(keyword) {
      await InventoryService.getListSupplier({keyword: keyword}).then(res => {
        this.optionMultiselect.supplier.list = res.data;
      });
    },

    async getOrderCoupons(inventory_id) {
      // this.$route.params.inventory_id
      await InventoryService.getOrderCoupons({inventory_id: inventory_id}).then(res => {
        this.optionMultiselect.orderCoupon.list = res.data;
      });
    },

    async searchProductSku(query) {
      await this.getProducts(this.optionMultiselect.inventory.value.id, query);
    },

    async getProducts(inventory_id, keyword = '') {
      // this.$route.params.inventory_id
      await InventoryService.getProductOfInventory({inventory_id: inventory_id, per_page: 20, keyword: keyword}).then(res => {
        this.optionMultiselect.addProductSku.list = res.data;
      });
    },

    calculator(product, is_total = false) {
      const total_price = parseInt(product.total_price);
      const shipping_fee = parseInt(product.shipping_fee);
      const rate = parseFloat(product.rate);
      const quantity = parseInt(product.quantity);
      const price = parseInt(product.price);

      if (is_total) {
        if (!shipping_fee || !rate || !quantity) {
          return;
        }
        product.price = Math.floor((total_price - shipping_fee) / (rate * quantity));
      } else {
        if (shipping_fee && rate && quantity) {
          product.total_price = Math.floor(quantity * price * rate) + shipping_fee;
        }
      }
    },

    formatNumber(value) {
      value.toString().replace(/\./gi, "").split(/(?=(?:\d{3})+$)/).join(".")
      return value;
    },

    deleteRow(index) {
      this.products.splice(index, 1);
    },

    onSearch(type, event) {
      switch (type) {
        case 'supplier':
          this.getListSupplier(event);
          break;
        case 'customers':
          this.getEmployees(event);
          break;
        default:
          break;
      }
    },

    async onSelected(e, field) {
      this.products = [];
      this.optionMultiselect.orderCoupon.value = null;
      this.optionMultiselect[field].value = e;
      if (field == 'inventory' && this.formData.type === INVENTORY_COUPON_TYPE.NHAP_MUA) {
        await this.getOrderCoupons(e.id);
        await this.getProducts(e.id);
      }
      Object.entries(this.optionMultiselect).forEach(([key, value]) => {
        this.optionMultiselect[key].disabled = false;
      });
    },

    inputOrderCoupon() {
      this.products = [];
      if (this.optionMultiselect.orderCoupon.value) {
        const productInOrder = this.optionMultiselect.orderCoupon.value;
        for (let i = 0; i < productInOrder.product_receipts.length; i++) {
          let product = {...this.templateData};
          product.skuSelected = productInOrder.product_receipts[i].product;
          product.price = productInOrder.product_receipts[i].price;
          product.quantity = productInOrder.product_receipts[i].quantity;
          product.rate = productInOrder.product_receipts[i].rate;
          product.shipping_fee = productInOrder.product_receipts[i].shipping_fee;
          product.total_price = productInOrder.product_receipts[i].total_price;
          product.description = productInOrder.product_receipts[i].description;
          this.products.push(product);
        }

        this.showAddProductBtn = true;
      } else {
        this.showAddProductBtn = false;
      }
    },

    selectProductSku(item) {
      item.rate = item.skuSelected ? item.skuSelected.goods.currency.rate : 1;
    },

    getValueOfProductSku(product) {
      if (product) {
        if (product.values) {
          return `${product.values.variant.name} : ${product.values.value}`;
        }
      }
      return '';
    },
    getCurrencyOfProductSku(product) {
      if (product) {
        if (product.goods.currency) {
          return product.goods.currency.iso_code;
        }
      }
      return '';
    },
    getNameForUser(user) {
      return user.display_name ? user.display_name : (user.fullname ? user.fullname : '');
    },
    createCoupon() {
      this.loadingCreate = true;
      if (!this.validateCouponForm()) {
        this.loadingCreate = false;
        this.$store.dispatch("alerts/error", 'Bạn cần nhập đủ thông tin của phiếu!');
        return;
      }

      let couponForm = {...this.formData};
      couponForm.created_by_id = this.currentUser.id;
      couponForm.vendor_id = this.optionMultiselect.supplier.value.id;
      couponForm.status = 1;
      couponForm.type = this.formData.type;
      couponForm.inventory_main_id = this.optionMultiselect.inventory.value.id;
      couponForm.receipt_internal_code = this.optionMultiselect.orderCoupon.value.internal_code;
      if (this.optionMultiselect.customers.value) {
        couponForm.manager_id = this.optionMultiselect.customers.value.id;
      }
      let orderDate = new Date(this.importDate);
      couponForm.date = moment(orderDate).format('YYYY-MM-DD');

      couponForm.product_coupons = [];
      this.products.forEach(item => {
        let productCoupon = {...item};
        delete productCoupon.skuSelected;
        productCoupon.product_id = item.skuSelected.id;
        couponForm.product_coupons.push(productCoupon);
      })

      InventoryService.createCoupon(couponForm).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", 'Bạn đã tạo phiếu thành công');
          this.$modal.hide('add-multiple-product-modal');
        }
      });

      return;

    },
    validateCouponForm() {
      let multiselectData = this.optionMultiselect;
      if (!multiselectData.orderCoupon.value
          || !multiselectData.supplier.value
          || !multiselectData.inventory.value
          || this.products.length === 0  || helpers.isEmpty(this.importDate)) {
        return false;
      }
      for (let i = 0; i < this.products.length; i++) {
        if (helpers.isEmpty(this.products[i].quantity)
            || helpers.isEmpty(this.products[i].rate)
            || helpers.isEmpty(this.products[i].price)
            || helpers.isEmpty(this.products[i].shipping_fee)
            || helpers.isEmpty(this.products[i].total_price)) {
          return false;
        }
      }
      return true;
    }
  },

  created() {

  },
  mounted() {
  },

}
</script>

<style lang="scss">
.add-multiple-product-modal {
  textarea {
    width: 100%;
    resize: none;
    border-radius: 4px;
    border: 1px solid #dfd2d2;
    padding: 10px;
  }

  .multiselect__content-wrapper {
    .multiselect__option {
      display: flex;
      align-items: center;
      padding: 10px !important;
    }
  }
}

.coupon-modal {
  $default-border: 1px solid #d1d1d1;
  $color-default: #2b2b2b;

  .infomation-add {
    border: 1px solid #D8DAE5;
    padding: 10px 15px;
    position: relative;
    color: $color-default;

    .title-form {
      position: absolute;
      top: -10px;
      background: #fff;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .md-field {
    &:before {
      background-color: unset !important;
    }

    &:after {
      height: 0 !important;
    }

    .md-theme-default {
      &:before {
        background-color: unset !important;
      }
    }
  }

  .options-box-layout {
    display: grid;
    grid-gap: 20px;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 15px 0 0 0;

    .options-left, .options-right {
      .option {
        display: flex;
        align-items: center;
        grid-gap: 5px;
        margin: 0 0 8px 0;

        .title-option {
          width: 20%;

          .title {
            color: $color-default;
            font-size: 14px;
          }
        }

        .input-option {
          width: 80%;

          select {
            width: 100%;
            height: 40px;
            border: 1px solid #dfd2d2;
            border-radius: 3px;
          }
        }
      }
    }

    .options-left {
      .code-tracking {
        margin-bottom: 5px;
      }
    }

    .md-field {
      margin: 0;
      padding: 0;

      .md-input {
        height: 40px;
        border-radius: 4px;
        border: 1px solid #dfd2d2;
        padding: 0 10px;
      }

      textarea {
        min-height: 80px !important;
        border-radius: 4px;
        border: 1px solid #dfd2d2;
        padding: 0 10px;
      }
    }

    .md-disabled {
      input {
        background: #ffffff;
      }

      &:after {
        border: none;
      }
    }

    .pd-0 {
      padding: 0;
    }
  }
}

.add-product-row {
  cursor: pointer;
  background: #376bfb;
  color: #fff;
  padding: 10px 10px;
  border: none;
  border-radius: 4px;
}

.vdatetime-input {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>