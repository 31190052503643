export default {
    state: {
        settingModel: null,
    },

    getters: {
        getNotificationSetting(state) {
            return state.settingModel;
        },
    },

    mutations: {
        putNotificationSetting(state, payload) {
            state.settingModel = payload;
        },
    },

    actions: {
        refreshNotificationSetting({commit, dispatch}, payload) {
            commit("putNotificationSetting", null);
        },
        putNotificationSetting({commit, dispatch}, payload) {
            commit("putNotificationSetting", payload);
        },
    }
};
