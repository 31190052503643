<template>
  <div class="pagination-template__wrapper">
    <span class="mr-10px text-400-medium">Hiển thị</span>
    <select :value="params.perPage" @change="changeParams('PER_PAGE', $event)">
      <option class="text-400-medium" v-for="item in perPageList" :key="'per_page_' + item" :value="item">
        {{item}}
      </option>
    </select>
    <span class="cursor-pointer" @click="changeParams('FIRST_PAGE')">
      <md-icon>first_page</md-icon>
    </span>
    <span class="cursor-pointer" @click="changeParams('PREV_PAGE')">
      <md-icon>navigate_before</md-icon>
    </span>
    <span class="text-400-medium mx-5px neutral_2">{{paginationContent}}</span>
    <span class="cursor-pointer" @click="changeParams('NEXT_PAGE')">
      <md-icon>navigate_next</md-icon>
    </span>
    <span class="cursor-pointer" @click="changeParams('LAST_PAGE')">
      <md-icon>last_page</md-icon>
    </span>
  </div>
</template>

<script>
export default {
  name: "pagination-template",
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    perPageList() {
      return [5, 10, 25, 50, 100];
    },
    paginationContent() {
      let content = "FROM-TO trong " + this.params.total;
      if (this.params.page === 1) {
        content = content.replace("FROM", this.params.page).replace("TO", this.params.perPage > this.params.total ? this.params.total : this.params.perPage);
      } else {
        let from = (parseInt(this.params.page) - 1) * parseInt(this.params.perPage);
        let to = from + parseInt(this.params.perPage);
        if (to > parseInt(this.params.total)) {
          to = parseInt(this.params.total);
        }
        content = content.replace("FROM", from.toString()).replace("TO", to.toString());
      }
      return content;
    }
  },
  methods: {
    changeParams(type, event) {
      let newParams = {...this.params};
      switch (type) {
        case 'PER_PAGE':
          newParams.perPage = parseInt(event.target.value);
          newParams.page = 1;
          break;
        case 'FIRST_PAGE':
          if (newParams.page === 1) {
            return;
          }
          newParams.page = 1;
          break;
        case 'PREV_PAGE':
          if (newParams.page === 1) {
            return;
          }
          newParams.page = newParams.page - 1;
          break;
        case 'NEXT_PAGE':
          if (newParams.page === newParams.lastPage) {
            return;
          }
          newParams.page = newParams.page + 1;
          break;
        case 'LAST_PAGE':
          if (newParams.page === newParams.lastPage) {
            return;
          }
          newParams.page = newParams.lastPage;
          break;
      }
      this.$emit("change-params", newParams);
    },
  }
}
</script>

<style lang="scss">
.pagination-template__wrapper {
  .md-icon {
    color: #6e6e6e !important;
    border-radius: 2px;
    &:hover {
      background: #a6e2f5;
    }
  }
  select {
    padding: 3px 4px;
    border: 1px solid #9b9ba5;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 25px;
  }
}
</style>