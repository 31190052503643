import BaseService from "./BaseService";

export default class InventoryService extends BaseService {
    static async getInventories(params = {}) {
        const res = await this.get(`/inventory`, params, 'INVENTORY');
        return res.data;
    }

    static async getProductOfInventory(params = {}) {
        let inventory_id = params.inventory_id;
        delete params.inventory_id;
        const res = await this.get(`/inventory/${inventory_id}/list-product`, params, 'INVENTORY');
        return res.data;
    }

    static async getUserOfInventory(params = {}) {
        const res = await this.get(`/inventory/${params.inventory_id}/list-user`, params, 'INVENTORY');
        return res.data;
    }

    static async getGoodOfInventory(params = {}) {
        let inventory_id = params.inventory_id;
        delete params.inventory_id;
        const res = await this.get(`/inventory/${inventory_id}/list-good`, params, 'INVENTORY');
        return res.data;
    }

    static async getReportTotalStock(params = {}) {
        let inventory_id = params.inventory_id;
        delete params.inventory_id;
        const res = await this.get(`/inventory/${inventory_id}/report/total-stock`, params, 'INVENTORY');
        return res.data;
    }

    static async getReportAvailableStock(params = {}) {
        const res = await this.get(`/inventory/${params.inventory_id}/report/available-stock`, params, 'INVENTORY');
        return res.data;
    }

    static async dowloadExcelReportTotalStock(params = {}) {
        const res = await this.get(`/inventory/${params.inventory_id}/report/total-stock/export-excel`, params, 'INVENTORY');
        return res.data;
    }

    static async getCountry(params = {}) {
        const res = await this.get(`/inventory/list-country`, params, 'INVENTORY');
        return res.data;
    }

    static async importFileExcel(type, params = {}) {
        const res = await this.post(`/inventory/coupon/${type}/import`, params, 'INVENTORY');
        return res.data;
    }

    static async getCoupons(params = {}) {
        const res = await this.get(`/inventory/${params.inventory_id}/coupon/${params.type}`, params, 'INVENTORY');
        return res.data;
    }

    static async getReportWarehouse(params = {}) {
        const res = await this.get(`/inventory/${params.inventory_id}/report-warehouse/${params.type}`, params, 'INVENTORY');
        return res.data;
    }

    static async deleteCoupon(params = {}) {
        const res = await this.delete(`/inventory/${params.inventory_id}/coupon/${params.type}/${params.coupon_id}`, 'INVENTORY');
        return res.data;
    }

    static async deleteMultiCoupon(params = {}) {
        const res = await this.post(`/inventory/${params.inventory_id}/coupon/multiple/${params.type}`, {coupon_ids: params.coupon_ids}, 'INVENTORY');
        return res.data;
    }

    static async deleteMultiCouponByQuery(params = {}) {
        const res = await this.post(`/inventory/${params.inventory_id}/coupon/multiple-by-query/${params.type}`, {
            start_date: params.start_date,
            end_date: params.end_date
        }, 'INVENTORY');
        return res.data;
    }

    static async getDetailProduct(params = {}) {
        const res = await this.get(`/coupon/${params.coupon_id}`, {}, 'INVENTORY');
        return res.data;
    }

    static async getDetailOrder(id) {
        const res = await this.get(`/receipt/${id}/detail`, {}, 'INVENTORY');
        return res.data;
    }

    static async getOrderCoupons(params = {}) {
        const res = await this.get(`/receipt/${params.inventory_id}`, params, 'INVENTORY');
        return res.data;
    }

    static async getListSupplier(params = {}) {
        const res = await this.get(`/vendor`, params, 'INVENTORY');
        return res.data;
    }

    static async deleteMultiOrder(params = {}) {
        const res = await this.post('/receipt/delete', params, 'INVENTORY');
        return res.data;
    }

    static async deleteMultiOrderByQuery(params = {}) {
        const res = await this.post(`/receipt/delete-by-query`, {
            start_date: params.start_date,
            end_date: params.end_date
        }, 'INVENTORY');
        return res.data;
    }

    static async deleteCouponNotInStock(params = {}) {
        const res = await this.post(`/receipt/delete-coupon-not-in-stock`, params, 'INVENTORY');
        return res.data;
    }

    static async createCoupon(params = {}) {
        const res = await this.post(`/coupon/`, params, 'INVENTORY');
        return res.data;
    }

    static async updateCoupon(params = {}) {
        const id = params.id;
        delete params.id;
        const res = await this.post(`/coupon/${id}`, params, 'INVENTORY');
        return res.data;
    }

    static async createOrderCoupon(params = {}) {
        const res = await this.post(`/receipt/`, params, 'INVENTORY');
        return res.data;
    }

    static async updateOrderCoupon(params = {}) {
        const id = params.id;
        delete params.id;
        const res = await this.post(`/receipt/${id}`, params, 'INVENTORY');
        return res.data;
    }

    static async getOrderCouponDetail(params = {}) {
        const res = await this.get(`/receipt/${params.order_coupon_id}/detail`, {}, 'INVENTORY');
        return res.data;
    }

    static async getCouponSyncHistory(params = {}) {
        const res = await this.get(`/coupon/list-import`, params, 'INVENTORY');
        return res.data;
    }

    static async getReportTotalStockByMonth(params = {}) {
        let inventory_id = params.inventory_id;
        delete params.inventory_id;
        const res = await this.get(`/inventory/${inventory_id}/report/total-stock-by-month`, params, 'INVENTORY');
        return res.data;
    }

    static async cacheOpeningStoke(data = {}) {
        const res = await this.post(`/inventory/cache-opening-stoke`, data, 'INVENTORY');
        return res.data;
    }

    static async deleteCouponByInventorySyncId(data = {}) {
        const res = await this.post(`/coupon/delete-by`, data, 'INVENTORY');
        return res.data;
    }

    static async getSettings(params = {}) {
        const res = await this.get(`/settings`, params, 'INVENTORY');
        return res.data;
    }

    static async updateSettings(params = {}) {
        const res = await this.post(`/settings`, params, 'INVENTORY');
        return res.data;
    }

    static async getCouponOrReceipt(params = {}) {
        const res = await this.get(`/coupon-or-receipt`, params, 'INVENTORY');
        return res.data;
    }

    static async getCouponOverview(params = {}) {
        const res = await this.get(`/coupon-or-receipt/overview`, params, 'INVENTORY');
        return res.data;
    }

    static async getSwitchStockAdjustmentCoupons(params = {}) {
        const res = await this.get(`/switch-stock-adjustment-coupon`, params, 'INVENTORY');
        return res.data;
    }

    static async switchStockAdjustmentAccept(params = {}) {
        const res = await this.post(`/switch-stock-adjustment-coupon/accept`, params, 'INVENTORY');
        return res.data;
    }

    static async switchStockAdjustmentAcceptAll(params = {}) {
        const res = await this.post(`/switch-stock-adjustment-coupon/accept-all`, params, 'INVENTORY');
        return res.data;
    }

    static async switchStockAdjustmentReject(params = {}) {
        const res = await this.post(`/switch-stock-adjustment-coupon/reject`, params, 'INVENTORY');
        return res.data;
    }

    static async switchStockAdjustmentDate(params = {}) {
        const res = await this.get(`/switch-stock-adjustment-coupon/date`, params, 'INVENTORY');
        return res.data;
    }

    static async createAdjustmentCoupon(params = {}) {
        const res = await this.post(`/create-adjustment-coupon`, params, 'INVENTORY');
        return res.data;
    }

    static async deleteAdjustmentCoupon(params = {}) {
        const res = await this.post(`/create-adjustment-coupon/delete`, params, 'INVENTORY');
        return res.data;
    }

    static async getInsufficientProduct(params = {}) {
        const res = await this.get(`/inventory/insufficient-product`, params, 'INVENTORY');
        return res.data;
    }

    static async updateStatusCoupon(params = {}, coupon_id) {
        const res = await this.post(`/coupon/${coupon_id}/change-status `, params, 'INVENTORY');
        return res.data;
    }
}
