<template>
  <div>
    <modal name="request-join-chat-group-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="request-join-chat-group-modal modal-custom request-join-chat-group-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Yêu cầu vào nhóm chat</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('request-join-chat-group-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="gs-group">
          <label class="heading-400 neutral_2 required">Link nhóm</label>
          <div class="heading-300 secondary_r400" v-show="showError.isError">{{showError.message}}</div>
          <input type="text" placeholder="Link nhóm" class="form-control input-custom mt-10px" required name="name"
                 v-model="linkGroup">
        </div>
      </md-card-content>

      <md-dialog-actions>
        <button type="submit" class="btn-active md-button text-400-medium" style="width: auto !important;" @click="requestJoin">
          <vue-element-loading :active="requestJoinLoading" spinner="bar-fade-scale"/>
          Gửi yêu cầu vào nhóm
        </button>
      </md-dialog-actions>

    </modal>
  </div>
</template>

<script>

import ChatService from "../../../store/services/ChatService";

export default {
  name: 'request-join-chat-group-modal',
  data() {
    return {
      showError: {
        isError: false,
        message: '',
      },
      requestJoinLoading: false,
      linkGroup: '',
      chatGroupId: '',
      user: this.$store.state.auth.user,
    }
  },
  computed: {
    show() {
      return this.showDialog;
    }
  },
  mounted() {
  },
  props: {
  },
  methods: {
    beforeOpened(event) {
      this.linkGroup = '';
      this.showError = {
        isError: false,
        message: '',
      };
    },

    beforeClosed(event){
    },

    requestJoin() {
      if (this.validate()) {
        return;
      }
      this.showError.isError = false;
      this.requestJoinLoading = true;

      ChatService.requestJoinGroup({group_id: this.chatGroupId, user_id: this.user.id}).then(res => {
        if (res.status) {
          this.$store.dispatch("alerts/success", "Bạn đã yêu cầu vào nhóm thành công");
        } else {
          this.showError.isError = true;
          this.showError.message = 'Không tồn tại nhóm này';
        }
        this.requestJoinLoading = false;
      });


    },
    validate() {
      this.linkGroup = this.linkGroup.trim();
      const domain = window.location.origin;
      let regex;
      if (domain === 'http://localhost:8080') {
        regex = /^http:\/\/localhost:8080\/messenger\/(\d+)$/;
      } else {
        regex = /^https:\/\/social\.azagroup\.asia\/messenger\/(\d+)$/;
      }
      if (this.linkGroup === '') {
        this.showError.isError = true;
        this.showError.message = 'Bạn cần nhập link nhóm';
        return true;
      } else {
        const match = this.linkGroup.match(regex);
        if (match) {
          this.chatGroupId = parseInt(match[1]);
        } else {
          this.showError.isError = true;
          this.showError.message = 'Bạn cần nhập đúng link nhóm';
          return true;
        }
      }
      return false;
    }

  }

}
</script>

<style lang="scss">
  .request-join-chat-group-modal {
    .gs-group {
      min-height: 100px;
    }
  }

</style>
