<template>
  <div>
    <inventory-filter
        @change="handleFilterChange"
        :pick-type="[INVENTORY_TYPE_OPTIONS.SKU,INVENTORY_TYPE_OPTIONS.GOODS]"
        :tab-active="tabActive"
        :id-filter-date="'total_stock'"
    ></inventory-filter>
    <template v-if="(totalStockInPage.length == 0 || totalStockOfMainInventory.length == 0) && !isClickFilter">
      <p class="text-center">Chọn điều kiện trong bộ lọc và click và Tạo báo cáo</p>
    </template>
    <template v-else>
      <div v-if="isInventoryParent">
        <div class="mb-20px d-flex-space-between">
          <div class="heading-600">{{ titleHeading }}</div>
          <div class="d-flex-center">
            <div class="export-excel-btn"
                @click="dowloadExcel()">
              <md-icon>file_download</md-icon>
              <span>
                  Xuất Excel
                </span>
            </div>
            <div class="ml-10px zoom-out-btn"
                @click="getReportTotalStock">
              <md-icon>autorenew</md-icon>
            </div>
          </div>
        </div>
        <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
        <ve-table border-y :columns="overviewColumns" :table-data="totalStockOfMainInventory"
                  :event-custom-option="eventCustomOption" :cell-style-option="cellStyleOptionWithMainInventory"/>
        <div v-if="showSubInventory">
          <div class="mb-20px d-flex-space-between mt-4">
            <div class="heading-600 mt-4">{{ titleSubHeading }}</div>
            <div class="d-flex-center">
              <div class="export-excel-btn"
                  @click="dowloadExcel(true)">
                <md-icon>file_download</md-icon>
                <span>
                  Xuất Excel
                </span>
              </div>
              <div class="ml-10px zoom-out-btn"
                  @click="getReportTotalStock">
                <md-icon>autorenew</md-icon>
              </div>
              <div class="ml-10px zoom-out-btn"
                  @click="$modal.show('show-inventory-table-modal')">
                <md-icon>zoom_out_map</md-icon>
              </div>
            </div>
          </div>
          <div>
            <div class="w_100">
              <div class="float-right">
                <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                    @change-params="changePagingParam"/>
              </div>
            </div>
            <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
            <ve-table border-y :columns="totalSubStockColumns" :table-data="totalStockInPage" :sort-option="sortOption"
                      :cell-style-option="cellStyleOption"/>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mb-20px d-flex-space-between">
          <div class="heading-600">{{ titleHeading }}</div>
          <div class="d-flex-center">
            <div class="export-excel-btn"
                @click="dowloadExcel()">
              <md-icon>file_download</md-icon>
              <span>
                  Xuất Excel
                </span>
            </div>
            <div class="ml-10px zoom-out-btn"
                @click="getReportTotalStock">
              <md-icon>autorenew</md-icon>
            </div>
            <div class="ml-10px zoom-out-btn"
                @click="$modal.show('show-inventory-table-modal')">
              <md-icon>zoom_out_map</md-icon>
            </div>
          </div>
        </div>
        <div>
          <div class="w_100">
            <div class="float-right">
              <pagination-template :class="'mb-10px float-right'" :params="pagingParams"
                                  @change-params="changePagingParam"/>
            </div>
          </div>
          <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
          <ve-table border-y :columns="totalStockColumns"
                    :scroll-width="1600"
                    :table-data="totalStockInPage"
                    :sort-option="sortOption"
                    :cell-span-option="cellSpanOption"
                    :cell-style-option="cellStyleOptionWithMainInventory"/>
        </div>
      </div>
      <show-inventory-table-modal :total-stock-data="totalStockInPage"
                                  :total-stock-columns="totalStockColumns"
                                  :paging-params="pagingParams"
                                  :cell-style-option="cellStyleOptionWithMainInventory"
                                  @change-paging-params="changePagingParam"
                                  @export="dowloadExcel()"/>
    </template>
  </div>
</template>

<script>

import InventoryFilter from "../Filter";
import InventoryService from "../../../../../store/services/InventoryService";
import {INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../../const/const";
import {helpers} from "../../../../../helper/helpers";
import ShowInventoryTableModal from "@/pages/Components/Modal/ShowInventoryTableModal";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import axios from 'axios';
import moment from "moment";

export default {
  name: "ReportAvailableStock",
  components: {
    InventoryFilter,
    ShowInventoryTableModal,
    PaginationTemplate
  },
  data() {
    return {
      INVENTORY_TYPE_OPTIONS,
      isLoad: false,
      inventoryId: this.$route.params.inventory_id,
      inventorySubId: '',
      tabActive: INVENTORY_REPORT_TAB.TOTAL_STOCK,
      isInventoryParent: false,
      showSubInventory: false,
      totalStockParams: {
        start_date: '',
        end_date: '',
        product_id: [],
        inventory_id: this.$route.params.inventory_id,
      },
      inventoryList: [],
      stringDataRowKeyList: ['sku', 'product_name', 'value', 'dvt'],
      overviewColumns: [
        {
          field: "order",
          key: "a1",
          title: "STT",
          width: 50,
          align: "center"
        },
        {field: "inventory_name", key: "a2", title: "Tên kho", align: "left"},
        {
          title: "Đầu kỳ",
          align: "center",
          children: [
            {field: "begin", key: "a3", title: "Số lượng", align: "right"},
          ]
        },
        {
          title: "Nhập kho",
          align: "center",
          children: [
            {field: "buy", key: "a4", title: "Nhập mua", align: "right"},
            {
              field: "totalBuyByPlatforms.social", key: "a41", title: "Nhập hoàn FB/TT", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalBuyByPlatforms.social.toLocaleString();
              }
            },
            {field: "totalBuyByPlatforms.ecom", key: "a42", title: "Nhập hoàn Ecom", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalBuyByPlatforms.ecom.toLocaleString();
              }
            },
            // {field: "completeBuy", key: "a5", title: "Nhập hoàn", align: "right"},
            {field: "transferBuy", key: "a6", title: "Nhập điều chuyển", align: "right"},
            {field: "anotherBuy", key: "a7", title: "Nhập khác", align: "right"},
            {field: "shoppeBuy", key: "k1", title: "Hoàn TMĐT", align: "right"},
            {field: "totalBuy", key: "a8", title: "Tổng nhập", align: "right"},
          ]
        },
        {
          title: "Xuất kho",
          align: "center",
          children: [
            {
              field: "totalSellByPlatforms.social", key: "a91", title: "Xuất bán FB/TT", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalSellByPlatforms.social.toLocaleString();
              }
            },
            {field: "totalSellByPlatforms.ecom", key: "a92", title: "Xuất bán Ecom", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalSellByPlatforms.ecom.toLocaleString();
              }
            },
            // {field: "sell", key: "a9", title: "Xuất bán", align: "right"},
            {field: "transferSell", key: "a10", title: "Xuất điều chuyển", align: "right"},
            {field: "anotherSell", key: "a11", title: "Xuất khác", align: "right"},
            {field: "anotherShoppeSell", key: "a12", title: "Xuất TMĐT", align: "right"},
            {field: "cancelSell", key: "a13", title: "Xuất hàng hỏng", align: "right"},
            {field: "totalSell", key: "a14", title: "Tổng xuất", align: "right"},
          ]
        },
        {
          title: "Cuối kỳ",
          align: "center",
          children: [
            {field: "last", key: "a15", title: "Số lượng", align: "right"},
          ]
        },
      ],
      totalSubStockColumns: [
        {
          field: "",
          key: "b1",
          title: "STT",
          width: 50,
          align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            let index = (this.pagingParams.page - 1) * this.pagingParams.perPage;
            return ++rowIndex + index;
          },
        },
        {field: "sku", key: "b2", title: "SKU", align: "center"},
        {
          field: "product_name",
          key: "b3",
          title: "Sản phẩm",
          align: "left",
        },
        {field: "value", key: "b4", title: "Biến thể", align: "left"},
        {field: "dvt", key: "b5", title: "ĐVT", align: "left"},
        {
          field: "beginning",
          title: "Đầu kỳ",
          align: "center",
          children: [
            {field: "begin", key: "b6", title: "Số lượng", align: "right", sortBy: "",},
          ]
        },
        {
          title: "Nhập kho",
          align: "center",
          children: [
            {field: "buy", key: "b7", title: "Nhập mua", align: "right", sortBy: "",},
            {
              field: "totalBuyByPlatforms.social", key: "b71", title: "Nhập hoàn FB/TT", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalBuyByPlatforms.social.toLocaleString();
              }
            },
            {field: "totalBuyByPlatforms.ecom", key: "b72", title: "Nhập hoàn Ecom", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalBuyByPlatforms.ecom.toLocaleString();
              }
            },
            // {field: "completeBuy", key: "b8", title: "Nhập hoàn", align: "right", sortBy: "",},
            {field: "transferBuy", key: "b9", title: "Nhập điều chuyển", align: "right", sortBy: "",},
            {field: "anotherBuy", key: "b10", title: "Nhập khác", align: "right", sortBy: "",},
            {field: "shoppeBuy", key: "b19", title: "Hoàn TMĐT", align: "right", sortBy: "",},
            {field: "totalBuy", key: "b11", title: "Tổng nhập", align: "right", sortBy: "",},
          ]
        },
        {
          title: "Xuất kho",
          align: "center",
          children: [
            {field: "totalSellByPlatforms.social", key: "b121", title: "Xuất bán FB/TT", align: "right", sortBy: "",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalSellByPlatforms.social.toLocaleString();
              }
            },
            {field: "totalSellByPlatforms.ecom", key: "b122", title: "Xuất bán Ecom", align: "right", sortBy: "",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalSellByPlatforms.ecom.toLocaleString();
              }
            },
            // {field: "sell", key: "b12", title: "Xuất bán", align: "right", sortBy: "",},
            {field: "transferSell", key: "b13", title: "Xuất điều chuyển", align: "right", sortBy: "",},
            {field: "anotherSell", key: "b14", title: "Xuất khác", align: "right", sortBy: "",},
            {field: "anotherShoppeSell", key: "b15", title: "Xuất TMĐT", align: "right", sortBy: "",},
            {field: "cancelSell", key: "b16", title: "Xuất hàng hỏng", align: "right", sortBy: "",},
            {field: "totalSell", key: "b17", title: "Tổng xuất", align: "right", sortBy: "",},
          ]
        },
        {
          title: "Cuối kỳ",
          align: "center",
          children: [
            {field: "last", key: "b18", title: "Số lượng", align: "right", sortBy: "",},
          ]
        },
      ],
      totalStockColumns: [
        {field: "sku", key: "b1", title: "SKU", align: "left", fixed: "left"},
        {
          field: "product_name",
          key: "c",
          title: "Sản phẩm",
          align: "left",
          fixed: "left"
        },
        {field: "value", key: "d", title: "Biến thể", align: "left", fixed: "left"},
        {field: "dvt", key: "e", title: "ĐVT", align: "left", fixed: "left"},
        {
          title: "Đầu kỳ",
          align: "center",
          children: [
            {field: "begin", key: "f", title: "Số lượng", align: "right", sortBy: "",},
          ]
        },
        {
          title: "Nhập kho",
          align: "center",
          children: [
            {field: "buy", key: "g", title: "Nhập mua", align: "right", sortBy: "",},
            {
              field: "totalBuyByPlatforms.social", key: "g71", title: "Nhập hoàn FB/TT", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalBuyByPlatforms.social.toLocaleString();
              }
            },
            {field: "totalBuyByPlatforms.ecom", key: "g72", title: "Nhập hoàn Ecom", align: "right",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalBuyByPlatforms.ecom.toLocaleString();
              }
            },
            // {field: "completeBuy", key: "h", title: "Nhập hoàn", align: "right", sortBy: "",},
            {field: "transferBuy", key: "i", title: "Nhập điều chuyển", align: "right", sortBy: "",},
            {field: "anotherBuy", key: "k", title: "Nhập khác", align: "right", sortBy: "",},
            {field: "shoppeBuy", key: "k1", title: "Hoàn TMĐT", align: "right", sortBy: "",},
            {field: "totalBuy", key: "l", title: "Tổng nhập", align: "right", sortBy: "",},
          ]
        },
        {
          title: "Xuất kho",
          align: "center",
          children: [
            {field: "totalSellByPlatforms.social", key: "m1", title: "Xuất bán FB/TT", align: "right", sortBy: "",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalSellByPlatforms.social.toLocaleString();
              }
            },
            {field: "totalSellByPlatforms.ecom", key: "m2", title: "Xuất bán Ecom", align: "right", sortBy: "",
              renderBodyCell: ({row, column, rowIndex}, h) => {
                return row.totalSellByPlatforms.ecom.toLocaleString();
              }
            },
            // {field: "sell", key: "m", title: "Xuất bán", align: "right", sortBy: "",},
            {field: "transferSell", key: "a1", title: "Xuất điều chuyển", align: "right", sortBy: "",},
            {field: "anotherSell", key: "d1", title: "Xuất khác", align: "right", sortBy: "",},
            {field: "anotherShoppeSell", key: "e1", title: "Xuất TMĐT", align: "right", sortBy: "",},
            {field: "cancelSell", key: "f1", title: "Xuất hàng hỏng", align: "right", sortBy: "",},
            {field: "totalSell", key: "g1", title: "Tổng xuất", align: "right", sortBy: "",},
          ]
        },
        {
          field: "ending",
          title: "Cuối kỳ",
          align: "center",
          children: [
            {field: "last", key: "a2", title: "Số lượng", align: "right", sortBy: "",},
          ]
        },
      ],
      totalStockData: [],
      totalStockInPage: [],
      totalStockOfMainInventory: [],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      titleHeading: '',
      titleSubHeading: '',
      eventCustomOption: {
        bodyRowEvents: ({row, rowIndex}) => {
          return {
            click: (event) => {
              this.getReportSubTotalStock(rowIndex);
            },
          };
        },
      },
      axiosSource: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      cellStyleOptionWithMainInventory: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (rowIndex === 0) {
            if (column.field === 'product_name') {
              return "background_blue_100 good-width";
            } else if (column.field === 'begin') {
              return "background_a400 text-400-medium";
            } else if (column.field === 'totalBuy') {
              return "background_o300 text-400-medium";
            } else if (column.field === 'totalSell') {
              return "background_yellow_a200 text-400-medium";
            } else if (column.field === 'last') {
              if (helpers.isLessThan100(row.last)) {
                return "background_cyan_200 text-400-medium";
              } else {
                return "background_deep_purple_300 text-400-medium";
              }
            } else {
              return "background_blue_100 text-400-medium";
            }
          }
          if (column.field === 'begin') {
            return "background_a400 text-400-medium";
          } else if (column.field === 'totalBuy') {
            return "background_o300 text-400-medium";
          } else if (column.field === 'totalSell') {
            return "background_yellow_a200 text-400-medium";
          } else if (column.field === 'last') {
            if (helpers.isLessThan100(row.last)) {
              return "background_cyan_200 text-400-medium";
            } else {
              return "background_deep_purple_300 text-400-medium";
            }
          }
        },
        headerCellClass: ({column, rowIndex}) => {
          if (column.field === 'product_name') {
            return "background_blue_100 good-width";
          } else if (column.field === 'sku') {
            return "background_blue_100 sku-width";
          } else if (column.title === 'Đầu kỳ' || column.field === 'begin') {
            return "background_a400 text-400-medium";
          } else if (column.field === 'totalBuy') {
            return "background_o300 text-400-medium";
          } else if (column.field === 'totalSell') {
            return "background_yellow_a200 text-400-medium";
          } else if (column.title === 'Cuối kỳ' || column.field === 'last') {
            return "background_deep_purple_300 text-400-medium";
          } else {
            return "background_blue_100";
          }
        },
      },
      cellStyleOption: {
        bodyCellClass: ({row, column, rowIndex}) => {
          if (column.field === 'begin') {
            return "background_a400 text-400-medium";
          } else if (column.field === 'totalBuy') {
            return "background_o300 text-400-medium";
          } else if (column.field === 'totalSell') {
            return "background_yellow_a200 text-400-medium";
          } else if (column.field === 'last') {
            if (helpers.isLessThan100(row.last)) {
              return "background_cyan_200 text-400-medium";
            } else {
              return "background_deep_purple_300 text-400-medium";
            }
          }
        },
        headerCellClass: ({column, rowIndex}) => {
          if (column.title === 'Đầu kỳ' || column.field === 'begin') {
            return "background_a400 text-400-medium";
          } else if (column.field === 'totalBuy') {
            return "background_o300 text-400-medium";
          } else if (column.field === 'totalSell') {
            return "background_yellow_a200 text-400-medium";
          } else if (column.title === 'Cuối kỳ' || column.field === 'last') {
            return "background_deep_purple_300 text-400-medium";
          } else {
            return "background_blue_100";
          }
        },
      },
      cellSpanOption: {
        bodyCellSpan: ({row, column, rowIndex}) => {
          if (rowIndex === 0) {
            if (column.field === "inventory_name") {
              return {
                colspan: 4,
                rowspan: 1,
              };
            } else if (column.field === "sku") {
              return {
                colspan: 4,
                rowspan: 1,
              };
            } else if (column.field === "product_name") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            } else if (column.field === "value") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            } else if (column.field === "dvt") {
              return {
                colspan: 0,
                rowspan: 0,
              };
            }

          }
        },
      },
      isClickFilter: false
    };
  },
  created() {
    let today = moment().format('YYYY-MM-DD')
    let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    this.totalStockParams.start_date = firstDateOfMonth;
    this.totalStockParams.end_date = today;
    if(Object.keys(this.$route.query).length > 0){
      let filters = {
        end_date: this.$route.query.end_date ?? '',
        start_date: this.$route.query.start_date ?? '',
      };
      this.handleFilterChange(filters);
    }
  },
  props: {},
  watch: {
    '$route.params.inventory_id': function (newVal) {
      console.log(1);
      this.inventoryId = newVal;
      this.totalStockParams.inventory_id = newVal;
      this.totalStockParams.product_id = [];
      let today = moment().format('YYYY-MM-DD')
      let firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      this.totalStockParams.start_date = firstDateOfMonth;
      this.totalStockParams.end_date = today;
      this.resetData();
      // setTimeout(() => {
      //   this.getReportTotalStock();
      // }, 1000);
    },
    'totalStockData': function (newVal) {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
      this.pagingParams.total = this.totalStockData.length;
      this.changeDataInPage();
    },
    'isLoad': function (newVal) {
      this.$emit('change-is-load', newVal);
    },
    // '$store.state.auth.tabActive'(newV) {
    //   if(newV == INVENTORY_REPORT_TAB.TOTAL_STOCK){
    //     console.log(1);
    //     this.getReportTotalStock();
    //   }
    // },
    '$store.state.category.reloadOnListProduct': function (newVal, oldVal) {
      if(newVal === 1){
        this.getReportTotalStock(124);
        // reset
        this.$store.commit("updateReloadOnListProduct", 0);
      }
    }
  },
  mounted() {
    // this.getReportTotalStock();
  },
  methods: {
    async getReportTotalStock(count = 0) {
      if(this.$route.query.tab && this.$route.query.tab != this.tabActive){
        return;
      }
      this.isLoad = true;
      this.totalStockData = [];
      this.totalStockOfMainInventory = [];
      if (this.axiosSource) {
        this.axiosSource.cancel('Canceled by user');
      }

      this.axiosSource = axios.CancelToken.source();
      let params = {...this.totalStockParams};
      params.cancelToken = this.axiosSource.token;

      InventoryService.getReportTotalStock(params).then(res => {
        if (res) {
          this.inventoryList = res.data;
          if (this.inventoryList.length > 1) {
            this.isInventoryParent = true;
          }
          for (let i = 0; i < this.inventoryList.length; i++) {
            let firstRow = {...this.inventoryList[i]};
            delete firstRow.products;
            firstRow.sku = "Tổng";

            for (let key in firstRow) {
              if (i > 0) firstRow.order = i;
              let value = parseFloat(firstRow[key]);
              if (!isNaN(value) && !this.stringDataRowKeyList.includes(key)) {
                firstRow[key] = value.toLocaleString();
              }
            }

            this.totalStockOfMainInventory.push(firstRow);
            this.getTitleHeading();
            const productList = this.inventoryList[i].products;

            for (let i = 0; i < productList.length; i++) {
              let row = {...productList[i]};
              row.inventory_name = firstRow.inventory_name;
              for (let key in row) {
                let value = parseFloat(row[key]);
                if (!isNaN(value) && !this.stringDataRowKeyList.includes(key)) {
                  row[key] = value.toLocaleString();
                }
              }
              this.totalStockData.push(row);
            }
          }
        }
        this.isLoad = false;
      }).catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        count++;
        if (count < 3) {
          setTimeout(() => {
            this.getReportTotalStock(count)
          }, 1000);
        }
      });
    },
    getReportSubTotalStock(row) {
      this.showSubInventory = true;
      this.titleSubHeading = 'Chi tiết ' + this.inventoryList[row]['inventory_name'];
      this.inventorySubId = this.inventoryList[row].inventory_id;

      this.totalStockData = []
      const productList = this.inventoryList[row].products;
      for (let i = 0; i < productList.length; i++) {
        let row = {...productList[i]};
        for (let key in row) {
          let value = parseFloat(row[key]);
          if (!isNaN(value) && !this.stringDataRowKeyList.includes(key)) {
            row[key] = value.toLocaleString();
          }
        }
        this.totalStockData.push(row);
      }
    },

    handleFilterChange(filters) {
      // if (filters.tab === INVENTORY_REPORT_TAB.TOTAL_STOCK) {
      this.totalStockParams.end_date = filters.end_date
      this.totalStockParams.start_date = filters.start_date
      this.totalStockParams.product_id = filters.product_id
      this.totalStockParams.good_id = filters.good_id
      this.getReportTotalStock();
      this.isClickFilter = true;
      // }
    },

    dowloadExcel(subInventory = false) {
      const inventoryId = subInventory ? this.inventorySubId : this.inventoryId;
      let baseUrl = process.env.VUE_APP_API_INVENTORY_BASE_URL + '/v1';
      let url = `${baseUrl}/inventory/${inventoryId}/report/total-stock/export-excel?${helpers.convertObjToQueryUrl(
          this.totalStockParams
      )}`;
      window.open(url, '_blank');
    },
    changePagingParam(value) {
      this.pagingParams = value;
      this.changeDataInPage();
    },
    changeDataInPage() {
      this.totalStockInPage = [];
      this.totalStockInPage = this.isInventoryParent ? [] : this.totalStockInPage.concat(this.totalStockOfMainInventory);
      let quotient = Math.floor(this.pagingParams.total / this.pagingParams.perPage);
      if (this.pagingParams.total / this.pagingParams.perPage > quotient) {
        quotient += 1;
      }
      this.pagingParams.lastPage = quotient;
      let startIndex = (this.pagingParams.page - 1) * this.pagingParams.perPage;
      let endIndex = startIndex + this.pagingParams.perPage;
      for (let i = startIndex; i < endIndex && i < this.totalStockData.length; i++) {
        if (this.totalStockData[i]) {
          this.totalStockInPage.push(this.totalStockData[i]);
        }
      }
    },
    resetData() {
      this.titleHeading = '';
      this.titleSubHeading = '';
      this.isInventoryParent = false;
      this.showSubInventory = false;
      this.inventoryList = [];
      this.totalStockData = [];
      this.totalStockInPage = [];
      this.totalStockOfMainInventory = [];
      this.isClickFilter = false;
    },
    getTitleHeading() {
      this.titleHeading = 'Tổng hợp ' + this.totalStockOfMainInventory[0]['inventory_name']
    },
    sortChange(params) {
      this.pagingParams.page = 1;
      this.totalStockData.sort((a, b) => {
        for (const property in params) {
          let props = property.split('.');
          const reg = /[,.]/g
          let ap = a[property];
          let bp = b[property];
          if (props.length === 2) {
            ap = a[props[0]][props[1]].toString();
            bp = b[props[0]][props[1]].toString();
          }
          if (params[property] === 'asc') {
            return ap.replaceAll(reg, '') - bp.replaceAll(reg, '');
          } else if (params[property] === 'desc'){
            return bp.replaceAll(reg, '') - ap.replaceAll(reg, '');
          }
        }
        return 0;
      });
      this.changeDataInPage();
    },
  }
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }

  .main-inventory {
    font-weight: bold !important;
    color: black !important;

    td {
      font-weight: 700 !important;
    }
  }

}
</style>