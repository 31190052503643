<template>
  <div>
    <modal name="create-category-modal"
           :click-to-close="false"
           width="580px"
           height="auto"
           class="create-category-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >
      <vue-element-loading :active="show" spinner="bar-fade-scale"/>
      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Tạo hạng mục</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('create-category-modal')">close</span>
        </md-dialog-title>
      </md-card>
      <md-card-content class="content" :style="{'height': form.parent_id == 0 ? '400px' :'150px'}">
        <!--          <md-list-item class="title">-->
        <!--              <md-avatar>-->
        <!--                <div class="md-layout-item item item-icon cursor-pointer"-->
        <!--                     :style="{'background-color': form.color }">-->
        <!--                  <div class="md-alignment-center">-->
        <!--                    <span class="material-icons">{{ form.icon }}</span>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </md-avatar>-->

        <!--              <div class="md-list-item-text">-->
        <!--                <md-field>-->
        <!--                  <label class="required heading-400 neutral_3">Tên hạng mục</label>-->
        <!--                  <md-input v-model="form.title" required></md-input>-->
        <!--                </md-field>-->
        <!--              </div>-->
        <!--          </md-list-item>-->
        <div>
          <label class="required heading-400 neutral_3">Tên hạng mục</label>
          <input type="text" placeholder="Nhập tên hạng mục" required class="form-control form-info"
                 v-model="form.title">
        </div>

        <template v-if="form.parent_id == 0">
          <p class="required heading-400 neutral_3">Màu sắc</p>
          <div class="md-layout md-gutter">
            <div class="md-layout-item item item-color cursor-pointer" v-for="(color, index) in COLOR" :key="index"
                 @click="chosenColor(color)"
                 :style="{'background-color': color }">
              <div class="md-alignment-center" v-if=" form.color == color" :class="{'active': form.color == color}">
                <div class="dot"></div>
              </div>
            </div>
          </div>

          <p class="required heading-400 neutral_3">Icon</p>
          <div class="md-layout md-gutter">
            <div class="md-layout-item item item-icon cursor-pointer" v-for="(icon, index) in ICON" :key="index"
                 @click="chosenIcon(icon)"
                 :class="{'icon-active': form.icon == icon}">
              <div class="md-alignment-center">
                <span class="material-icons">{{ icon }}</span>
              </div>
            </div>
          </div>
        </template>
      </md-card-content>
      <md-dialog-actions>
        <button :disabled="form.title == ''" class="btn-active md-button" @click="save()">Tạo hạng mục</button>
        <button class="btn-stroke md-button" @click="$modal.hide('create-category-modal')">Huỷ</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>

import GroupService from "../../../store/services/GroupService";
import CategoryService from "../../../store/services/CategoryService";
import {helpers} from "../../../helper/helpers";

const COLOR = [
  '#000000',
  '#a42727',
  '#7d5e5e',
  '#572121',
  '#b99191',
  '#a4833d',
  '#55af42',
  '#265593',
  '#8d46bd',
  '#b63386',
  '#286fd9',
  '#bd5846',
  '#46bd93',
]

const ICON = [
  'star',
  'recycling',
  'drive_folder_upload',
  'screen_search_desktop',
  'browser_updated',
  'favorite',
  'public',
  'rocket',
  'celebration',
  'verified',
  'support',
  'wb_sunny',
  'join_full',
  'sports_soccer',
  'spa'
]

export default {
  name: 'create-category-modal',
  data: () => ({
    COLOR,
    ICON,
    form: {
      "title": '',
      "color": '#000000',
      "icon": 'star',
      "parent_id": 0,
      "slug": ''
    },
    show: false,
    categoryParent: null,
  }),
  mounted() {

  },

  methods: {
    beforeOpened(event) {
      if (event.params.categoryParent != null) {
        this.categoryParent = event.params.categoryParent;
        this.form.parent_id = this.categoryParent.id;
        this.form.color = this.categoryParent.color??'#000000';
        this.form.icon = this.categoryParent.icon??'star';
        // this.$store.commit("updateModalIsOpen", true);
      }
    },

    beforeClosed(event) {
      this.categoryParent = null;
      this.form.parent_id = 0;
      this.form.color = '#000000';
      this.form.icon = 'star';
      this.form.title = '';
      // this.$store.commit("updateModalIsOpen", false);
    },

    save() {
      this.form.slug = helpers.createSlug(this.form.title)
      this.createCategory(this.form);
      this.show = true;
    },

    async createCategory(data) {
      await CategoryService.createCategory(data).then(res => {
        this.show = false;
        if (res && res.status) {
          data.id = res.data.category_id
          this.$store.dispatch("alerts/success", 'Bạn đã tạo hạng mục thành công.');
          this.$modal.hide('create-category-modal');

          // if (this.form.parent_id == 0) {
          this.$store.commit("updateCategory", data);
          this.$store.dispatch("getCategories");
          localStorage.setItem("create_category", JSON.stringify(data))
          window.dispatchEvent(new Event('storage'))
          this.$router.push("/category/" + data.id + "/?type=edit");
          // } else {
          //   this.$store.commit("categoryState/updateSubCategory", data);
          //   this.$router.push("/sub_category/" + data.id + "/?type=create");
          // }
        } else {
          let html = ''
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              html += '<p>' + res.data[i][0] + '</p>';
            }
          }
          this.$store.dispatch("alerts/error", 'Bạn tạo hạng mục không thành công. Nguyên nhân : ' + res.message + html);
        }
      });
    },

    chosenColor(color) {
      this.form.color = color;
    },

    chosenIcon(icon) {
      this.form.icon = icon;
    },

  },
}
</script>

<style lang="scss">
.create-category-modal {
  .md-gutter {
    margin: auto !important;

    .item {
      min-width: 44px;
      max-width: 44px;
      min-height: 44px;
      max-height: 44px;
      height: 44px;
      width: 44px;
      border-radius: 4px;
      margin: 5px;
      padding-right: 15px;
      padding-left: 15px;
    }

    .item-color {
      .dot {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background: rgb(255, 255, 255);
        margin-top: 13px;
      }
    }

    .item-icon {
      background-color: rgb(242, 243, 245);

      .material-icons {
        margin-left: -5px;
        margin-top: 10px;
        color: #697fe7;
      }
    }

    .icon-active {
      background-color: #ffd700;
      opacity: 1;
      box-shadow: 0px 0px 5px 0 rgb(0 0 0 / 14%);
    }
  }

  .title {
    .md-list-item-content {
      padding: 0px;
    }
  }

  //.active {
  //  bo
  //}
}

</style>
