<template>
  <div class="show-inventory-table-modal__wrapper">
    <modal name="show-inventory-table-modal"
           :click-to-close="false"
           width='100%'
           height="100%"
           class="modal-custom show-inventory-table-modal"
           transition="easeInOut"
           @before-open="beforeOpen"
           @before-close="beforeClosed"
    >

      <md-card-content>
        <div class="mb-20px d-flex-space-between">
          <div class="heading-600">Chi tiết dữ liệu</div>
          <div class="d-flex-center">
            <div class="export-excel-btn" v-show="!hiddenExportBtn"
                 @click="$emit('export')">
              <md-icon>file_download</md-icon>
              <span>
                Xuất Excel
              </span>
            </div>
            <div class="ml-10px zoom-in-btn"
                 @click="$modal.hide('show-inventory-table-modal')">
              <md-icon>zoom_in_map</md-icon>
            </div>
          </div>
        </div>
        <div class="d-flex-end">
          <div class="float-right">
            <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam" />
          </div>
        </div>
        <div class="inventory-table">
          <inventory-table border-y :columns="totalStockColumns" :table-data="totalStockData" :cell-style-option="cellStyleOption"/>
        </div>
      </md-card-content>
    </modal>
  </div>
</template>

<script>

import InventoryTable from "@/pages/Workplace/Pages/Inventory/Table";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";

export default {
  name: 'show-inventory-table-modal',
  components: {InventoryTable, PaginationTemplate},
  computed: {
  },

  created() {
  },
  data: () => ({

  }),

  props: {
    totalStockColumns: {
      type: Array,
      default: () => [],
    },
    totalStockData: {
      type: Array,
      default: () => [],
    },
    pagingParams: {
      type: Object,
      default: () => {},
    },
    hiddenExportBtn: {
      type: Boolean,
      default: false,
    },
    cellStyleOption: {
      type: Object,
      default: () => {},
    }
  },

  methods: {
    beforeOpen(event) {
      // console.log(event)
      // this.totalStockColumns = event.params.totalStockColumns;
      // this.totalStockData = event.params.totalStockData;
    },
    beforeClosed(event){
    },
    changePagingParam(value) {
      this.$emit('change-paging-params', value);
    },
  },
}
</script>

<style lang="scss">
.show-inventory-table-modal__wrapper {
  .inventory-table {
    height: calc(100vh - 100px);
    overflow: auto;
  }
}

</style>
