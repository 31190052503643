import BaseService from "./BaseService";

export default class GroupService extends BaseService {

    static async getGroups(params = {}) {
        const res = await this.get(`/groups`, params);
        return res.data;
    }

    static async createGroup(params = {}) {
        const res = await this.post(`/groups`, params);
        return res.data;
    }

    static async getGroup(params = {}) {
        const res = await this.get(`/groups/` + params.groupId, {});
        return res.data;
    }

    static async outGroup(params = {}) {
        const res = await this.post(`/groups/` + params.groupId + `/leave`, {});
        return res;
    }

    static async addMembers(params = {}) {
        const res = await this.post(`/groups/` + params.groupId + `/add-members`, {user_ids: params.members});
        return res;
    }

    static async getFiles(params = {}) {
        const res = await this.get(`/groups/` + params.groupId + `/files`, params);
        return res.data;
    }

    static async getWorkProcess(params = {}) {
        const res = await this.get(`/groups/` + params.groupId + `/work_process`, {});
        return res.data;
    }

    static async updateWallpaper(params = {}) {
        let groupId = params.get("groupId");
        params.delete("groupId");
        const res = await this.post(`/groups/` + groupId + `/wallpaper`, params);
        return res.data;
    }

    static async getMembers(params = {}) {
        const res = await this.get(`/groups/` + params.groupId + `/members`, params);
        return res.data;
    }

    static async pinGroup(params = {}) {
        const res = await this.post(`/groups/` + params.groupId + `/pin`, params);
        return res.data;
    }

    static async requestMember(params = {}) {
        const res = await this.post(`/groups/` + params.groupId + `/request-member`, params);
        return res.data;
    }

    static async updateGroup(groupId, params = {}) {
        const res = await this.post(`/groups/` + groupId, params);
        return res.data;
    }

    static async approveJoinGroupRequest(groupId, userId, params = {}) {
        const res = await this.post(`/groups/` + groupId + `/accept-member/` + userId, params);
        return res.data;
    }

    static async removeMember(groupId, userId) {
        const res = await this.delete(`/groups/` + groupId + `/members/` + userId);
        return res.data;
    }

    static async handleAdminRole(groupId, userId, params = {}) {
        const res = await this.post(`/groups/` + groupId + `/admin/` + userId, params);
        return res.data;
    }

    static async getGroupOverview(params = {}) {
        const res = await this.get(`/groups/overview`, params);
        return res.data;
    }

    static async toggleNotification(groupId, params = {}) {
        const res = await this.post(`/groups/` + groupId + `/off-notification`, params);
        return res.data;
    }

    static async createChatSocical(groupId, params = {}) {
        const res = await this.post(`/chat-group/` + groupId, params);
        return res.data;
    }

    static async getLinkMetaDataChatGroup(params) {
        const res = await this.get(`/chat-group/${params.group_id}/get-link-metadata`, params);
        return res.data;
    }

    static async getFileChatGroup(params) {
        const res = await this.get(`/chat-group/${params.group_id}/files`, params);
        return res.data;
    }
}

