<template>
  <ve-table border-y
            row-key-field-name="rowKey"
            :columns="columns"
            :table-data="tableData"
            :event-custom-option="eventCustomOption"
            :sort-option="sortOption"
            :cell-style-option="cellStyleOption"
            :checkbox-option="checkboxOption"
            :cell-span-option="cellSpanOption"
  />
</template>

<script>
export default {
  name: "InventoryTable",
  components: {},
  data() {
    return {
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    eventCustomOption: {
      type: Object,
      default: () => {},
    },
    sortOption: {
      type: Object,
      default: () => {},
    },
    cellStyleOption: {
      type: Object,
      default: () => {},
    },
    checkboxOption: {
      type: Object,
      default: () => {},
    },
    cellSpanOption: {
      type: Object,
      default: () => {},
    }
  },
  methods: {}
}
</script>

<style lang="scss">

</style>