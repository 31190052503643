<template>
  <div>
    <modal name="errors-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom errors-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500 text-header-modal">Danh sách lỗi</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('errors-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content class="container-table">
        <table>
          <thead>
            <tr>
              <th class="stt">STT</th>
              <th>Lỗi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(error, index) in errors" v-bind:key="index">
              <td class="stt">{{ index + 1 }}</td>
              <td>{{ error }}</td>
            </tr>
          </tbody>
        </table>
      </md-card-content>
      <md-dialog-actions>
        <button type="submit" class="btn-active md-button heading-300" @click="$modal.hide('errors-modal')">Hủy</button>
      </md-dialog-actions>

    </modal>
  </div>
</template>

<script>

export default {
  name: 'errors-modal',
  computed: {},
  mounted() {
  },
  data() {
    return {
      errors: []
    }
  },
  methods: {
    beforeOpened(event) {
      this.errors = event.params.errors ?? [];
    },

    beforeClosed(event) {
    },

  }

}
</script>

<style lang="scss">
.errors-modal{
  .container-table{
    min-height: 300px;
  }
  table {
    .stt{
      width: 40px;
      text-align: center;
    }
    border-collapse: collapse;
    width: 100%;
    thead {
      background: #D8DAE5;
    }
    
    tbody {
      height: 500px;
      min-height: 300px;
      overflow-y: auto;
      display: block;
      overflow-x: hidden;
    }
    tr{
      display: table;
      width: 100%;
    }
    th, td {
      border: 1px solid #cfc9c9;
      padding: 10px 5px;
    }
  }
}
</style>
