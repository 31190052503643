<template>
  <div>
    <vue-element-loading spinner="bar-fade-scale" :active="isLoad"/>
    <inventory-filter
        @change="handleFilterChange"
        :pick-type="[INVENTORY_TYPE_OPTIONS.SKU]"
        :tab-active="tabActive"
        :only-show-date="true"
        :date-picker="true"
        :time-only-show-month="true"
        :id-filter-date="'inventory-by-month'"
    ></inventory-filter>
    <template v-if="couponSyncHistories.length == 0  && !isClickFilter">
      <p class="text-center">Chọn điều kiện trong bộ lọc và click và Tạo báo cáo</p>
    </template>
    <template v-else>
      <div class="mb-20px d-flex-space-between">
        <div class="heading-600">{{ getHeading() }}</div>
        <div class="d-flex-center">
          <div class="export-excel-btn mr-10px"
              @click="dowloadExcel()">
            <md-icon>file_download</md-icon>
            <span> Xuất Excel</span>
          </div>
          <div class="export-excel-btn" @click="cacheOpeningStoke">
            <md-icon>lock</md-icon>
            <span>
              Chốt dữ liệu kho
            </span>
          </div>
        </div>
      </div>
      <div class="w_100">
        <div class="float-right">
          <pagination-template :class="'mb-10px float-right'" :params="pagingParams" @change-params="changePagingParam"/>
        </div>
      </div>
      <inventory-table border-y :columns="couponSyncHistoryHeader" :table-data="couponSyncHistories"
                      :cell-style-option="cellStyleOption"/>
    </template>
  </div>
</template>

<script>
import {
  INVENTORY_COUPON_TYPE,
  INVENTORY_REPORT_TAB,
  INVENTORY_TYPE_OPTIONS,
  SETTING_TAB
} from "../../../../../const/const";
import moment from "moment";
import InventoryService from "@/store/services/InventoryService";
import {helpers} from "@/helper/helpers";
import InventoryTable from "@/pages/Workplace/Pages/Inventory/Table";
import InventoryFilter from "@/pages/Workplace/Pages/Inventory/Filter";
import PaginationTemplate from "@/pages/Components/PaginationTemplate";
import ErrorsModal from "@/pages/Components/Modal/ErrorsModal";

export default {
  name: 'InventoryInfo',
  components: {
    InventoryTable,
    InventoryFilter,
    PaginationTemplate,
  },
  props: {},
  data() {
    return {
      SETTING_TAB,
      INVENTORY_TYPE_OPTIONS,
      INVENTORY_COUPON_TYPE,
      isLoad: false,
      tabActive: SETTING_TAB.INVENTORY_INFO,
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
        inventory_id: this.$route.params.inventory_id,
      },
      filterParams: {
        date: moment().startOf('month').format('YYYY-MM-DD'),
        inventory_id: this.$route.params.inventory_id,
      },
      couponSyncHistories: [],
      couponSyncHistoryHeader: [
        {
          field: "stt", key: "sync_history_1", title: "STT", align: "center",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return ++rowIndex;
          },
        },
        {field: "product_sku", key: "sync_history_2", title: "SKU", align: "left"},
        {field: "product_name", key: "sync_history_3", title: "Sản phẩm", align: "left"},
        {field: "product_variant", key: "sync_history_4", title: "Biến thể", align: "left"},
        {field: "product_dvt", key: "sync_history_5", title: "Đơn vị", align: "left"},
        {
          field: "quantity",
          key: "sync_history_6",
          title: "Số lượng",
          align: "right",
          renderBodyCell: ({row, column, rowIndex}, h) => {
            return helpers.convertStringToInt(row.quantity).toLocaleString();
          }
        },
      ],
      isClickFilter: false
    };
  },
  mounted() {
    // this.getReportTotalStockByMonth();
  },
  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.filterParams.date = moment().startOf('month').format('YYYY-MM-DD');
      this.filterParams.inventory_id = this.$route.params.inventory_id;
      this.isClickFilter = false;
      // this.getReportTotalStockByMonth();
    },
    // '$store.state.auth.tabActive'(newV) {
    //   if(newV == INVENTORY_REPORT_TAB.INVENTORY_INFO){
    //     this.getReportTotalStockByMonth();
    //   }
    // },
    '$store.state.category.reloadOnListProduct': function (newVal) {
      if(newVal === 1){
        this.getReportTotalStockByMonth();
        // reset
        this.$store.commit("updateReloadOnListProduct", 0);
      }
    }
  },
  methods: {
    refreshPagingParams() {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
        inventory_id: this.$route.params.inventory_id,
      };
    },
    handleFilterChange(filters) {
      this.refreshPagingParams();
      this.filterParams.date = filters.date
      this.getReportTotalStockByMonth();
      this.isClickFilter = true;
    },
    getReportTotalStockByMonth() {
      if(this.$route.query.tab && this.$route.query.tab != this.tabActive){
        return;
      }
      this.couponSyncHistories = [];
      this.isLoad = true;
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      let params = {...this.filterParams, ...paging};

      InventoryService.getReportTotalStockByMonth(params).then(res => {
        if (res) {
          this.pagingParams.lastPage = res.meta.last_page;
          this.pagingParams.total = res.meta.total;
          this.couponSyncHistories = res.data;
        }
        this.isLoad = false;
      });
    },
    changePagingParam(value) {
      this.pagingParams = value;
      this.getReportTotalStockByMonth();
    },
    cacheOpeningStoke() {
      this.$modal.show('inventory-by-month-modal')
    },
    getHeading() {
      let date = this.filterParams.date;
      if(this.$route.query.dateMonth){
        date = this.$route.query.dateMonth;
      }
      return 'Tồn kho ' + (moment(date).format('DD/MM'));
    },
    dowloadExcel(subInventory = false) {
      const inventoryId = this.pagingParams.inventory_id;
      this.pagingParams.user_id = this.$store.state.auth.user.id;
      let baseUrl = process.env.VUE_APP_API_INVENTORY_BASE_URL + '/v1';
      let url = `${baseUrl}/inventory/${inventoryId}/report/total-stock-by-month/export-excel?${helpers.convertObjToQueryUrl(
          this.pagingParams
      )}`;
      window.open(url, '_blank');
    },
  },
}
</script>

<style lang="scss">
.inventory-content__container {
  .velmld-full-screen {
    background-color: initial !important;
  }

  .show-detail {
    font-size: 20px;
    vertical-align: inherit;

    &:hover {
      cursor: pointer;
      color: red;
    }
  }

}
</style>