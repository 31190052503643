<template>
  <div class="new-filter-container mb-20px">
    <div class="display-flex" style="flex-wrap: wrap;">
      <div class="d-flex-center mb-10px">
        <div style="margin-left: auto;">
          <input class="input-date mr-15px" style="width: 100px" type="text" v-model="internalCode" placeholder=" Mã ĐHNB"/>
        </div>
        <div class="mr-10px">
          <multiselect v-model="shippingMethod.value" :options="shippingMethod.list" class="shipping-method-multiselect"
                       :searchable="false"
                       :close-on-select="true"
                       :show-labels="false"
                       placeholder="PTVC"
                       :class="'multiselect-left'"
          >
          </multiselect>
        </div>
        <div class="mr-10px">
          <multiselect v-model="fileSync.value" :options="fileSync.list" class="file-sync-multiselect"
                       track-by="id"
                       label="file_name"
                       :searchable="false"
                       :close-on-select="true"
                       placeholder="File ĐB"
                       :class="'multiselect-left'"
          >
            <template slot="option" slot-scope="{ option }">
              <md-list-item>
                <div class="md-list-item-text">
                  <span>{{ option.file_name }}</span>
                  <p>{{convertDate(option.created_date)}}</p>
                </div>
              </md-list-item>
            </template>
            <template slot="afterList">
              <div v-observe-visibility="rea" v-if="hasNextPageForFileSync"/>
            </template>
            <span slot="noResult">Chưa có dữ liệu!</span>
          </multiselect>
        </div>
      </div>
      <div>
        <input v-bind:id="`${idFilterDate}`" class="input-date mr-15px" type="text" name="daterange"/>
        <md-button class="btn-filter text-400-default" @click="onFilter">Tạo báo cáo</md-button>
      </div>
    </div>
  </div>
</template>

<script>

import {DATE_RANGE_PICKER_CONFIG, INVENTORY_REPORT_TAB, INVENTORY_TYPE_OPTIONS} from "../../../../const/const";
import InventoryService from "../../../../store/services/InventoryService";
import moment from "moment";

export default {
  name: "new-filter",
  props: {
    idFilterDate: {
      type: String,
      default: 'filter_date'
    },
  },
  data() {
    return {
      internalCode: '',
      shippingMethod: {
        value: null,
        list: ['SEA', 'AIR', 'LOCAL']
      },
      fileSync: {
        value: null,
        list: []
      },
      fileSyncPagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
      }
    };
  },

  computed: {
    hasNextPageForFileSync() {
      return this.fileSyncPagingParams.page < this.fileSyncPagingParams.lastPage;
    },
  },
  watch: {
    '$route.params.inventory_id': function (newVal) {
      this.refreshDateFilter();
    },
  },
  mounted() {
  },
  created() {
    this.refreshDateFilter();
    this.getCouponSyncHistory();
  },
  methods: {
    //Filter - update url
    emitFilters() {
      let idFilterDate = '#' + this.idFilterDate;
      let startDate = $(idFilterDate).data('daterangepicker').startDate.format('YYYY-MM-DD');
      let endDate = $(idFilterDate).data('daterangepicker').endDate.format('YYYY-MM-DD');
      let paramChanged = {
        start_date: startDate,
        end_date: endDate,
        internal_code: this.internalCode,
        shipping_method: this.shippingMethod.value ?? '',
        sync_history_id: this.fileSync.value ? this.fileSync.value.id : '',
      };

      this.$emit('change', paramChanged);
    },
    onFilter() {
      this.emitFilters();
    },

    refreshDateFilter() {
      this.internalCode = '';
      this.shippingMethod.value = null;
      this.fileSync.value = null;
      let dateRangePickerConfig = {...DATE_RANGE_PICKER_CONFIG};
      $(function () {
        let today = moment().format('DD/MM/YYYY')
        let firstDateOfMonth = moment().startOf('month').format('DD/MM/YYYY');
        dateRangePickerConfig.startDate = firstDateOfMonth;
        dateRangePickerConfig.endDate = today;
        $('input[name="daterange"]').daterangepicker(dateRangePickerConfig);
      });
    },

    getCouponSyncHistory() {
      this.couponSyncHistories = [];
      this.isLoad = true;
      let paging = {
        page: this.fileSyncPagingParams.page,
        per_page: this.fileSyncPagingParams.perPage,
        limit: this.fileSyncPagingParams.perPage
      };

      InventoryService.getCouponSyncHistory(paging).then(res => {
        if (res) {
          this.fileSyncPagingParams.lastPage = res.last_page;
          this.fileSync.list = this.fileSync.list.concat(res.data);
        }
        this.isLoad = false;
      });
    },
    reachedEndOfListForFileSync(reached) {
      if (reached) {
        this.fileSyncPagingParams.page++;
        this.getCouponSyncHistory();
      }
    },
    convertDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm:ss')
    }
  }
}
</script>

<style lang="scss">
.new-filter-container {
  padding: 15px;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  font-weight: 500;

  .input-date {
    height: 40px;
    border: 1px solid #D8DAE5;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
    margin: 0;
  }

  .btn-filter {
    flex-direction: row;
    justify-content: center;
    padding: 0 10px;
    height: 36px;
    background: #4D77EC !important;
    border-radius: 4px;
    margin: 0px;
  }

  .multiselect-left {
    width: 140px;

    .multiselect__tags {
      border-radius: 4px 0 0 4px !important;

    }
  }


  .multiselect__content-wrapper {
    .multiselect__option {
      display: flex;
      align-items: center;
      padding: 10px !important;
    }
  }
  .shipping-method-multiselect {
    width: 140px;
  }
  .file-sync-multiselect {
    width: 170px;
    .multiselect__single {
      width: 215px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .action-column {
    overflow: initial !important;
    position: relative;
  }
}
</style>